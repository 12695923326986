import React, { useCallback } from 'react';
import classnames from 'classnames';
import TurtleGlyph from 'ui/components/svg/TurtleGlyph';
import styles from './Rating.module.scss';
import { MAX_DIFFICULTY_RATING, MAX_SOLUTION_RATING } from 'ui/constants';
import { IconComponent } from 'types/icon';
import Star from 'ui/components/svg/Star';

interface DefaultProps {
  value: number;
  readonly?: boolean;
  iconClass?: string;
  className?: string;
  onChange?: (value: number) => void;
}

interface Props extends DefaultProps {
  icon?: 'turtle' | 'star';
  range: number;
}

const CommonRating: React.FC<Props> = ({ range, value, iconClass, icon = 'turtle', readonly, className, onChange }) => {
  const renderIconList = useCallback(() => {
    return new Array(Math.max(range, value)).fill(null).map((v, i) => {
      const n = i + 1;
      const classNames = classnames(iconClass, styles.icon, {
        [styles.turtle]: icon === 'turtle',
        [styles.star]: icon === 'star',
        [styles.filled]: n <= value,
        [styles.stroked]: n > value,
        [styles.bonus]: n > range,
        [styles.editable]: !readonly,
      });
      const Icon: IconComponent = icon === 'turtle' ? TurtleGlyph : Star;
      const handleIconClick = (): void => {
        if (onChange) onChange(n);
      };
      return <Icon key={i} data-value={n} className={classNames} onClick={!readonly ? handleIconClick : undefined} />;
    });
  }, [range, value, readonly, iconClass, icon]);

  return <div className={classnames(styles.container, className)}>{renderIconList()}</div>;
};

export const DifficultyRating: React.FC<DefaultProps> = (props) => (
  <CommonRating range={MAX_DIFFICULTY_RATING} icon="turtle" {...props} />
);

export const SolutionRating: React.FC<DefaultProps> = (props) => (
  <CommonRating range={MAX_SOLUTION_RATING} icon="star" {...props} />
);
