import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Turtle: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M16.26855,13.85938C14.91992,13.28906,13.48438,13,12,13C5.93457,13,1,17.93457,1,24 c0,1.23145,0.21289,2.45996,0.63184,3.64941c0.11719,0.33301,0.40137,0.5791,0.74707,0.64844 c0.06543,0.0127,0.13086,0.01953,0.19629,0.01953c0.28027,0,0.55176-0.11816,0.74316-0.33105C5.02832,26.08887,7.46387,25,10,25 l0.25879,0.01074c0.5791-0.00586,0.96387-0.42969,0.97559-0.98145c0.00671-0.34485-0.19031-0.63873-0.4657-0.82458l4.58215-7.55402 c0.04565,0.01843,0.09302,0.03131,0.13843,0.05048c0.50684,0.21484,1.0957-0.02344,1.31055-0.53125 C17.01465,14.66113,16.77734,14.07422,16.26855,13.85938z"
        className={styles.white}
      />
      <path
        d="M36,13c-1.48438,0-2.91992,0.28906-4.26855,0.85938c-0.50879,0.21484-0.74609,0.80176-0.53125,1.31055 s0.80371,0.74609,1.31055,0.53125c0.04541-0.01917,0.09277-0.03204,0.13843-0.05048l4.56073,7.5188 c-0.29395,0.1795-0.49701,0.49316-0.4892,0.86273c0.01172,0.54492,0.45703,0.97852,1,0.97852c0.00684,0,0.01367,0,0.02148,0L38,25 c2.53613,0,4.97168,1.08887,6.68164,2.98633c0.19141,0.21289,0.46289,0.33105,0.74316,0.33105 c0.06543,0,0.13086-0.00684,0.19629-0.01953c0.3457-0.06934,0.62988-0.31543,0.74707-0.64844C46.78711,26.45996,47,25.23145,47,24 C47,17.93457,42.06543,13,36,13z"
        className={styles.white}
      />
      <path
        d="M14.39258,39.14551c-0.0296,0.00323-0.16107,0.01807-0.35864,0.04449l-2.75336-5.65985 c0.47498-0.23224,0.69598-0.7923,0.48602-1.28406c-0.21777-0.50977-0.80859-0.74414-1.3125-0.52734 C7.14062,33.13281,5,36.38281,5,40c0,1.02344,0.19629,2.07324,0.58496,3.12109c0.10742,0.29102,0.34473,0.51562,0.64062,0.60742 c0.09766,0.03027,0.19727,0.04492,0.29688,0.04492c0.20312,0,0.40527-0.0625,0.5752-0.18262 c2.75586-1.93848,7.45508-2.4502,7.50195-2.45605c0.5498-0.05762,0.94824-0.54883,0.89062-1.09766 C15.43262,39.4873,14.95117,39.10156,14.39258,39.14551z"
        className={styles.white}
      />
      <path
        d="M37.54297,31.72656c-0.50391-0.21582-1.0957,0.01758-1.3125,0.52734 c-0.21002,0.49194,0.01111,1.05237,0.48651,1.28436L33.96625,39.19c-0.19769-0.02649-0.32922-0.04126-0.35883-0.04449 c-0.55957-0.0459-1.04004,0.3418-1.09766,0.8916c-0.05762,0.54883,0.34082,1.04004,0.89062,1.09766 c0.04688,0.00586,4.74609,0.51758,7.50195,2.45605c0.16992,0.12012,0.37109,0.18262,0.5752,0.18262 c0.09961,0,0.19922-0.01465,0.29688-0.04492c0.2959-0.0918,0.5332-0.31641,0.64062-0.60742C42.80371,42.07324,43,41.02344,43,40 C43,36.38965,40.8584,33.1416,37.54297,31.72656z"
        className={styles.white}
      />
      <path
        d="M24,1c-3.85938,0-7,3.14062-7,7v5.44434c0,0.55273,0.44727,1,1,1s1-0.44727,1-1v-0.00372l10.00055-0.03259 c0.00159,0.55139,0.44769,0.99725,0.99945,0.99725c0.55273,0,1-0.44727,1-1V8C31,4.14062,27.85938,1,24,1z"
        className={styles.white}
      />
      <path
        d="M10,27c0-8.28427,6.26801-15,14-15s14,6.71573,14,15s-6.26801,19-14,19S10,35.28427,10,27z"
        className={styles.darkGreen}
      />
    </g>
  </svg>
);

export default Turtle;
