import classnames from 'classnames';
import TooltipNavigation from './TooltipNavigation';
import HelpingHand from 'ui/components/svg/HelpingHand';
import styles from './Tooltip.module.scss';
import { StepProps } from '../Tour';
import { getTestSelector } from 'util/testUtil';

const Tooltip = ({ step, index, steps, actions }: StepProps) => {
  const stepClassName = classnames(styles[`position-${step?.position}`], styles[`align-${step?.align}`]);

  return (
    <div className={classnames(styles.container, stepClassName)}>
      <div className={classnames(styles.header, stepClassName)}>
        <HelpingHand className={classnames(styles.hand, stepClassName)} />
        <span className={styles.counter} data-testid={getTestSelector('pagetour.step_counter')}>
          {index + 1}/{steps.length}
        </span>
        <TooltipNavigation onNextClick={actions.nextStep} onPrevClick={actions.previousStep} index={index} />
      </div>
      <p data-testid={getTestSelector('pagetour.step_content')}>{step.content}</p>
    </div>
  );
};

export default Tooltip;
