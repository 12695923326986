import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { writeStorage } from '@rehooks/local-storage';
import { PAGE_TOUR_STORAGE_KEY, TOUR_ROOT_ELEMENT_ID } from 'ui/constants';
import { pageTourStateSelector } from 'ui/store/tour/selectors';
import { usePageTourStoreActions } from 'ui/store/tour/usePageTourStoreActions';
import TourModal from 'ui/components/modals/TourModal';
import Tooltip from './Tooltip';
import Tour, { Spotlight, Floater, FadeOut, TourStep } from './Tour';
import styles from './PageTour.module.scss';

const tourRoot = document.getElementById(TOUR_ROOT_ELEMENT_ID);

type Props = {
  steps: TourStep[];
};

const PageTour: React.FC<Props> = ({ steps }) => {
  const [rideStarted, setRideStarted] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { tourVisible, tourIntroDisplayed } = useSelector(pageTourStateSelector);
  const { setTourVisibility, setTourIntroVisibility } = usePageTourStoreActions(dispatch);

  const closeOverlay = useCallback(() => {
    setTourIntroVisibility(false);
  }, [setTourIntroVisibility]);

  const handleEnd = useCallback(() => {
    setTourIntroVisibility(false);
    setTourVisibility(true);
    writeStorage(PAGE_TOUR_STORAGE_KEY, true);
  }, [setTourIntroVisibility, setTourVisibility]);

  const onBtnClick = useCallback(() => {
    closeOverlay();
    setRideStarted(true);
  }, [closeOverlay, setRideStarted]);

  const onCloseModal = useCallback(() => {
    setTourVisibility(true);
    setTourIntroVisibility(false);
    writeStorage(PAGE_TOUR_STORAGE_KEY, true);
  }, [setTourVisibility, setTourIntroVisibility]);

  return (
    <>
      {!tourVisible &&
        (tourIntroDisplayed ? (
          <TourModal onClose={onCloseModal} onButtonClick={onBtnClick} />
        ) : (
          rideStarted && (
            <Tour steps={steps} onEnd={handleEnd} tourRoot={tourRoot}>
              <FadeOut className={styles.overlay} holeClass={styles.hole} />
              <Spotlight className={styles.spotlight} />
              <Floater className={styles.floater}>
                {/* @ts-ignore */}
                {(stepProps) => <Tooltip {...stepProps} />}
              </Floater>
            </Tour>
          )
        ))}
    </>
  );
};

export default PageTour;
