import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const MyPictures: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" {...props}>
    <rect className={styles.white} x="3.44" y="7.11" width="34.84" height="27.58" rx="1.59" />
    <path
      className={styles.green}
      d="M36.69,35.93H5a2.84,2.84,0,0,1-2.84-2.84V8.7A2.84,2.84,0,0,1,5,5.86H36.69A2.84,2.84,0,0,1,39.53,8.7V33.09A2.84,2.84,0,0,1,36.69,35.93ZM5,8.36a.34.34,0,0,0-.34.34V33.09a.34.34,0,0,0,.34.34H36.69a.34.34,0,0,0,.34-.34V8.7a.34.34,0,0,0-.34-.34Z"
    />
    <path
      className={styles.white}
      d="M43.69,14.86V26.91L39,21.84a2,2,0,0,0-2.85,0L25.19,33.64l-5-5.34a2,2,0,0,0-2.86,0l-6.72,7.26V14.86a.55.55,0,0,1,.54-.55h32A.55.55,0,0,1,43.69,14.86Z"
    />
    <path
      className={styles.white}
      d="M23.86,32.22,20.23,28.3a2,2,0,0,0-2.86,0l-6.73,7.26v-.08l6.7-7.24a2,2,0,0,1,2.84,0Z"
    />
    <path
      className={styles.white}
      d="M39,21.78l4.69,5.07v.07L39,21.84a2,2,0,0,0-2.86,0L26.62,32.08l9.54-10.3A2,2,0,0,1,39,21.78Z"
    />
    <path
      className={styles.darkGreen}
      d="M39,21.78l4.69,5.07V39.48a.54.54,0,0,1-.54.54H19.29l5.89-6.37h0l.06-.06,1.38-1.5,9.54-10.3A2,2,0,0,1,39,21.78Z"
    />
    <path
      className={styles.green}
      d="M25.18,33.64h0L19.29,40h-8.1a.55.55,0,0,1-.55-.54v-4l6.7-7.24a2,2,0,0,1,2.84,0l3.68,4,1.26,1.36Z"
    />
    <path
      className={styles.green}
      d="M43.15,42.14h-32a2.67,2.67,0,0,1-2.67-2.66V14.85a2.67,2.67,0,0,1,2.67-2.66h32a2.67,2.67,0,0,1,2.66,2.66V39.48A2.67,2.67,0,0,1,43.15,42.14Zm-32-27.83a.55.55,0,0,0-.55.54V39.48a.55.55,0,0,0,.55.54h32a.55.55,0,0,0,.54-.54V14.85a.55.55,0,0,0-.54-.54Z"
    />
    <circle className={styles.yellow} cx="25.19" cy="21.26" r="3.51" />
  </svg>
);

export default MyPictures;
