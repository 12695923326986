import { PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from './InvisibleBox.module.scss';

interface RowProps {
  className?: string;
}

const Row = ({ children, className }: PropsWithChildren<RowProps>) => (
  <div className={classnames(styles.row, className)}>{children}</div>
);

interface HeadingProps {
  className?: string;
}

const Heading = ({ children, className }: PropsWithChildren<HeadingProps>) => (
  <h1 className={classnames(styles.heading, className)}>{children}</h1>
);

interface Props {
  className?: string;
}

const InvisibleBox = ({ children }: PropsWithChildren<Props>) => <div className={styles.container}>{children}</div>;

InvisibleBox.Row = Row;
InvisibleBox.Heading = Heading;

export default InvisibleBox;
