import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  tourRoot: HTMLElement | null;
};

const TourPortal = ({ children, tourRoot }: PropsWithChildren<Props>) => {
  if (!tourRoot) return null;

  return createPortal(children, tourRoot);
};

export default TourPortal;
