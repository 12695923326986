import React from 'react';
import { useTranslation } from 'react-i18next';
import { Direction, TourStep } from 'ui/components/common/smart/PageTour/Tour';
import PageTour, { PageTourStep } from 'ui/components/common/smart/PageTour';
import { useDispatch } from 'react-redux';
import { useHomeScreenStoreActions } from 'ui/store/homescreen/useHomeScreenStoreActions';
import { HomeSubscreen } from 'ui/store/homescreen/enums';

const CreativeTour: React.FC = () => {
  const { t } = useTranslation('pageTour');
  const dispatch = useDispatch();
  const { setHomeScreen } = useHomeScreenStoreActions(dispatch);

  const steps = React.useMemo<TourStep[]>(
    () => [
      {
        area: PageTourStep.Modes,
        content: t(PageTourStep.Modes),
      },
      {
        area: PageTourStep.Editor,
        content: t(PageTourStep.Editor),
      },
      {
        area: PageTourStep.Canvas,
        content: t(PageTourStep.Canvas),
        position: Direction.Left,
        align: Direction.Bottom,
        onBefore: () => setHomeScreen(HomeSubscreen.None),
      },
      {
        area: PageTourStep.Save,
        content: t(PageTourStep.Save),
        position: Direction.Bottom,
        align: Direction.Right,
      },
      {
        area: PageTourStep.Help,
        content: t(PageTourStep.Help),
      },
    ],
    [t]
  );

  return <PageTour steps={steps} />;
};

export default CreativeTour;
