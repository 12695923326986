import { ChallengeOnServer } from 'types/challenge';

export const challengeLanguageFallback =
  (interfaceLang: string) =>
  (challenge: ChallengeOnServer): ChallengeOnServer => {
    if (challenge.texts[interfaceLang] === undefined) {
      const existingLang = Object.keys(challenge.texts)[0];
      challenge.texts[interfaceLang] = challenge.texts[existingLang];
    }
    return challenge;
  };
