import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Close from 'ui/components/svg/Close';
import AllTopicsButton from './AllTopicsButton';
import AllChallengesButton from './AllChallengesButton';
import { getExpArticleName } from 'util/articleUtil';
import { START_REFERENCE_ARTICLE, START_CONTEXT_ARTICLE } from 'ui/constants';
import styles from './ContentHeader.module.scss';
import { PageTourStep } from 'ui/components/common/smart/PageTour/types';
import Button, { ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import { referenceSelector } from 'ui/store/help/selectors';
import { useHomeScreenStoreActions } from 'ui/store/homescreen/useHomeScreenStoreActions';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';
import { homeScreenStateSelector } from 'ui/store/homescreen/selectors';
import { HomeSubscreen } from 'ui/store/homescreen/enums';
import { currentExpSelector } from 'ui/store/editor/selectors';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { getTestSelector } from 'util/testUtil';
import { getWithExpiry } from './ContentHeader.util';
import { renderToastPopup } from 'util/toast';

const codePropsSelector = createCodeStatePropSelector('isHintLoading', 'hint');

const ContentHeader: React.FC = () => {
  const { id: currentChallengeId } = useParams();
  const { t } = useTranslation(['navigation', 'challenges']);
  const reference = useSelector(referenceSelector);
  const dispatch = useDispatch();
  const { subscreen } = useSelector(homeScreenStateSelector);
  const { isHintLoading, hint } = useSelector(codePropsSelector);
  const { setHomeScreen } = useHomeScreenStoreActions(dispatch);
  const { setAskForHint } = useCodeStoreActions(dispatch);
  const currentExp = useSelector(currentExpSelector);

  const getAllTopicsButton = useCallback((): JSX.Element | null => {
    if (subscreen === HomeSubscreen.WhatIs && getExpArticleName(currentExp) !== START_CONTEXT_ARTICLE) {
      return <AllTopicsButton />;
    }

    if (subscreen === HomeSubscreen.Index && reference !== START_REFERENCE_ARTICLE) {
      return <AllTopicsButton />;
    }

    if (subscreen === HomeSubscreen.Challenges) {
      return <AllChallengesButton />;
    }

    return null;
  }, [subscreen, reference, currentExp]);

  const handleClose = useCallback(() => {
    setHomeScreen(HomeSubscreen.None);
  }, [setHomeScreen]);

  const handleGetHint = async () => {
    const challengeIdInLocalStorage = getWithExpiry(currentChallengeId as string);

    if (challengeIdInLocalStorage && challengeIdInLocalStorage === currentChallengeId) {
      renderToastPopup('error', t('challenges:challenge.hint.limit'));
      return;
    }

    if (currentChallengeId) {
      setAskForHint(true);
    }
  };

  return (
    <div className={styles.container} data-tour-area={PageTourStep.ChallengeDescription}>
      <div className={styles.col}>
        {getAllTopicsButton()}
        <Button
          size={ButtonSize.Small}
          flavor={hint ? ButtonFlavor.Active : ButtonFlavor.Gray}
          onClick={handleGetHint}
          disabled={isHintLoading}
          data-testid={getTestSelector('hint.button')}
          className={styles['hint-button']}
        >
          {t(isHintLoading ? 'challenges:challenge.hint.loading' : 'navigation:user.menu.hint')}
        </Button>
      </div>
      <Button
        Icon={Close}
        size={ButtonSize.Small}
        onClick={handleClose}
        data-tour-area={PageTourStep.CloseContent}
        noBorder
      />
    </div>
  );
};

export default ContentHeader;
