import React from 'react';
import classnames from 'classnames';
import styles from './SocialShareLink.module.scss';
import { useTranslation } from 'react-i18next';
import Twitter from 'ui/components/svg/Twitter';
import Facebook from 'ui/components/svg/Facebook';

interface Props {
  via: 'fb' | 'twitter';
  className?: string;
  text: string;
}

const SocialShareLink: React.FC<Props> = ({ via, className, text }) => {
  const { t } = useTranslation('socialMedia');
  const isFacebook: boolean = via === 'fb';
  const isTwitter: boolean = via === 'twitter';
  const key = `share${via.replace(via[0], via[0].toUpperCase())}`;

  const href: string = isFacebook
    ? `https://www.facebook.com/sharer.php?s=100&p[url]=${text}`
    : `https://twitter.com/intent/tweet?text=${text}`;

  return (
    <a
      className={classnames(styles.button, { [styles.fb]: isFacebook, [styles.twitter]: isTwitter }, className)}
      href={href}
      target="_blank'"
      rel="noopener noreferrer"
    >
      {isFacebook && <Facebook width={20} className={styles.icon} />}
      {isTwitter && <Twitter width={20} className={styles.icon} />}
      {t(key)}
    </a>
  );
};

export default SocialShareLink;
