import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from './LoginRegisterSwitch.module.scss';
import { UserEnterMode } from '../UserForm/types';
import { useTranslation } from 'react-i18next';

interface Props {
  mode: UserEnterMode;
}

const LoginRegisterSwitch: React.FC<Props> = ({ mode }) => {
  const { t } = useTranslation('userForm');
  const isLogin: boolean = mode === 'login';
  const labelText: string = t(isLogin ? 'noAccount' : 'hasAccount');
  const linkPath = `/user/${isLogin ? 'register' : 'login'}`;
  const linkText: string = t(isLogin ? 'register' : 'signIn');
  return (
    <div className={styles.container}>
      <p className={styles.heading}>{labelText}</p>
      <NavLink className={classnames(styles.btn, styles.success)} to={linkPath}>
        {linkText}
      </NavLink>
    </div>
  );
};

export default LoginRegisterSwitch;
