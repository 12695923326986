import React, { PropsWithChildren } from 'react';
import { Actions } from './actions';

export enum Direction {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

export type Inset = {
  top: number;
  bottom: number;
  left: number;
  right: number;
  width: number;
  height: number;
};

export type StepConfig = {
  area: string;
  content: string;
  position: Direction;
  align: Direction;
  onBefore: StepCallback;
  onAfter: StepCallback;
};

export type TourStep = Partial<StepConfig>;

export type TourProps = PropsWithChildren<{
  tourRoot: HTMLElement | null;
  steps: TourStep[];
  onStart?: () => void;
  onEnd?: () => void;
}>;

export type StepProps = {
  step: StepConfig;
  index: number;
  steps: StepConfig[];
  actions: Actions;
};

export type StepRenderer = (props: StepProps) => React.ReactNode;
export type StepCallback = (props: StepProps) => void;
