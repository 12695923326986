import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Range } from 'react-range';
import { IThumbProps, ITrackProps } from 'react-range/lib/types';
import Button, { ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import Parameter from 'ui/components/common/dumb/Parameter';
import Tooltip from 'ui/components/common/smart/Tooltip';
import Input from 'ui/components/common/dumb/Input';
import Pause from 'ui/components/svg/Pause';
import Play from 'ui/components/svg/Play';
import Stop from 'ui/components/svg/Stop';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';
import styles from './AnimationTimeSlider.module.scss';
import { PageTourStep } from 'ui/components/common/smart/PageTour';

const animationSelector = createCodeStatePropSelector('animation', 'animationCode');

const AnimationTimeSlider: React.FC = () => {
  const { t } = useTranslation('asideTop');
  const dispatch = useDispatch();
  const { animation, animationCode } = useSelector(animationSelector);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [lastFrameTimestamp, setLastFrameTimestamp] = useState<number | undefined>(undefined);
  const [playing, setPlaying] = useState<boolean>(false);
  const { setAnimation, setAnimationTime } = useCodeStoreActions(dispatch);

  const onChange = (values: number[]) => {
    setCurrentTime(values[0] || 0);
  };

  const { length } = animation;

  const onPlayPauseClick = () => {
    setPlaying((v) => !v);
    setLastFrameTimestamp(undefined);
  };

  const onStopClick = () => {
    setPlaying(false);
    setCurrentTime(0);
    setLastFrameTimestamp(undefined);
  };

  const onMaxTimeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newLength = parseInt(event.target.value);
      if (newLength < currentTime) {
        setCurrentTime(newLength);
      }
      setAnimation({
        ...animation,
        text: animationCode,
        length: newLength,
      });
    },
    [animation, animationCode, currentTime, setAnimation]
  );

  const playPauseTooltip = t(playing ? 'pause' : 'play');
  const resetTooltip = t('stop');

  useEffect(() => {
    if (playing) {
      const requestId = requestAnimationFrame((timestamp) => {
        let step: number;
        if (lastFrameTimestamp === undefined) {
          step = 0;
        } else {
          step = (timestamp - lastFrameTimestamp) / 1000;
        }
        setLastFrameTimestamp(timestamp);
        setCurrentTime((v) => (v > length ? 0 : v + step));
      });

      return () => {
        cancelAnimationFrame(requestId);
      };
    }
  }, [playing, setCurrentTime, lastFrameTimestamp, setLastFrameTimestamp]);

  useEffect(() => {
    setAnimationTime(Math.min(currentTime, animation.length));
  }, [currentTime, animation]);

  return (
    <div className={styles.container} data-tour-area={PageTourStep.AnimationSlider}>
      <Tooltip tooltip={playPauseTooltip}>
        <Button
          noBorder
          flavor={ButtonFlavor.Gray}
          className={styles.container__btn}
          onClick={onPlayPauseClick}
          size={ButtonSize.Small}
        >
          {playing ? <Pause width={20} height={20} /> : <Play width={20} height={20} />}
        </Button>
      </Tooltip>
      <Tooltip tooltip={resetTooltip}>
        <Button
          noBorder
          flavor={ButtonFlavor.Gray}
          className={styles.container__btn}
          disabled={!playing}
          onClick={onStopClick}
          size={ButtonSize.Small}
        >
          <Stop width={20} height={20} />
        </Button>
      </Tooltip>
      <Parameter>
        {t('time', {
          time: (Math.round(currentTime * 10) / 10).toFixed(1),
        })}
      </Parameter>
      <Range
        step={1}
        min={0}
        max={length || 0}
        values={[currentTime]}
        onChange={onChange}
        renderTrack={renderTrack}
        renderThumb={renderThumb}
        renderMark={renderMark}
      />
      <Parameter>{t('max_time')}</Parameter>
      <Input
        compact
        type="number"
        min={1}
        step={1}
        value={length || ''}
        onChange={onMaxTimeChange}
        disabled={playing}
        className={styles.container__input}
      />
    </div>
  );
};

function renderTrack({ props, children }: { props: ITrackProps; children: React.ReactNode }) {
  return (
    <div
      {...props}
      style={{
        ...props.style,
      }}
      className={styles.container__slider}
    >
      {children}
    </div>
  );
}

function renderThumb({ props }: { props: IThumbProps; value: number }): React.ReactNode {
  return (
    <div
      {...props}
      style={{
        ...props.style,
      }}
      className={styles.container__thumb}
    />
  );
}

function renderMark({
  props,
}: {
  props: { key: string; style: React.CSSProperties; ref: React.RefObject<any> };
  index: number;
}) {
  return <div {...props} className={styles.container__mark} />;
}

export default AnimationTimeSlider;
