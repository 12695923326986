import { deleteFromStorage } from '@rehooks/local-storage';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { LinkButton, ButtonSize } from 'ui/components/common/dumb/Button';
import { renderToastPopup } from 'util/toast';
import { useDispatch } from 'react-redux';
import { STORAGE_API_KEY } from 'ui/constants';
import Leave from 'ui/components/svg/Leave';
import Single05 from 'ui/components/svg/Single05';
import MyPictures from 'ui/components/svg/MyPictures';
import MyAnimationsIcon from 'ui/components/svg/MyAnimations';
import Winner from 'ui/components/svg/Winner';
import Separator from 'ui/components/common/dumb/Separator';
import PodiumTrophy from 'ui/components/svg/PodiumTrophy';
import { getTestSelector } from 'util/testUtil';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';
import { useUserStoreActions } from 'ui/store/user/useUserStoreActions';

const AuthorizedContent: React.FC = () => {
  const dispatch = useDispatch();
  const { resetProgramUserData } = useCodeStoreActions(dispatch);
  const { setAnonymousUser } = useUserStoreActions(dispatch);
  const { t } = useTranslation(['navigation', 'toast']);

  const handleLogout = useCallback(() => {
    setAnonymousUser();
    resetProgramUserData();
    deleteFromStorage(STORAGE_API_KEY);
    renderToastPopup('success', t('toast:farewell'));
  }, [resetProgramUserData, setAnonymousUser, t]);

  return (
    <>
      <Button
        size={ButtonSize.Small}
        onClick={handleLogout}
        title={t('navigation:user.menu.logout')}
        Icon={Leave}
        noBorder
        bigIcon
        data-testid={getTestSelector('navigation.logout')}
      />
      <LinkButton
        size={ButtonSize.Small}
        to="/user/profile"
        title={t('navigation:user.menu.my_profile')}
        Icon={Single05}
        noBorder
        bigIcon
      />
      <Separator vertical />
      <LinkButton
        size={ButtonSize.Small}
        to="/user/animations"
        title={t('navigation:user.menu.my_animations')}
        Icon={MyAnimationsIcon}
        noBorder
        bigIcon
      />
      <LinkButton
        size={ButtonSize.Small}
        to="/user/programs"
        title={t('navigation:user.menu.my_programs')}
        Icon={MyPictures}
        noBorder
        bigIcon
      />
      <LinkButton
        size={ButtonSize.Small}
        to="/user/my-challenges"
        title={t('navigation:user.menu.my_challenges')}
        Icon={Winner}
        noBorder
        bigIcon
      />
      <LinkButton
        data-testid={getTestSelector('navigation.system_challenges')}
        size={ButtonSize.Small}
        to="/user/challenges"
        title={t('user.menu.challenges')}
        Icon={PodiumTrophy}
        noBorder
        bigIcon
      />
    </>
  );
};

export default AuthorizedContent;
