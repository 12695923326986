import classnames from 'classnames';
import { type TFunction } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { writeStorage, deleteFromStorage } from '@rehooks/local-storage';
import { usePromise } from 'react-use-promise-matcher';
import useApi from 'ui/components/hooks/useApi';
import { HOUR_OF_CODE_TOKEN_STORAGE_KEY, STORAGE_API_KEY, USER_TOKEN_STORAGE_KEY } from 'ui/constants';
import Form from 'ui/components/common/dumb/Form';
import FormError from 'ui/components/common/dumb/FormError';
import Input from 'ui/components/common/dumb/Input';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import styles from './UserForm.module.scss';
import { UserEnterMode } from './types';
import Loader from 'ui/components/common/dumb/Loader';
import { DataItem } from 'types/data';
import { userSelector } from 'ui/store/user/selectors';
import { getTestSelector } from 'util/testUtil';

interface Props {
  mode: UserEnterMode;
}

const UserForm: React.FC<Props> = ({ mode }) => {
  const [prevLogin, setPrevLogin] = useState<string>('');
  const [prevEmail, setPrevEmail] = useState<string>('');
  const [prevConsent, setPrevConsent] = useState<boolean>(false);
  const { register, reset, handleSubmit, formState, setValue, trigger } = useForm({
    mode: 'onChange',
  });
  const user = useSelector(userSelector);
  const {
    t,
    i18n: { language },
  } = useTranslation('userForm');
  const errorTranslation = useTranslation('errors').t;

  const { login, register: registerUser } = useApi();

  const isLogin: boolean = mode === 'login';
  const { errors } = formState;

  const loginOrRegisterHandler = useCallback(
    async (payload: DataItem) => {
      setPrevLogin(payload.login);
      setPrevEmail(payload.email);
      if (!isLogin) {
        setPrevConsent(payload.privacyConsent);
      }
      const { data } = isLogin
        ? await login(payload.loginOrEmail as string, payload.password as string)
        : await registerUser(
            payload.login as string,
            payload.email as string,
            payload.password as string,
            payload.emailAgreement as boolean
          );

      writeStorage(STORAGE_API_KEY, data.apiKey);
      deleteFromStorage(USER_TOKEN_STORAGE_KEY);
      deleteFromStorage(HOUR_OF_CODE_TOKEN_STORAGE_KEY);
    },
    [isLogin, setPrevEmail, setPrevLogin, setPrevConsent, login, registerUser]
  );

  const [result, load] = usePromise<any, any, DataItem>(loginOrRegisterHandler);

  const renderForm = useCallback(() => {
    return (
      <>
        {user ? (
          <>
            <p className={classnames(styles.message, styles.success)}>{t('alreadyLoggedIn')}</p>
            <Navigate to="/" />
          </>
        ) : (
          <Form onSubmit={handleSubmit(load)} data-testid={getTestSelector('user.form')}>
            {isLogin ? (
              <>
                <Input
                  className={styles.input}
                  type="text"
                  placeholder={t('loginOrEmailPlaceholder')}
                  {...register('loginOrEmail', { required: t('loginOrEmailRequired') as string })}
                />
                <FormError errors={errors} name="loginOrEmail" />
              </>
            ) : (
              <>
                <Input
                  className={styles.input}
                  type="text"
                  placeholder={t('loginPlaceholder')}
                  {...register('login', {
                    required: t('loginRequired') as string,
                    validate: (value: string) => /^[a-zA-Z0-9_]*$/.test(value) || (t('loginValidate') as string),
                  })}
                />
                <FormError errors={errors} name="login" />
                <Input
                  className={styles.input}
                  type="email"
                  placeholder={t('emailPlaceholder')}
                  {...register('email', {
                    required: t('emailRequired') as string,
                  })}
                />
                <FormError errors={errors} name="email" />
              </>
            )}
            <Input
              className={styles.input}
              type="password"
              placeholder={t('passwordPlaceholder')}
              {...register('password', {
                required: t('passwordRequired') as string,
              })}
            />
            <FormError errors={errors} name="password" />
            {!isLogin && (
              <>
                <label htmlFor="privacyConsent" className={styles.label}>
                  <Input
                    id="privacyConsent"
                    className={classnames(styles.input, styles.checkbox)}
                    type="checkbox"
                    data-testid="privacyConsent"
                    {...register('privacyConsent', {
                      required: true,
                    })}
                  />
                  <span>
                    {t('accept')}&nbsp;
                    <NavLink className={styles.policyLink} to="/privacy-policy">
                      {t('acceptPolicy')}
                    </NavLink>
                    &nbsp;{t('and')}&nbsp;
                    <NavLink className={styles.policyLink} to="/terms-of-service">
                      {t('acceptTerms')}
                    </NavLink>
                    <span className={styles.required}>*</span>
                  </span>
                </label>
                <label htmlFor="emailAgreement" className={styles.label}>
                  <Input
                    id="emailAgreement"
                    className={classnames(styles.input, styles.checkbox)}
                    type="checkbox"
                    {...register('emailAgreement')}
                  />
                  <span>{t('emailAgreement')}</span>
                </label>
              </>
            )}
            <Button
              data-testid={getTestSelector('user.form.submit')}
              disabled={!formState.isValid}
              flavor={ButtonFlavor.Success}
            >
              {isLogin ? t('signIn') : t('register')}
            </Button>
            {isLogin && (
              <NavLink className={styles.navLink} to="/user/recover-lost-password">
                {t('forgotPassword')}
              </NavLink>
            )}
            {!isLogin && (
              <>
                <p className={styles.note}>{t('requiredField')}</p>
                <label className={styles.info}>
                  <span>
                    {t('acceptPersonalData')}&nbsp;
                    <NavLink className={styles.policyLink} to="/privacy-policy">
                      {t('acceptPolicy2')}
                    </NavLink>
                  </span>
                </label>
              </>
            )}
          </Form>
        )}
      </>
    );
  }, [isLogin, load, handleSubmit, register, errors, formState, user, t]);

  useEffect(() => {
    reset();
  }, [mode, reset]);

  useEffect(() => {
    result.match({
      Idle: () => null,
      Loading: () => null,
      Rejected: () => {
        setValue('login', prevLogin);
        setValue('email', prevEmail);
        setValue('privacyConsent', prevConsent);
        trigger(['login', 'email', 'privacyConsent']);
      },
      Resolved: () => null,
    });
  }, [result, setValue, trigger, isLogin, prevLogin, prevEmail, prevConsent]);

  useEffect(() => {
    trigger();
  }, [language, trigger]);

  return result.match({
    Idle: renderForm,
    Loading: () => <Loader className={styles.loader} />,
    Rejected: (err: any) => (
      <>
        {renderForm()}
        <p className={classnames(styles.message, styles.error)}>
          {errorMessage(errorTranslation, err.response, t('invalidUserOrPassword'))}
        </p>
      </>
    ),
    Resolved: renderForm,
  });
};

export default UserForm;

function errorMessage(errorTranslation: TFunction, response: any, err: string) {
  if (response.status === 401) {
    return err;
  } else {
    return errorTranslation(response.data.error);
  }
}
