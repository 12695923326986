export enum PageTourStep {
  Editor = 'editor',
  Modes = 'modes',
  Help = 'help',
  Canvas = 'canvas',
  Save = 'save',
  ChallengeDescription = 'challenge-description',
  CheckChallenge = 'check-challenge',
  ToggleChallengeDescription = 'toggle-challenge-description',
  CloseContent = 'close-content',
  AnimationPrefix = 'animation-prefix',
  AnimationSlider = 'animation-slider',
}
