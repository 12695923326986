export class Position {
  constructor(line: number, column: number) {
    this.line = line;
    this.column = column;
  }

  public readonly line: number;
  public readonly column: number;

  public toString(): string {
    return `(${this.line},${this.column})`;
  }
}

export class PositionRange {
  constructor(start: Position, end: Position) {
    this.start = start;
    this.end = end;
  }

  static SINGLE(line: number, column: number): PositionRange {
    const p = new Position(line, column);
    return new PositionRange(p, p);
  }

  static SINGLE_POS(p: Position): PositionRange {
    return new PositionRange(p, p);
  }

  public readonly start: Position;
  public readonly end: Position;

  public toString(): string {
    return `${this.start}->${this.end}`;
  }
}
