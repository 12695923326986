import { InterpretError } from 'interpret/error/InterpretError';
import { InterpretController } from 'interpret/InterpretController';
import { InstrsWithStats } from 'interpret/InterpretResultWithStats';
import * as monaco from 'monaco-editor';
import { Position } from 'monaco-editor';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { AttrEditZone } from 'ui/components/turtle/zone/AttrEditZone';
import { MODEL_MARKERS_OWNER } from 'ui/constants';
import { useEditorStoreActions } from 'ui/store/editor/useEditorStoreActions';
import { currentStmt, getCurrentPosition } from 'util/editorUtil';
import { CodeType } from '../../../../../../types/code';
import { codeTypeSelector, createCodeStatePropSelector } from '../../../../../store/code/selectors';
import { AppState } from '../../../../../store/types';
import editor, { editorElement, model, worker } from './editor';
import styles from './EditorCodePane.module.scss';
import { setFontSize } from './helpers';
import VersionManager from './VersionManager';

type Props = {
  onChange?: (value: string) => void;
  code: string;
};

const animationTimeSelector = createCodeStatePropSelector('animationTime');

const EditorCodePane: React.FC<Props> = ({ onChange = () => null, code }) => {
  const dispatch = useDispatch();
  const store = useStore<AppState>();
  const { setError, setInstr, setPos } = useEditorStoreActions(dispatch);
  const { animationTime } = useSelector(animationTimeSelector);
  const { codeType } = useSelector(codeTypeSelector);

  const editorRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const handleError = useCallback(
    (err: InterpretError) => {
      setError(err);
      monaco.editor.setModelMarkers(model, MODEL_MARKERS_OWNER, [
        {
          startColumn: err.pos.start.column,
          startLineNumber: err.pos.start.line,
          endColumn: err.pos.end.column,
          endLineNumber: err.pos.end.line,
          message: `${err.code}:${err.args.join(', ')}`,
          severity: monaco.MarkerSeverity.Error,
        },
      ]);
    },
    [setError]
  );

  const handleInstruction = useCallback(
    (instrs: InstrsWithStats) => {
      setInstr(instrs);
      monaco.editor.setModelMarkers(model, MODEL_MARKERS_OWNER, []);
    },
    [setInstr]
  );

  const controller = useMemo(() => new InterpretController(worker, handleError, handleInstruction), [worker]);

  // we need to get the state independently from the animationTimeSelector as this is called both
  // when the animationTime changes, and when there's a code change in the editor - the latter
  // callback is done independently from react-redux
  function interpretProgram() {
    const state = store.getState();
    const prefix = state.code.codeType === CodeType.Animation ? 'let t = ' + state.code.animationTime + '; ' : '';
    const program = prefix + model.getValue();
    controller.onModelChanged(program);
  }

  useEffect(() => {
    interpretProgram();
  }, [animationTime, codeType]);

  useEffect(() => {
    if (code === model.getValue()) return;
    model.setValue(code);
  }, [code]);

  useEffect(() => {
    if (!editorRef.current) return;

    setFontSize();
    editorRef.current.append(editorElement);
    editor.layout();

    let currentAttrEditZone: { zone: AttrEditZone; line: number; startColumn: number } | undefined;
    interpretProgram();

    const changeTracker = editor.onDidChangeModelContent(() => {
      onChange(model.getValue());
    });

    const emptyContentTracker = editor.onDidChangeModelContent((e) => {
      interpretProgram();
      // Trigger cursor-changed when the program content is removed (new program was clicked)
      // so that the attr edit zone can be closed if open.
      if (
        e.changes.length === 1 &&
        e.changes[e.changes.length - 1].text === '' &&
        editor.getModel()?.getValue() === ''
      ) {
        // Otherwise getting current stmt fails as the position isn't updated.
        editor.setPosition({ lineNumber: 1, column: 1 });
        onDidChangeCursorPosition({ position: new Position(1, 1) });
      }
    });

    const versionManager = new VersionManager(dispatch);
    const versionIdTracker = editor.onDidChangeModelContent(versionManager.track);

    editor.focus();

    function onDidChangeCursorPosition(e: { position: Position }) {
      const stmt = currentStmt(editor);

      if (currentAttrEditZone) {
        // we need to re-create the zone if it corresponds to a different statement: starting
        // at another line, or from a different column; or if the basic instruction changed
        if (
          currentAttrEditZone.line !== e.position.lineNumber ||
          stmt.startColumn !== currentAttrEditZone.startColumn ||
          !stmt.value.startsWith(currentAttrEditZone.zone.attributable)
        ) {
          currentAttrEditZone.zone.removeFromEditor();
          currentAttrEditZone = undefined;
        }
      }

      const newZone = currentAttrEditZone?.zone || AttrEditZone.CREATE(editor, stmt);
      if (newZone) {
        // even if the zone stays the same, updating the position
        currentAttrEditZone = {
          zone: newZone,
          line: e.position.lineNumber,
          startColumn: stmt.startColumn,
        };
      }

      setPos({ position: getCurrentPosition(editor), currentExp: stmt.value });
    }

    const attrEditZoneTracker = editor.onDidChangeCursorPosition(onDidChangeCursorPosition);
    // trigger on setup, if the first line contains an attr edit zone
    onDidChangeCursorPosition({ position: editor.getPosition()! });

    return () => {
      currentAttrEditZone?.zone.removeFromEditor();
      attrEditZoneTracker.dispose();
      changeTracker.dispose();
      versionIdTracker.dispose();
      emptyContentTracker.dispose();
      editorRef.current?.removeChild(editorElement);
    };
  }, [editorRef, setPos]);

  useEffect(() => {
    window.addEventListener('resize', setFontSize);
    return () => {
      window.removeEventListener('resize', setFontSize);
    };
  }, []);

  // Schedule editor size calculation after component has been mounted.
  // This prevents the editor from overflowing in Animation mode.
  useEffect(() => {
    setTimeout(() => editor.layout(), 0);
  }, [editor]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container} ref={editorRef} />
    </div>
  );
};

export default EditorCodePane;
