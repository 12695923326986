import React from 'react';
import Logo from 'ui/components/svg/Logo';
import styles from './Image.module.scss';
import classnames from 'classnames';

type Props = React.ImgHTMLAttributes<HTMLImageElement>;

const Image: React.FC<Props> = ({ src, className, ...htmlAttributes }) => {
  const [source, setSource] = React.useState(src);
  const [placeholder, setPlaceholder] = React.useState(false);

  React.useEffect(() => {
    setSource(src);
    setPlaceholder(false);
  }, [src, setSource, setPlaceholder]);

  const handleError = React.useCallback(() => {
    setPlaceholder(true);
  }, [setSource]);

  if (placeholder)
    return (
      <div {...htmlAttributes} className={classnames(className, styles.placeholder)}>
        <Logo className={styles.logo} />
      </div>
    );

  return <img role="presentation" {...htmlAttributes} src={source} className={className} onError={handleError} />;
};

export default Image;
