import * as React from 'react';
import Main from 'ui/components/common/dumb/Main/';

const inMainScreen =
  (Component: React.FC): React.FC =>
  () => (
    <Main>
      <Component />
    </Main>
  );

export default inMainScreen;
