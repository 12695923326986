import { IconComponent } from 'types/icon';

const ChevronRight: IconComponent = (props) => (
  <svg viewBox="0 0 9 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 6.274c0 .256-.12.496-.32.642l-7.5 5.49a.725.725 0 01-.776.053.79.79 0 01-.404-.696V.783C0 .491.155.223.404.088A.725.725 0 011.18.14l7.5 5.49c.2.146.32.386.32.642v.001z"
      stroke="none"
    />
  </svg>
);

export default ChevronRight;
