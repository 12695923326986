import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { User } from 'types/user';

export interface UserState {
  user?: User;
  loggedIn?: boolean;
}

export const initialUserState: UserState = {};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser: (state: UserState, action: PayloadAction<User>) => {
      state.loggedIn = true;
      state.user = action.payload;
    },
    setAnonymousUser: (state: UserState) => {
      state.user = undefined;
      state.loggedIn = false;
    },
  },
});

export const actionCreators = userSlice.actions;

export default userSlice.reducer;
