import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { usePromise } from 'react-use-promise-matcher';
import { CodeType } from 'types/code';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import Modal from 'ui/components/common/smart/Modal';
import ProgramLinkCopy from 'ui/components/common/smart/ProgramLinkCopy';
import SaveAsImage from 'ui/components/common/smart/SaveAsImage';
import SocialShareLink from 'ui/components/common/smart/SocialShareLink';
import useCodeSave from 'ui/components/hooks/useCodeSave';
import { useSocialMediaShare } from 'ui/components/hooks/useSocialMediaShare';
import { pathForCode } from 'ui/components/routing/programHelpers';
import { codeIdSelector, codeStateSelector, createCodeStatePropSelector } from 'ui/store/code/selectors';
import { userSelector } from 'ui/store/user/selectors';
import { getTestSelector } from 'util/testUtil';
import ErrorWrapper from '../ErrorWrapper';
import LoaderWrapper from '../LoaderWrapper';
import styles from '../SocialShare.module.scss';
import { Phase } from '../types';

type Props = {
  setPhase: (phase: Phase) => void;
  onClose: () => void;
};

const codeSelector = createCodeStatePropSelector('creativeCode', 'animationCode');

const ShareProgram: React.FC<Props> = ({ setPhase, onClose }) => {
  const { t } = useTranslation('socialMedia');
  const { codeType } = useSelector(codeStateSelector);
  const isAnimation = codeType === CodeType.Animation;
  const user = useSelector(userSelector);
  const id = useSelector(codeIdSelector);
  const isSaved = Boolean(useSelector(codeIdSelector));
  const codePath = pathForCode(codeType, id, user?.login);
  const { creativeCode, animationCode } = useSelector(codeSelector);
  const { facebookText, twitterText } = useSocialMediaShare(codePath, isSaved, codeType);

  const [handleProgramSave] = useCodeSave(isAnimation ? animationCode : creativeCode, isAnimation, true);
  const [saveResult, saveProgram] = usePromise(handleProgramSave);

  React.useEffect(() => {
    saveProgram();
  }, []);

  const handleCreateChallenge = useCallback(() => {
    setPhase(Phase.CreateChallenge);
  }, [setPhase]);

  const renderSocialLinks = useCallback(() => {
    return (
      <>
        <SocialShareLink via="fb" className={styles.link} text={facebookText} />
        <SocialShareLink via="twitter" className={styles.link} text={twitterText} />
        {!isAnimation && <SaveAsImage as="a" />}
        {!isAnimation && isSaved && (
          <div className={styles.challengeViewSwitch} data-testid={getTestSelector('social_media.share_program_panel')}>
            <p className={styles.prompt}>{t('socialMedia:save.as_challenge.prompt')}</p>
            <Button
              flavor={ButtonFlavor.Success}
              className={styles.challengeViewSwitchBtn}
              onClick={handleCreateChallenge}
              data-testid={getTestSelector('social_media.create_challenge')}
            >
              {t('socialMedia:save.as_challenge')}
            </Button>
          </div>
        )}
      </>
    );
  }, [facebookText, twitterText, isSaved, isAnimation, handleCreateChallenge, t]);

  return (
    <Modal onClose={onClose} heading={t('shareYourProject')} showLogo showLang={false}>
      {isSaved && user?.login ? (
        <>
          <ProgramLinkCopy codeType={codeType} />
          {renderSocialLinks()}
        </>
      ) : (
        <>
          {saveResult.match({
            Loading: () => <LoaderWrapper />,
            Rejected: () => <ErrorWrapper />,
            Resolved: () => (
              <>
                {isSaved && <ProgramLinkCopy codeType={codeType} />}
                {renderSocialLinks()}
              </>
            ),
          })}
        </>
      )}
    </Modal>
  );
};

export default ShareProgram;
