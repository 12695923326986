import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromise, PromiseResultShape } from 'react-use-promise-matcher';
import { renderToastPopup } from 'util/toast';
import usePrevious from 'ui/components/hooks/usePrevious';
import { LIST_PORTION_LENGTH } from 'ui/constants';

type Loader<U> = (limit: number, offset: number) => Promise<U[]>;
type UseListLoaderShape<U> = {
  loadPortion: () => Promise<void>;
  items: U[];
  moreAvailable: boolean;
  result: PromiseResultShape<U[], string>;
};

const useListLoader = <T>(loader: Loader<T> | Loader<T>[]): UseListLoaderShape<T> => {
  const { t } = useTranslation();

  const [items, setItems] = useState<T[]>([]);
  const [moreAvailable, setMoreAvailable] = useState<boolean>(false);

  const [currentLoaderIndex, setCurrentLoaderIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [result, load] = usePromise(Array.isArray(loader) ? loader[currentLoaderIndex] : loader);
  const previousResult = usePrevious(result);

  const isLastLoader = Array.isArray(loader) ? loader.length === currentLoaderIndex + 1 : true;

  const appendPortion = useCallback(
    (portion: T[]) => {
      const portionClamped = portion.slice(0, LIST_PORTION_LENGTH);

      const didFetchAll = portion.length === portionClamped.length;

      if (!didFetchAll) {
        setMoreAvailable(true);
        setOffset((offset) => offset + LIST_PORTION_LENGTH);
      }
      if (didFetchAll && isLastLoader) {
        setMoreAvailable(false);
      }
      if (didFetchAll && !isLastLoader) {
        setCurrentLoaderIndex((loaderIndex) => loaderIndex + 1);
        setOffset(0);
      }

      setItems([...items, ...portionClamped]);
    },
    [items, setItems, setMoreAvailable]
  );

  const loadPortion = useCallback(() => load(LIST_PORTION_LENGTH + 1, offset), [items, load]);

  useEffect(() => {
    if (result === previousResult) return;

    result.match({
      Loading: () => null,
      Resolved: appendPortion,
      Rejected: (err) => {
        renderToastPopup('error', t(`toast:cannotLoadList`));
        setMoreAvailable(true);
        console.error(err);
      },
    });
  }, [result, appendPortion]);

  return { loadPortion, items, moreAvailable, result };
};

export default useListLoader;
