import React from 'react';
import classnames from 'classnames';
import styles from './ProgressBar.module.scss';

interface Props {
  value: number;
  max: number;
  containerClassName?: string;
  progressBarClassName?: string;
  valueBarClassName?: string;
}

const ProgressBar: React.FC<Props> = ({ value, max, containerClassName, progressBarClassName, valueBarClassName }) => {
  return (
    <div className={classnames(styles.container, containerClassName)}>
      <div className={classnames(styles.progressBar, progressBarClassName)}>
        <div
          className={classnames(styles.valueBar, valueBarClassName)}
          style={{ width: `calc(100% * ${value / max})` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
