import { createSelector } from '@reduxjs/toolkit';
import { CodeType } from 'types/code';
import { AppState } from '../types';
import { createStatePropSelectorCreator } from '../utils';
import { CodeState } from './slice';

const getStateEntry = (codeType: CodeType): 'program' | 'challenge' | 'animation' =>
  codeType === CodeType.Creative ? 'program' : codeType;

export const codeStateSelector = (state: AppState): CodeState => state.code;

export const codeIdSelector = createSelector(codeStateSelector, (state) => {
  const entry = getStateEntry(state.codeType);
  return state[entry]?.id;
});

export const codeLoginSelector = createSelector(codeStateSelector, (state) => {
  const entry = getStateEntry(state.codeType);
  return state[entry]?.userLogin;
});

export const createCodeStatePropSelector = createStatePropSelectorCreator<'code'>('code');

export const challengeSelector = createCodeStatePropSelector('challenge');

export const challengeNodesSelector = createCodeStatePropSelector('challengeNodes');

export const codeTypeSelector = createCodeStatePropSelector('codeType');
