import React from 'react';
import Nav from 'ui/components/common/dumb/Nav';
import { matchPath, useLocation } from 'react-router-dom';
import UserMenu from './UserMenu';
import LanguageSwitch from 'ui/components/common/smart/LanguageSwitch';
import { ANIMATION_PATHS, CHALLENGE_PATHS, PROGRAM_PATHS } from 'ui/constants';
import Separator from 'ui/components/common/dumb/Separator';
import ProgramModeMenu from './ProgramModeMenu';
import NonEditorMenu from './NonEditorMenu';
import HelpMenu from './HelpMenu';

const Navbar: React.FC = () => {
  const { pathname } = useLocation();
  const isChallengeEditor = CHALLENGE_PATHS.find((pathPattern) => matchPath(pathPattern, pathname));
  const isCreativeEditor = PROGRAM_PATHS.find((pathPattern) => matchPath(pathPattern, pathname));
  const isAnimationEditor = ANIMATION_PATHS.find((pathPattern) => matchPath(pathPattern, pathname));
  const isEditor = !!isChallengeEditor || !!isCreativeEditor || !!isAnimationEditor;

  return (
    <>
      <Nav vertical>
        {isEditor ? (
          <>
            <ProgramModeMenu />
            <Separator vertical />
            <HelpMenu />
          </>
        ) : (
          <NonEditorMenu />
        )}
        <UserMenu />
      </Nav>
      <LanguageSwitch />
    </>
  );
};

export default Navbar;
