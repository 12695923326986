import { editor } from 'monaco-editor';
import { Position } from './Position';
import { stmtAround, PositionedStmt } from './stringUtil';

export function getCurrentPosition(e: editor.IStandaloneCodeEditor): Position {
  const currentPosition = e.getPosition();
  const currentColumn = currentPosition ? currentPosition.column : 1;
  const currentLineNumber = currentPosition ? currentPosition.lineNumber : 1;

  return new Position(currentLineNumber, currentColumn);
}

export function currentStmt(editor: editor.IStandaloneCodeEditor): PositionedStmt {
  const p = getCurrentPosition(editor);
  return stmtAround(editor.getModel()!.getLineContent(p.line), p.column);
}
