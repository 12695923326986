import classnames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import InvisibleBox from 'ui/components/common/dumb/InvisibleBox';
import styles from './Policies.module.scss';
import { useTranslation } from 'react-i18next';
import inMainScreen from 'ui/components/hocs/inMainScreen';

const Policies: React.FC = () => {
  const { t } = useTranslation('policies');
  return (
    <InvisibleBox>
      <InvisibleBox.Row>
        <InvisibleBox.Heading>{t('policies:policies')}</InvisibleBox.Heading>
      </InvisibleBox.Row>
      <InvisibleBox.Row className={styles.row}>
        <NavLink to="/privacy-policy" className={classnames(styles.link, styles.default)}>
          {t('policies:privacyPolicy')}
        </NavLink>
      </InvisibleBox.Row>
      <InvisibleBox.Row className={styles.row}>
        <NavLink to="/cookie-policy" className={classnames(styles.link, styles.default)}>
          {t('policies:cookiePolicy')}
        </NavLink>
      </InvisibleBox.Row>
      <InvisibleBox.Row className={styles.row}>
        <NavLink to="/terms-of-service" className={classnames(styles.link, styles.default)}>
          {t('policies:termsOfService')}
        </NavLink>
      </InvisibleBox.Row>
    </InvisibleBox>
  );
};

export default inMainScreen(Policies);
