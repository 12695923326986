import React from 'react';
import { FieldError, type DeepMap } from 'react-hook-form';
import classnames from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import styles from './FormError.module.scss';

interface FormMessageProps {
  errors?: DeepMap<Record<string, any>, FieldError>;
  name: string;
  msg?: string;
  className?: string;
}

const FormMessage: React.FC<FormMessageProps> = ({ errors, name, msg, className }) => (
  <>
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className={classnames(styles.error, className)}>{message}</p>}
      />
    )}
    {msg && <p className={classnames(styles.error, className)}>{msg}</p>}
  </>
);

export default FormMessage;
