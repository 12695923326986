import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button, { ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import Book39 from 'ui/components/svg/Book39';
import { START_REFERENCE_ARTICLE } from 'ui/constants';
import { useHomeScreenStoreActions } from 'ui/store/homescreen/useHomeScreenStoreActions';
import { useHelpStoreActions } from 'ui/store/help/useHelpStoreActions';
import { HomeSubscreen } from 'ui/store/homescreen/enums';
import { getTestSelector } from 'util/testUtil';

const AllTopicsButton: React.FC = () => {
  const dispatch = useDispatch();
  const { setReference } = useHelpStoreActions(dispatch);
  const { setHomeScreen } = useHomeScreenStoreActions(dispatch);
  const { t } = useTranslation('challenges');

  const onClick = useCallback(() => {
    setReference(START_REFERENCE_ARTICLE);
    setHomeScreen(HomeSubscreen.Index);
  }, [setHomeScreen, setReference]);

  return (
    <Button
      flavor={ButtonFlavor.Gray}
      size={ButtonSize.Small}
      onClick={onClick}
      Icon={Book39}
      data-testid={getTestSelector('help.all_topics')}
    >
      {t('navigation:descriptions.all_topics')}
    </Button>
  );
};

export default AllTopicsButton;
