import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '@rehooks/local-storage';
import { USER_TOKEN_STORAGE_KEY, STORAGE_API_KEY } from 'ui/constants';
import ApiService from 'ui/api/ApiService';

const useApi = (): ApiService => {
  const [userToken] = useLocalStorage(USER_TOKEN_STORAGE_KEY);
  const [apiKey] = useLocalStorage(STORAGE_API_KEY);
  const {
    i18n: { language },
  } = useTranslation();

  const lng = language.split('-')[0];
  const apiService = useMemo<ApiService>(() => new ApiService(lng, apiKey, userToken), [language, apiKey, userToken]);

  return apiService;
};

export default useApi;
