import React, { useEffect } from 'react';
import { Color } from 'turtle/Color';
import { StdFnNames } from 'turtle/StdFnName';
import styles from './FillEditZone.module.scss';
import commonStyles from '../EditZone.module.scss';
import ColorPicker from '../ColorPicker';
import { type RGBColor } from 'react-color';
import { useZoneViewType } from 'ui/components/hooks/useZoneViewType';
import 'src/i18n';
import { useTranslation } from 'react-i18next';
import Brush from 'ui/components/svg/Brush';
import { useLocalColor } from '../useLocalColor';

interface Props {
  zoneNode: HTMLElement;
  currentColor?: Color;
  applyToCodeHandler: (color: Color) => void;
  callbackSetupFn: (node: HTMLElement) => void;
}

const FillEditZone: React.FC<Props> = ({ zoneNode, currentColor, applyToCodeHandler, callbackSetupFn }) => {
  const [viewType, toggle] = useZoneViewType();
  const [localColor, setLocalColor, onChange, onPredefinedColorClick] = useLocalColor(applyToCodeHandler);
  const { t } = useTranslation('editZone');

  useEffect(() => {
    if (viewType === 'main') {
      callbackSetupFn(zoneNode);
    }
  }, [viewType, callbackSetupFn, zoneNode]);

  useEffect(() => {
    if (currentColor) {
      setLocalColor({
        r: currentColor.r,
        g: currentColor.g,
        b: currentColor.b,
      });
    }
  }, [currentColor, setLocalColor]);

  const c = localColor as RGBColor;

  return (
    <>
      {viewType === 'main' ? (
        <>
          <div className={commonStyles.row}>
            <div className={commonStyles.color}>
              {Color.ALL_DEFAULT.map((c) => (
                <button
                  title={t('color', { color: t(c.name) })}
                  key={c.name}
                  className={commonStyles.colorEntry}
                  style={{ backgroundColor: c.hex }}
                  data-value={c.name}
                  onClick={onPredefinedColorClick}
                ></button>
              ))}
              <button
                style={{ backgroundColor: `rgb(${c.r}, ${c.g}, ${c.b})` }}
                className={commonStyles.customColorBtn}
                onClick={toggle}
                title={t('selectCustomColor')}
              >
                <Brush className={commonStyles.icon} />
              </button>
            </div>
          </div>
          <div className={commonStyles.row}>
            <h4 className={styles.opacityTitle}>{t('opacity')}</h4>
            <div className={styles.opacity}>
              {StdFnNames.ALL_OPACITIES.map((op) => (
                <button title={t('opacity-' + op)} key={op} className={styles.opacityBtn} data-value={op}>
                  {op}
                </button>
              ))}
            </div>
          </div>
        </>
      ) : (
        <ColorPicker onChange={onChange} color={localColor} onGoBack={toggle} />
      )}
    </>
  );
};

export default FillEditZone;
