import { CodeType } from 'types/code';
import { ImageType } from 'types/image';
import { AXIOS_BASE_URL, APP_URL, DEFAULT_CHALLENGE_USER_LOGIN } from 'ui/constants';

function getUserPathComponent(userLogin?: string) {
  return userLogin !== undefined && userLogin !== DEFAULT_CHALLENGE_USER_LOGIN ? `${userLogin.toLowerCase()}/` : '';
}

export function pathForChallenge(id?: string, userLogin?: string): string {
  return id === undefined ? '/' : `/c/${getUserPathComponent(userLogin)}${id}`;
}

export function pathForProgram(id?: string, userLogin?: string): string {
  return id === undefined ? '/p' : `/p/${getUserPathComponent(userLogin)}${id}`;
}

export function pathForAnimation(id?: string, userLogin?: string): string {
  return id === undefined ? '/a' : `/a/${getUserPathComponent(userLogin)}${id}`;
}

export function pathForCode(codeType: CodeType, id?: string, userLogin?: string): string {
  switch (codeType) {
    case CodeType.Challenge:
      return pathForChallenge(id, userLogin);
    case CodeType.Creative:
      return pathForProgram(id, userLogin);
    case CodeType.Animation:
      return pathForAnimation(id, userLogin);
    default:
      return pathForChallenge(id, userLogin);
  }
}

export function pathForImage(codeType: CodeType, id?: string, imageType: ImageType = ImageType.Card): string {
  const codeTypePaths: { [P in CodeType]: string } = {
    [CodeType.Creative]: 'program',
    [CodeType.Challenge]: 'challenge',
    [CodeType.Animation]: 'animation',
  };
  const assetTypePath = codeTypePaths[codeType];

  return `${AXIOS_BASE_URL}/${assetTypePath}/${id}/image/${imageType}`;
}

export function pathForChallengeImage(id?: string, imageType?: ImageType): string {
  return pathForImage(CodeType.Challenge, id, imageType);
}

export function pathForProgramImage(id?: string, imageType?: ImageType): string {
  return pathForImage(CodeType.Creative, id, imageType);
}

export function pathForAnimationImage(id?: string, imageType?: ImageType): string {
  return pathForImage(CodeType.Animation, id, imageType);
}

export function setMetaTags(codeType: CodeType, id?: string, name?: string, userLogin?: string): void {
  const tags = {
    image: `${APP_URL}/banner-1.png`,
    description: 'Shelly: a programming language for drawing',
    url: APP_URL,
  };

  if (id) {
    tags.image = pathForImage(codeType, id);
    tags.url = `${APP_URL}/${pathForCode(codeType, id, userLogin)}`;
  }

  if (name && name.trim() !== '') {
    tags.description = name;
  }

  document.querySelector('meta[property="og:image"]')?.setAttribute('content', tags.image);
  document.querySelector('meta[name="twitter:image"]')?.setAttribute('content', tags.image);

  document.querySelector('meta[property="og:description"]')?.setAttribute('content', tags.description);
  document.querySelector('meta[name="twitter:description"]')?.setAttribute('content', tags.description);

  document.querySelector('meta[property="og:url"]')?.setAttribute('content', tags.url);
}

export function replaceUrl(codeType: CodeType, id?: string, userLogin?: string): void {
  const url: string = pathForCode(codeType, id, userLogin);
  window.history.replaceState(null, '', url);
}
