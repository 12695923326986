import { IconComponent } from 'types/icon';

const Brush: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 16 16">
    <g fill="#ffffff">
      <path fill="#ffffff" d="M9,10L6,7l6.4-6.4c0.8-0.8,2.2-0.8,3,0l0,0c0.8,0.8,0.8,2.2,0,3L9,10z"></path>
      <path d="M1.4,10.3c1.2-1.2,3.1-1.2,4.2,0s1.2,3.1,0,4.2S0,16,0,16S0.2,11.5,1.4,10.3z"></path>
    </g>
  </svg>
);

export default Brush;
