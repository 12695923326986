import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import Editor from 'ui/components/common/smart/Editor';
import TurtleInfoBar from 'ui/components/common/smart/TurtleInfoBar';
import HelpTopic from 'ui/components/common/smart/HelpTopic';
import ContentModal from 'ui/components/common/smart/ContentModal';
import PaintCanvas from 'ui/components/common/smart/PaintCanvas';
import ChallengeSolveResult from './ChallengeSolveResult';
import ChallengeDescription from './ChallengeDescription';
import Loader from 'ui/components/common/dumb/Loader';
import EditorTopChallenge from './EditorTopChallenge';
import EditorMessages from 'ui/components/common/smart/EditorMessages';
import ChallengePattern from './ChallengePattern';
import ChallengeTour from './ChallengeTour';
import { setMetaTags } from 'ui/components/routing/programHelpers';
import { CodeType } from 'types/code';
import { getChallengeInProgress, getPreviousChallenge } from 'ui/store/code/helpers';
import { useSelector } from 'react-redux';
import { HomeSubscreen } from 'ui/store/homescreen/enums';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useEditorLogic } from 'ui/components/hooks/useEditorLogic';

const codePropsSelector = createCodeStatePropSelector('challengeCode', 'challenge', 'challengeNodes');

const ChallengeEditor: React.FC = () => {
  const { id: idFromUrl } = useParams();
  const {
    i18n: { language: lang },
  } = useTranslation();
  const { serverFetch, subscreen, onChallengeCodeChange } = useEditorLogic(CodeType.Challenge, idFromUrl);
  const { challengeCode, challenge, challengeNodes } = useSelector(codePropsSelector);

  React.useEffect(() => {
    const isFirstChallenge = !challenge || !getPreviousChallenge(challenge, challengeNodes);

    if (isFirstChallenge) {
      setMetaTags(CodeType.Challenge);
      return;
    }

    setMetaTags(CodeType.Challenge, challenge?.id, challenge?.texts[lang].name, challenge?.userLogin);
  }, [challenge, challengeNodes]);

  if (!idFromUrl && Object.keys(challengeNodes).length > 0) {
    const redirectChallengeId = getChallengeInProgress(challengeNodes)?.data.id;
    return <Navigate to={`/c/${redirectChallengeId}`} />;
  }

  return (
    <>
      <EditorTopChallenge />
      <Editor onChange={onChallengeCodeChange} code={challengeCode} />
      <EditorMessages />
      <TurtleInfoBar />
      <PaintCanvas crosshair={false} />
      <ChallengePattern />
      {subscreen !== HomeSubscreen.None && (
        <ContentModal>
          <SubScreen subscreen={subscreen} />
        </ContentModal>
      )}
      <ChallengeTour />
      {(serverFetch.isLoading || serverFetch.isIdle) && <Loader overlay />}
    </>
  );
};

const SubScreen: React.FC<{ subscreen: HomeSubscreen }> = ({ subscreen }) => {
  switch (subscreen) {
    case HomeSubscreen.Challenges:
      return <ChallengeDescription />;
    case HomeSubscreen.ChallengeSolved:
      return <ChallengeSolveResult />;
    default:
      return <HelpTopic />;
  }
};

export default ChallengeEditor;
