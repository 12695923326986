import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const CheckList: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M44,7H21a1,1,0,0,0,0,2H44a1,1,0,0,0,0-2Z" className={styles.gray} />
      <path d="M44,23H21a1,1,0,0,0,0,2H44a1,1,0,0,0,0-2Z" className={styles.gray} />
      <path d="M44,39H21a1,1,0,0,0,0,2H44a1,1,0,0,0,0-2Z" className={styles.gray} />
      <rect height="12" width="12" className={styles.gray} rx="1" ry="1" x="3" y="2" />
      <rect height="12" width="12" className={styles.gray} rx="1" ry="1" x="3" y="34" />
      <path
        d="M14.211,18.386,7.906,26.492l-3.2-3.2a1,1,0,0,0-1.414,1.414l4,4A1,1,0,0,0,8,29l.062,0a1,1,0,0,0,.727-.384l7-9a1,1,0,1,0-1.578-1.228Z"
        className={styles.green}
      />
    </g>
  </svg>
);

export default CheckList;
