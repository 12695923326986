import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { usePromise } from 'react-use-promise-matcher';
import Input from 'ui/components/common/dumb/Input';
import TextArea from 'ui/components/common/dumb/TextArea';
import Form from 'ui/components/common/dumb/Form';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import FormError from 'ui/components/common/dumb/FormError';
import useApi from 'ui/components/hooks/useApi';
import { DataItem } from 'types/data';
import { renderToastPopup } from 'util/toast';
import styles from './CustomChallengeWrapper.module.scss';
import ShareChallenge from '../ShareChallenge';
import Modal from 'ui/components/common/smart/Modal';
import { userSelector } from 'ui/store/user/selectors';
import { getTestSelector } from 'util/testUtil';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';

type Props = {
  onClose: () => void;
};

const codePropsSelector = createCodeStatePropSelector('program', 'customChallengeDesc', 'customChallengeName');

const CustomChallengeCreator: React.FC<Props> = ({ onClose }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('challenges');
  const { postChallenge } = useApi();
  const {
    program: { name, id },
    customChallengeDesc,
    customChallengeName,
  } = useSelector(codePropsSelector);
  const dispatch = useDispatch();
  const { setCustomChallengeDesc, setCustomChallengeName } = useCodeStoreActions(dispatch);

  const user = useSelector(userSelector);
  const [submitted, setSubmitted] = useState(false);

  const { register, handleSubmit, formState, setValue, trigger, reset } = useForm({
    mode: 'onChange',
    defaultValues: { name: customChallengeName || name || '', descriptions: customChallengeDesc || '' },
  });

  const { errors } = formState;

  const submitHandler = useCallback(
    async (payload: DataItem) => {
      if (id) {
        const { name, descriptions } = payload;
        setCustomChallengeDesc(descriptions);
        setCustomChallengeName(name);
        return postChallenge(id, {
          [language]: {
            name,
            description: descriptions,
          },
        });
      }
      return undefined;
    },
    [postChallenge, setCustomChallengeDesc, setCustomChallengeName, language, id]
  );

  const [result, load] = usePromise(submitHandler);

  const submitChallenge = React.useCallback(() => {
    setTimeout(() => setSubmitted(true), 0);
  }, [setSubmitted]);

  useEffect(() => {
    if (name) {
      setValue('name', name);
      trigger('name');
    }
  }, [name, setValue, trigger]);

  useEffect(() => {
    if (result.isResolved) {
      renderToastPopup('success', t('create.message.success'));
    }
  }, [result, setSubmitted]);

  useEffect(() => {
    reset();
  }, [user, id]);

  if (submitted) {
    return <ShareChallenge result={result} onClose={onClose} />;
  }

  return (
    <Modal onClose={onClose} heading={t('create.description')} showLogo showLang={false}>
      <Form
        className={styles.container}
        onSubmit={handleSubmit(load)}
        data-testid={getTestSelector('social_media.custom_challenge_form')}
      >
        <Input
          type="text"
          id="name"
          placeholder={t('create.title.placeholder')}
          {...register('name', { required: t('create.title.required') as string })}
        />
        <FormError errors={errors} name="name" className={styles.error} />
        <TextArea
          rows={10}
          cols={20}
          id="descriptions"
          placeholder={t('create.content.placeholder')}
          {...register('descriptions', { required: t('create.content.required') as string })}
        />
        <FormError errors={errors} name="descriptions" />
        <Button
          flavor={ButtonFlavor.Success}
          disabled={!formState.isValid}
          onClick={submitChallenge}
          type="submit"
          data-testid={getTestSelector('social_media.custom_challenge_submit')}
        >
          {t('create.submit')}
        </Button>
      </Form>
    </Modal>
  );
};

export default CustomChallengeCreator;
