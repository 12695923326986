import React from 'react';
import Stats from './Stats';
import styles from './TurtleInfoBar.module.scss';
import Switch from './Switch';
import SaveAsImage from 'ui/components/common/smart/SaveAsImage';
import { PageTourStep } from 'ui/components/common/smart/PageTour/types';

const TurtleInfoBar: React.FC = () => (
  <div className={styles.container} data-tour-area={PageTourStep.Canvas}>
    <div className={styles.left}>
      <Switch />
    </div>
    <div className={styles.right}>
      <SaveAsImage as="button" />
      <Stats />
    </div>
  </div>
);

export default TurtleInfoBar;
