import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import ProgressBar from 'ui/components/common/dumb/ProgressBar';
import ProgressIndicator from 'ui/components/common/dumb/ProgressIndicator';
import Rating from 'ui/components/common/dumb/Rating';
import ChallengeListItem from './ChallengeListItem';
import styles from './ChallengeList.module.scss';
import { ChallengePackLevel, ChallengeState } from 'types/challenge';
import CircleCaretDown from 'ui/components/svg/CircleCaretDown';
import CircleCaretRight from 'ui/components/svg/CircleCaretRight';
import Check from 'ui/components/svg/Check';
import Controller from 'ui/components/svg/Controller';
import Padlock from 'ui/components/svg/Padlock';
import Tooltip from 'ui/components/common/smart/Tooltip';
import { useSelector } from 'react-redux';
import { challengeNodesSelector } from 'ui/store/code/selectors';

interface Props {
  level: ChallengePackLevel;
}

const ChallengeList: React.FC<Props> = ({ level }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['user_activity', 'challenges']);
  const { challengeNodes } = useSelector(challengeNodesSelector);
  const [open, setOpen] = useState<boolean>(true);

  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const progressRange = level.challengeNodeIds.length;
  const challenges = level.challengeNodeIds.map((id) => challengeNodes[id]);
  const progressValue = challenges.filter((c) => c.data.attempted === true && c.data.stars > 0).length;
  const isLocked = challenges.every((c) => c.state === ChallengeState.Locked);
  const isInProgress = challenges.some((c) => c.state === ChallengeState.InProgress);
  const isDone = progressValue === progressRange;
  const { difficulty } = level;

  const getDifficultyLevelDescription = useCallback(() => {
    let levelKey = '';
    if (difficulty <= 2) levelKey = 'junior';
    else if (difficulty > 2 && difficulty <= 4) levelKey = 'mid';
    else levelKey = 'senior';
    return t(`challenges:challenge.level.${levelKey}`);
  }, [difficulty, t]);

  const getLevelStateDescription = useCallback(() => {
    let statusKey = '';
    if (isDone) statusKey = 'done';
    else if (isInProgress) statusKey = 'in_progress';
    else statusKey = 'locked';
    return t(`challenges:challenge.level.status.${statusKey}`);
  }, [isDone, isInProgress, isLocked, t]);

  return (
    <details className={styles.container} open onToggle={toggle}>
      <summary className={styles.info}>
        <div className={styles.headings}>
          {open ? <CircleCaretDown className={styles.icon} /> : <CircleCaretRight className={styles.icon} />}
          <h4 className={styles.heading}>{level.names[language]}</h4>
          <Tooltip className={styles.tooltip} tooltip={getDifficultyLevelDescription()}>
            <Rating.DifficultyRating value={level.difficulty} iconClass={styles.ratingIcon} readonly />
          </Tooltip>
          <Tooltip className={styles.tooltip} tooltip={getLevelStateDescription()}>
            {isDone && <Check className={classnames(styles.icon, styles['icon--levelState'])} />}
            {isInProgress && <Controller className={classnames(styles.icon, styles['icon--levelState'])} />}
            {isLocked && <Padlock className={classnames(styles.icon, styles['icon--levelState'])} />}
          </Tooltip>
        </div>
        <div className={styles.progress}>
          <ProgressIndicator
            value={progressValue}
            range={progressRange}
            text={t('challenges:descriptions.indicator')}
            className={styles.subHeading}
          />
          <ProgressBar value={progressValue} max={progressRange} containerClassName={styles.progressBar} />
        </div>
      </summary>
      <ul className={classnames(styles.list, { [styles.locked]: isLocked })}>
        {isLocked ? (
          <h5 className={styles.locked}>{t('user_activity:challenges.locked')}</h5>
        ) : (
          <>
            {challenges.map((c) => (
              <ChallengeListItem key={c.data.id} challenge={c.data} state={c.state as ChallengeState} />
            ))}
          </>
        )}
      </ul>
    </details>
  );
};

export default ChallengeList;
