import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PromiseResultShape, usePromise } from 'react-use-promise-matcher';
import { ProgramOnServer } from 'types/program';
import { renderToastPopup } from 'util/toast';
import useApi from 'ui/components/hooks/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';

const codePropSelector = createCodeStatePropSelector('program');

const useServerCreative = (idFromUrl: string | undefined): PromiseResultShape<ProgramOnServer | null, string> => {
  const { t } = useTranslation();
  const { getProgram } = useApi();
  const dispatch = useDispatch();
  const { setProgram, setCreativeCode } = useCodeStoreActions(dispatch);
  const { program } = useSelector(codePropSelector);

  const fetchCreative = useCallback(() => {
    if (idFromUrl === undefined || idFromUrl === program.id) return Promise.resolve(null);

    return getProgram(idFromUrl).then((response) => response.data);
  }, [idFromUrl, getProgram, program]);
  const [result, load] = usePromise(fetchCreative);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    result.match({
      Loading: () => null,
      Resolved: (creative) => {
        if (creative === null) return;

        setProgram(creative);
        setCreativeCode(creative.text);
      },
      Rejected: (err) => {
        renderToastPopup('error', t(`toast:cannotLoadChallenge`));
        console.error(err);
      },
    });
  }, [result]);

  return result;
};

export default useServerCreative;
