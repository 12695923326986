import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import ContentHeader from './ContentHeader';
import styles from './ContentModal.module.scss';
import { PageTourStep } from 'ui/components/common/smart/PageTour/types';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { CodeType } from 'types/code';

const codeTypeSelector = createCodeStatePropSelector('codeType');

const ContentModal = ({ children }: { children: ReactNode }) => {
  const { codeType } = useSelector(codeTypeSelector);
  return (
    <>
      <ContentHeader />
      <div
        className={classNames(styles.container, { [styles.animations]: codeType === CodeType.Animation })}
        data-tour-area={PageTourStep.ChallengeDescription}
      >
        <div className={styles.innerContainer}>{children}</div>
      </div>
    </>
  );
};

export default ContentModal;
