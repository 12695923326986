import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Bus: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M47,33H1V12c0-2.76142,2.23858-5,5-5h36c2.76143,0,5,2.23858,5,5V33z" className={styles.yellow} />
      <path d="M47,29h-1c-0.55228,0-1-0.44772-1-1v-2c0-0.55228,0.44772-1,1-1h1V29z" className={styles.red} />
      <path d="M47,33H1v1c0,0.55228,0.44772,1,1,1h44c0.55228,0,1-0.44772,1-1V33z" className={styles.yellow} />
      <path
        d="M11,41c-3.30859,0-6-2.69141-6-6s2.69141-6,6-6s6,2.69141,6,6S14.30859,41,11,41z"
        className={styles.black}
      />
      <path
        d="M37,41c-3.30859,0-6-2.69141-6-6s2.69141-6,6-6s6,2.69141,6,6S40.30859,41,37,41z"
        className={styles.black}
      />
      <path d="M11,38c-1.6543,0-3-1.3457-3-3s1.3457-3,3-3s3,1.3457,3,3S12.6543,38,11,38z" className={styles.white} />
      <path d="M37,38c-1.6543,0-3-1.3457-3-3s1.3457-3,3-3s3,1.3457,3,3S38.6543,38,37,38z" className={styles.white} />
      <path
        d="M13,19H9c-2.20914,0-4-1.79086-4-4v-5c0-0.55229,0.44772-1,1-1h7c0.55229,0,1,0.44771,1,1v8 C14,18.55228,13.55229,19,13,19z"
        className={styles.white}
      />
      <path
        d="M24,19h-7c-0.55228,0-1-0.44772-1-1v-8c0-0.55229,0.44772-1,1-1h7c0.55228,0,1,0.44771,1,1v8 C25,18.55228,24.55228,19,24,19z"
        className={styles.white}
      />
      <path
        d="M35,19h-7c-0.55228,0-1-0.44772-1-1v-8c0-0.55229,0.44772-1,1-1h7c0.55228,0,1,0.44771,1,1v8 C36,18.55228,35.55228,19,35,19z"
        className={styles.white}
      />
      <path
        d="M44,22h-2.58578c-0.26522,0-0.51957-0.10536-0.70711-0.29289l-2.41422-2.41422 C38.10536,19.10536,38,18.851,38,18.58579V10c0-0.55229,0.44772-1,1-1h3c1.65685,0,3,1.34315,3,3v9C45,21.55228,44.55228,22,44,22z"
        className={styles.white}
      />
    </g>
  </svg>
);

export default Bus;
