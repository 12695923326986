import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { usePromise } from 'react-use-promise-matcher';
import PromptBox from 'ui/components/common/dumb/PromptBox';
import styles from '../MyProfile.module.scss';
import Form from 'ui/components/common/dumb/Form';
import Input from 'ui/components/common/dumb/Input';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import { useForm } from 'react-hook-form';
import useApi from 'ui/components/hooks/useApi';
import FormError from 'ui/components/common/dumb/FormError';
import Loader from 'ui/components/common/dumb/Loader';
import { renderToastPopup } from 'util/toast';
import { DataItem } from 'types/data';
import { useTranslation } from 'react-i18next';

const successMsg = 'Password successfully changed';

const PasswordDetails: React.FC = () => {
  const { register, handleSubmit, formState, clearErrors, watch } = useForm({
    mode: 'onChange',
  });
  const { t } = useTranslation('passwordDetails');
  const { changePassword } = useApi();

  const { errors } = formState;

  const submitHandler = (payload: Record<string, any>) => changePassword(payload.currentPassword, payload.newPassword);

  const [result, load] = usePromise<any, any, DataItem>(submitHandler);

  const renderForm = useCallback(() => {
    return (
      <Form onSubmit={handleSubmit(load)}>
        <Input
          type="password"
          className={styles.input}
          placeholder={t('currentPassword')}
          {...register('currentPassword', {
            required: t('currentPasswordRequired') as string,
          })}
        />
        <FormError errors={errors} name="currentPassword" />
        <Input
          type="password"
          className={styles.input}
          placeholder={t('newPassword')}
          {...register('newPassword', {
            required: true,
          })}
        />
        <FormError errors={errors} name="newPassword" />
        <Input
          type="password"
          className={styles.input}
          placeholder={t('repeatNewPassword')}
          {...register('repeatNewPassword', {
            required: true,
            validate: (value: string) => value === watch('newPassword'),
          })}
        />
        {errors.repeatNewPassword && <FormError msg={t('repeatPasswordError')} name="repeatNewPassword" />}
        <Button flavor={ButtonFlavor.Success} disabled={!formState.isValid} className={styles.btn}>
          {t('updatePassword')}
        </Button>
      </Form>
    );
  }, [register, errors, register, handleSubmit, load, t]);

  useEffect(() => {
    return () => {
      clearErrors();
    };
  }, [clearErrors]);

  useEffect(() => {
    result.match({
      Loading: () => null,
      Rejected: (err: any) => {
        renderToastPopup('error', err.response.data.error);
      },
      Resolved: () => {
        renderToastPopup('success', successMsg);
      },
    });
  }, [result]);

  return (
    <PromptBox>
      <PromptBox.Row>
        <PromptBox.Heading>{t('details')}</PromptBox.Heading>
      </PromptBox.Row>
      <PromptBox.Row className={styles.row}>
        {result.match({
          Idle: renderForm,
          Loading: () => <Loader />,
          Rejected: (err: any) => (
            <>
              {renderForm()}
              <p className={classnames(styles.message, styles.error)}>{err.response.data.error}</p>
            </>
          ),
          Resolved: () => (
            <>
              {renderForm()}
              <p className={classnames(styles.message, styles.success)}>{successMsg}</p>
            </>
          ),
        })}
      </PromptBox.Row>
    </PromptBox>
  );
};

export default PasswordDetails;
