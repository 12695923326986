import React from 'react';
import { ChallengePackLevel } from 'types/challenge';
import ChallengeList from './ChallengeList';
import { useTranslation } from 'react-i18next';
import styles from '../Challenges.module.scss';
import OverallResultsBox from './OverallResultsBox';
import { getTestSelector } from 'util/testUtil';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useSelector } from 'react-redux';

const challengeLevelsSelector = createCodeStatePropSelector('challengeLevels');

const SystemChallenges: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { challengeLevels } = useSelector(challengeLevelsSelector);

  return (
    <div className={styles.container} data-testid={getTestSelector('system_challenges.container')}>
      <h1 data-testid={getTestSelector('system_challenges.heading')}>{t('challenges:descriptions.heading')}</h1>
      <OverallResultsBox />
      {challengeLevels.map((level: ChallengePackLevel, i: number) => (
        <ChallengeList key={`${level.names[language]}_${i}`} level={level} />
      ))}
    </div>
  );
};

export default SystemChallenges;
