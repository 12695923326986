import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Stop: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      className={styles.red}
      stroke="none"
      d="M41,43H7c-1.10457,0-2-0.89543-2-2V7c0-1.10457,0.89543-2,2-2h34c1.10457,0,2,0.89543,2,2v34 C43,42.10457,42.10457,43,41,43z"
    ></path>
  </svg>
);

export default Stop;
