import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onClickOutside: (event: MouseEvent) => void;
  onClickInside?: (event: React.MouseEvent) => void;
}

const ClickOutsideDetector: React.FC<Props> = ({ children, onClickOutside, onClickInside, ...props }) => {
  const wrapper = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (!wrapper.current || wrapper.current.contains(event.target as Node)) {
        return;
      }

      onClickOutside(event);
    },
    [onClickOutside]
  );

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div ref={wrapper} onClick={onClickInside} {...props}>
      {children}
    </div>
  );
};

export default ClickOutsideDetector;
