import React from 'react';
import { useTranslation } from 'react-i18next';
import smlLogo from 'img/sml_logo.svg';
import AppLink from 'ui/components/common/dumb/AppLink';
import styles from '../Footer.module.scss';

const CreatedBy: React.FC = () => {
  const { t } = useTranslation(['footer']);
  return (
    <div className={styles.info}>
      <span className={styles.createdBy}>{t('createdBy')}</span>
      <a href="https://softwaremill.com" target="_blank" rel="noopener noreferrer">
        <img className={styles.logo} src={smlLogo} alt="SoftwareMill Logo" />
      </a>
      <span className={styles.link}>
        <AppLink to="/policies" className={styles.link}>
          {t('footer:legal')}
        </AppLink>
      </span>
      <span className={styles.link}>
        <AppLink to="/contact-us" className={styles.link}>
          {t('footer:contactUs')}
        </AppLink>
      </span>
    </div>
  );
};

export default CreatedBy;
