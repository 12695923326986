import {
  ChallengeState,
  ChallengeNodeList,
  ChallengeNode,
  ChallengeOnServer,
  ChallengePackLevel,
  ChallengePackLevelOnServer,
} from 'types/challenge';

const formChallengeNode = (
  challenge: ChallengeOnServer,
  index: number,
  challenges: ChallengeOnServer[]
): ChallengeNode => ({
  data: challenge,
  state: challenge.stars > 0 ? ChallengeState.Done : ChallengeState.Locked,
  prevNodeId: challenges[index - 1]?.id || null,
  nextNodeId: challenges[index + 1]?.id || null,
});

const setInProgressState = (node: ChallengeNode, index: number, nodes: ChallengeNode[]): ChallengeNode => ({
  ...node,
  state:
    nodes[index - 1]?.state !== ChallengeState.Locked && node.state === ChallengeState.Locked
      ? ChallengeState.InProgress
      : node.state,
});

export const formChallengeLevelsOnServer = (
  nodeList: ChallengeNodeList,
  levels: ChallengePackLevel[]
): ChallengePackLevelOnServer[] =>
  levels.map((level) => ({
    ...level,
    challenges: level.challengeNodeIds.map((id) => nodeList[id]?.data || null),
  }));

export const formChallengeNodes = (levels: ChallengePackLevelOnServer[]): ChallengeNodeList =>
  Object.fromEntries(
    levels
      .flatMap(({ challenges }) => challenges)
      .map(formChallengeNode)
      .map(setInProgressState)
      .map((node) => [node.data.id, node])
  );

export const formChallengeLevels = (levels: ChallengePackLevelOnServer[]): ChallengePackLevel[] =>
  levels.map(({ challenges, ...level }) => ({
    ...level,
    challengeNodeIds: challenges.map(({ id }) => id),
  }));
