import React from 'react';
import { Phase } from './types';
import ShareProgram from './ShareProgram';
import CustomChallengeCreator from './CustomChallengeCreator';

interface Props {
  onClose: () => void;
}

const SocialShare: React.FC<Props> = ({ onClose }) => {
  const [phase, setPhase] = React.useState(Phase.ShareProgram);

  switch (phase) {
    case Phase.CreateChallenge:
      return <CustomChallengeCreator onClose={onClose} />;
    case Phase.ShareProgram:
    default:
      return <ShareProgram setPhase={setPhase} onClose={onClose} />;
  }
};

export default SocialShare;
