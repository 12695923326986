import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Rating from 'ui/components/common/dumb/Rating';
import { ChallengeOnServer, ChallengeState } from 'types/challenge';
import { LinkButton, ButtonSize, ButtonFlavor } from 'ui/components/common/dumb/Button';
import { pathForChallenge, pathForChallengeImage } from 'ui/components/routing/programHelpers';
import styles from './ChallengeListItem.module.scss';
import { clamp } from 'util/stringUtil';
import Image from 'ui/components/common/dumb/Image';

interface Props {
  challenge: ChallengeOnServer;
  state: ChallengeState;
}

const ChallengeListItem: React.FC<Props> = ({ challenge, state }) => {
  const { texts, stars, id, userLogin } = challenge;
  const {
    t,
    i18n: { language },
  } = useTranslation(['challenges', 'toast']);

  return (
    <li className={classnames(styles.container, styles[state])}>
      <Image
        src={pathForChallengeImage(challenge.id)}
        className={styles.thumbnail}
        alt={challenge.texts[language].name}
      />
      <div className={styles.content}>
        <div className={styles.titleBox}>
          <h2 className={styles.title}>{challenge.texts[language].name}</h2>
          <Rating.SolutionRating value={stars} iconClass={styles.ratingIcon} readonly />
        </div>
        <p className={styles.description}>{clamp(texts[language].description, 100)}</p>
        {state && state != ChallengeState.Locked && (
          <div className={styles.actionBox}>
            <LinkButton
              size={ButtonSize.Small}
              flavor={state == ChallengeState.InProgress ? ButtonFlavor.Success : ButtonFlavor.Gray}
              to={pathForChallenge(id, userLogin)}
            >
              {t(`challenges:cta.${state}`)}
            </LinkButton>
          </div>
        )}
      </div>
    </li>
  );
};

export default ChallengeListItem;
