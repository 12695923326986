import { IconComponent } from 'types/icon';

const TurtleGlyph: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M14.55426,13.30969C13.72382,13.11182,12.87189,13,12,13C5.93457,13,1,17.93457,1,24 c0,1.23145,0.21289,2.45996,0.63184,3.64941c0.11719,0.33301,0.40137,0.5791,0.74707,0.64844 c0.06543,0.0127,0.13086,0.01953,0.19629,0.01953c0.28027,0,0.55176-0.11816,0.74316-0.33105 c1.27399-1.4137,2.95123-2.37677,4.77228-2.77686C8.57373,20.33014,11.01605,16.07324,14.55426,13.30969z" />
      <path d="M8.77661,32.68726C6.4455,34.3512,5,37.04388,5,40 c0,1.02344,0.19629,2.07324,0.58496,3.12109c0.10742,0.29102,0.34473,0.51562,0.64062,0.60742 c0.09766,0.03027,0.19727,0.04492,0.29688,0.04492c0.20312,0,0.40527-0.0625,0.5752-0.18262 c1.72076-1.21039,4.19891-1.86438,5.82935-2.18646C11.04114,38.85553,9.599,35.80603,8.77661,32.68726z" />
      <path d="M24,10c2.51239,0,4.88214,0.63574,7,1.73688V8c0-3.85938-3.14062-7-7-7s-7,3.14062-7,7 v3.73688C19.11786,10.63574,21.48761,10,24,10z" />
      <path d="M36,13c-0.87189,0-1.72382,0.11182-2.55426,0.30969 c3.53821,2.76355,5.98053,7.02045,6.46362,11.89978c1.82104,0.40009,3.49829,1.36316,4.77228,2.77686 c0.19141,0.21289,0.46289,0.33105,0.74316,0.33105c0.06543,0,0.13086-0.00684,0.19629-0.01953 c0.3457-0.06934,0.62988-0.31543,0.74707-0.64844C46.78711,26.45996,47,25.23145,47,24C47,17.93457,42.06543,13,36,13z" />
      <path d="M39.22125,32.69513c-0.82281,3.11603-2.26398,6.16254-4.14825,8.70923 c1.63043,0.32208,4.10858,0.97607,5.82935,2.18646c0.16992,0.12012,0.37109,0.18262,0.5752,0.18262 c0.09961,0,0.19922-0.01465,0.29688-0.04492c0.2959-0.0918,0.5332-0.31641,0.64062-0.60742C42.80371,42.07324,43,41.02344,43,40 C43,37.04938,41.55383,34.35895,39.22125,32.69513z" />
      <path d="M10,27c0-8.28427,6.26801-15,14-15s14,6.71573,14,15s-6.26801,19-14,19S10,35.28427,10,27z" />
    </g>
  </svg>
);

export default TurtleGlyph;
