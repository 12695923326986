import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const CircleCaretDown: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 48 48">
    <g>
      <path d="M24,47A23,23,0,1,0,1,24,23,23,0,0,0,24,47Z" className={styles.transparent} />
      <path
        d="M23.168,32.555l-8-12A1,1,0,0,1,16,19H32a1,1,0,0,1,.832,1.554l-8,12a1,1,0,0,1-1.664,0Z"
        className={styles.green}
      />
    </g>
  </svg>
);

export default CircleCaretDown;
