import React from 'react';
import Privacy from 'ui/legal/privacy.mdx';
import InvisibleBox from 'ui/components/common/dumb/InvisibleBox';
import { useTranslation } from 'react-i18next';
import inMainScreen from 'ui/components/hocs/inMainScreen';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation(['policies']);
  return (
    <InvisibleBox>
      <InvisibleBox.Row>
        <InvisibleBox.Heading>{t('policies:privacyPolicy')}</InvisibleBox.Heading>
      </InvisibleBox.Row>
      <InvisibleBox.Row>
        <Privacy />
      </InvisibleBox.Row>
    </InvisibleBox>
  );
};

export default inMainScreen(PrivacyPolicy);
