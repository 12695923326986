import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styles from './RegistrationInfoBox.module.scss';

const RegistrationInfoBox: React.FC = () => {
  const { t } = useTranslation('challenges');
  return (
    <div className={styles.container}>
      <p className={styles.info}>
        <Trans t={t} i18nKey="challenge.infobox.register">
          If you&apos;d like your progress to be saved, be sure to <NavLink to="/user/register">register</NavLink>
        </Trans>
      </p>
    </div>
  );
};

export default RegistrationInfoBox;
