import { Map } from 'immutable';
import { Position } from './Position';

export class ByPos<T> {
  private data: Map<number, Map<number, T[]>> = Map();

  add(p: Position, t: T): void {
    const lineData = this.data.get(p.line) || Map();
    const columnData = lineData.get(p.column) || [];

    columnData.push(t);

    this.data = this.data.set(p.line, lineData.set(p.column, columnData));
  }

  find(p: Position): T[] {
    const inThisLine = this.doFindInLine(p);
    if (inThisLine) {
      return inThisLine;
    }

    // if not found, looking for the right-most value in first available of previous lines
    const previousLineData = ByPos.findAtOrBefore(this.data, p.line - 1) || Map();
    const maxCol = previousLineData.keySeq().max();
    if (maxCol != undefined) {
      return previousLineData.get(maxCol) || [];
    } else {
      return [];
    }
  }

  findInLine(p: Position): T[] {
    return this.doFindInLine(p) || [];
  }

  private doFindInLine(p: Position): T[] | undefined {
    // looking for a value in the given line, at or before the given column
    const lineData = this.data.get(p.line);
    if (lineData) {
      const colData = ByPos.findAtOrBefore(lineData, p.column);
      if (colData) {
        return colData;
      }
    }

    return undefined;
  }

  findLast(): T | undefined {
    const maxLine = this.data.keySeq().max();
    if (maxLine) {
      const maxLineData = this.data.get(maxLine)!;
      const maxCol = maxLineData.keySeq().max();
      if (maxCol) {
        const values = maxLineData.get(maxCol)!;
        return values[values.length - 1];
      }
    }
    return undefined;
  }

  private static findAtOrBefore<T>(m: Map<number, T>, n: number): T | undefined {
    let i = n;
    while (i >= 0) {
      const r = m.get(i);
      if (r) return r;
      i--;
    }
    return undefined;
  }
}
