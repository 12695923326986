import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Single05: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M43.2495117,37.7966309L30,32V22H18v10l-0.124939,0.062439 l-13.12146,5.7341919C3.2328491,38.4612427,2,40.3496704,2,42.0092163v2.987854C2,45.5509644,2.4498291,46,3.001709,46h41.9966431 C45.5515747,46,46,45.5621338,46,44.9970703v-2.987854C46,40.34729,44.7710571,38.4623413,43.2495117,37.7966309z"
        className={styles.darkGreen}
        fillRule="evenodd"
      />
      <path
        d="M24,28c-6.0654297,0-11-4.9345703-11-11v-4c0-6.0654297,4.9345703-11,11-11s11,4.9345703,11,11v4 C35,23.0654297,30.0654297,28,24,28z"
        className={styles.green}
      />
    </g>
  </svg>
);

export default Single05;
