import { Dispatch, createContext } from 'react';
import type { StepConfig, Inset } from './types';

export interface TourState {
  steps: StepConfig[];
  step: StepConfig | null;
  requestedStepIndex: number;
  elementInset: Inset | null;
  onStart: () => void;
  onEnd: () => void;
}

export enum ActionType {
  Set = 'set',
  NextStep = 'next-step',
  PreviousStep = 'previous-step',
}

export interface SetAction {
  type: ActionType.Set;
  payload: Partial<TourState>;
}
export interface NextStepAction {
  type: ActionType.NextStep;
}
export interface PreviosStepAction {
  type: ActionType.PreviousStep;
}
export type Action = SetAction | NextStepAction | PreviosStepAction;

export interface ContextValue {
  dispatch: Dispatch<Action>;
  state: TourState;
}

export const initialState: TourState = {
  steps: [],
  step: null,
  requestedStepIndex: 0,
  elementInset: null,
  onStart: () => null,
  onEnd: () => null,
};

export const reducer = (state: TourState = initialState, action: Action): TourState => {
  switch (action.type) {
    case ActionType.Set:
      return {
        ...state,
        ...action.payload,
      };
    case ActionType.NextStep:
      return {
        ...state,
        requestedStepIndex: state.requestedStepIndex + 1,
      };
    case ActionType.PreviousStep:
      return {
        ...state,
        requestedStepIndex: state.requestedStepIndex - 1,
      };
    default:
      return state;
  }
};

const Context = createContext<ContextValue>({
  state: initialState,
  dispatch: () => null,
});

export default Context;
