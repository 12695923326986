import { IconComponent } from 'types/icon';

const Facebook: IconComponent = (props) => (
  <svg viewBox="0 0 25 25" fill="none" {...props}>
    <path
      d="M10.5184 22L10.4909 13.8182H7V10.5455H10.4909V8.5C10.4909 5.4634 12.4967 4 15.3862 4C16.7702 4 17.9598 4.09661 18.3064 4.13979V7.31321L16.3025 7.31407C14.731 7.31407 14.4267 8.01412 14.4267 9.0414V10.5455H19L17.2545 13.8182H14.4267V22H10.5184Z"
      fill={props.fill}
    />
  </svg>
);

export default Facebook;
