import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const CircleCaretRight: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <g>
      <path d="M47,24A23,23,0,1,0,24,47,23,23,0,0,0,47,24Z" className={styles.transparent} />
      <path
        d="M32.555,24.832l-12,8A1,1,0,0,1,19,32V16a1,1,0,0,1,1.554-.832l12,8a1,1,0,0,1,0,1.664Z"
        className={styles.green}
      />
    </g>
  </svg>
);

export default CircleCaretRight;
