import { InstrNames } from './InstrNames';
import { StdFnNames } from './StdFnName';

export class PenAttrNames {
  static COLOR = 'color';
  static THICKNESS = 'thickness';
  static PATTERN = 'pattern';

  static ALL: string[] = [PenAttrNames.COLOR, PenAttrNames.THICKNESS, PenAttrNames.PATTERN];
}

export class FillAttrNames {
  static COLOR = 'color';
  static OPACITY = 'opacity';

  static ALL: string[] = [FillAttrNames.COLOR, FillAttrNames.OPACITY];
}

//

const COLOR_HEX_REGEXP = /#[a-fA-F0-9]{6}/;

// eslint-disable-next-line
export function verifyAttr(attributable: string, key: string, value: any): boolean {
  if (key === FillAttrNames.COLOR && value === StdFnNames.NONE) {
    return true;
  }

  if (key === PenAttrNames.COLOR) {
    return COLOR_HEX_REGEXP.test(value);
  }

  if (attributable === InstrNames.Attributable.PEN) {
    if (key === PenAttrNames.THICKNESS) {
      return StdFnNames.ALL_THICKNESS.indexOf(value) >= 0;
    }
    if (key === PenAttrNames.PATTERN) {
      return StdFnNames.ALL_PATTERNS.indexOf(value) >= 0;
    }
  }

  if (attributable === InstrNames.Attributable.FILL) {
    if (key === FillAttrNames.OPACITY) {
      return StdFnNames.ALL_OPACITIES.indexOf(value) >= 0;
    }
  }

  return false;
}
