import React from 'react';
import TourPortal from './TourPortal';
import Context, { reducer, initialState } from './Context';
import TourCore from './TourCore';
import actions from './actions';
import { defaultStep } from './defaults';
import { type TourProps } from './types';

const Tour: React.FC<TourProps> = ({ tourRoot, steps, children, onStart = () => null, onEnd = () => null }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const filledSteps = steps.map((step) => ({ ...defaultStep, ...step }));
    actions(dispatch).set({
      steps: filledSteps,
      onStart,
      onEnd,
    });
  }, [steps, dispatch]);

  return (
    <TourPortal tourRoot={tourRoot}>
      <Context.Provider value={{ state, dispatch }}>
        <TourCore />
        {children}
      </Context.Provider>
    </TourPortal>
  );
};

export default Tour;
