import { Direction } from './types';
import type { StepConfig } from './types';

export const defaultStep: StepConfig = {
  area: '',
  content: '',
  position: Direction.Right,
  align: Direction.Top,
  onBefore: () => null,
  onAfter: () => null,
};
