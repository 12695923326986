import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkButton, ButtonSize } from 'ui/components/common/dumb/Button';
import { pathForCode } from '../../programHelpers';
import CodeEditor from 'ui/components/svg/CodeEditor';
import { codeIdSelector, codeLoginSelector, createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useSelector } from 'react-redux';

const codeTypeSelector = createCodeStatePropSelector('codeType');

const NonEditorMenu: React.FC = () => {
  const { t } = useTranslation('navigation');
  const { codeType } = useSelector(codeTypeSelector);
  const codeId = useSelector(codeIdSelector);
  const codeLogin = useSelector(codeLoginSelector);

  return (
    <LinkButton
      Icon={CodeEditor}
      size={ButtonSize.Small}
      to={pathForCode(codeType, codeId, codeLogin)}
      title={t('navigation:subtype.editor')}
      noBorder
      bigIcon
    />
  );
};

export default NonEditorMenu;
