import { PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from './TooltipCard.module.scss';
import { Direction } from '../types';
import TooltipTick from 'ui/components/svg/TooltipTick';

interface Props {
  className?: string;
  direction: Direction;
  distance?: number;
}

const CHEVRON_LENGTH = 8;

const TooltipCard = ({ children, className, direction, distance = 0 }: PropsWithChildren<Props>) => {
  const containerStylesMap: { [P in Direction]: React.CSSProperties } = {
    bottom: { top: `calc(100% + ${distance + CHEVRON_LENGTH}px)`, left: '50%', transform: `translateX(-50%)` },
    left: { right: `calc(100% + ${distance + CHEVRON_LENGTH}px)`, top: '50%', transform: `translateY(-50%)` },
    right: { left: `calc(100% + ${distance + CHEVRON_LENGTH}px)`, top: '50%', transform: `translateY(-50%)` },
    top: { bottom: `calc(100% + ${distance + CHEVRON_LENGTH}px)`, left: '50%', transform: `translateX(-50%)` },
    'top-left': {
      bottom: `calc(100% + ${distance + CHEVRON_LENGTH}px)`,
      left: `calc(50% - ${CHEVRON_LENGTH}px)`,
    },
    'bottom-left': {
      top: `calc(100% + ${distance + CHEVRON_LENGTH}px)`,
      left: `calc(50% - ${CHEVRON_LENGTH}px)`,
    },
  };

  return (
    <div style={containerStylesMap[direction]} className={classnames(styles.container, className)}>
      <TooltipTick className={classnames(styles.chevron, styles[direction])} />
      {children}
    </div>
  );
};

export default TooltipCard;
