import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ANIMATION_PATHS, CHALLENGE_PATHS, PROGRAM_PATHS } from 'ui/constants';
import CreativeEditor from 'ui/components/screens/CreativeEditor';
import ChallengeEditor from 'ui/components/screens/ChallengeEditor';
import MyPrograms from 'ui/components/screens/MyPrograms';
import MyAnimations from 'ui/components/screens/MyAnimations';
import MyProfile from 'ui/components/screens/MyProfile';
import PrivacyPolicy from 'ui/components/screens/PrivacyPolicy';
import CookiePolicy from 'ui/components/screens/CookiePolicy';
import TermsOfService from 'ui/components/screens/TermsOfService';
import Policies from 'ui/components/screens/Policies';
import FbCallback from 'ui/components/screens/FbCallback';
import NotFound from 'ui/components/screens/NotFound';
import ContactUsForm from 'ui/components/screens/ContactUsForm';
import RegistrationForm from 'ui/components/screens/RegistrationForm';
import LoginForm from 'ui/components/screens/LoginForm';
import ResetPasswordForm from 'ui/components/screens/ResetPasswordForm';
import RecoverPasswordForm from 'ui/components/screens/RecoverPasswordForm';
import SystemChallenges from 'ui/components/screens/Challenges/SystemChallenges';
import MyChallenges from 'ui/components/screens/Challenges/MyChallenges';
import AnimationEditor from 'ui/components/screens/AnimationEditor';

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path={PROGRAM_PATHS[0]} element={<CreativeEditor />} />
    <Route path={PROGRAM_PATHS[1]} element={<CreativeEditor />} />
    <Route path={PROGRAM_PATHS[2]} element={<CreativeEditor />} />
    <Route path={CHALLENGE_PATHS[0]} element={<ChallengeEditor />} />
    <Route path={CHALLENGE_PATHS[1]} element={<ChallengeEditor />} />
    <Route path={CHALLENGE_PATHS[2]} element={<ChallengeEditor />} />
    <Route path={ANIMATION_PATHS[0]} element={<AnimationEditor />} />
    <Route path={ANIMATION_PATHS[1]} element={<AnimationEditor />} />
    <Route path={ANIMATION_PATHS[2]} element={<AnimationEditor />} />
    <Route path="/user/recover-lost-password" element={<RecoverPasswordForm />} />
    <Route path="/password-reset" element={<ResetPasswordForm />} />
    <Route path="/user/login" element={<LoginForm />} />
    <Route path="/user/register" element={<RegistrationForm />} />
    <Route path="/contact-us" element={<ContactUsForm />} />
    <Route path="/user/programs" element={<MyPrograms />} />
    <Route path="/user/animations" element={<MyAnimations />} />
    <Route path="/user/challenges" element={<SystemChallenges />} />
    <Route path="/user/my-challenges" element={<MyChallenges />} />
    <Route path="/user/profile" element={<MyProfile />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/cookie-policy" element={<CookiePolicy />} />
    <Route path="/terms-of-service" element={<TermsOfService />} />
    <Route path="/policies" element={<Policies />} />
    <Route path="/callback/fb" element={<FbCallback />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
