interface InitialCodeValues {
  creative: string;
  challenge: string;
  animation: string;
}

export const initialCodeContents: InitialCodeValues = {
  creative: [
    'let side = 15',
    'pen color:yellow',
    'repeat 20 (i.',
    '    fw side*i',
    '    right 90',
    ')',
    '',
    'fill color:orange',
    'circle 3',
  ].join('\n'),
  animation: ['pen pattern:cars', 'fill color:(rgb (round 255*(t/10)) 0 0)', 'right t*10', 'square 50*t'].join('\n'),
  challenge: '',
};
