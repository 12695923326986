import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import Modal from 'ui/components/common/smart/Modal';
import { getTestSelector } from 'util/testUtil';
import styles from './TourModal.module.scss';

interface TourModalProps {
  onClose: () => void;
  onButtonClick: () => void;
}

const TourModal: React.FC<TourModalProps> = ({ onClose, onButtonClick }) => {
  const { t } = useTranslation('pageTour');

  return (
    <Modal data-testid={getTestSelector('pagetour.modal')} onClose={onClose} showLogo heading={t('welcome')}>
      <p className={styles.description}>{t('intro')}</p>
      <Button flavor={ButtonFlavor.Success} onClick={onButtonClick} data-testid={getTestSelector('pagetour.take_tour')}>
        {t('takeTour')}
      </Button>
    </Modal>
  );
};

export default TourModal;
