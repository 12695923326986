import { Color } from '../turtle/Color';
import { InstrNames } from '../turtle/InstrNames';
import { StdFnName, StdFnNames } from '../turtle/StdFnName';
import { degToRad } from '../util/numberUtil';
import { PositionRange } from '../util/Position';
import { InterpretError } from './error/InterpretError';
import { InterpretResult, NoValue } from './InterpretResult';
import { Set } from 'immutable';

export interface StdFnDesc {
  isInstr: boolean;
  argTypes: string[];
  run: (args: any[], pos: PositionRange) => InterpretResult;
}

export function stdFnDesc(name: string): StdFnDesc | undefined {
  const name2 = canonicalName(name);
  const instrArgs = instrFnArgs(name2);
  if (instrArgs !== undefined) {
    return {
      isInstr: true,
      argTypes: instrArgs,
      run: (args) => new InterpretResult([{ name: name2, args: args }], NoValue.INSTANCE),
    };
  } else {
    return pureFn(name);
  }
}

function instrFnArgs(name: StdFnName): string[] | undefined {
  switch (name) {
    case InstrNames.FW:
      return ['number'];
    case InstrNames.BK:
      return ['number'];
    case InstrNames.RIGHT:
      return ['number'];
    case InstrNames.LEFT:
      return ['number'];
    case InstrNames.UP:
      return [];
    case InstrNames.DOWN:
      return [];
    case InstrNames.SQUARE:
      return ['number'];
    case InstrNames.RECTANGLE:
      return ['number', 'number'];
    case InstrNames.CIRCLE:
      return ['number'];
    case InstrNames.ELLIPSE:
      return ['number', 'number'];
    case InstrNames.TRIANGLE:
      return ['number'];
    case InstrNames.TEXT:
      return ['string'];
    case InstrNames.GOTO:
      return ['number', 'number'];
    case InstrNames.HEADING:
      return ['number'];
    default:
      return undefined;
  }
}

const ALL_CONSTANT_ATTR_VALUES = Set([
  ...StdFnNames.ALL_OPACITIES,
  ...StdFnNames.ALL_PATTERNS,
  ...StdFnNames.ALL_THICKNESS,
]);

/**
 * Thickness, opacity and patterns are interpreted as strings corresponding to their names.
 * Colors are interpreted as hex values.
 */
function pureFn(name: StdFnName): StdFnDesc | undefined {
  switch (name) {
    case StdFnNames.SQRT:
      return {
        isInstr: false,
        argTypes: ['number'],
        run: (args) => InterpretResult.VALUE(Math.sqrt(args[0])),
      };
    case StdFnNames.SIN:
      return {
        isInstr: false,
        argTypes: ['number'],
        run: (args) => InterpretResult.VALUE(Math.sin(degToRad(args[0]))),
      };
    case StdFnNames.COS:
      return {
        isInstr: false,
        argTypes: ['number'],
        run: (args) => InterpretResult.VALUE(Math.cos(degToRad(args[0]))),
      };
    case StdFnNames.TAN:
      return {
        isInstr: false,
        argTypes: ['number'],
        run: (args) => InterpretResult.VALUE(Math.tan(degToRad(args[0]))),
      };
    case StdFnNames.ROUND:
      return {
        isInstr: false,
        argTypes: ['number'],
        run: (args) => InterpretResult.VALUE(Math.round(args[0])),
      };
    case StdFnNames.FLOOR:
      return {
        isInstr: false,
        argTypes: ['number'],
        run: (args) => InterpretResult.VALUE(Math.floor(args[0])),
      };
    case StdFnNames.CEIL:
      return {
        isInstr: false,
        argTypes: ['number'],
        run: (args) => InterpretResult.VALUE(Math.ceil(args[0])),
      };
    case StdFnNames.POW:
      return {
        isInstr: false,
        argTypes: ['number', 'number'],
        run: (args) => InterpretResult.VALUE(Math.pow(args[0], args[1])),
      };
    case StdFnNames.MIN:
      return {
        isInstr: false,
        argTypes: ['number', 'number'],
        run: (args) => InterpretResult.VALUE(Math.min(args[0], args[1])),
      };
    case StdFnNames.MAX:
      return {
        isInstr: false,
        argTypes: ['number', 'number'],
        run: (args) => InterpretResult.VALUE(Math.max(args[0], args[1])),
      };
    case StdFnNames.ABS:
      return {
        isInstr: false,
        argTypes: ['number'],
        run: (args) => InterpretResult.VALUE(Math.abs(args[0])),
      };
    case StdFnNames.LN:
      return {
        isInstr: false,
        argTypes: ['number'],
        run: (args) => InterpretResult.VALUE(Math.log(args[0])),
      };
    case StdFnNames.PI:
      return constant(Math.PI);
    case StdFnNames.E:
      return constant(Math.E);
    case StdFnNames.RGB:
      return {
        isInstr: false,
        argTypes: ['number', 'number', 'number'],
        run: (args, pos: PositionRange) => {
          function verifyComponent(n: number) {
            if (!Number.isInteger(n) || n < 0 || n > 255) throw InterpretError.RGB_OUT_OF_RANGE(pos, n);
          }

          verifyComponent(args[0]);
          verifyComponent(args[1]);
          verifyComponent(args[2]);
          return InterpretResult.VALUE(Color.FROM_RGB(args[0], args[1], args[2]).hex);
        },
      };
    case StdFnNames.NONE:
      return constant(StdFnNames.NONE);
  }

  if (ALL_CONSTANT_ATTR_VALUES.contains(name)) {
    return constant(name);
  }

  const color = Color.FROM_NAME(name);
  if (color) {
    return constant(color.hex);
  }

  return undefined;
}

function constant(v: any): StdFnDesc {
  return {
    isInstr: false,
    argTypes: [],
    run: () => InterpretResult.VALUE(v),
  };
}

function canonicalName(name: string): StdFnName {
  switch (name) {
    case InstrNames.Aliases.FORWARD:
      return InstrNames.FW;
    case InstrNames.Aliases.BACKWARD:
      return InstrNames.BK;
    case InstrNames.Aliases.RECT:
      return InstrNames.RECTANGLE;
    default:
      return name;
  }
}

export function isArticleForExp(exp: string, article: string): boolean {
  const names: { [key: string]: StdFnName } = {
    [InstrNames.BK]: InstrNames.Aliases.BACKWARD,
    [InstrNames.RECTANGLE]: InstrNames.Aliases.RECT,
    [InstrNames.FW]: InstrNames.Aliases.FORWARD,
  };
  if (names.hasOwnProperty(article) && exp.includes(names[article])) {
    return true;
  } else {
    return exp.includes(article);
  }
}
