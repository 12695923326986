import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ChallengeEvaluation } from 'types/challenge';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';

export type EvaluationListState = {
  correct: boolean;
  covered: boolean;
  exact: boolean;
  similarAstSize: boolean;
  similarInstructionsCount: boolean;
  smallerBonus: boolean;
};

type UseEvaluationListState = {
  getEvaluationListState: () => EvaluationListState;
  getListEntries: () => [string, boolean][];
};

const initialEvaluationState: EvaluationListState = {
  correct: false,
  covered: false,
  exact: false,
  similarAstSize: false,
  similarInstructionsCount: false,
  smallerBonus: false,
};

const selector = createCodeStatePropSelector('lastChallengeEvaluation');

export const useEvaluationListState = (evaluation?: ChallengeEvaluation): UseEvaluationListState => {
  const { lastChallengeEvaluation } = useSelector(selector);

  const getEvaluationListState = useCallback(() => {
    const evaluationObject = evaluation || lastChallengeEvaluation;
    if (evaluationObject) {
      const { cover, exactness, astSize, instructionsCount } = evaluationObject;
      const correct = cover >= 95 && exactness >= 95;
      const covered = correct && cover >= 99;
      const exact = correct && exactness >= 99;
      const similarAstSize = correct && astSize <= 150;
      const similarInstructionsCount = correct && instructionsCount <= 150;
      const smallerBonus = correct && (astSize < 100 || instructionsCount < 100);
      return {
        correct,
        covered,
        exact,
        similarAstSize,
        similarInstructionsCount,
        smallerBonus,
      };
    }
    return initialEvaluationState;
  }, [lastChallengeEvaluation, evaluation]);

  const getListEntries = useCallback(() => {
    const state = getEvaluationListState();
    const entries = Object.entries(state as EvaluationListState);
    return state.smallerBonus ? entries : entries.filter(([key]) => key !== 'smallerBonus');
  }, [getEvaluationListState]);

  return { getEvaluationListState, getListEntries };
};
