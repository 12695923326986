import classnames from 'classnames';
import React from 'react';
import Tooltip from 'ui/components/common/smart/Tooltip';
import styles from './Parameter.module.scss';
import { IconComponent } from 'types/icon';

interface Props extends React.ButtonHTMLAttributes<any> {
  Icon?: IconComponent;
  className?: string;
  title?: string;
  'data-testid'?: string;
}

const Parameter: React.FC<Props> = ({ children, Icon, className, title = '', ...props }) => (
  <Tooltip tooltip={title} className={classnames(styles.container, className)} {...props}>
    {Icon && <Icon className={styles.icon} />}
    {children !== undefined ? <span className={styles.content}>{children}</span> : null}
  </Tooltip>
);

export default Parameter;
