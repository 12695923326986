import { useCallback, useState } from 'react';
import { type ColorResult, type RGBColor } from 'react-color';
import { Color } from 'turtle/Color';

type UseLocalColor = [
  RGBColor,
  React.Dispatch<React.SetStateAction<RGBColor>>,
  (value: ColorResult) => void,
  (event: React.MouseEvent<HTMLButtonElement>) => void,
];

export const useLocalColor = (applyToCodeHandler: (color: Color) => void): UseLocalColor => {
  const [localColor, setLocalColor] = useState<RGBColor>({ r: 200, g: 200, b: 200 });

  const onChange = useCallback(
    (value: ColorResult) => {
      const rgbArray: number[] = Object.values(value.rgb);
      setLocalColor(value.rgb);
      applyToCodeHandler(new Color(`(rgb ${rgbArray})`, rgbArray));
    },
    [setLocalColor, applyToCodeHandler]
  );

  const onPredefinedColorClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const dataValue = (<HTMLButtonElement>event.target).getAttribute('data-value');
      if (dataValue) {
        const value = Color.ColorValues[dataValue];
        const newColor = new Color(dataValue, value);
        applyToCodeHandler(newColor);
        setLocalColor({
          r: newColor.r,
          g: newColor.g,
          b: newColor.b,
        });
      }
    },
    [setLocalColor, applyToCodeHandler]
  );

  return [localColor, setLocalColor, onChange, onPredefinedColorClick];
};
