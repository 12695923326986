import { ReactNode, useEffect } from 'react';
import { usePromise } from 'react-use-promise-matcher';
import useApi from 'ui/components/hooks/useApi';
import { CHALLENGE_PACK_ID } from 'ui/constants';
import { formChallengeNodes, formChallengeLevels } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import { loggedInSelector } from 'ui/store/user/selectors';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';

const evaluationSelector = createCodeStatePropSelector('lastChallengeEvaluation');

const ChallengeDataLoader = ({ children }: { children: ReactNode }) => {
  const loggedIn = useSelector(loggedInSelector);
  const { lastChallengeEvaluation } = useSelector(evaluationSelector);
  const { getChallengePack } = useApi();
  const [result, load] = usePromise(getChallengePack);
  const dispatch = useDispatch();
  const { setChallengeNodes, setChallengeLevels } = useCodeStoreActions(dispatch);

  useEffect(() => {
    if (loggedIn === undefined) return;

    load(CHALLENGE_PACK_ID);
  }, [load, loggedIn, lastChallengeEvaluation]);

  useEffect(() => {
    result.match({
      Loading: () => null,
      Resolved: ({ data: { levels } }) => {
        setChallengeNodes(formChallengeNodes(levels));
        setChallengeLevels(formChallengeLevels(levels));
      },
      Rejected: (err) => console.warn(err),
    });
  }, [result, dispatch]);

  return <>{children}</>;
};

export default ChallengeDataLoader;
