import { IconComponent } from 'types/icon';

const DownloadImageRaw: IconComponent = (props) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15.5161 10.871V19" stroke={props.stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M19 15.5161L15.5161 19L12.0322 15.5161"
      stroke={props.stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2903 18.4194H2.16129C1.52026 18.4194 1 17.8991 1 17.2581V2.16129C1 1.52026 1.52026 1 2.16129 1H17.2581C17.8991 1 18.4194 1.52026 18.4194 2.16129V10.2903"
      stroke={props.stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.32275 12.6129L11.4518 6.80646L13.7744 9.12904"
      stroke={props.stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.64526 7.38707C6.6073 7.38707 7.38719 6.60718 7.38719 5.64513C7.38719 4.68309 6.6073 3.9032 5.64526 3.9032C4.68321 3.9032 3.90332 4.68309 3.90332 5.64513C3.90332 6.60718 4.68321 7.38707 5.64526 7.38707Z"
      fill={props.stroke}
    />
  </svg>
);

export default DownloadImageRaw;
