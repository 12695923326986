import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Trophy: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M11,20c-3.85938,0-7-3.14014-7-7V8h7V5H2C1.44769,5,1,5.44769,1,6v7c0,5.52283,4.47717,10,10,10V20z"
        className={styles.yellow}
      />
      <path
        d="M37,20c3.85938,0,7-3.14014,7-7V8h-7V5h9c0.55231,0,1,0.44769,1,1v7c0,5.52283-4.47717,10-10,10V20z"
        className={styles.yellow}
      />
      <path
        d="M31,43h-1c-2.20917,0-4-1.79089-4-4v-8h-4v8c0,2.20911-1.79083,4-4,4h-1c-1.65686,0-3,1.34314-3,3v1h20v-1 C34,44.34314,32.65686,43,31,43z"
        className={styles.yellow}
      />
      <path
        d="M24,33c-8.27148,0-15-6.729-15-15V2c0-0.55225,0.44727-1,1-1h28c0.55273,0,1,0.44775,1,1v16 C39,26.271,32.27148,33,24,33z"
        className={styles.yellow}
      />
    </g>
  </svg>
);

export default Trophy;
