import React from 'react';
import { useTranslation } from 'react-i18next';
import confetti from 'canvas-confetti';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import PodiumTrophy from 'ui/components/svg/PodiumTrophy';
import Modal from 'ui/components/common/smart/Modal';
import { getOverallScore } from 'ui/store/code/helpers';
import styles from './Congratulations.module.scss';
import Rating from 'ui/components/common/dumb/Rating';
import { MAX_SOLUTION_RATING } from 'ui/constants';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useSelector } from 'react-redux';

interface Props {
  onClose: () => void;
}

const spreadConfetti = () =>
  confetti({
    particleCount: 50,
    spread: 120,
    startVelocity: 30,
    zIndex: 1000,
    origin: {
      x: 0.5,
      y: 0.25,
    },
  });

const nodesSelector = createCodeStatePropSelector('challengeNodes');

const Congratulations: React.FC<Props> = ({ onClose }) => {
  const { challengeNodes } = useSelector(nodesSelector);

  const { t } = useTranslation('congratulations');

  const score = React.useMemo(() => getOverallScore(challengeNodes), [challengeNodes]);

  React.useEffect(() => {
    const interval = setInterval(spreadConfetti, 2000);
    spreadConfetti();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Modal onClose={onClose} showLang={false} heading={t('heading')} className={styles.container}>
      <PodiumTrophy className={styles.trophy} />
      <p className={styles.description}>{t('description')}</p>
      <Rating.SolutionRating
        value={Math.floor((score.score / score.maxScore) * MAX_SOLUTION_RATING)}
        className={styles.rating}
        iconClass={styles.ratingIcon}
      />
      <p className={styles.score}>{t('challenges:challenges.overall_result', score)}</p>
      <Button flavor={ButtonFlavor.Success} onClick={onClose}>
        {t('cta')}
      </Button>
    </Modal>
  );
};

export default Congratulations;
