import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useCanvasStoreActions } from 'ui/store/canvas/useCanvasStoreActions';

interface UseDrawingDownload {
  handleImageDownload: (event: React.MouseEvent) => void;
}

export const useDrawingDownload = (preventDefaultOnDownload?: boolean): UseDrawingDownload => {
  const dispatch = useDispatch();
  const { setDownloadImage } = useCanvasStoreActions(dispatch);

  const handleImageDownload = useCallback(
    (event: React.MouseEvent) => {
      if (preventDefaultOnDownload) {
        event.preventDefault();
      }
      setDownloadImage(true);
    },
    [preventDefaultOnDownload, setDownloadImage]
  );

  return { handleImageDownload };
};
