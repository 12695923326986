import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useApi from 'ui/components/hooks/useApi';
import ProgramItem from './ProgramItem';
import Loader from 'ui/components/common/dumb/Loader';
import styles from './MyPrograms.module.scss';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import useListLoader from 'ui/components/hooks/useListLoader';

const MyPrograms: React.FC = () => {
  const { t } = useTranslation('myPrograms');
  const { getProgramList } = useApi();

  const { loadPortion, items: programs, moreAvailable, result } = useListLoader([getProgramList]);

  useEffect(() => {
    if (result.isIdle) loadPortion();
  }, [result, loadPortion]);

  return (
    <div className={styles.container}>
      <h1>{t('myPrograms:myPrograms')}</h1>
      {programs.length > 0 && (
        <div className={styles.list}>
          {programs.map((program) => (
            <ProgramItem key={program.id} code={program} />
          ))}
        </div>
      )}

      {programs.length === 0 && result.isResolved && <p>{t('noProgramsSaved')}</p>}

      {result.isLoading && <Loader />}

      {moreAvailable && !result.isLoading && (
        <Button flavor={ButtonFlavor.Gray} noBorder onClick={loadPortion}>
          {t(result.isResolved ? 'user_activity:load_more' : 'user_activity:retry')}
        </Button>
      )}
    </div>
  );
};

export default MyPrograms;
