import React, { useCallback, createRef, RefObject } from 'react';
import styles from './ProgramLinkCopy.module.scss';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import { useTranslation } from 'react-i18next';
import Input from 'ui/components/common/dumb/Input';
import { renderToastPopup } from 'util/toast';
import { APP_URL } from 'ui/constants';
import { pathForCode } from 'ui/components/routing/programHelpers';
import { CodeType } from 'types/code';
import { useSelector } from 'react-redux';
import { userSelector } from 'ui/store/user/selectors';
import { getTestSelector } from 'util/testUtil';
import { codeIdSelector } from 'ui/store/code/selectors';

interface Props {
  codeType: CodeType;
  responseChallengeId?: string;
}

const ProgramLinkCopy: React.FC<Props> = ({ codeType, responseChallengeId }) => {
  const user = useSelector(userSelector);
  const { t } = useTranslation('socialMedia');
  const id = useSelector(codeIdSelector);
  const pathnameId = responseChallengeId ? responseChallengeId : id;
  const pathname = pathForCode(codeType, pathnameId, user?.login);
  const value = `${APP_URL}${pathname}`;
  const input: RefObject<HTMLInputElement> | null | undefined = createRef();

  const onClick = useCallback(() => {
    if (input && input.current) {
      input.current.select();
      document.execCommand('copy');
      renderToastPopup('success', t('linkSaved'));
    }
  }, [value, input]);

  return (
    <div className={styles.container} data-testid={getTestSelector('social_media.program_link_copy')}>
      <Input ref={input} type="text" defaultValue={value} className={styles.input} />
      <Button flavor={ButtonFlavor.Success} className={styles.btn} onClick={onClick}>
        {t('copyLink')}
      </Button>
    </div>
  );
};

export default ProgramLinkCopy;
