import React from 'react';
import { useSelector } from 'react-redux';
import { interpretResultSelector } from 'ui/store/editor/selectors';
import StatsInfo from './StatsInfo';

const Stats: React.FC = () => {
  const interpretResult = useSelector(interpretResultSelector);

  if ('instructions' in interpretResult) {
    return <StatsInfo />;
  }
  return <></>;
};

export default Stats;
