export default {
  turtleFull: [
    'M13.5543 12.3097C12.7238 12.1118 11.8719 12 11 12C4.93457 12 0 16.9346 0 23C0 24.2314 0.21289 25.46 0.63184 26.6494C0.74903 26.9824 1.03321 27.2285 1.37891 27.2978C1.44434 27.3105 1.50977 27.3174 1.5752 27.3174C1.85547 27.3174 2.12696 27.1992 2.31836 26.9863C3.59235 25.5726 5.26959 24.6096 7.09064 24.2095C7.57373 19.3301 10.0161 15.0732 13.5543 12.3097Z',
    'M7.77661 31.6873C5.4455 33.3512 4 36.0439 4 39C4 40.0234 4.19629 41.0732 4.58496 42.1211C4.69238 42.4121 4.92969 42.6367 5.22558 42.7285C5.32324 42.7588 5.42285 42.7734 5.52246 42.7734C5.72558 42.7734 5.92773 42.7109 6.09766 42.5908C7.81842 41.3804 10.2966 40.7264 11.927 40.4043C10.0411 37.8555 8.599 34.806 7.77661 31.6873Z',
    'M23 9C25.5124 9 27.8821 9.63574 30 10.7369V7C30 3.14062 26.8594 0 23 0C19.1406 0 16 3.14062 16 7V10.7369C18.1179 9.63574 20.4876 9 23 9Z',
    'M35.0001 12C34.1282 12 33.2762 12.1118 32.4458 12.3097C35.984 15.0732 38.4263 19.3301 38.9094 24.2095C40.7305 24.6096 42.4077 25.5726 43.6817 26.9863C43.8731 27.1992 44.1446 27.3174 44.4249 27.3174C44.4903 27.3174 44.5557 27.3105 44.6212 27.2978C44.9669 27.2285 45.251 26.9824 45.3682 26.6494C45.7872 25.46 46.0001 24.2314 46.0001 23C46.0001 16.9346 41.0655 12 35.0001 12Z',
    'M38.2212 31.6951C37.3984 34.8112 35.9573 37.8577 34.073 40.4044C35.7034 40.7264 38.1816 41.3804 39.9023 42.5908C40.0723 42.7109 40.2734 42.7734 40.4775 42.7734C40.5772 42.7734 40.6768 42.7588 40.7744 42.7285C41.0703 42.6367 41.3076 42.4121 41.415 42.1211C41.8037 41.0732 42 40.0234 42 39C42 36.0494 40.5538 33.3589 38.2212 31.6951Z',
    'M9 26C9 17.7157 15.268 11 23 11C30.732 11 37 17.7157 37 26C37 34.2843 30.732 45 23 45C15.268 45 9 34.2843 9 26Z',
  ],
  turtleStroke: [
    'M1.33931 26.4004L1.33924 26.4002C0.947758 25.2888 0.75 24.1444 0.75 23C0.75 17.3488 5.34878 12.75 11 12.75C11.2853 12.75 11.5688 12.7629 11.8507 12.7878C8.94787 15.5372 6.9511 19.3302 6.40414 23.6078C4.63558 24.1009 3.01745 25.0902 1.76121 26.4842L1.76063 26.4849C1.71327 26.5376 1.64542 26.5674 1.5752 26.5674C1.56266 26.5674 1.54601 26.5661 1.52432 26.5621C1.4382 26.544 1.3682 26.4825 1.33931 26.4004Z',
    'M5.22558 42.7285C5.32324 42.7588 5.42285 42.7734 5.52246 42.7734C5.72558 42.7734 5.92773 42.7109 6.09766 42.5908C7.55498 41.5657 9.55555 40.9397 11.1243 40.5764C10.9682 40.359 10.8152 40.1383 10.6654 39.9146M5.22558 42.7285L5.44782 42.0122M5.22558 42.7285L5.44762 42.0121C5.44769 42.0122 5.44775 42.0122 5.44782 42.0122M5.22558 42.7285C4.92969 42.6367 4.69238 42.4121 4.58496 42.1211C4.19629 41.0732 4 40.0234 4 39C4 36.3252 5.18342 33.8662 7.13552 32.1897C7.20925 32.4559 7.28734 32.7216 7.36969 32.9864M5.44782 42.0122C5.47239 42.0198 5.49702 42.0234 5.52246 42.0234C5.57284 42.0234 5.62331 42.0077 5.66474 41.9784L5.66616 41.9774C7.14403 40.9378 9.09916 40.2969 10.6654 39.9146M5.44782 42.0122C5.37415 41.9893 5.31506 41.9332 5.28856 41.8614L5.28815 41.8603C4.92771 40.8885 4.75 39.9269 4.75 39C4.75 36.6685 5.73126 34.5146 7.36969 32.9864M10.6654 39.9146C10.9407 39.8474 11.204 39.7881 11.4506 39.7362C9.94733 37.5484 8.7612 35.0402 7.9933 32.4595C7.77618 32.6254 7.56812 32.8013 7.36969 32.9864M10.6654 39.9146C9.25559 37.809 8.13066 35.4334 7.36969 32.9864',
    'M29.25 9.55112C27.3206 8.72 25.2142 8.25 23 8.25C20.7858 8.25 18.6794 8.72 16.75 9.55112V7C16.75 3.55483 19.5548 0.75 23 0.75C26.4452 0.75 29.25 3.55483 29.25 7V9.55112Z',
    'M44.2394 26.4849L44.2388 26.4842C42.9826 25.0902 41.3645 24.1009 39.5959 23.6078C39.049 19.3302 37.0522 15.5372 34.1494 12.7878C34.4312 12.7629 34.7148 12.75 35.0001 12.75C40.6513 12.75 45.2501 17.3488 45.2501 23C45.2501 24.1444 45.0523 25.2888 44.6608 26.4002L44.6608 26.4004C44.6319 26.4825 44.5619 26.544 44.4758 26.562C44.454 26.5661 44.4373 26.5674 44.4249 26.5674C44.3547 26.5674 44.2868 26.5376 44.2394 26.4849Z',
    'M40.3338 41.9774L40.3353 41.9784C40.377 42.0079 40.4265 42.0234 40.4775 42.0234C40.503 42.0234 40.5276 42.0198 40.5522 42.0122L40.3338 41.9774ZM40.3338 41.9774C38.856 40.9378 36.9008 40.2969 35.3346 39.9146C36.743 37.811 37.8671 35.438 38.628 32.9935C40.2682 34.5213 41.25 36.6733 41.25 39C41.25 39.9269 41.0723 40.8885 40.7119 41.8603L40.7115 41.8614M40.3338 41.9774L40.7115 41.8614M40.7115 41.8614C40.685 41.9331 40.626 41.9893 40.5524 42.0121L40.7115 41.8614Z',
    'M9.75 26C9.75 18.0804 15.7301 11.75 23 11.75C30.2699 11.75 36.25 18.0804 36.25 26C36.25 29.9747 34.7383 34.5796 32.2799 38.184C29.8096 41.8058 26.5077 44.25 23 44.25C19.4923 44.25 16.1904 41.8058 13.7201 38.184C11.2617 34.5796 9.75 29.9747 9.75 26Z',
  ],
};
