import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const CurvedArrowRight: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M46.569,31.178l-13-9A1,1,0,0,0,32,23v7H15.5a10.5,10.5,0,0,1,0-21H34a2,2,0,0,0,0-4H15.5a14.5,14.5,0,0,0,0,29H32v7a1,1,0,0,0,1.569.822l13-9a1,1,0,0,0,0-1.644Z"
        className={styles.yellow}
      />
    </g>
  </svg>
);

export default CurvedArrowRight;
