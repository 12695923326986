import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Bulb from 'ui/components/svg/Bulb';
import Close from 'ui/components/svg/Close';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';
import Button, { ButtonSize } from 'ui/components/common/dumb/Button';
import styles from './HintBox.module.scss';
import { getWithExpiry, setWithExpiry } from 'ui/components/common/smart/ContentModal/ContentHeader/ContentHeader.util';
import { renderToastPopup } from 'util/toast';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useParams } from 'react-router-dom';
import useApi from 'ui/components/hooks/useApi';

const codePropsSelector = createCodeStatePropSelector('challengeCode', 'hint');
const HINT_TIMER_EXPIRY = 300000;

const AskForHintBox: React.FC = () => {
  const { t } = useTranslation('user_activity');
  const { id: currentChallengeId } = useParams();
  const dispatch = useDispatch();
  const { setHint, setAskForHint, setIsHintLoading } = useCodeStoreActions(dispatch);
  const { hint, challengeCode } = useSelector(codePropsSelector);
  const { postHint } = useApi();

  const handleClose = useCallback(() => {
    setAskForHint(false);
    setHint('');
  }, []);

  const handleAccept = useCallback(async () => {
    if (hint) return setHint('');

    const challengeIdInLocalStorage = getWithExpiry(currentChallengeId as string);

    if (challengeIdInLocalStorage && challengeIdInLocalStorage === currentChallengeId) {
      renderToastPopup('error', t('challenges:challenge.hint.limit'));
      return;
    }

    if (currentChallengeId) {
      setWithExpiry(currentChallengeId, currentChallengeId, HINT_TIMER_EXPIRY);
      setAskForHint(false);
      setIsHintLoading(true);
      const hintResponse = await postHint(currentChallengeId, challengeCode);

      if (hintResponse.status === 200) {
        setHint(hintResponse.data.hint);
        setIsHintLoading(false);
      } else {
        setHint('');
        setIsHintLoading(false);
        renderToastPopup('error', t('challenges:challenge.hint.error'));
      }
    }
  }, []);

  return (
    <div className={styles.container} data-testid="askForHint.box">
      <Button
        Icon={Close}
        size={ButtonSize.Small}
        onClick={handleClose}
        noBorder
        className={styles.close}
        aria-label="Close ask hint"
      />
      <div className={styles.heading}>
        <Bulb className={styles.icon} />
        <h4 className={styles.h4}>{t('hint')}</h4>
      </div>
      <p className={styles.info} data-testid="askForHint.content">
        {t('challenges:challenge.askForHint.content')}
      </p>
      <Button className={styles.button} onClick={handleAccept} aria-label="Get hint">
        {t('challenges:challenge.askForHint.button')}
      </Button>
    </div>
  );
};

export default AskForHintBox;
