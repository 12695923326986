import { dataTestSelectors } from '../ui/dataTestSelectors';

export const getTestSelector = (key: string): string => {
  const selectors = dataTestSelectors.filter((s) => s === key);
  if (selectors.length === 0) {
    console.error(`Unknown selector key: ${key}. Integration tests will fail`);
    return '';
  } else {
    if (selectors.length > 1) console.error(`Duplicated selector key: ${key}. Integration tests might fail`);
    return selectors[0];
  }
};

export const getTestAttribute = (key: string): string => `[data-testid="${getTestSelector(key)}"]`;
