import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { usePromise } from 'react-use-promise-matcher';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PromptBox from 'ui/components/common/dumb/PromptBox';
import Form from 'ui/components/common/dumb/Form';
import Input from 'ui/components/common/dumb/Input';
import styles from '../MyProfile.module.scss';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import FormError from 'ui/components/common/dumb/FormError';
import useApi from 'ui/components/hooks/useApi';
import Loader from 'ui/components/common/dumb/Loader';
import { renderToastPopup } from 'util/toast';
import { DataItem } from 'types/data';
import { USER_LOGIN_REGEX } from 'ui/constants';
import { User } from 'types/user';
import { userSelector } from 'ui/store/user/selectors';
import { useUserStoreActions } from 'ui/store/user/useUserStoreActions';

const ProfileDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { setUser } = useUserStoreActions(dispatch);
  const user = useSelector(userSelector);
  const { register, handleSubmit, formState, clearErrors, setValue, trigger } = useForm({
    mode: 'onChange',
  });
  const { t } = useTranslation('myProfile');
  const { changeProfileDetails } = useApi();

  const { errors } = formState;

  const submitHandler = useCallback(
    (payload: Record<string, any>) => {
      return changeProfileDetails(payload.login, payload.email, payload.emailAgreement).then((response) => {
        const newUser = JSON.parse(response.config.data);
        setUser(newUser);
        return newUser;
      });
    },
    [changeProfileDetails, setUser]
  );

  const [result, load] = usePromise<any, any, DataItem>(submitHandler);

  const renderForm = useCallback(
    () => (
      <Form onSubmit={handleSubmit(load)}>
        <Input
          className={styles.input}
          type="text"
          placeholder={t('yourLogin')}
          {...register('login', {
            required: t('loginRequired') as string,
            validate: (value: string) => USER_LOGIN_REGEX.test(value) || (t('loginValidate') as string),
          })}
        />
        <FormError errors={errors} name="login" />
        <Input
          className={styles.input}
          type="email"
          placeholder={t('yourEmail')}
          {...register('email', {
            required: t('emailRequired') as string,
          })}
        />
        <FormError errors={errors} name="email" />
        <label htmlFor="emailAgreement" className={styles.label}>
          <Input
            className={classnames(styles.input, styles.checkbox)}
            type="checkbox"
            id="emailAgreement"
            {...register('emailAgreement')}
          />
          <span>{t('emailAgreement')}</span>
        </label>
        <FormError errors={errors} name="sendEmails" />
        <Button flavor={ButtonFlavor.Success} disabled={!formState.isValid}>
          {t('updateData')}
        </Button>
      </Form>
    ),
    [handleSubmit, register, errors, formState]
  );

  const setFieldValues = useCallback(() => {
    if (user) {
      setValue('login', user.login);
      setValue('email', user.email);
      setValue('emailAgreement', user.emailAgreement);
      trigger();
    }
  }, [user, trigger, setValue]);

  useEffect(() => {
    return () => {
      clearErrors();
    };
  }, [clearErrors]);

  useEffect(() => {
    result.match({
      Loading: () => null,
      Rejected: (err: any) => {
        renderToastPopup('error', err.response.data.error);
        setFieldValues();
      },
      Resolved: (payload: User) => {
        renderToastPopup('success', t('detailsChanged'));
        setValue('login', payload.login);
        setValue('email', payload.email);
        setValue('emailAgreement', payload.emailAgreement);
        trigger();
      },
    });
  }, [result, setFieldValues, setValue, trigger, t]);

  useEffect(() => {
    setFieldValues();
  }, [setFieldValues]);

  return (
    <PromptBox>
      <PromptBox.Row>
        <PromptBox.Heading>{t('details')}</PromptBox.Heading>
      </PromptBox.Row>
      <PromptBox.Row className={styles.row}>
        {result.match({
          Idle: renderForm,
          Loading: () => <Loader />,
          Rejected: (err: any) => (
            <>
              {renderForm()}
              <p className={classnames(styles.message, styles.error)}>{err.response.data.error}</p>
            </>
          ),
          Resolved: () => (
            <>
              {renderForm()}
              <p className={classnames(styles.message, styles.success)}>{t('detailsChanged')}</p>
            </>
          ),
        })}
      </PromptBox.Row>
    </PromptBox>
  );
};

export default ProfileDetails;
