import React, { createRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { hrefFromMouseEvent } from 'util/htmlUtil';
import { getExpArticleName } from 'util/articleUtil';
import MdxWrapper from './MdxWrapper';
import { referenceSelector } from 'ui/store/help/selectors';
import { useHelpStoreActions } from 'ui/store/help/useHelpStoreActions';
import { homeScreenStateSelector } from 'ui/store/homescreen/selectors';
import { useHomeScreenStoreActions } from 'ui/store/homescreen/useHomeScreenStoreActions';
import { HomeSubscreen } from 'ui/store/homescreen/enums';
import { currentExpSelector } from 'ui/store/editor/selectors';
import { MdxImportMap } from 'src/i18n';

const HelpTopic: React.FC = () => {
  const dispatch = useDispatch();
  const reference = useSelector(referenceSelector);
  const { setReference } = useHelpStoreActions(dispatch);
  const { subscreen } = useSelector(homeScreenStateSelector);
  const { setHomeScreen } = useHomeScreenStoreActions(dispatch);
  const currentExp = useSelector(currentExpSelector);

  const { t } = useTranslation(['tabs']);
  const ref: React.MutableRefObject<HTMLDivElement | null> = createRef<HTMLDivElement>();
  const subjects: MdxImportMap = useTranslation('mdx').t('reference') as unknown as MdxImportMap;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const href = hrefFromMouseEvent(e);
      if (href) {
        setReference(href);
        setHomeScreen(HomeSubscreen.Index);
      }
      if (ref.current) ref.current.scrollIntoView();
      e.preventDefault();
    },
    [setReference, setHomeScreen, ref]
  );

  const getReferenceArticle = useCallback((): JSX.Element | null => {
    const Reference = subjects.get(reference)?.default;
    if (Reference) {
      return <Reference />;
    }
    return null;
  }, [subjects, reference, t]);

  const getContextHelpArticle = useCallback((): JSX.Element | null => {
    const articleName = getExpArticleName(currentExp);
    const Element = subjects.get(articleName)?.default;
    if (Element) {
      return <Element />;
    }
    return null;
  }, [currentExp]);

  return (
    <MdxWrapper ref={ref} onClick={handleClick}>
      {subscreen === HomeSubscreen.WhatIs ? getContextHelpArticle() : getReferenceArticle()}
    </MdxWrapper>
  );
};

export default HelpTopic;
