import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const CornerUpRight: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M33.6,26.2A1,1,0,0,0,32,27v7H16a2,2,0,0,1-2-2V16h7a1,1,0,0,0,.8-1.6l-9-12a1.036,1.036,0,0,0-1.458-.142A1.059,1.059,0,0,0,11.2,2.4l-9,12A1,1,0,0,0,3,16h7V32a6.006,6.006,0,0,0,6,6H32v7a1,1,0,0,0,1.6.8l12-9a1,1,0,0,0,0-1.6Z"
        className={styles.yellow}
      />
    </g>
  </svg>
);

export default CornerUpRight;
