import React, { useCallback } from 'react';
import useStorage, { deleteFromStorage } from '@rehooks/local-storage';
import IconRadioGroup from 'ui/components/common/smart/IconRadioGroup';
import { RadioIconProps } from 'ui/components/common/smart/IconRadioGroup/IconRadioGroup';
import { useTranslation } from 'react-i18next';
import Book39 from 'ui/components/svg/Book39';
import Potion from 'ui/components/svg/Potion';
import Bus from 'ui/components/svg/Bus';
import { PageTourStep } from 'ui/components/common/smart/PageTour/types';
import { PAGE_TOUR_STORAGE_KEY } from 'ui/constants';
import { useDispatch, useSelector } from 'react-redux';
import { homeScreenStateSelector } from 'ui/store/homescreen/selectors';
import { useHomeScreenStoreActions } from 'ui/store/homescreen/useHomeScreenStoreActions';
import { HomeSubscreen } from 'ui/store/homescreen/enums';
import { usePageTourStoreActions } from 'ui/store/tour/usePageTourStoreActions';

const HelpMenu: React.FC = () => {
  const { t } = useTranslation(['navigation', 'toast']);
  const dispatch = useDispatch();
  const { subscreen } = useSelector(homeScreenStateSelector);
  const { setHomeScreen } = useHomeScreenStoreActions(dispatch);
  const { setTourVisibility, setTourIntroVisibility } = usePageTourStoreActions(dispatch);

  const setHelpView = useCallback(
    (id: string) => {
      setHomeScreen(id as HomeSubscreen);
    },
    [setHomeScreen]
  );

  const pageTourLaunched = useStorage(PAGE_TOUR_STORAGE_KEY);
  const startTour = useCallback(() => {
    if (!!pageTourLaunched) {
      deleteFromStorage(PAGE_TOUR_STORAGE_KEY);
    }
    setTourVisibility(false);
    setTourIntroVisibility(true);
  }, [setTourVisibility, setTourIntroVisibility, pageTourLaunched]);

  const helpIcons: RadioIconProps[] = [
    { id: 'index', icon: Book39, onClick: setHelpView, tooltipText: t('navigation:subtype.index') },
    { id: 'whatis', icon: Potion, onClick: setHelpView, tooltipText: t('navigation:subtype.whatis') },
    { id: 'tour', icon: Bus, onClick: startTour, tooltipText: t('pageTour:takeTour') },
  ];

  return <IconRadioGroup vertical radioIcons={helpIcons} activeItem={subscreen} data-tour-area={PageTourStep.Help} />;
};

export default HelpMenu;
