import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { HomeSubscreen } from './enums';

export interface HomeScreenState {
  subscreen: HomeSubscreen;
}

export const initialHomeScreenState: HomeScreenState = {
  subscreen: HomeSubscreen.None,
};

export const homescreenSlice = createSlice({
  name: 'homescreen',
  initialState: initialHomeScreenState,
  reducers: {
    setHomeScreen: (state: HomeScreenState, action: PayloadAction<HomeSubscreen>) => {
      state.subscreen = action.payload;
    },
  },
});

export const { setHomeScreen } = homescreenSlice.actions;

export default homescreenSlice.reducer;
