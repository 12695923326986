export enum ChallengeState {
  Locked = 'locked',
  InProgress = 'in_progress',
  Done = 'done',
}

type Names = {
  [key: string]: string;
};

export type ChallengesText = {
  name: string;
  description: string;
};

export interface ChallengeOnServer {
  userLogin?: string;
  id: string;
  texts: { [key: string]: ChallengesText };
  attempted: boolean;
  stars: number;
}

export interface ChallengeNode {
  data: ChallengeOnServer;
  state: ChallengeState;
  prevNodeId: string | null;
  nextNodeId: string | null;
}

export type ChallengeNodeList = { [index: string]: ChallengeNode };

interface ChallengePackLevelRoot {
  names: Names;
  difficulty: number;
}

export interface ChallengePackLevel extends ChallengePackLevelRoot {
  challengeNodeIds: string[];
}

export interface ChallengePackLevelOnServer extends ChallengePackLevelRoot {
  challenges: ChallengeOnServer[];
}

export interface ChallengePackOnServer {
  names: Names;
  levels: ChallengePackLevelOnServer[];
}

export interface ChallengeEvaluation {
  stars: number;
  cover: number;
  exactness: number;
  astSize: number;
  instructionsCount: number;
  userToken: string;
}

export interface ChallengePack {
  names: Names;
  levels: string[];
}
