import React, { useCallback } from 'react';
import Button, { ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import { useTranslation } from 'react-i18next';
import { createCanvasStatePropSelector } from 'ui/store/canvas/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useCanvasStoreActions } from 'ui/store/canvas/useCanvasStoreActions';
import { getTestSelector } from 'util/testUtil';

const canvasPropSelector = createCanvasStatePropSelector('showTurtle');

const Switch: React.FC = () => {
  const dispatch = useDispatch();
  const { setShowTurtle } = useCanvasStoreActions(dispatch);
  const { showTurtle } = useSelector(canvasPropSelector);
  const { t } = useTranslation('turtleInfoBar');

  const handleChange = useCallback(() => {
    setShowTurtle(!showTurtle);
  }, [setShowTurtle, showTurtle]);

  return (
    <Button
      size={ButtonSize.Small}
      flavor={!showTurtle ? ButtonFlavor.Active : ButtonFlavor.Gray}
      onClick={handleChange}
      data-testid={getTestSelector('canvas.hide_turtle')}
    >
      {t('hideTurtle')}
    </Button>
  );
};

export default Switch;
