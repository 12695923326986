import React from 'react';
import classnames from 'classnames';
import { NavLink, type NavLinkProps } from 'react-router-dom';
import styles from './AppLink.module.scss';

interface Props extends NavLinkProps {
  className?: string;
  variant?: 'default' | 'bordered';
}

const AppLink: React.FC<Props> = ({ children, className, to, variant = 'default', ...other }) => (
  <NavLink to={to} className={classnames(styles.container, className, styles[variant])} {...other}>
    {children}
  </NavLink>
);

export default AppLink;
