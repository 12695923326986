import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './EditorControls.module.scss';
import Button, { ButtonSize } from 'ui/components/common/dumb/Button';
import CurvedArrowRight from 'ui/components/svg/CurvedArrowRight';
import CurvedArrowLeft from 'ui/components/svg/CurvedArrowLeft';
import CheckAll from 'ui/components/svg/CheckAll';
import editor from '../EditorCodePane/editor';
import { getTestSelector } from 'util/testUtil';
import { useSelector } from 'react-redux';
import { undoRedoSelector } from 'ui/store/editor/selectors';

const EditorControls: React.FC = () => {
  const { t } = useTranslation(['asideTop']);

  const { undoDisabled, redoDisabled } = useSelector(undoRedoSelector);

  const handleFormat = () => {
    editor?.getAction('editor.action.formatDocument')?.run();
  };

  const handleRedo = () => {
    editor?.trigger('', 'redo', null);
  };

  const handleUndo = () => {
    editor?.trigger('', 'undo', null);
  };

  return (
    <div className={styles.container}>
      <Button
        size={ButtonSize.Small}
        className={classnames(styles.button, styles.undoredo)}
        onClick={handleUndo}
        disabled={undoDisabled}
        Icon={CurvedArrowLeft}
        noBorder
        data-testid={getTestSelector('editor.undo')}
      >
        {t('asideTop:undo')}
      </Button>
      <Button
        size={ButtonSize.Small}
        className={classnames(styles.button, styles.undoredo)}
        onClick={handleRedo}
        disabled={redoDisabled}
        Icon={CurvedArrowRight}
        noBorder
        data-testid={getTestSelector('editor.redo')}
      >
        {t('asideTop:redo')}
      </Button>
      <Button
        size={ButtonSize.Small}
        className={classnames(styles.button, styles.format)}
        onClick={handleFormat}
        Icon={CheckAll}
        noBorder
      >
        {t('asideTop:formatCode')}
      </Button>
    </div>
  );
};

export default EditorControls;
