import Konva from 'konva';
import { Turtle } from '../turtle/Turtle';
import turtlePaths from './turtlePaths';
import { Resizeable } from './interfaces/Resizeable';

export class TurtleLayer implements Resizeable {
  private readonly turtleLayer: Konva.Layer;
  private readonly turtle: Konva.Group;
  private readonly turtleOutline: Konva.Group;

  constructor(turtleLayer: Konva.Layer, stageCenter: { x: number; y: number; scaleY: number }) {
    this.turtleLayer = turtleLayer;

    this.turtleOutline = createTurtleGroup(stageCenter, true);
    this.turtle = createTurtleGroup(stageCenter, false);

    this.turtleLayer.add(this.turtleOutline);
    this.turtleLayer.add(this.turtle);
  }

  onResize(newStageCenter: { x: number; y: number }): void {
    this.turtleOutline.setPosition(newStageCenter);
    this.turtle.setPosition(newStageCenter);
  }

  handleTurtleShow(show: boolean): void {
    this.turtleOutline.visible(show);
    this.turtle.visible(show);
    this.turtleLayer.batchDraw();
  }

  handleTurtleSelected(t: Turtle): void {
    this.paintTurtle(t, this.turtleOutline);
  }

  isVisible(): boolean {
    return this.turtle.isVisible();
  }

  paintTurtleMain(t: Turtle): void {
    this.paintTurtle(t, this.turtle);
  }

  private paintTurtle(t: Turtle, group: Konva.Group) {
    group.children.forEach((c) => {
      c.x(t.x);
      c.y(-t.y); // The turtle group has a positive scaleY, unlike the rest (it's "upside down").
      c.rotation(t._heading);
    });
    this.turtleLayer.batchDraw();
  }
}

function createTurtleGroup(stageCenter: { x: number; y: number; scaleY: number }, outline: boolean): Konva.Group {
  const baseGroup = new Konva.Group();
  if (outline) {
    renderStrokeTurtle(baseGroup);
  } else {
    renderFullTurtle(baseGroup, 'white');
  }
  // The turtle has about 56 width/height. Scaled down that's about 23x23.5.
  // The offset then is half of these dimensions.
  baseGroup.offsetX(11.5);
  baseGroup.offsetY(11.75);
  const group = new Konva.Group({
    // Omitting the scaleY from this.xy, as the turtle drawing is upside down.
    x: stageCenter.x,
    y: stageCenter.y,
  });
  group.add(baseGroup);
  return group;
}

function renderFullTurtle(group: Konva.Group, fill: string): void {
  turtlePaths.turtleFull.forEach((p) => {
    const path = new Konva.Path({
      data: p,
      fill,
      scaleY: 0.5,
      scaleX: 0.5,
      stroke: 'gray',
      strokeWidth: 0.8,
    });
    group.add(path);
  });
}

function renderStrokeTurtle(group: Konva.Group): void {
  turtlePaths.turtleStroke.forEach((p) => {
    const path = new Konva.Path({
      scaleY: 0.5,
      scaleX: 0.5,
      data: p,
      fill: 'rgba(0, 0, 0, 0)',
      stroke: 'white',
    });
    group.add(path);
  });
}
