import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PromiseResultShape } from 'react-use-promise-matcher';
import { ChallengeOnServer } from 'types/challenge';
import { CodeType } from 'types/code';
import { AnimationOnServer, ProgramOnServer } from 'types/program';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';
import { HomeSubscreen } from 'ui/store/homescreen/enums';
import { homeScreenStateSelector } from 'ui/store/homescreen/selectors';
import { useHomeScreenStoreActions } from 'ui/store/homescreen/useHomeScreenStoreActions';
import useServerChallenge from '../screens/ChallengeEditor/useServerChallenge';
import useServerCreative from '../screens/CreativeEditor/useServerCreative';
import useServerAnimation from '../screens/AnimationEditor/useServerAnimation';

type UseEditorLogic = {
  serverFetch:
    | PromiseResultShape<ChallengeOnServer | null, string>
    | PromiseResultShape<AnimationOnServer | null, string>
    | PromiseResultShape<ProgramOnServer | null, string>;
  subscreen: HomeSubscreen;
  onCreativeCodeChange: (payload: string) => void;
  onChallengeCodeChange: (payload: string) => void;
  onAnimationCodeChange: (payload: string) => void;
};

export const useEditorLogic = (codeType: CodeType, idFromUrl?: string): UseEditorLogic => {
  const dispatch = useDispatch();
  const { setHomeScreen } = useHomeScreenStoreActions(dispatch);
  const { setCodeType, setCreativeCode, setChallengeCode, setAnimationCode } = useCodeStoreActions(dispatch);
  const serverFetchHook = useMemo(() => {
    switch (codeType) {
      case CodeType.Challenge:
        return useServerChallenge;
      case CodeType.Creative:
        return useServerCreative;
      case CodeType.Animation:
        return useServerAnimation;
      default:
        return useServerChallenge;
    }
  }, [codeType]);
  const { subscreen } = useSelector(homeScreenStateSelector);

  const onCreativeCodeChange = useCallback(
    (value: string) => {
      setCreativeCode(value);
    },
    [setCreativeCode]
  );

  const onChallengeCodeChange = useCallback(
    (value: string) => {
      setChallengeCode(value);
    },
    [setChallengeCode]
  );

  const onAnimationCodeChange = useCallback(
    (value: string) => {
      setAnimationCode(value);
    },
    [setAnimationCode]
  );

  React.useEffect(() => {
    setHomeScreen(codeType === CodeType.Challenge ? HomeSubscreen.Challenges : HomeSubscreen.None);
  }, [setHomeScreen, codeType]);

  React.useEffect(() => {
    setCodeType(codeType);
  }, [codeType, setCodeType]);

  const serverFetch = serverFetchHook(idFromUrl);

  return {
    serverFetch,
    subscreen,
    onCreativeCodeChange,
    onChallengeCodeChange,
    onAnimationCodeChange,
  };
};
