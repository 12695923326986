import React from 'react';
import actions from './actions';
import Context from './Context';
import useStepProps from './useStepProps';

type UseUpdateStep = () => void;

const useUpdateStep: UseUpdateStep = () => {
  const { state, dispatch } = React.useContext(Context);
  const { steps, requestedStepIndex, onStart, onEnd } = state;

  const requestedStep = steps[requestedStepIndex] || null;
  const requestedStepProps = useStepProps(requestedStep);

  React.useEffect(() => {
    onStart();
  }, [onStart]);

  React.useEffect(() => {
    if (!requestedStep || !requestedStepProps) return;

    requestedStep.onBefore(requestedStepProps);
    return () => {
      requestedStep.onAfter(requestedStepProps);
    };
  }, [dispatch, requestedStepProps, requestedStep]);

  React.useEffect(() => {
    if (requestedStepIndex >= steps.length) {
      onEnd();
      return;
    }

    const clampedIndex = Math.max(0, Math.min(steps.length - 1, requestedStepIndex));
    const step = steps[clampedIndex];

    actions(dispatch).set({ step, requestedStepIndex: clampedIndex });
  }, [dispatch, steps, requestedStepIndex, onEnd]);
};

export default useUpdateStep;
