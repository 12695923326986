import classnames from 'classnames';
import React from 'react';
import styles from './TextArea.module.scss';

interface Props extends React.TextareaHTMLAttributes<any> {
  className?: string;
  name?: string;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(({ className, name, ...props }, ref) => (
  <textarea id={name} name={name} ref={ref} className={classnames(styles.container, className)} {...props} />
));

export default TextArea;
