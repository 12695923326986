import Konva from 'konva';
import { Paint } from 'paint/Paint';
import Vector2d = Konva.Vector2d;

export function setupCanvasDrag(paint: Paint): void {
  const { stage } = paint;

  stage.draggable(true);
  stage.dragBoundFunc(dragBoundsHandler);
  addStageCursorListeners();

  function addStageCursorListeners(): void {
    const {
      attrs: { container },
    } = stage;
    stage.on('dragstart', () => {
      container.style.cursor = 'move';
    });
    stage.on('dragend', () => {
      container.style.cursor = 'default';
      paint.triggerOnViewChanged();
    });
  }

  function dragBoundsHandler(pos: Vector2d) {
    const { canvasSize } = paint;

    const zoom = paint.getCurrentZoom();
    const zoomedCanvas = {
      w: canvasSize.canvasWidth * zoom,
      h: canvasSize.canvasHeight * zoom,
    };
    // a. determine if zoomed canvas is bigger than its viewport (affects bounds)
    const isCanvasWider = canvasSize.clientWidth < zoomedCanvas.w;
    const isCanvasHigher = canvasSize.clientHeight < zoomedCanvas.h;
    // b. calculate bounds
    const zoomedBoundX = canvasSize.clientWidth - zoomedCanvas.w;
    const zoomedBoundY = canvasSize.clientHeight - zoomedCanvas.h;
    const boundHits = {
      minX: isCanvasWider ? pos.x > 0 : pos.x > zoomedBoundX,
      maxX: isCanvasWider ? pos.x < zoomedBoundX : pos.x < 0,
      minY: isCanvasHigher ? pos.y > 0 : pos.y > zoomedBoundY,
      maxY: isCanvasHigher ? pos.y < zoomedBoundY : pos.y < 0,
    };
    // c. check if bounds have been reached
    const hitsBoundsX = boundHits.minX || boundHits.maxX;
    const hitsBoundsY = boundHits.minY || boundHits.maxY;

    const newPos = {
      x: hitsBoundsX ? stage.getAbsolutePosition().x : pos.x,
      y: hitsBoundsY ? stage.getAbsolutePosition().y : pos.y,
    };

    paint.triggerOnViewChanged();
    return newPos;
  }
}
