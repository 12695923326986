import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const PodiumTrophy: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M31,26H17a1,1,0,0,0-1,1V47H32V27A1,1,0,0,0,31,26Z" className={styles.white} />{' '}
      <path d="M16,32H3a1,1,0,0,0-1,1V46a1,1,0,0,0,1,1H16Z" className={styles.yellow} />{' '}
      <path d="M45,37H32V47H45a1,1,0,0,0,1-1V38A1,1,0,0,0,45,37Z" className={styles.yellow} />{' '}
      <path
        d="M17.283,11.953A7.01,7.01,0,0,0,23,16.92V20H22a3,3,0,0,0-3,3H29a3,3,0,0,0-3-3H25V16.92a7.01,7.01,0,0,0,5.717-4.967A6.006,6.006,0,0,0,36,6V3a1,1,0,0,0-1-1H31V1H17V2H13a1,1,0,0,0-1,1V6A6.006,6.006,0,0,0,17.283,11.953ZM34,4V6a4,4,0,0,1-3,3.858V4ZM14,4h3V9.858A4,4,0,0,1,14,6Z"
        className={styles.green}
      />
    </g>
  </svg>
);

export default PodiumTrophy;
