import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import SocialShare from 'ui/components/modals/SocialShare';
import { getTestSelector } from 'util/testUtil';

interface Props {
  className?: string;
}

const SocialShareToggle: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation('socialMedia');
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const open = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const close = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <>
      <Button
        className={className}
        flavor={ButtonFlavor.Success}
        size={ButtonSize.Small}
        onClick={open}
        title={t('shareAndSave')}
        data-testid={getTestSelector('social_media.share_button')}
      >
        {t('share')}
      </Button>
      {modalOpen && <SocialShare onClose={close} />}
    </>
  );
};

export default SocialShareToggle;
