import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from './NotFound.module.scss';
import PromptBox from 'ui/components/common/dumb/PromptBox';
import inMainScreen from 'ui/components/hocs/inMainScreen';

const NotFound: React.FC = () => (
  <PromptBox>
    <PromptBox.Row>
      <PromptBox.Heading className={styles.heading}>Ooops!</PromptBox.Heading>
    </PromptBox.Row>
    <PromptBox.Row>
      <h3 className={styles.subheading}>You shouldn&apos;t be here for sure :)</h3>
    </PromptBox.Row>
    <PromptBox.Row>
      <NavLink className={classnames(styles.btn, styles.success)} to="/">
        Home
      </NavLink>
    </PromptBox.Row>
  </PromptBox>
);

export default inMainScreen(NotFound);
