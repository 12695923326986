import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChallengeState, ChallengeNode, ChallengeOnServer } from 'types/challenge';
import { getChallengeByIdWithFallbackToInProgress, getChallenges } from 'ui/store/code/helpers';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';

type UseBoundingChallenges = {
  index: number;
  count: number;
  nextChallenge: ChallengeNode | null;
  prevChallenge: ChallengeNode | null;
  nextChallengeAvailable: boolean;
  prevChallengeAvailable: boolean;
};

const selector = createCodeStatePropSelector('challenge', 'challengeLevels', 'challengeNodes');

export const useBoundingChallenges = (): UseBoundingChallenges => {
  const dispatch = useDispatch();
  const { setAskForHint, setHint } = useCodeStoreActions(dispatch);
  const { challenge, challengeLevels, challengeNodes } = useSelector(selector);

  const [index, setIndex] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  const [challenges, setChallenges] = useState<ChallengeOnServer[]>([]);
  const [challengeNode, setChallengeNode] = useState<ChallengeNode | undefined>();

  const [nextChallenge, setNextChallenge] = useState<ChallengeNode | null>(null);
  const [prevChallenge, setPrevChallenge] = useState<ChallengeNode | null>(null);

  const [nextChallengeAvailable, setNextChallengeAvailable] = useState<boolean>(false);
  const [prevChallengeAvailable, setPrevChallengeAvailable] = useState<boolean>(false);

  useEffect(() => {
    setChallenges(getChallenges(challengeLevels, challengeNodes));
  }, [setChallenges, challengeLevels, challengeNodes]);

  useEffect(() => {
    setCount(challenges.length);
  }, [setCount, challenges]);

  useEffect(() => {
    setChallengeNode(challenge ? getChallengeByIdWithFallbackToInProgress(challengeNodes, challenge.id) : undefined);
  }, [setChallengeNode, challenge, challengeLevels]);

  useEffect(() => {
    if (!challengeNode) return;

    const { nextNodeId, prevNodeId } = challengeNode;
    setNextChallenge(nextNodeId ? challengeNodes[nextNodeId] : null);
    setPrevChallenge(prevNodeId ? challengeNodes[prevNodeId] : null);
  }, [setPrevChallenge, setNextChallenge, challengeNode, challengeNodes]);

  useEffect(() => {
    if (challenge) {
      const challengeIndex = challenges.findIndex(({ id }) => id === challenge.id);
      setIndex(challengeIndex);
      setAskForHint(false);
      setHint('');
    }
  }, [setIndex, challenge, challenges]);

  useEffect(() => {
    setNextChallengeAvailable(!!nextChallenge && nextChallenge?.state !== ChallengeState.Locked);
  }, [setNextChallengeAvailable, nextChallenge]);

  useEffect(() => {
    setPrevChallengeAvailable(!!prevChallenge && prevChallenge?.state !== ChallengeState.Locked);
  }, [setPrevChallengeAvailable, prevChallenge]);

  return {
    index,
    count,
    nextChallenge,
    prevChallenge,
    nextChallengeAvailable,
    prevChallengeAvailable,
  };
};
