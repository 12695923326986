import { Instr } from '../interpret/InterpretResult';
import { Color } from './Color';
import { degToRad, simplifyRad } from '../util/numberUtil';
import { Fill } from './Fill';
import { Pen } from './Pen';
import { StdFnNames } from './StdFnName';
import { Thickness } from './Thickness';

export class Turtle {
  readonly x: number;
  readonly y: number;
  readonly _heading: number;
  readonly _down: boolean;
  readonly _pen: Pen;
  readonly _fill: Fill;
  readonly stack: Instr[];

  constructor(x: number, y: number, heading: number, down: boolean, pen: Pen, fill: Fill, stack: Instr[]) {
    this.x = x;
    this.y = y;
    this._heading = heading;
    this._down = down;
    this._pen = pen;
    this._fill = fill;
    this.stack = stack;
  }

  forward(l: number): Turtle {
    const deltaX = l * Math.sin(this._heading);
    const deltaY = l * Math.cos(this._heading);
    const newX = this.x + deltaX;
    const newY = this.y + deltaY;

    return new Turtle(newX, newY, this._heading, this._down, this._pen, this._fill, this.stack);
  }

  backward(l: number): Turtle {
    return this.forward(-l);
  }

  goto(x: number, y: number): Turtle {
    return new Turtle(x, y, this._heading, this._down, this._pen, this._fill, this.stack);
  }

  right(deg: number): Turtle {
    const newHeading = simplifyRad(this._heading + degToRad(deg));
    return new Turtle(this.x, this.y, newHeading, this._down, this._pen, this._fill, this.stack);
  }

  left(deg: number): Turtle {
    return this.right(-deg);
  }

  headingDeg(heading: number): Turtle {
    return this.headingRad(degToRad(heading));
  }

  headingRad(heading: number): Turtle {
    return new Turtle(this.x, this.y, heading, this._down, this._pen, this._fill, this.stack);
  }

  up(): Turtle {
    return new Turtle(this.x, this.y, this._heading, false, this._pen, this._fill, this.stack);
  }

  down(): Turtle {
    return new Turtle(this.x, this.y, this._heading, true, this._pen, this._fill, this.stack);
  }

  penThickness(t: Thickness): Turtle {
    return new Turtle(this.x, this.y, this._heading, this._down, this._pen.thickness(t), this._fill, this.stack);
  }

  penColor(c: Color | undefined): Turtle {
    return new Turtle(this.x, this.y, this._heading, this._down, this._pen.color(c), this._fill, this.stack);
  }

  penPattern(p: string): Turtle {
    return new Turtle(this.x, this.y, this._heading, this._down, this._pen.pattern(p), this._fill, this.stack);
  }

  fillColor(c: Color | undefined): Turtle {
    return new Turtle(this.x, this.y, this._heading, this._down, this._pen, this._fill.color(c), this.stack);
  }

  fillOpacity(o: string): Turtle {
    return new Turtle(this.x, this.y, this._heading, this._down, this._pen, this._fill.opacity(o), this.stack);
  }

  push(instr: Instr): Turtle {
    return new Turtle(this.x, this.y, this._heading, this._down, this._pen, this._fill, [...this.stack, instr]);
  }

  pop(): [Turtle, Instr | undefined] {
    const newStack = [...this.stack];
    const instr = newStack.pop();
    return [new Turtle(this.x, this.y, this._heading, this._down, this._pen, this._fill, newStack), instr];
  }

  static DEFAULT: Turtle = new Turtle(
    0.0,
    0.0,
    0,
    true,
    new Pen(Thickness.FROM_NAME(StdFnNames.MEDIUM)!, Color.FROM_NAME('white')!, StdFnNames.NONE),
    new Fill(undefined, StdFnNames.FULL),
    []
  );
}
