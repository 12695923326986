import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Direction, TourStep } from 'ui/components/common/smart/PageTour/Tour';
import PageTour, { PageTourStep } from 'ui/components/common/smart/PageTour';
import { useHomeScreenStoreActions } from 'ui/store/homescreen/useHomeScreenStoreActions';
import { HomeSubscreen } from 'ui/store/homescreen/enums';

const ChallengeTour: React.FC = () => {
  const { t } = useTranslation('pageTour');
  const dispatch = useDispatch();
  const { setHomeScreen } = useHomeScreenStoreActions(dispatch);

  const steps = React.useMemo<TourStep[]>(
    () => [
      {
        area: PageTourStep.Modes,
        content: t(PageTourStep.Modes),
      },
      {
        area: PageTourStep.ChallengeDescription,
        content: t(PageTourStep.ChallengeDescription),
        position: Direction.Left,
        align: Direction.Bottom,
        onBefore: () => setHomeScreen(HomeSubscreen.Challenges),
      },
      {
        area: PageTourStep.CloseContent,
        content: t(PageTourStep.CloseContent),
        position: Direction.Bottom,
        align: Direction.Right,
        onBefore: () => setHomeScreen(HomeSubscreen.Challenges),
      },
      { area: PageTourStep.Editor, content: t(PageTourStep.Editor + '-challenge') },
      {
        area: PageTourStep.Canvas,
        content: t(PageTourStep.Canvas + '-challenge'),
        position: Direction.Left,
        align: Direction.Bottom,
        onBefore: () => setHomeScreen(HomeSubscreen.None),
      },
      {
        area: PageTourStep.CheckChallenge,
        content: t(PageTourStep.CheckChallenge),
        position: Direction.Bottom,
        align: Direction.Right,
      },
      {
        area: PageTourStep.ToggleChallengeDescription,
        content: t(PageTourStep.ToggleChallengeDescription),
        position: Direction.Bottom,
        align: Direction.Right,
        onAfter: () => setHomeScreen(HomeSubscreen.Challenges),
      },
      {
        area: PageTourStep.Help,
        content: t(PageTourStep.Help),
      },
    ],
    [t]
  );

  return <PageTour steps={steps} />;
};

export default ChallengeTour;
