import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useApi from 'ui/components/hooks/useApi';
import AnimationItem from './AnimationItem';
import Loader from 'ui/components/common/dumb/Loader';
import styles from './MyAnimations.module.scss';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import useListLoader from 'ui/components/hooks/useListLoader';

const MyAnimations: React.FC = () => {
  const { t } = useTranslation('myAnimations');
  const { getAnimationList } = useApi();

  const { loadPortion, items: animations, moreAvailable, result } = useListLoader([getAnimationList]);

  useEffect(() => {
    if (result.isIdle) loadPortion();
  }, [result, loadPortion]);

  return (
    <div className={styles.container}>
      <h1>{t('myAnimations:myAnimations')}</h1>
      {animations.length > 0 && (
        <div className={styles.list}>
          {animations.map((animation) => (
            <AnimationItem key={animation.id} code={animation} />
          ))}
        </div>
      )}

      {animations.length === 0 && result.isResolved && <p>{t('noAnimationsSaved')}</p>}

      {result.isLoading && <Loader />}

      {moreAvailable && !result.isLoading && (
        <Button flavor={ButtonFlavor.Gray} noBorder onClick={loadPortion}>
          {t(result.isResolved ? 'user_activity:load_more' : 'user_activity:retry')}
        </Button>
      )}
    </div>
  );
};

export default MyAnimations;
