import React from 'react';
import styles from './UserMenu.module.scss';
import AuthorizedHeaderContent from './AuthorizedContent';
import UnauthorizedContent from './UnauthorizedContent';
import { useSelector } from 'react-redux';
import { userSelector } from 'ui/store/user/selectors';
import { HOUR_OF_CODE_TOKEN_STORAGE_KEY } from 'ui/constants';
import useLocalStorage from '@rehooks/local-storage';

const UserMenu: React.FC = () => {
  const user = useSelector(userSelector);
  const [contestToken] = useLocalStorage<boolean>(HOUR_OF_CODE_TOKEN_STORAGE_KEY);

  return (
    <div className={styles.container}>
      {user ? <AuthorizedHeaderContent /> : <>{contestToken === true ? null : <UnauthorizedContent />}</>}
    </div>
  );
};

export default UserMenu;
