import React from 'react';
import { getTestSelector } from 'util/testUtil';
import styles from './MdxWrapper.module.scss';

interface Props {
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  children: React.ReactNode;
}

const MdxWrapper = React.forwardRef<HTMLDivElement, Props>(({ children, onClick }, ref) => (
  <div
    ref={ref}
    className={styles.mdx}
    onClick={onClick}
    role="navigation"
    data-testid={getTestSelector('homescreen.mdx_wrapper')}
  >
    {children}
  </div>
));

export default MdxWrapper;
