import React from 'react';
import { useTranslation } from 'react-i18next';
import { type ColorResult, type InjectedColorProps, CustomPicker } from 'react-color';
import { Hue, Saturation } from 'react-color/lib/components/common';
import Button, { ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import styles from './ColorPicker.module.scss';

interface Props {
  onChange: (color: ColorResult) => void;
  onGoBack: () => void;
}

const Pointer: React.FC = () => <div className={styles.pointer}></div>;

const ColorPicker = ({ onChange, onGoBack, ...props }: Props & InjectedColorProps) => {
  const { t } = useTranslation('editZone');
  return (
    <div className={styles.container}>
      <div className={styles.hueBox}>
        <div className={styles.color}>
          <div
            className={styles.swatch}
            style={{
              backgroundColor: `rgb(${props.rgb ? Object.values(props.rgb) : '255, 255, 255'})`,
            }}
          />
        </div>
        <div className={styles.apply}>
          <Button size={ButtonSize.Small} flavor={ButtonFlavor.Success} className={styles.btn} onClick={onGoBack}>
            {t('back')}
          </Button>
        </div>
        <div className={styles.hue}>
          <Hue onChange={onChange} pointer={<Pointer />} {...props} />
        </div>
      </div>
      <div className={styles.saturation}>
        <Saturation onChange={onChange} {...props} />
      </div>
    </div>
  );
};

export default CustomPicker(ColorPicker);
