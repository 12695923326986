import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Rotation: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M40.979,7.047A23.848,23.848,0,0,0,24.011.01H24A24,24,0,0,0,0,24a2,2,0,0,0,2,2H2a2,2,0,0,0,2-2A20,20,0,0,1,24,4.01h.01a20,20,0,1,1-.009,40h-.009a20.041,20.041,0,0,1-10.74-3.169l3.254-2.76a1,1,0,0,0-.378-1.727L4.742,33.183a1,1,0,0,0-1.232.694.989.989,0,0,0-.029.394L4.939,45.865a1,1,0,0,0,1.639.638l3.488-2.959A24.088,24.088,0,0,0,23.989,48.01H24A24,24,0,0,0,40.979,7.047Z"
        className={styles.yellow}
      />
    </g>
  </svg>
);

export default Rotation;
