import { Color } from './Color';

export class Fill {
  readonly _color: Color | undefined;
  readonly _opacity: string;

  constructor(color: Color | undefined, opacity: string) {
    this._color = color;
    this._opacity = opacity;
  }

  color(c: Color | undefined): Fill {
    return new Fill(c, this._opacity);
  }

  opacity(o: string): Fill {
    return new Fill(this._color, o);
  }

  hasFill(): boolean {
    return this._color != undefined;
  }
}
