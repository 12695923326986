import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Bulb: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <g className="nc-icon-wrapper">
      <circle fill="#B3B3B3" cx="24" cy="42" r="5"></circle>
      <path
        className={styles.yellow}
        d="M22.19336,1.09389C14.44846,1.89722,8.11993,8.05841,7.14065,15.78304 C6.17718,23.38294,10.23984,30.43416,17,33.48535V39h14v-5.51465C37.09717,30.7334,41,24.72754,41,18 C41,8.03032,32.37408,0.0379,22.19336,1.09389z"
      ></path>
      <path
        fill="#EDE4BC"
        d="M16,19c-0.55225,0-1-0.44727-1-1c0-4.96289,4.0376-9,9-9c0.55225,0,1,0.44727,1,1s-0.44775,1-1,1 c-3.85986,0-7,3.14062-7,7C17,18.55273,16.55225,19,16,19z"
      ></path>
      <path fill="#E6E6E6" d="M17,38v4c0,1.10455,0.89545,2,2,2h10c1.10461,0,2-0.89545,2-2v-4H17z"></path>
    </g>
  </svg>
);

export default Bulb;
