import { useCallback, useState } from 'react';

export type EditZoneViewType = 'main' | 'colorpicker';

export const useZoneViewType = (): [EditZoneViewType, () => void] => {
  const [viewType, setViewType] = useState<EditZoneViewType>('main');

  const toggle = useCallback(() => {
    setViewType(viewType === 'main' ? 'colorpicker' : 'main');
  }, [setViewType, viewType]);

  return [viewType, toggle];
};
