import React from 'react';
import Context from './Context';
import { Direction, StepRenderer } from './types';
import useStepProps from './useStepProps';

type Props = {
  children: StepRenderer;
} & React.HTMLAttributes<HTMLDivElement>;

const Floater = ({ children, style, ...htmlAttributes }: Props) => {
  const { state } = React.useContext(Context);
  const { step, elementInset } = state;
  const stepProps = useStepProps(step);

  if (step == null || elementInset === null || stepProps == null) return null;

  const { position, align } = step;
  const { top, bottom, left, right, height, width } = elementInset;

  const getAlignment = () => {
    switch (align) {
      case Direction.Top:
        return { top: `${top}px` };
      case Direction.Bottom:
        return { bottom: `${bottom}px` };
      case Direction.Left:
        return { left: `${left}px` };
      case Direction.Right:
        return { right: `${right}px` };
      default:
        return {};
    }
  };

  const getPositioning = () => {
    switch (position) {
      case Direction.Top:
        return { bottom: `${bottom + height}px` };
      case Direction.Bottom:
        return { top: `${top + height}px` };
      case Direction.Left:
        return { right: `${right + width}px` };
      case Direction.Right:
        return { left: `${left + width}px` };
      default:
        return {};
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        ...getAlignment(),
        ...getPositioning(),
        ...style,
      }}
      {...htmlAttributes}
    >
      {children(stepProps)}
    </div>
  );
};

export default Floater;
