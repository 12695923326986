import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const PhotoFrame: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <rect
        height="15.25662"
        width="2.00061"
        className={styles.white}
        transform="matrix(0.60382 0.79712 -0.79712 0.60382 13.85908 -9.09661)"
        x="15.08051"
        y="1.76573"
      />
      <rect
        height="2.00061"
        width="15.25662"
        className={styles.white}
        transform="matrix(0.79714 0.6038 -0.6038 0.79714 12.14731 -17.36704)"
        x="24.29088"
        y="8.39374"
      />
      <path d="M24,7c-1.6543,0-3-1.3457-3-3s1.3457-3,3-3s3,1.3457,3,3S25.6543,7,24,7z" className={styles.yellow} />
      <path
        d="M45,47H3c-1.10457,0-2-0.89543-2-2V15c0-1.10457,0.89543-2,2-2h42c1.10457,0,2,0.89543,2,2v30 C47,46.10457,46.10457,47,45,47z"
        className={styles.yellow}
      />
      <path
        d="M42,43H6c-0.55225,0-1-0.44775-1-1V18c0-0.55225,0.44775-1,1-1h36c0.55225,0,1,0.44775,1,1v24 C43,42.55225,42.55225,43,42,43z"
        className={styles.white}
      />
      <circle cx="21" cy="23" className={styles.yellow} r="3" />
      <path
        d="M26.42188,40l-8.71484-8.70703c-0.19678-0.19727-0.46387-0.30518-0.74561-0.29199 c-0.27832,0.01025-0.53955,0.13672-0.7207,0.34814l-6,7c-0.25391,0.29639-0.3125,0.71387-0.14941,1.06885 C10.25488,39.77246,10.60938,40,11,40H26.42188z"
        className={styles.green}
      />
      <path
        d="M37.80859,38.41162l-8-11C29.62402,27.15771,29.33057,27.00537,29.01611,27 c-0.32715,0.00049-0.6123,0.1377-0.80566,0.38623L18.39183,40h10.17439H37c0.37598,0,0.72021-0.21094,0.89111-0.54639 C38.06201,39.11865,38.02979,38.71582,37.80859,38.41162z"
        className={styles.darkGreen}
      />
    </g>
  </svg>
);

export default PhotoFrame;
