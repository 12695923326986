import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import styles from './Nav.module.scss';
import LogoLink from 'ui/components/common/smart/LogoLink';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { CodeType } from 'types/code';

interface Props {
  className?: string;
  vertical?: boolean;
}

const codeTypeSelector = createCodeStatePropSelector('codeType');

const Nav = ({ children, className, vertical = false }: PropsWithChildren<Props>) => {
  const { codeType } = useSelector(codeTypeSelector);
  return (
    <>
      <div className={styles.logo}>
        <LogoLink />
      </div>
      <nav
        className={classnames(
          styles.container,
          { [styles.animations]: codeType === CodeType.Animation, [styles.vertical]: vertical },
          className
        )}
      >
        {children}
      </nav>
    </>
  );
};

export default Nav;
