import Konva from 'konva';

import { Instr } from 'interpret/InterpretResult';
import { Turtle } from 'turtle/Turtle';
import { ByPos } from 'util/ByPos';
import { CanvasSize } from './CanvasSize';
import { PaintInstr } from './PaintInstr';
import { PatternShapes } from './PatternShapes';
import { TurtleLayer } from './TurtleLayer';

import Vector2d = Konva.Vector2d;

type PaintEnhancer = (paint: Paint) => void;
type ZoomAndPosition = { zoom: number; position: Vector2d };
type OnViewChanged = (zoomAndPosition: ZoomAndPosition) => void;

export class Paint {
  public canvasSize: CanvasSize;
  public readonly stage: Konva.Stage;

  private onViewChanged: OnViewChanged = () => null;
  private readonly paintInstr: PaintInstr;
  private readonly mainLayer: Konva.Layer;
  private readonly turtleLayer: TurtleLayer;
  private readonly crosshairLayer: Konva.Layer;
  private readonly crosshairGroup: Konva.Group;

  constructor(
    paintArea: HTMLDivElement | undefined, // needed for the render server
    canvasSize: CanvasSize,
    enhancers: PaintEnhancer[] = []
  ) {
    Konva.angleDeg = false;

    this.canvasSize = canvasSize;

    // scaling y so that it y-s grow upwards, not downwards
    const stageCenter = {
      x: this.canvasSize.canvasWidth / 2,
      y: this.canvasSize.canvasHeight / 2,
      scaleY: -1,
    };

    this.paintInstr = new PaintInstr(stageCenter, new PatternShapes());

    this.crosshairLayer = new Konva.Layer();
    this.crosshairGroup = createCrosshairGroup(stageCenter);
    this.crosshairLayer.add(this.crosshairGroup);

    this.mainLayer = new Konva.Layer();

    const _turtleLayer = new Konva.Layer();
    this.turtleLayer = new TurtleLayer(_turtleLayer, stageCenter);

    const defaultZoom = 1;
    const clientPosWhenCentered = this.canvasSize.clientPosWhenCentered(defaultZoom);
    this.stage = new Konva.Stage({
      container: <HTMLDivElement>paintArea,
      width: this.canvasSize.canvasWidth,
      height: this.canvasSize.canvasHeight,
      x: clientPosWhenCentered.x,
      y: clientPosWhenCentered.y,
    });

    this.stage.add(this.crosshairLayer);
    this.stage.add(this.mainLayer);
    this.stage.add(_turtleLayer);

    enhancers.forEach((enhancer) => {
      enhancer(this);
    });
  }

  resize(canvasSize: CanvasSize): void {
    const positionBeforeResize = this.getCurrentPosition();

    this.canvasSize = canvasSize;
    this.stage.width(canvasSize.canvasWidth);
    this.stage.height(canvasSize.canvasHeight);

    this.handlePositionCanvas(positionBeforeResize);

    this.resizeLayers(canvasSize);

    this.redraw();
  }

  private resizeLayers(newSize: CanvasSize): void {
    const newStageCenter = {
      x: newSize.canvasWidth / 2,
      y: newSize.canvasHeight / 2,
    };

    this.crosshairGroup.setPosition(newStageCenter);

    this.turtleLayer.onResize(newStageCenter);
    this.paintInstr.onResize(newStageCenter);
  }

  paint(instr: Instr[]): [ByPos<Turtle>, ByPos<any>] {
    //console.time('paint');
    try {
      const paintResult = this.paintInstr.apply(instr);

      // Releasing all shapes created beforehand. Calling removeChildren() instead causes a memory
      // leak, as the shapes are still references from a global Konva object.
      this.mainLayer.destroyChildren();
      this.mainLayer.add(paintResult[0]);
      this.mainLayer.batchDraw();

      const lastTurtle = paintResult[1].findLast();
      if (lastTurtle) {
        this.turtleLayer.paintTurtleMain(lastTurtle);
      }

      return [paintResult[1], paintResult[2]];
    } finally {
      //console.timeEnd('paint');
    }
  }

  setOnViewChanged(onViewChanged: OnViewChanged): void {
    this.onViewChanged = onViewChanged;
  }

  setTurtleShow(show: boolean): void {
    this.turtleLayer.handleTurtleShow(show);
  }

  setTurtleSelected(t: Turtle): void {
    this.turtleLayer.handleTurtleSelected(t);
  }

  triggerOnViewChanged(): void {
    this.onViewChanged({
      zoom: this.getCurrentZoom(),
      position: this.positionAbsoluteToRelative(this.stage.position()),
    });
  }

  private handleZoomCanvas(newZoom: number): void {
    if (Paint.isEqualZoom(this.getCurrentZoom(), newZoom)) return;
    this.stage.scale({ x: newZoom, y: newZoom });
  }

  private handlePositionCanvas(newRelativePosition: Vector2d): void {
    const newPosAbsolute = this.positionRelativeToAbsolute(newRelativePosition);
    if (this.stage.position().x === newPosAbsolute.x && this.stage.position().y === newPosAbsolute.y) return;

    this.stage.position(newPosAbsolute);
  }

  private redraw(): void {
    this.stage.batchDraw();
  }

  setZoomAndPosition(newZoom: number, newRelativePosition: Vector2d, triggerCallback = true): void {
    this.handleZoomCanvas(newZoom);
    this.handlePositionCanvas(newRelativePosition);
    this.redraw();

    if (triggerCallback) this.triggerOnViewChanged();
  }

  setZoom(newZoom: number, triggerCallback = true): void {
    const positionBeforeZoom = this.getCurrentPosition();
    this.handleZoomCanvas(newZoom);
    this.handlePositionCanvas(positionBeforeZoom);
    this.redraw();

    if (triggerCallback) this.triggerOnViewChanged();
  }

  setPosition(newRelativePosition: Vector2d, triggerCallback = true): void {
    this.handlePositionCanvas(newRelativePosition);
    this.redraw();

    if (triggerCallback) this.triggerOnViewChanged();
  }

  getCurrentZoom(): number {
    return Paint.fixZoom(this.stage.scaleX());
  }

  getCurrentPosition(): Vector2d {
    return this.positionAbsoluteToRelative(this.stage.position());
  }

  static isEqualZoom(zoomA: number, zoomB: number): boolean {
    return zoomA.toFixed(2) === zoomB.toFixed(2);
  }

  static fixZoom(zoom: number): number {
    return Number(zoom.toFixed(2));
  }

  positionRelativeToAbsolute(relativePosition: Vector2d): Vector2d {
    const zoom = this.getCurrentZoom();
    const clientPosWhenCentered = this.canvasSize.clientPosWhenCentered(zoom);

    return {
      x: clientPosWhenCentered.x - relativePosition.x * zoom,
      y: clientPosWhenCentered.y - relativePosition.y * zoom,
    };
  }

  positionAbsoluteToRelative(absolutePosition: Vector2d): Vector2d {
    const zoom = this.getCurrentZoom();
    const clientPosWhenCentered = this.canvasSize.clientPosWhenCentered(zoom);

    return {
      x: (clientPosWhenCentered.x - absolutePosition.x) / zoom,
      y: (clientPosWhenCentered.y - absolutePosition.y) / zoom,
    };
  }

  destroy(): void {
    this.stage.destroy();
  }

  getDataUrlImage(): string {
    const wasTurtleVisible = this.turtleLayer.isVisible();
    this.setTurtleShow(false);
    this.setCrosshairShow(false);
    const oldZoom = this.getCurrentZoom().toString();
    this.setZoom(1, false);
    return this.stage.toDataURL({
      width: this.canvasSize.canvasWidth,
      height: this.canvasSize.canvasHeight,
      pixelRatio: 1,
      ...this.stage.position(),
      callback: () => {
        this.setZoom(parseFloat(oldZoom), false);
        this.setTurtleShow(wasTurtleVisible);
        this.setCrosshairShow(true);
      },
    });
  }

  setCrosshairShow(show: boolean): void {
    if (show) this.crosshairLayer.show();
    else this.crosshairLayer.hide();
    this.stage.batchDraw();
  }
}

function createCrosshairGroup(xy: { x: number; y: number; scaleY: number }): Konva.Group {
  const group = new Konva.Group(xy);
  const common = {
    stroke: '#353540',
    strokeWidth: 2,
  };
  const horizontal = new Konva.Line({
    ...common,
    points: [-xy.x, 0, xy.x, 0],
  });
  const vertical = new Konva.Line({
    ...common,
    points: [0, -xy.y, 0, xy.y],
  });
  group.add(horizontal);
  group.add(vertical);
  return group;
}
