import React from 'react';
import classnames from 'classnames';
import PromptBox from 'ui/components/common/dumb/PromptBox';
import UserForm from 'ui/components/common/smart/UserForm/UserForm';
import UseFacebook from 'ui/components/common/smart/UseFacebook';
import LoginRegisterSwitch from 'ui/components/common/smart/LoginRegisterSwitch';
import styles from './RegistrationForm.module.scss';
import { useTranslation } from 'react-i18next';
import inMainScreen from 'ui/components/hocs/inMainScreen';
import { HOUR_OF_CODE_TOKEN_STORAGE_KEY } from 'ui/constants';
import { Navigate } from 'react-router-dom';
import useLocalStorage from '@rehooks/local-storage';

const RegistrationForm: React.FC = () => {
  const { t } = useTranslation('userForm');
  const [contestToken] = useLocalStorage<boolean>(HOUR_OF_CODE_TOKEN_STORAGE_KEY);

  if (contestToken === true) {
    return <Navigate to="/" />;
  }

  return (
    <PromptBox>
      <PromptBox.Row>
        <PromptBox.Heading>{t('registerHeading')}</PromptBox.Heading>
        <UserForm mode="register" />
      </PromptBox.Row>
      <PromptBox.Row className={styles.row}>
        <UseFacebook mode="register" />
      </PromptBox.Row>
      <PromptBox.Row className={classnames(styles.pad, styles.row)}>
        <LoginRegisterSwitch mode="register" />
      </PromptBox.Row>
    </PromptBox>
  );
};

export default inMainScreen(RegistrationForm);
