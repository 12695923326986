import { PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from './PromptBox.module.scss';
import { getTestSelector } from 'util/testUtil';

interface RowProps {
  className?: string;
}

const Row = ({ children, className }: PropsWithChildren<RowProps>) => (
  <div className={classnames(styles.row, className)}>{children}</div>
);

interface HeadingProps {
  className?: string;
}

const Heading = ({ children, className }: PropsWithChildren<HeadingProps>) => (
  <h1 data-testid={getTestSelector('user.form.heading')} className={classnames(styles.heading, className)}>
    {children}
  </h1>
);

interface Props {
  className?: string;
}

const PromptBox = ({ className, children }: PropsWithChildren<Props>) => (
  <div className={classnames(styles.container, className)}>{children}</div>
);

PromptBox.Row = Row;
PromptBox.Heading = Heading;

export default PromptBox;
