import { createSelector, type Selector } from '@reduxjs/toolkit';
import { AppState } from './types';

type AppStateKey = keyof AppState;
type Substore<T extends AppStateKey> = AppState[T];
type SubstoreKeys<T extends AppStateKey> = keyof Substore<T>;
type SubstoreSpecifics<T extends AppStateKey> = Pick<Substore<T>, SubstoreKeys<T>>;
type StatePropSelector<T extends AppStateKey> = Selector<
  AppState,
  Pick<Substore<T>, SubstoreKeys<T>>,
  // @ts-ignore
  (res: Substore<T>) => SubstoreSpecifics<T>
>;

export const createStatePropSelectorCreator =
  <T extends AppStateKey>(key: T) =>
  (...props: SubstoreKeys<T>[]): StatePropSelector<T> =>
    // @ts-ignore
    createSelector(
      (s: AppState): Substore<T> => s[key],
      (subState: Substore<T>) =>
        Object.fromEntries(
          Object.entries(subState).filter(([key]) => props.includes(key as SubstoreKeys<T>))
        ) as SubstoreSpecifics<T>
    );
