import { useCallback } from 'react';
import { type Action, type Dispatch } from '@reduxjs/toolkit';
import { actionCreators } from './slice';

type UseHelpStoreActions = {
  setReference: (referenceArticle: string) => void;
  setContextHelp: (contextHelpArticle: string) => void;
};

export const useHelpStoreActions = (dispatch: Dispatch<Action>): UseHelpStoreActions => {
  const setReference = useCallback(
    (referenceArticle: string) => {
      dispatch(actionCreators.setReference(referenceArticle));
    },
    [dispatch]
  );

  const setContextHelp = useCallback(
    (contextHelpArticle: string) => {
      dispatch(actionCreators.setContextHelp(contextHelpArticle));
    },
    [dispatch]
  );

  return {
    setReference,
    setContextHelp,
  };
};
