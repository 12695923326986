import { createSelector } from '@reduxjs/toolkit';
import { EditorState } from './slice';
import { AppState } from '../types';

export const editorStateSelector = (state: AppState): EditorState => state.editor;

export const currentExpSelector = createSelector(editorStateSelector, (s: EditorState) => s.currentExp);

export const undoRedoSelector = createSelector(editorStateSelector, (s: EditorState) => ({
  undoDisabled: s.undoDisabled,
  redoDisabled: s.redoDisabled,
}));

export const positionSelector = createSelector(editorStateSelector, (s: EditorState) => s.position);

export const valueByPosSelector = createSelector(editorStateSelector, (s: EditorState) => s.valueByPos);

export const turtleByPosSelector = createSelector(editorStateSelector, (s: EditorState) => s.turtleByPos);

export const interpretResultSelector = createSelector(editorStateSelector, (s: EditorState) => s.interpretResult);

export const selectedTurtleSelector = createSelector(editorStateSelector, (s: EditorState) => s.selectedTurtle);
