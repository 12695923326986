const DEGREE_IN_RAD = Math.PI / 180;
const TWO_PI = Math.PI * 2;

export function degToRad(deg: number): number {
  return deg * DEGREE_IN_RAD;
}

export function radToDeg(rad: number): number {
  return rad / DEGREE_IN_RAD;
}

export function simplifyRad(rad: number): number {
  return rad % TWO_PI;
}

export function clamp(value: number, minimum: number, maximum: number): number {
  return Math.min(maximum, Math.max(minimum, value));
}
