import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonSize, ButtonFlavor } from 'ui/components/common/dumb/Button';
import Input from 'ui/components/common/dumb/Input';
import useCodeSave from 'ui/components/hooks/useCodeSave';
import FloppyDisk from 'ui/components/svg/FloppyDisk';
import SocialShareToggle from './SocialShareToggle';
import styles from './EditorTopCreative.module.scss';
import { createProgram } from 'ui/store/code/helpers';
import { PageTourStep } from 'ui/components/common/smart/PageTour/types';
import { pathForCode } from 'ui/components/routing/programHelpers';
import { CodeType } from 'types/code';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';

const codePropSelector = createCodeStatePropSelector('program', 'creativeCode');

const EditorTopCreative: React.FC = () => {
  const { t } = useTranslation(['asideTop']);
  const {
    program: { name },
    creativeCode,
  } = useSelector(codePropSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setCustomChallengeDesc, setCustomChallengeName, setCreativeCode, setProgram, setProgramName } =
    useCodeStoreActions(dispatch);

  const handleProgramNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setProgramName(e.target.value);
    },
    [setProgramName]
  );

  const handleNewProgram = useCallback(() => {
    const program = createProgram();
    setProgram(program);
    setCreativeCode(program.text);
    setCustomChallengeDesc('');
    setCustomChallengeName('');
    navigate(pathForCode(CodeType.Creative));
  }, [setProgram, setCreativeCode, setCustomChallengeDesc, setCustomChallengeName]);

  const [handleProgramSave, labelFromSaveType, saving] = useCodeSave(creativeCode);

  return (
    <header className={styles.container} data-tour-area={PageTourStep.Save}>
      <Input
        className={styles.input}
        placeholder={t('asideTop:nameYourProgram')}
        onChange={handleProgramNameChange}
        value={name || ''}
        compact
      />
      <div className={styles.buttons}>
        <Button
          size={ButtonSize.Small}
          flavor={ButtonFlavor.Gray}
          className={classnames(styles.button, styles.save)}
          onClick={handleProgramSave}
          disabled={saving}
          Icon={FloppyDisk}
          title={t(labelFromSaveType('save', 'update', 'saveCopy'))}
        />
        <SocialShareToggle className={classnames(styles.button, styles.share)} />
        <Button
          size={ButtonSize.Small}
          flavor={ButtonFlavor.Gray}
          className={classnames(styles.button, styles.newProgram)}
          onClick={handleNewProgram}
          disabled={saving}
        >
          {t('asideTop:newProgram')}
        </Button>
      </div>
    </header>
  );
};

export default EditorTopCreative;
