import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const HelpingHand: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M33,1a3,3,0,0,0-3,3V16.976a3,3,0,0,0-6,0,3,3,0,0,0-6,0v1a3,3,0,0,0-6,0V36a7,7,0,0,0,4,6.315V46a1,1,0,0,0,1,1H31a1,1,0,0,0,1-1V42.315A7,7,0,0,0,36,36V4A3,3,0,0,0,33,1Z"
        className={styles.green}
      />
      <path
        d="M29,39a1,1,0,0,1-1-1V34H22a4,4,0,0,1,0-8H36v2H22a2,2,0,0,0,0,4h7a1,1,0,0,1,1,1v5A1,1,0,0,1,29,39Z"
        className={styles.darkGreen}
      />
      <path d="M30,28a1,1,0,0,1-1-1V18a1,1,0,0,1,2,0v9A1,1,0,0,1,30,28Z" className={styles.darkGreen} />
      <path d="M24,28a1,1,0,0,1-1-1V18a1,1,0,0,1,2,0v9A1,1,0,0,1,24,28Z" className={styles.darkGreen} />
      <path
        d="M15.5,32A3.5,3.5,0,0,1,12,28.5a1,1,0,0,1,2,0,1.5,1.5,0,0,0,3,0V19a1,1,0,0,1,2,0v9.5A3.5,3.5,0,0,1,15.5,32Z"
        className={styles.darkGreen}
      />
    </g>
  </svg>
);

export default HelpingHand;
