import React, { FormHTMLAttributes } from 'react';
import classnames from 'classnames';
import styles from './Form.module.scss';

interface Props extends FormHTMLAttributes<any> {
  className?: string;
  'data-testid'?: string;
}

const Form: React.FC<Props> = ({ children, className, onSubmit, ...props }) => (
  <form className={classnames(styles.container, className)} onSubmit={onSubmit} {...props}>
    {children}
  </form>
);

export default Form;
