import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const CurvedArrowLeft: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M1.4,31.2l13-9a.984.984,0,0,1,1.4.3,1.421,1.421,0,0,1,.2.6v7H32.5A10.65,10.65,0,0,0,43,19.5,10.5,10.5,0,0,0,32.5,9H14a2,2,0,0,1,0-4H32.5a14.5,14.5,0,0,1,0,29H16v7a.945.945,0,0,1-1,1,1.421,1.421,0,0,1-.6-.2l-13-9a.984.984,0,0,1-.3-1.4C1.2,31.3,1.3,31.2,1.4,31.2Z"
        className={styles.yellow}
      />
    </g>
  </svg>
);

export default CurvedArrowLeft;
