import React from 'react';
import { useSelector } from 'react-redux';
import MDX from '@mdx-js/runtime';
import { useTranslation } from 'react-i18next';
import { pathForChallengeImage } from 'ui/components/routing/programHelpers';
import { userSelector } from 'ui/store/user/selectors';
import { challengeSelector, createCodeStatePropSelector } from 'ui/store/code/selectors';
import Loader from 'ui/components/common/dumb/Loader';
import RegistrationInfoBox from './RegistrationInfoBox';
import styles from './ChallengeText.module.scss';
import { HintBox, AskForHintBox } from './HintBox';

const hintSelector = createCodeStatePropSelector('hint', 'isHintLoading', 'askForHint');

const ChallengeText: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('challenges');
  const { challenge } = useSelector(challengeSelector);
  const user = useSelector(userSelector);
  const { hint, isHintLoading, askForHint } = useSelector(hintSelector);

  if (challenge) {
    return (
      <div className={styles.container}>
        <div className={styles.description}>
          {!hint && isHintLoading && (
            <div className={styles['loader-container']}>
              <Loader />
            </div>
          )}

          {askForHint && <AskForHintBox />}
          {hint && !isHintLoading && <HintBox hint={hint} />}
          {!user && <RegistrationInfoBox />}
          <img
            key={challenge.id}
            src={pathForChallengeImage(challenge.id)}
            className={styles.img}
            alt={t('descriptions.alt')}
          />
          <MDX>{challenge.texts[language].description}</MDX>
        </div>
      </div>
    );
  }
  return null;
};

export default ChallengeText;
