import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Book39: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M12,46c-3.309,0-6-2.691-6-6s2.691-6,6-6h31v12H12z" className={styles.white} />
      <path
        d="M42,1H10C7.239,1,5,3.239,5,6v34c0,3.866,3.134,7,7,7h30c0.553,0,1-0.448,1-1s-0.447-1-1-1l-29.777,0 c-2.61,0-4.945-1.903-5.199-4.5C6.733,37.52,9.078,35,12,35h30c0.552,0,1-0.448,1-1V2C43,1.448,42.552,1,42,1z"
        className={styles.red}
      />
      <path d="M43,41H11c-0.553,0-1-0.448-1-1s0.447-1,1-1h32V41z" fill="#B3B3B3" />
      <path
        d="M10,33c-0.553,0-1-0.448-1-1V4c0-0.552,0.447-1,1-1s1,0.448,1,1v28C11,32.552,10.553,33,10,33z"
        className={styles.black}
      />
      <path
        d="M33,23H19c-0.553,0-1-0.448-1-1v-8c0-0.552,0.447-1,1-1h14c0.553,0,1,0.448,1,1v8C34,22.552,33.553,23,33,23 z"
        className={styles.red}
      />
    </g>
  </svg>
);

export default Book39;
