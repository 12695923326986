import Konva from 'konva';
import { InterpretError } from '../interpret/error/InterpretError';
import { MAX_IMAGES, MAX_STEPS_IN_INSTR } from '../interpret/limits';
import { FillAttrNames, PenAttrNames } from '../turtle/attrs';
import { InstrNames } from '../turtle/InstrNames';
import { StdFnNames } from '../turtle/StdFnName';
import { Thickness } from '../turtle/Thickness';
import { ByPos } from '../util/ByPos';
import { Instr } from '../interpret/InterpretResult';
import { Turtle } from '../turtle/Turtle';
import { Position, PositionRange } from '../util/Position';
import { Color } from '../turtle/Color';
import { CountingPatternShapes, PatternShapes } from './PatternShapes';
import { Resizeable } from './interfaces/Resizeable';
import { LineCap, LineJoin } from 'konva/lib/Shape';

export class PaintInstr implements Resizeable {
  private xy: { x: number; y: number; scaleY: number };
  private instrGroup: Konva.Group;
  private readonly patternShapes: PatternShapes;

  constructor(xy: { x: number; y: number; scaleY: number }, patternShapes: PatternShapes) {
    this.xy = xy;
    this.instrGroup = new Konva.Group(this.xy);
    this.patternShapes = patternShapes;
  }

  apply(instrs: Instr[]): [Konva.Group, ByPos<Turtle>, ByPos<any>] {
    this.instrGroup = new Konva.Group(this.xy);
    let current = Turtle.DEFAULT;

    const turtleByPos = new ByPos<Turtle>();
    turtleByPos.add(new Position(1, 1), current);

    const valueByPos = new ByPos<any>();
    const patternShapes = this.patternShapes.withNewCounter(MAX_IMAGES);
    const paintOneInstr = new PaintOneInstr(patternShapes, this.instrGroup, turtleByPos, valueByPos);

    instrs.forEach((instr, index) => {
      try {
        current = paintOneInstr.apply(current, instr);
      } catch (e) {
        const pos = positionRangeForInstr(instrs, index);
        if (e === InterpretError._TOO_MANY_IMAGES) {
          throw InterpretError.TOO_MANY_IMAGES(pos, MAX_IMAGES);
        } else if (e === InterpretError._TOO_MANY_STEPS_IN_INSTR) {
          throw InterpretError.TOO_MANY_STEPS_IN_INSTR(pos, MAX_STEPS_IN_INSTR);
        } else {
          console.error('Unknown error: %o', e);
          throw new InterpretError(pos, InterpretError.UNKNOWN);
        }
      }
    });

    return [this.instrGroup, turtleByPos, valueByPos];
  }

  onResize(newStageCenter: { x: number; y: number }): void {
    this.xy = {
      x: newStageCenter.x,
      y: newStageCenter.y,
      scaleY: this.xy.scaleY,
    };

    this.instrGroup.setPosition(this.xy);
  }
}

class PaintOneInstr {
  private readonly patternShapes: CountingPatternShapes;
  private readonly group: Konva.Group;
  private readonly turtleByPos: ByPos<Turtle>;
  private readonly valueByPos: ByPos<any>;

  constructor(
    patternShapes: CountingPatternShapes,
    group: Konva.Group,
    turtleByPos: ByPos<Turtle>,
    valueByPos: ByPos<any>
  ) {
    this.patternShapes = patternShapes;
    this.group = group;
    this.turtleByPos = turtleByPos;
    this.valueByPos = valueByPos;
  }

  // tslint:disable-next-line:max-func-body-length
  apply(current: Turtle, i: Instr): Turtle {
    switch (i.name) {
      case InstrNames.FW: {
        const previous = current;
        current = previous.forward(i.args[0]);
        if (current._down) this.line(previous, current, i.args[0]);
        return current;
      }

      case InstrNames.BK: {
        const previous = current;
        current = previous.backward(i.args[0]);
        if (current._down) this.line(current, previous, i.args[0]);
        return current;
      }

      case InstrNames.RIGHT: {
        return current.right(i.args[0]);
      }

      case InstrNames.LEFT: {
        return current.left(i.args[0]);
      }

      case InstrNames.UP: {
        return current.up();
      }

      case InstrNames.DOWN: {
        return current.down();
      }

      case InstrNames.SQUARE: {
        if (current._down) this.rectangle(current, i.args[0], i.args[0]);
        return current;
      }

      case InstrNames.RECTANGLE: {
        if (current._down) this.rectangle(current, i.args[0], i.args[1]);
        return current;
      }

      case InstrNames.CIRCLE: {
        if (current._down) this.ellipse(current, i.args[0], i.args[0]);
        return current;
      }

      case InstrNames.ELLIPSE: {
        if (current._down) this.ellipse(current, i.args[0], i.args[1]);
        return current;
      }

      case InstrNames.TRIANGLE: {
        if (current._down) this.triangle(current, i.args[0]);
        return current;
      }

      case InstrNames.TEXT: {
        if (current._down) this.text(current, i.args[0]);
        return current;
      }

      case InstrNames.GOTO: {
        const previous = current;
        current = previous.goto(i.args[0], i.args[1]);
        if (current._down) {
          const dx = current.x - previous.x;
          const dy = current.y - previous.y;
          const previousWithHeading = previous.headingRad(Math.atan2(dx, dy));
          const d = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
          this.line(previousWithHeading, current, d);
        }
        return current;
      }

      case InstrNames.HEADING: {
        current = current.headingDeg(i.args[0]);
        return current;
      }

      case InstrNames.Attributable.PEN: {
        switch (i.args[0]) {
          case PenAttrNames.COLOR: {
            return current.penColor(colorFromArg(i.args[1]));
          }

          case PenAttrNames.THICKNESS: {
            return current.penThickness(Thickness.FROM_NAME(i.args[1])!);
          }

          case PenAttrNames.PATTERN: {
            return current.penPattern(i.args[1]);
          }

          default: {
            throw `Unknown attribute: ${i.args[0]}`;
          }
        }
      }

      case InstrNames.Attributable.FILL: {
        switch (i.args[0]) {
          case FillAttrNames.COLOR: {
            return current.fillColor(colorFromArg(i.args[1]));
          }

          case FillAttrNames.OPACITY: {
            return current.fillOpacity(i.args[1]);
          }

          default: {
            throw `Unknown attribute: ${i.args[0]}`;
          }
        }
      }

      case InstrNames.Stack.PUSH: {
        switch (i.args[0]) {
          case InstrNames.Stack.IS_UP: {
            const instr = current._down
              ? {
                  name: InstrNames.DOWN,
                  args: [],
                }
              : { name: InstrNames.UP, args: [] };
            return current.push(instr);
          }

          case InstrNames.Attributable.PEN: {
            return this.pushPen(current, i.args[1]);
          }

          case InstrNames.Attributable.FILL: {
            return this.pushFill(current, i.args[1]);
          }

          default: {
            throw `Unknown push: ${i.args[0]}`;
          }
        }
      }

      case InstrNames.Stack.POP: {
        const popResult = current.pop();
        if (popResult[1]) {
          return this.apply(popResult[0], popResult[1]);
        } else {
          return popResult[0];
        }
      }

      case InstrNames.Meta.POS: {
        this.turtleByPos.add(i.args[0], current);

        return current;
      }

      case InstrNames.Meta.VAL: {
        this.valueByPos.add(i.args[0], i.args[1]);

        return current;
      }

      default: {
        throw `Unknown instruction: ${i.name}`;
      }
    }
  }

  private pushPen(current: Turtle, attrKey: string): Turtle {
    let currentAttrValue;
    switch (attrKey) {
      case PenAttrNames.COLOR: {
        currentAttrValue = colorToArg(current._pen._color);
        break;
      }

      case PenAttrNames.THICKNESS: {
        currentAttrValue = current._pen._thickness.name;
        break;
      }

      case PenAttrNames.PATTERN: {
        currentAttrValue = current._pen._pattern;
        break;
      }

      default: {
        throw `Unknown attribute: ${attrKey}`;
      }
    }

    return current.push({ name: InstrNames.Attributable.PEN, args: [attrKey, currentAttrValue] });
  }

  private pushFill(current: Turtle, attrKey: string): Turtle {
    let currentAttrValue;
    switch (attrKey) {
      case FillAttrNames.COLOR: {
        currentAttrValue = colorToArg(current._fill._color);
        break;
      }

      case FillAttrNames.OPACITY: {
        currentAttrValue = current._fill._opacity;
        break;
      }

      default: {
        throw `Unknown attribute: ${attrKey}`;
      }
    }

    return current.push({
      name: InstrNames.Attributable.FILL,
      args: [attrKey, currentAttrValue],
    });
  }

  private line(t1: Turtle, t2: Turtle, length: number) {
    verifyNumberOfSteps(length);

    if (drawShape(t1)) {
      this.group.add(
        new Konva.Line({
          ...strokeFromTurtle(t1),
          points: [t1.x, t1.y, t2.x, t2.y],
        })
      );
    }

    if (drawPattern(t1)) {
      this.addToGroup(this.patternShapes.line(t1, length), t1);
    }
  }

  private rectangle(t: Turtle, width: number, height: number) {
    verifyNumberOfSteps(width);
    verifyNumberOfSteps(height);
    if (drawShape(t)) {
      this.addToGroup(
        new Konva.Rect({
          ...strokeFromTurtle(t),
          ...fillFromTurtle(t),
          offsetX: width / 2,
          offsetY: height / 2,
          width: width,
          height: height,
        }),
        t
      );
    }

    if (drawPattern(t)) {
      this.addToGroup(this.patternShapes.rectangle(t, width, height), t);
    }
  }

  private ellipse(t: Turtle, a: number, b: number) {
    verifyNumberOfSteps(a);
    verifyNumberOfSteps(b);
    if (drawShape(t)) {
      this.addToGroup(
        new Konva.Ellipse({
          ...strokeFromTurtle(t),
          ...fillFromTurtle(t),
          radiusX: Math.abs(a),
          radiusY: Math.abs(b),
        }),
        t
      );
    }

    if (drawPattern(t)) {
      this.addToGroup(this.patternShapes.ellipse(t, Math.abs(a), Math.abs(b)), t);
    }
  }

  private triangle(t: Turtle, a: number) {
    verifyNumberOfSteps(a);
    const h = (Math.sqrt(3) * a) / 2;
    const trianglePoints = [0, (h * 2) / 3, a / 2, -h / 3, -a / 2, -h / 3];

    if (drawShape(t)) {
      this.addToGroup(
        new Konva.Line({
          ...strokeFromTurtle(t),
          ...fillFromTurtle(t),
          points: trianglePoints,
          closed: true,
        }),
        t
      );
    }

    if (drawPattern(t)) {
      this.addToGroup(this.patternShapes.triangle(t, a, trianglePoints), t);
    }
  }

  private text(t: Turtle, text: string) {
    if (t._pen._color !== undefined) {
      verifyNumberOfSteps(text.length);
      const kt = new Konva.Text({
        text: text,
        fontSize: 30,
        fontFamily: 'Arial, sans-serif',
        fill: t._pen._color.name,
        scaleY: -1,
        align: 'center',
        verticalAlign: 'center',
      });
      kt.offsetX(kt.width() / 2);
      kt.offsetY(kt.height() / 2);
      this.addToGroup(kt, t);
    }
  }

  private addToGroup(node: Konva.Group | Konva.Shape, t: Turtle) {
    node.setAttrs(coordsFromTurtle(t));
    this.group.add(node);
  }
}

function coordsFromTurtle(t: Turtle) {
  return {
    x: t.x,
    y: t.y,
    rotation: -t._heading,
  };
}

function strokeFromTurtle(t: Turtle) {
  if (t._pen.hasPattern() || t._pen._color === undefined) {
    return {};
  } else {
    return {
      stroke: t._pen._color.hex,
      strokeWidth: t._pen._thickness.width,
      lineCap: 'round' as LineCap,
      lineJoin: 'round' as LineJoin,
    };
  }
}

function fillFromTurtle(t: Turtle) {
  if (t._fill._color === undefined) {
    return {};
  } else {
    return {
      fill: t._fill._color.hex,
      opacity: opacityToNumber(t._fill._opacity),
    };
  }
}

function opacityToNumber(opacity: string): number {
  switch (opacity) {
    case StdFnNames.FULL:
      return 1;
    case StdFnNames.MEDIUM:
      return 0.7;
    case StdFnNames.LOW:
      return 0.4;
    default:
      throw `Unknown opacity ${opacity}`;
  }
}

function drawShape(t: Turtle): boolean {
  return t._fill.hasFill() || !t._pen.hasPattern();
}

function drawPattern(t: Turtle): boolean {
  return t._pen.hasPattern() && t._pen._color !== undefined;
}

function positionRangeForInstr(instrs: Instr[], index: number): PositionRange {
  let firstBefore = index;
  while (firstBefore > 0 && instrs[firstBefore].name !== InstrNames.Meta.POS) {
    firstBefore--;
  }

  const total = instrs.length;
  let firstAfter = index;
  while (firstAfter < total && instrs[firstAfter].name !== InstrNames.Meta.POS) {
    firstAfter++;
  }

  if (firstBefore === -1 && firstAfter !== total) {
    firstBefore = firstAfter;
  }
  if (firstBefore !== -1 && firstAfter === total) {
    firstAfter = firstBefore;
  }
  if (firstBefore === -1 && firstAfter === total) {
    return PositionRange.SINGLE(1, 0);
  } else {
    return new PositionRange(instrs[firstBefore].args[0], instrs[firstAfter].args[0]);
  }
}

function verifyNumberOfSteps(n: number) {
  if (n > MAX_STEPS_IN_INSTR) {
    throw InterpretError._TOO_MANY_STEPS_IN_INSTR;
  }
}

function colorFromArg(arg: any): Color | undefined {
  return arg === StdFnNames.NONE ? undefined : Color.FROM_HEX(arg)!;
}

function colorToArg(c: Color | undefined): string {
  return c === undefined ? StdFnNames.NONE : c.hex;
}
