import { useLocalStorage } from '@rehooks/local-storage';
import React, { useCallback } from 'react';
import {
  CREATIVE_CONTENT_STORAGE_KEY,
  FACEBOOK_URL,
  PROGRAM_STATE_STORAGE_KEY,
  CHALLENGE_CONTENT_STORAGE_KEY,
} from 'ui/constants';
import { UserEnterMode } from '../UserForm/types';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import { useTranslation } from 'react-i18next';
import { ProgramOnServer } from 'types/program';
import { useSelector } from 'react-redux';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';

export interface Props {
  mode: UserEnterMode;
}

const selector = createCodeStatePropSelector('program', 'creativeCode', 'challengeCode');

const UseFacebook: React.FC<Props> = ({ mode }) => {
  const isLogin: boolean = mode === 'login';

  const [, setProgramStorageState] = useLocalStorage<ProgramOnServer>(PROGRAM_STATE_STORAGE_KEY);
  const [, setCreativeContent] = useLocalStorage(CREATIVE_CONTENT_STORAGE_KEY);
  const [, setChallengeContent] = useLocalStorage(CHALLENGE_CONTENT_STORAGE_KEY);

  const { program, creativeCode, challengeCode } = useSelector(selector);
  const { t } = useTranslation('userForm');

  const handleFbLogin = useCallback(() => {
    // Saving code before logging in with facebook, as this involves redirects
    setProgramStorageState(program);
    setCreativeContent(creativeCode);
    setChallengeContent(challengeCode);
    window.location.href = FACEBOOK_URL;
  }, [setProgramStorageState, program, creativeCode, challengeCode, setCreativeContent, setChallengeContent]);

  return (
    <Button flavor={ButtonFlavor.FB} onClick={handleFbLogin}>
      {t(isLogin ? 'loginWithFacebook' : 'registerWithFacebook')}
    </Button>
  );
};

export default UseFacebook;
