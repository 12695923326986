import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CodeType } from 'types/code';
import ContentModal from 'ui/components/common/smart/ContentModal';
import Editor from 'ui/components/common/smart/Editor';
import EditorMessages from 'ui/components/common/smart/EditorMessages';
import HelpTopic from 'ui/components/common/smart/HelpTopic';
import PaintCanvas from 'ui/components/common/smart/PaintCanvas';
import TurtleInfoBar from 'ui/components/common/smart/TurtleInfoBar';
import { useEditorLogic } from 'ui/components/hooks/useEditorLogic';
import { setMetaTags } from 'ui/components/routing/programHelpers';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { HomeSubscreen } from 'ui/store/homescreen/enums';
import AnimationTour from './AnimationTour';
import EditorTopAnimation from './EditorTopAnimation/EditorTopAnimation';

const codePropSelector = createCodeStatePropSelector('animationCode', 'animation', 'animationTime');

const AnimationEditor: React.FC = () => {
  const { id: idFromUrl } = useParams();
  const { animationCode, animation, animationTime } = useSelector(codePropSelector);
  const id = animation.id || idFromUrl; // if an animation is already loaded into memory, it takes precedence
  const { subscreen, onAnimationCodeChange } = useEditorLogic(CodeType.Animation, id);

  React.useEffect(() => {
    setMetaTags(CodeType.Animation, animation.id, animation.name, animation.userLogin);
  }, [animation]);

  return (
    <>
      <EditorTopAnimation />
      <Editor
        onChange={onAnimationCodeChange}
        code={animationCode}
        prefixLines={`let t = ${animationTime.toFixed(1)}`}
      />
      <EditorMessages />
      <TurtleInfoBar />
      <PaintCanvas />
      {subscreen !== HomeSubscreen.None && (
        <ContentModal>
          <HelpTopic />
        </ContentModal>
      )}
      <AnimationTour />
    </>
  );
};

export default AnimationEditor;
