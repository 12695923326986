import { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './Grid.module.scss';
import { useSelector } from 'react-redux';
import { homeScreenStateSelector } from 'ui/store/homescreen/selectors';
import { getTestSelector } from 'util/testUtil';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { CodeType } from 'types/code';

const codeTypeSelector = createCodeStatePropSelector('codeType');

const Grid = ({ children }: { children: ReactNode }) => {
  const { subscreen } = useSelector(homeScreenStateSelector);
  const { codeType } = useSelector(codeTypeSelector);

  return (
    <div
      data-testid={getTestSelector('layout.grid')}
      className={classnames(styles.container, {
        [styles.splited]: subscreen !== 'none',
        [styles.animations]: codeType === CodeType.Animation,
      })}
    >
      {children}
    </div>
  );
};

export default Grid;
