import classNames from 'classnames';
import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Star: IconComponent = ({ className, ...props }) => (
  <svg viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg" className={classNames(styles.yellow, className)} {...props}>
    <path d="M7.572 2.72l-2.158-.315L4.45.45a.52.52 0 00-.9 0l-.963 1.956-2.159.313a.5.5 0 00-.278.853l1.562 1.523-.369 2.15a.5.5 0 00.726.527L4 6.756l1.93 1.015a.5.5 0 00.726-.527l-.368-2.15L7.85 3.572a.5.5 0 00-.277-.852h-.001z" />
  </svg>
);

export default Star;
