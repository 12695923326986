import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Editor from 'ui/components/common/smart/Editor';
import TurtleInfoBar from 'ui/components/common/smart/TurtleInfoBar';
import HelpTopic from 'ui/components/common/smart/HelpTopic';
import ContentModal from 'ui/components/common/smart/ContentModal';
import PaintCanvas from 'ui/components/common/smart/PaintCanvas';
import Loader from 'ui/components/common/dumb/Loader';
import EditorTopCreative from './EditorTopCreative';
import EditorMessages from 'ui/components/common/smart/EditorMessages';
import CreativeTour from './CreativeTour';
import { setMetaTags } from 'ui/components/routing/programHelpers';
import { CodeType } from 'types/code';
import { HomeSubscreen } from 'ui/store/homescreen/enums';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useEditorLogic } from 'ui/components/hooks/useEditorLogic';

const codePropSelector = createCodeStatePropSelector('creativeCode', 'program');

const CreativeEditor: React.FC = () => {
  const { id: idFromUrl } = useParams();
  const { creativeCode, program } = useSelector(codePropSelector);
  const id = program.id || idFromUrl; // if a program is already loaded into memory, it takes precedence
  const { serverFetch, subscreen, onCreativeCodeChange } = useEditorLogic(CodeType.Creative, id);

  React.useEffect(() => {
    setMetaTags(CodeType.Creative, program.id, program.name, program.userLogin);
  }, [program]);

  return (
    <>
      <EditorTopCreative />
      <Editor onChange={onCreativeCodeChange} code={creativeCode} />
      <EditorMessages />
      <TurtleInfoBar />
      <PaintCanvas />
      {subscreen !== HomeSubscreen.None && (
        <ContentModal>
          <HelpTopic />
        </ContentModal>
      )}
      <CreativeTour />
      {(serverFetch.isLoading || serverFetch.isIdle) && <Loader overlay />}
    </>
  );
};

export default CreativeEditor;
