import React, { useEffect, useCallback } from 'react';
import { useLocalStorage, deleteFromStorage } from '@rehooks/local-storage';
import { useTranslation } from 'react-i18next';
import { usePromise } from 'react-use-promise-matcher';
import useApi from 'ui/components/hooks/useApi';
import Routes from 'ui/components/routing/Routes';
import Footer from 'ui/components/common/smart/Footer';
import { CONTEST_NAME, HOUR_OF_CODE_TOKEN_STORAGE_KEY, STORAGE_API_KEY } from 'ui/constants';
import Loader from 'ui/components/common/dumb/Loader';
import { renderToastPopup } from 'util/toast';
import Navbar from 'ui/components/routing/Navbar';
import Grid from './Grid';
import styles from './Routing.module.scss';
import { User } from 'types/user';
import { useLocation } from 'react-router-dom';
import usePrevious from 'ui/components/hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import { userStateSelector } from 'ui/store/user/selectors';
import { useUserStoreActions } from 'ui/store/user/useUserStoreActions';

const Routing: React.FC = () => {
  const { user, loggedIn } = useSelector(userStateSelector);
  const dispatch = useDispatch();
  const { setUser, setAnonymousUser } = useUserStoreActions(dispatch);
  const { t } = useTranslation(['user', 'toast']);
  const { getCurrentUser } = useApi();
  const [result, load] = usePromise(getCurrentUser);
  const [apiKey] = useLocalStorage(STORAGE_API_KEY);
  const [contestToken, setContestToken] = useLocalStorage<boolean>(HOUR_OF_CODE_TOKEN_STORAGE_KEY);
  const prevApiKey = usePrevious(apiKey);
  const { search, pathname } = useLocation();

  const query = new URLSearchParams(search).get('redirect');

  useEffect(() => {
    // modify the contest token when opening the root page - otherwise leave as-is
    if (pathname === '/') {
      if (query !== null && query === CONTEST_NAME) {
        setContestToken(true);
      } else {
        setContestToken(false);
      }
    }
  }, [query, pathname, setContestToken]);

  useEffect(() => {
    if (contestToken === true) {
      renderToastPopup('success', t('toast:hour_of_code'));
      // add tracking pixel
      const pixel = document.createElement('img');
      pixel.setAttribute('src', 'https://code.org/api/hour/begin_shelly.png');
      pixel.setAttribute('height', '1');
      pixel.setAttribute('width', '1');
      document.body.appendChild(pixel);
    }
  }, [contestToken]);

  const displayWelcomeMessage = useCallback(
    (newUser: User) => {
      if (newUser.login !== user?.login) {
        renderToastPopup('success', t('toast:welcome', { user: newUser.login }));
      }
    },
    [user, t]
  );

  useEffect(() => {
    if (!apiKey) {
      setAnonymousUser();
      return;
    }

    if (apiKey !== prevApiKey) {
      load();
    }
  }, [prevApiKey, apiKey, load, setAnonymousUser]);

  useEffect(() => {
    result.match({
      Loading: () => null,
      Resolved: (response) => {
        if (!apiKey) return;

        const { data: newUser } = response;
        setUser(newUser);
        displayWelcomeMessage(newUser);
      },
      Rejected: (err) => {
        renderToastPopup('error', err);
        deleteFromStorage(STORAGE_API_KEY);
      },
    });
  }, [result, apiKey, setUser]);

  return (
    <div className={styles.container}>
      {loggedIn === undefined ? (
        <Loader overlay />
      ) : (
        <Grid>
          <Navbar />
          <Routes />
          <Footer />
        </Grid>
      )}
    </div>
  );
};

export default Routing;
