export class Attr {
  readonly key: string;
  readonly value: string | undefined;

  constructor(key: string, value: string | undefined) {
    this.key = key;
    this.value = value;
  }

  toString(): string {
    if (this.value === undefined) {
      return this.key;
    } else {
      return this.key + Attr.SEPARATOR + this.value;
    }
  }

  static SEPARATOR = ':';
  static PARSE(s: string): Attr[] {
    let tokens: string[] = [];
    let parensLevel = 0;
    const length = s.length;
    let token = '';

    function finishToken() {
      if (token.length > 0) {
        tokens.push(token);
        token = '';
      }
    }

    // 1. splitting into tokens, where a token are separated by whitespace and can be: text, :, (anything)
    for (let i = 0; i < length; i++) {
      const c = s[i];

      if (c == '(') {
        parensLevel++;
        token += c;
      } else if (c == ')') {
        parensLevel--;
        token += c;
        if (parensLevel == 0) finishToken();
      } else if (parensLevel > 0) {
        token += c;
      } else if (c == ' ') {
        finishToken();
      } else if (c == Attr.SEPARATOR) {
        finishToken();
        tokens.push(Attr.SEPARATOR);
      } else {
        token += c;
      }
    }
    finishToken();

    // 2. removing the attributable ('pen' / 'fill')
    tokens = tokens.slice(1);

    // 3. grouping into pairs, if there's a :
    let i = 0;
    token = '';
    const tokensLength = tokens.length;
    const attrs: Attr[] = [];
    while (i < tokensLength) {
      if (i + 1 < tokensLength && tokens[i + 1] == Attr.SEPARATOR) {
        attrs.push(new Attr(tokens[i], i + 2 < tokensLength ? tokens[i + 2] : undefined));
        i += 3;
      } else {
        attrs.push(new Attr(tokens[i], undefined));
        i++;
      }
    }

    return attrs;
  }
}
