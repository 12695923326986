import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Enter: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <rect height="34" width="25" className={styles.gray} x="2" y="3" />
      <path
        d="M27,2H2A1,1,0,0,0,1,3V37a1,1,0,0,0,.459.841l14,9A1,1,0,0,0,17,46V38H27a1,1,0,0,0,1-1V3A1,1,0,0,0,27,2ZM26,36H17V12a1,1,0,0,0-.459-.841L5.405,4H26Z"
        className={styles.darkGreen}
      />
      <path
        transform="rotate(180) translate(-68 -42)"
        d="M46.614,20.211l-9-7A1,1,0,0,0,36,14v5H23a2,2,0,0,0,0,4H36v5a1,1,0,0,0,1.614.791l9-7a1,1,0,0,0,0-1.578Z"
        className={styles.yellow}
      />
    </g>
  </svg>
);

export default Enter;
