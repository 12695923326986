import React from 'react';
import Context from './Context';
import Spotlight from './Spotlight';
import actions from './actions';
import { getTestSelector } from 'util/testUtil';

type Props = {
  holeClass?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const FadeOut: React.FC<Props> = ({ holeClass, style, ...htmlAttributes }) => {
  const { dispatch } = React.useContext(Context);

  const handleClick = React.useCallback(() => {
    actions(dispatch).nextStep();
  }, [dispatch]);

  return (
    <div
      data-testid={getTestSelector('pagetour.fadeout')}
      style={{
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        mixBlendMode: 'darken',
        ...style,
      }}
      onClick={handleClick}
      {...htmlAttributes}
    >
      <Spotlight
        style={{
          backgroundColor: 'white',
        }}
        className={holeClass}
      />
    </div>
  );
};

export default FadeOut;
