import Konva from 'konva';

const paths = {
  cars: {
    width: 15,
    height: 16,
    path: 'M11.776 6.88302L11.176 6.28302L10.501 2.75802C10.426 2.38302 10.126 2.15802 9.75098 2.15802H7.50098V0.65802H4.50098V2.15802H2.25098C1.87598 2.15802 1.57598 2.38302 1.50098 2.75802L0.825977 6.28302L0.225977 6.88302C0.0759766 7.03302 0.000976562 7.18302 0.000976562 7.40802V10.408C0.000976562 10.858 0.300977 11.158 0.750977 11.158V11.908C0.750977 12.358 1.05098 12.658 1.50098 12.658H3.00098C3.45098 12.658 3.75098 12.358 3.75098 11.908V11.158H8.25098V11.908C8.25098 12.358 8.55098 12.658 9.00098 12.658H10.501C10.951 12.658 11.251 12.358 11.251 11.908V11.158C11.701 11.158 12.001 10.858 12.001 10.408V7.40802C12.001 7.18302 11.926 7.03302 11.776 6.88302ZM2.25098 9.65802C1.80098 9.65802 1.50098 9.35802 1.50098 8.90802C1.50098 8.45802 1.80098 8.15802 2.25098 8.15802C2.70098 8.15802 3.00098 8.45802 3.00098 8.90802C3.00098 9.35802 2.70098 9.65802 2.25098 9.65802ZM2.40098 5.90802L2.85098 3.65802H9.15098L9.60098 5.90802H2.40098ZM9.75098 9.65802C9.30098 9.65802 9.00098 9.35802 9.00098 8.90802C9.00098 8.45802 9.30098 8.15802 9.75098 8.15802C10.201 8.15802 10.501 8.45802 10.501 8.90802C10.501 9.35802 10.201 9.65802 9.75098 9.65802Z',
  },
  clovers: {
    width: 18,
    height: 18,
    path: 'M13.5026 5.10626C13.5026 4.08541 12.4669 3.392 11.332 3.65349L9.44242 4.25278L10.0392 2.3552C10.2996 1.21547 9.60908 0.175354 8.59256 0.175354C7.4993 0.175354 6.75129 0.791715 6.75129 0.791715C6.75129 0.791715 6.00328 0.175354 4.91003 0.175354C3.8935 0.175354 3.20302 1.21547 3.46341 2.3552L4.06016 4.25279L2.17062 3.65349C1.03571 3.392 0 4.08541 0 5.10626C0 6.20416 0.613752 6.95535 0.613752 6.95535C0.613752 6.95535 0 7.70654 0 8.80445C0 9.8253 1.03571 10.5187 2.17062 10.2572L4.06017 9.65793L3.46341 11.5555C3.20302 12.6952 3.8935 13.7354 4.91003 13.7354C6.00328 13.7354 6.75129 13.119 6.75129 13.119C6.75129 13.119 7.4993 13.7354 8.59256 13.7354C9.60909 13.7354 10.2996 12.6952 10.0392 11.5555L9.44243 9.65792L11.332 10.2572C12.4669 10.5187 13.5026 9.82529 13.5026 8.80445C13.5026 7.70654 12.8888 6.95535 12.8888 6.95535C12.8888 6.95535 13.5026 6.20416 13.5026 5.10626Z',
  },
  crabs: {
    width: 17,
    height: 16,
    path: 'M10.8776 7.12346C11.4678 6.44601 11.8895 5.51452 11.8895 4.24431C12.817 3.90558 13.4916 2.97409 13.4916 1.95792C13.4916 1.28048 13.2386 0.603031 12.7327 0.179626C12.4797 0.857073 11.8051 1.36516 11.0462 1.36516C10.2873 1.36516 9.61275 0.857073 9.35979 0.179626C8.93817 0.603031 8.60088 1.28048 8.60088 1.95792C8.60088 3.05878 9.27546 3.90558 10.203 4.24431C10.203 5.00643 9.95004 5.93792 9.27546 6.19197C8.43224 5.93792 7.67334 5.68388 6.74579 5.68388C5.81825 5.68388 5.05934 5.93792 4.30044 6.27665C3.54154 5.93792 3.28857 5.00643 3.28857 4.24431C4.21612 3.90558 4.8907 2.97409 4.8907 1.95792C4.8907 1.28048 4.63773 0.603031 4.1318 0.179626C3.87883 0.857073 3.20425 1.36516 2.44535 1.36516C1.68645 1.36516 1.01187 0.857073 0.758902 0.179626C0.252967 0.603031 0 1.28048 0 1.95792C0 3.05878 0.674579 3.90558 1.60213 4.24431C1.60213 5.51452 2.02374 6.44601 2.61399 7.12346C1.34916 7.97026 0.590257 8.81707 0.590257 8.81707C0.590257 8.81707 1.09619 9.32516 1.85509 10.0026C1.34916 10.6801 1.01187 11.3575 0.758902 12.1196H2.52967C2.69832 11.7809 2.86696 11.3575 3.20425 10.9341C4.21612 11.4422 5.39663 11.9503 6.66147 11.9503C7.92631 11.9503 9.10682 11.5269 10.1187 10.9341C10.456 11.2728 10.7089 11.7809 10.7933 12.1196H12.564C12.3111 11.3575 11.9738 10.6801 11.4678 10.0026C12.2267 9.40984 12.7327 8.90175 12.7327 8.90175C12.7327 8.90175 12.1424 7.97026 10.8776 7.12346Z',
  },
  fishes: {
    width: 20,
    height: 18,
    path: 'M12.141 7.9988C12.141 9.27484 12.5132 10.4977 13.2044 11.6142C15.0653 10.4977 16.1286 8.47732 16.1286 8.37098C16.235 8.15831 16.235 7.94564 16.1286 7.73296C16.0755 7.57346 15.0653 5.60624 13.2044 4.48971C12.5132 5.4999 12.141 6.72277 12.141 7.9988Z M10.6525 7.99885C10.6525 6.51014 11.0247 5.0746 11.769 3.79857C11.3969 3.7454 11.0247 3.69223 10.6525 3.63906C7.67508 3.63906 5.86736 5.76579 5.06984 6.88232L3.31529 5.55312C2.83678 5.18094 2.14559 5.55312 2.09242 6.13797L2.09242 9.7534C2.09242 10.3914 2.83678 10.7104 3.31529 10.3383L5.06984 9.00905C5.33568 9.38122 5.65469 9.80657 6.08004 10.2319C7.09023 11.2421 8.63211 12.2523 10.6525 12.2523C11.0778 12.2523 11.45 12.1991 11.769 12.0928C11.0247 10.9231 10.6525 9.48756 10.6525 7.99885Z',
  },
  hearts: {
    width: 18,
    height: 16,
    path: 'M13.7688 1.41641C12.121 -0.238333 9.44826 -0.238333 7.80052 1.41641C7.68704 1.53037 7.59982 1.66034 7.50229 1.78278C7.40476 1.66034 7.31754 1.53037 7.20406 1.41641C5.55632 -0.238333 2.88355 -0.238333 1.23581 1.41641C-0.411935 3.07116 -0.411935 5.7553 1.23581 7.41005L7.50229 13.3604L13.7688 7.41005C15.4165 5.7553 15.4165 3.07116 13.7688 1.41641Z',
  },
  hooks: {
    width: 15,
    height: 16,
    path: 'M9.10306 7.54862L10.4948 7.60887L10.4948 8.72619C10.4948 9.71064 9.79118 10.6095 8.81592 10.709C7.69498 10.8234 6.74665 9.93928 6.74665 8.83697V4.55778C7.7485 4.19906 8.42979 3.15704 8.20178 1.98598C8.0295 1.10115 7.30367 0.381506 6.42066 0.218728C4.99515 -0.0440546 3.74814 1.05341 3.74814 2.43806C3.74814 3.41817 4.3767 4.24597 5.24739 4.55773L5.2474 8.72619C5.2474 9.71064 4.54379 10.6095 3.56853 10.709C2.44759 10.8234 1.49926 9.93928 1.49926 8.83697V7.60887L2.89099 7.54862C3.25321 7.53294 3.38442 7.06135 3.08276 6.85938L4.49777e-07 4.79538L0 8.68334C-2.24888e-07 10.4349 1.25736 12.0069 2.99053 12.2029C4.21169 12.341 5.30518 11.8084 5.99702 10.94C6.68886 11.8084 7.78235 12.341 9.00352 12.2029C10.7367 12.0069 11.994 10.4349 11.994 8.68334V4.79538L8.91128 6.85938C8.60962 7.06135 8.74083 7.53294 9.10306 7.54862ZM5.99702 1.68524C6.41063 1.68524 6.74665 2.02305 6.74665 2.43806C6.74665 2.85306 6.41063 3.19087 5.99702 3.19087C5.58341 3.19087 5.24739 2.85306 5.24739 2.43806C5.24739 2.02305 5.58341 1.68524 5.99702 1.68524Z',
  },
  leafs: {
    width: 16,
    height: 14,
    path: 'M1.2283 11.775C1.3783 11.925 1.5283 12 1.7533 12C1.9783 12 2.1283 11.925 2.2783 11.775L3.6283 10.425C4.3033 10.725 5.2783 11.025 6.3283 11.025C7.5283 11.025 8.8033 10.65 10.0033 9.525C12.5533 6.975 13.0033 1.05 13.0033 0.825C13.0033 0.6 12.9283 0.375 12.7783 0.225C12.6283 0.075 12.4033 0 12.1783 0C11.9533 0 5.8783 0.6 3.4783 3C1.9783 4.5 1.7533 6.3 2.0533 7.8L6.5533 5.325L1.1533 10.725C0.928303 11.025 0.928303 11.475 1.2283 11.775Z',
  },
  trees: {
    width: 14,
    height: 17,
    path: 'M9.17288 5.83912H8.8548C9.05181 5.49924 9.17288 5.10933 9.17288 4.68775C9.17288 3.416 8.14626 2.38506 6.87989 2.38506C6.8701 2.38506 6.86104 2.38782 6.85129 2.38791C6.71238 1.41134 5.88438 0.65802 4.87355 0.65802C3.76544 0.65802 2.86716 1.56012 2.86716 2.67289C2.86716 2.8683 2.90358 3.05342 2.95528 3.23207C2.22459 3.61707 1.72067 4.37728 1.72067 5.26343C1.72067 5.48228 1.76079 5.68974 1.81757 5.8903C0.780064 6.11072 0.000976562 7.03432 0.000976562 8.14185C0.000976562 9.4136 1.02755 10.4446 2.29391 10.4446H4.98377V12.703H6.48302V10.4446H9.17288C10.4392 10.4446 11.4658 9.4136 11.4658 8.14185C11.4658 6.87006 10.4392 5.83912 9.17288 5.83912Z',
  },
  maple: {
    width: 16,
    height: 15,
    path: 'M11.507 7.87682L12.2572 6.75156L9.63157 6.37647L12.9879 3.75087L11.507 3.00069L12.2572 0.750174L10.0067 1.50035L9.25649 0.0195045L6.63088 3.37578L6.25579 0.750174L5.13053 1.50035L3.66394 0L3.2551 2.25052H1.75475L2.88001 5.6263L1.75475 6.75156L4.06004 7.90458L1.22438 10.7402C1.15273 10.8094 1.09558 10.8922 1.05627 10.9837C1.01695 11.0753 0.996255 11.1737 0.99539 11.2733C0.994524 11.3729 1.0135 11.4717 1.05122 11.5639C1.08894 11.6561 1.14465 11.7398 1.21508 11.8103C1.28552 11.8807 1.36928 11.9364 1.46147 11.9741C1.55366 12.0119 1.65245 12.0308 1.75205 12.03C1.85166 12.0291 1.9501 12.0084 2.04162 11.9691C2.13315 11.9298 2.21592 11.8726 2.28513 11.801L5.11478 8.97132L6.25579 11.2526L7.38105 10.1273L10.7568 11.2526V9.75226L13.0074 9.34341L11.507 7.87682Z',
  },
  stars: {
    width: 17,
    height: 17,
    path: 'M11.362 4.64257L8.12351 4.17147L6.67795 1.2376C6.60926 1.11942 6.51075 1.02134 6.39227 0.953172C6.27379 0.885 6.1395 0.849121 6.00281 0.849121C5.86612 0.849121 5.73182 0.885 5.61334 0.953172C5.49486 1.02134 5.39635 1.11942 5.32766 1.2376L3.8821 4.17222L0.643661 4.64257C0.504983 4.66246 0.374646 4.72079 0.267413 4.81094C0.160179 4.9011 0.0803345 5.01948 0.0369231 5.15268C-0.0064884 5.28589 -0.011732 5.42858 0.0217862 5.56461C0.0553044 5.70064 0.126245 5.82456 0.226572 5.92235L2.56932 8.20658L2.01645 11.4323C1.99282 11.5702 2.00825 11.712 2.061 11.8417C2.11375 11.9713 2.20172 12.0836 2.31497 12.1659C2.42821 12.2482 2.56221 12.2971 2.70181 12.3072C2.84141 12.3173 2.98105 12.2881 3.10494 12.2229L6.00281 10.6994L8.89918 12.2214C9.02306 12.2866 9.1627 12.3158 9.3023 12.3057C9.44191 12.2956 9.5759 12.2467 9.68915 12.1644C9.80239 12.0821 9.89036 11.9698 9.94311 11.8402C9.99587 11.7105 10.0113 11.5687 9.98766 11.4308L9.43479 8.20508L11.779 5.92235C11.8791 5.82463 11.9499 5.70088 11.9834 5.56508C12.0168 5.42927 12.0116 5.28681 11.9684 5.15378C11.9252 5.02076 11.8456 4.90247 11.7387 4.81227C11.6318 4.72206 11.5019 4.66355 11.3635 4.64332L11.362 4.64257Z',
  },
  circles: {
    width: 14,
    height: 15,
    path: 'M0.000980000000000203,6.65802a6,6 0 1,0 12,0a6,6 0 1,0 -12,0',
  },
};

const konvaPaths: { [key: string]: Konva.Path } = {};
Object.entries(paths).forEach(
  ([key, value]) =>
    (konvaPaths[key] = new Konva.Path({
      width: value.width,
      height: value.height,
      data: value.path,
    }))
);

export default konvaPaths;
