import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Vector2d } from 'konva/lib/types';
import { ZoomAndPos } from './types';

export interface CanvasState {
  canvasZoomAndPos: ZoomAndPos;
  lastTurtlePos: Vector2d;
  showTurtle: boolean;
  downloadImage: boolean;
  getDataUrlImage: (() => string) | null;
}

export const initialCanvasState: CanvasState = {
  canvasZoomAndPos: { zoom: 1, pos: { x: 0, y: 0 }, applied: true },
  lastTurtlePos: { x: 0, y: 0 },
  showTurtle: true,
  downloadImage: false,
  getDataUrlImage: null,
};

const canvasSlice = createSlice({
  name: 'canvas',
  initialState: initialCanvasState,
  reducers: {
    setCanvasZoomAndPos: (state, action: PayloadAction<ZoomAndPos>) => {
      state.canvasZoomAndPos = action.payload;
    },
    setDataUrlImageGetter: (state, action: PayloadAction<(() => string) | null>) => {
      state.getDataUrlImage = action.payload;
    },
    setDownloadImage: (state, action: PayloadAction<boolean>) => {
      state.downloadImage = action.payload;
    },
    setLastTurtlePos: (state, action: PayloadAction<Vector2d>) => {
      state.lastTurtlePos = action.payload;
    },
    setShowTurtle: (state, action: PayloadAction<boolean>) => {
      state.showTurtle = action.payload;
    },
  },
});

export const actionCreators = canvasSlice.actions;

export default canvasSlice.reducer;
