import { AnimationOnServer, ProgramOnServer } from 'types/program';
import { getCurrentDate } from 'util/dateUtil';
import {
  ChallengeOnServer,
  ChallengePackLevel,
  ChallengeState,
  ChallengeNode,
  ChallengeNodeList,
} from 'types/challenge';
import { DEFAULT_ANIMATION_TIME, MAX_SOLUTION_RATING } from 'ui/constants';
import { initialCodeContents } from './constants';

export const getChallenges = (levels: ChallengePackLevel[], challengeNodes: ChallengeNodeList): ChallengeOnServer[] =>
  levels.flatMap((level: ChallengePackLevel) => level.challengeNodeIds).map((id) => challengeNodes[id]?.data);

export const getChallengeNodeById = (
  challengeNodes: ChallengeNodeList,
  id?: string | null
): ChallengeNode | undefined => (id ? challengeNodes[id] : undefined);

export const getChallengeNode = (
  challenge: ChallengeOnServer,
  challengeNodes: ChallengeNodeList
): ChallengeNode | undefined => getChallengeNodeById(challengeNodes, challenge.id);

export const getChallengeInProgress = (challengeNodes: ChallengeNodeList): ChallengeNode | undefined =>
  Object.entries(challengeNodes).find(([, challengeNode]) => challengeNode.state === ChallengeState.InProgress)?.[1];

export const getChallengeByIdWithFallbackToInProgress = (
  challengeNodes: ChallengeNodeList,
  id?: string
): ChallengeNode | undefined =>
  id ? getChallengeNodeById(challengeNodes, id) : getChallengeInProgress(challengeNodes);

export const getNextChallenge = (
  currentChallenge: ChallengeOnServer,
  challengeNodes: ChallengeNodeList
): ChallengeOnServer | undefined => {
  const nextChallengeId = getChallengeNode(currentChallenge, challengeNodes)?.nextNodeId;
  return getChallengeNodeById(challengeNodes, nextChallengeId)?.data;
};

export const getPreviousChallenge = (
  currentChallenge: ChallengeOnServer,
  challengeNodes: ChallengeNodeList
): ChallengeOnServer | undefined => {
  const prevChallengeId = getChallengeNode(currentChallenge, challengeNodes)?.prevNodeId;
  return getChallengeNodeById(challengeNodes, prevChallengeId)?.data;
};

export const getOverallScore = (challengeNodes: ChallengeNodeList): { score: number; maxScore: number } => {
  const challenges = Object.values(challengeNodes).map(({ data }) => data);
  const maxScore = challenges.length * MAX_SOLUTION_RATING;
  const score = challenges.reduce((sum, item) => sum + item.stars, 0);

  return {
    score,
    maxScore,
  };
};

export const createProgram = (text = ''): ProgramOnServer => ({
  name: '',
  text,
  id: undefined,
  userLogin: undefined,
  saveToken: undefined,
  created: getCurrentDate(),
  originalContent: '',
  originalName: '',
});
export const createInitialProgram = (): ProgramOnServer => createProgram(initialCodeContents.creative);

export const createAnimation = (text = ''): AnimationOnServer => ({
  name: '',
  text,
  id: undefined,
  userLogin: undefined,
  saveToken: undefined,
  created: getCurrentDate(),
  originalContent: '',
  originalName: '',
  length: DEFAULT_ANIMATION_TIME,
});

export const createInitialAnimation = (): AnimationOnServer => createAnimation(initialCodeContents.animation);
