import { useCallback } from 'react';
import { type Action, type Dispatch } from '@reduxjs/toolkit';
import { codeActions } from './slice';
import { CodeType } from 'types/code';
import { AnimationOnServer, ProgramOnServer } from 'types/program';
import { ChallengeEvaluation, ChallengeNodeList, ChallengeOnServer, ChallengePackLevel } from 'types/challenge';
import { createInitialAnimation, createInitialProgram } from './helpers';

type UseCodeStoreActions = {
  setCodeType: (payload: CodeType) => void;
  setCreativeCode: (payload: string) => void;
  resetCreativeCode: () => void;
  setChallengeCode: (payload: string) => void;
  resetChallengeCode: () => void;
  resetChallenge: () => void;
  setCustomChallengeDesc: (payload: string) => void;
  setCustomChallengeName: (payload: string) => void;
  setProgram: (payload: ProgramOnServer) => void;
  setProgramName: (payload: string) => void;
  resetProgram: () => void;
  setChallenge: (payload: ChallengeOnServer) => void;
  setChallengeLevels: (payload: ChallengePackLevel[]) => void;
  setChallengeNodes: (payload: ChallengeNodeList) => void;
  setChallengeEvaluation: (payload: ChallengeEvaluation) => void;
  resetProgramUserData: () => void;
  setAnimationCode: (payload: string) => void;
  resetAnimationCode: () => void;
  setAnimationTime: (payload: number) => void;
  setAnimation: (payload: AnimationOnServer) => void;
  resetAnimation: () => void;
  setAnimationName: (payload: string) => void;
  setHint: (payload: string) => void;
  setIsHintLoading: (payload: boolean) => void;
  setAskForHint: (payload: boolean) => void;
};

export const useCodeStoreActions = (dispatch: Dispatch<Action>): UseCodeStoreActions => {
  const setCodeType = useCallback(
    (payload: CodeType) => {
      return dispatch(codeActions.setCodeType(payload));
    },
    [dispatch]
  );

  const setCreativeCode = useCallback(
    (payload: string) => {
      return dispatch(codeActions.setCreativeCode(payload));
    },
    [dispatch]
  );

  const resetCreativeCode = useCallback(() => {
    return dispatch(codeActions.resetCreativeCode(createInitialProgram()));
  }, [dispatch]);

  const setChallengeCode = useCallback(
    (payload: string) => {
      return dispatch(codeActions.setChallengeCode(payload));
    },
    [dispatch]
  );

  const resetChallengeCode = useCallback(() => {
    return dispatch(codeActions.resetChallengeCode());
  }, [dispatch]);

  const resetChallenge = useCallback(() => {
    return dispatch(codeActions.resetChallenge());
  }, [dispatch]);

  const setCustomChallengeDesc = useCallback(
    (payload: string) => {
      return dispatch(codeActions.setCustomChallengeDesc(payload));
    },
    [dispatch]
  );

  const setCustomChallengeName = useCallback(
    (payload: string) => {
      return dispatch(codeActions.setCustomChallengeName(payload));
    },
    [dispatch]
  );

  const setProgram = useCallback(
    (payload: ProgramOnServer) => {
      return dispatch(codeActions.setProgram(payload));
    },
    [dispatch]
  );

  const setProgramName = useCallback(
    (payload: string) => {
      dispatch(codeActions.setProgramName(payload));
    },
    [dispatch]
  );

  const resetProgram = useCallback(() => {
    dispatch(codeActions.resetProgram(createInitialProgram()));
  }, [dispatch]);

  const setChallenge = useCallback(
    (payload: ChallengeOnServer) => {
      return dispatch(codeActions.setChallenge(payload));
    },
    [dispatch]
  );

  const setChallengeLevels = useCallback(
    (payload: ChallengePackLevel[]) => {
      return dispatch(codeActions.setChallengeLevels(payload));
    },
    [dispatch]
  );

  const setChallengeNodes = useCallback(
    (payload: ChallengeNodeList) => {
      return dispatch(codeActions.setChallengeNodes(payload));
    },
    [dispatch]
  );

  const setChallengeEvaluation = useCallback(
    (payload: ChallengeEvaluation) => {
      return dispatch(codeActions.setChallengeEvaluation(payload));
    },
    [dispatch]
  );

  const resetProgramUserData = useCallback(() => {
    return dispatch(codeActions.resetProgramUserData());
  }, [dispatch]);

  const setAnimationCode = useCallback(
    (payload: string) => {
      return dispatch(codeActions.setAnimationCode(payload));
    },
    [dispatch]
  );

  const resetAnimationCode = useCallback(() => {
    return dispatch(codeActions.resetAnimationCode(createInitialAnimation()));
  }, [dispatch]);

  const setAnimationTime = useCallback(
    (payload: number) => {
      return dispatch(codeActions.setAnimationTime(payload));
    },
    [dispatch]
  );

  const setAnimation = useCallback(
    (payload: AnimationOnServer) => {
      return dispatch(codeActions.setAnimation(payload));
    },
    [dispatch]
  );

  const resetAnimation = useCallback(() => {
    return dispatch(codeActions.resetAnimation(createInitialAnimation()));
  }, [dispatch]);

  const setAnimationName = useCallback(
    (payload: string) => {
      return dispatch(codeActions.setAnimationName(payload));
    },
    [dispatch]
  );

  const setHint = useCallback(
    (payload: string) => {
      return dispatch(codeActions.setHint(payload));
    },
    [dispatch]
  );

  const setIsHintLoading = useCallback(
    (payload: boolean) => {
      return dispatch(codeActions.setIsHintLoading(payload));
    },
    [dispatch]
  );

  const setAskForHint = useCallback(
    (payload: boolean) => {
      return dispatch(codeActions.setAskForHint(payload));
    },
    [dispatch]
  );

  return {
    setCodeType,
    setCreativeCode,
    resetCreativeCode,
    setChallengeCode,
    resetChallengeCode,
    resetChallenge,
    setCustomChallengeDesc,
    setCustomChallengeName,
    setProgram,
    setProgramName,
    resetProgram,
    setChallenge,
    setChallengeLevels,
    setChallengeNodes,
    setChallengeEvaluation,
    resetProgramUserData,
    setAnimationCode,
    resetAnimationCode,
    setAnimationTime,
    setAnimation,
    resetAnimation,
    setAnimationName,
    setHint,
    setIsHintLoading,
    setAskForHint,
  };
};
