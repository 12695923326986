import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { PAGE_TOUR_STORAGE_KEY } from 'ui/constants';

export interface PageTourState {
  tourVisible: boolean;
  tourIntroDisplayed: boolean;
}

const stateValue = Boolean(localStorage.getItem(PAGE_TOUR_STORAGE_KEY));

const initialPageTourState: PageTourState = {
  tourVisible: stateValue,
  tourIntroDisplayed: !stateValue,
};

export const tourSlice = createSlice({
  name: 'pagetour',
  initialState: initialPageTourState,
  reducers: {
    setTourVisibility: (state: PageTourState, action: PayloadAction<boolean>) => {
      state.tourVisible = action.payload;
    },
    setTourIntroVisibility: (state: PageTourState, action: PayloadAction<boolean>) => {
      state.tourIntroDisplayed = action.payload;
    },
  },
});

export const actionCreators = tourSlice.actions;

export default tourSlice.reducer;
