import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Questionnaire: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M18,3H5A2,2,0,0,0,3,5V18a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V5A2,2,0,0,0,18,3ZM5,18V5H18l0,13Z"
        className={styles.gray}
      />
      <path
        d="M43,28H30a2,2,0,0,0-2,2V43a2,2,0,0,0,2,2H43a2,2,0,0,0,2-2V30A2,2,0,0,0,43,28ZM30,43V30H43l0,13Z"
        className={styles.gray}
      />
      <path
        d="M18,28H5a2,2,0,0,0-2,2V43a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V30A2,2,0,0,0,18,28ZM5,43V30H18l0,13Z"
        className={styles.gray}
      />
      <path
        d="M33.408,15.286,28.5,10.379,26.379,12.5l6.06,6.061A1.5,1.5,0,0,0,33.5,19h.062a1.508,1.508,0,0,0,1.084-.53L46.614,4.324l-2.29-1.938Z"
        className={styles.green}
      />
    </g>
  </svg>
);

export default Questionnaire;
