import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from 'ui/components/svg/Logo';
import styles from './LogoLink.module.scss';

const LogoLink: React.FC = () => (
  <NavLink to="/">
    <Logo className={styles.logo} />
  </NavLink>
);

export default LogoLink;
