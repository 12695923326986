import { useTranslation } from 'react-i18next';
import { CodeType } from 'types/code';
import { APP_URL } from 'ui/constants';

type UseSocialMediaShare = {
  facebookText: string;
  twitterText: string;
};

export const useSocialMediaShare = (codePath: string, isSaved: boolean, codeType: CodeType): UseSocialMediaShare => {
  const { t } = useTranslation('socialMedia');
  const translationKey: string = codeType === CodeType.Creative ? 'program_share_saved' : 'challenge_share';
  const facebookText = `${APP_URL}${codePath}`;
  const twitterText = isSaved
    ? t(`twitter.${translationKey}`, { path: codePath })
    : t('twitter.program_share_not_saved');

  return { facebookText, twitterText };
};
