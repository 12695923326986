import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import styles from './PositionIndicator.module.scss';
import { Turtle } from 'turtle/Turtle';
import Button, { ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import ChevronLeft from 'ui/components/svg/ChevronLeft';
import ChevronRight from 'ui/components/svg/ChevronRight';
import Input from 'ui/components/common/dumb/Input';
import CornerUpRight from 'ui/components/svg/CornerUpRight';
import Rotation from 'ui/components/svg/Rotation';
import { radToDeg } from 'util/numberUtil';
import { useTranslation } from 'react-i18next';
import Parameter from 'ui/components/common/dumb/Parameter';
import { getTestSelector } from 'util/testUtil';
import { useDispatch, useSelector } from 'react-redux';
import { positionSelector, turtleByPosSelector } from 'ui/store/editor/selectors';
import { codeTypeSelector, createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useEditorStoreActions } from 'ui/store/editor/useEditorStoreActions';
import { CodeType } from 'types/code';

interface TurtlePosition {
  top: number;
  left: number;
}

const animationTimeSelector = createCodeStatePropSelector('animationTime');

const PositionIndicator: React.FC = () => {
  const dispatch = useDispatch();
  const position = useSelector(positionSelector);
  const turtleByPos = useSelector(turtleByPosSelector);
  const { animationTime } = useSelector(animationTimeSelector);
  const { codeType } = useSelector(codeTypeSelector);
  const { setSelectedTurtle } = useEditorStoreActions(dispatch);
  const [turtles, setTurtles] = useState<Turtle[]>([]);
  const [turtlePosition, setTurtlePosition] = useState<TurtlePosition>({ top: 0, left: 0 });
  const [idx, setIdx] = useState<number>(0);
  const [idxInput, setIdxInput] = useState<string>(`${idx + 1}`);
  const { t } = useTranslation('turtleInfoBar');

  const [animationTimeout, setAnimationTimeout] = useState(true);
  const animationTimeoutIdRef = useRef<number>();

  const turtlesJSON = JSON.stringify(turtles);

  const selectIdx = useCallback(
    (i: number) => {
      if (i >= 0 && i < turtles.length) {
        setIdx(i);
        setIdxInput((i + 1).toString());
      }
    },
    [setIdx, setIdxInput, turtlesJSON]
  );

  const handleIdxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const i: number = parseInt(value);
      if (!isNaN(i)) {
        selectIdx(i - 1);
      }
    },
    [setIdxInput, idx]
  );

  const handleIdxNext = useCallback(() => {
    selectIdx(idx + 1);
  }, [idx, selectIdx]);

  const handleIdxPrev = useCallback(() => {
    selectIdx(idx - 1);
  }, [idx, selectIdx]);

  useEffect(() => {
    const newTurtles = turtleByPos.find(position);
    selectIdx(0);
    setTurtles(newTurtles.length === 0 ? [Turtle.DEFAULT] : newTurtles);
  }, [position, turtleByPos, selectIdx, setTurtles]);

  useEffect(() => {
    const left: number = turtles.length > 0 && turtles[idx] ? turtles[idx].x : 0;
    const top: number = turtles.length > 0 && turtles[idx] ? turtles[idx].y : 0;
    setTurtlePosition({
      left,
      top,
    });
    setSelectedTurtle(turtles[idx]);
  }, [turtlesJSON, idx, setTurtlePosition, setSelectedTurtle]);

  // animation timeout checks
  useEffect(() => {
    setAnimationTimeout(false);
    clearTimeout(animationTimeoutIdRef.current);

    animationTimeoutIdRef.current = setTimeout(() => {
      setAnimationTimeout(true);
    }, 100) as unknown as number;
  }, [animationTime]);

  const isAnimationRunning = useMemo(() => {
    const isAnimationCodeType = codeType === CodeType.Animation;

    return isAnimationCodeType && !animationTimeout;
  }, [codeType, animationTimeout]);

  return (
    <>
      <div className={classnames(styles.container, { [styles.hidden]: isAnimationRunning })}>
        <Parameter Icon={CornerUpRight} title={t('currentPos')} data-testid={getTestSelector('editor.turtle_pos')}>
          ({turtlePosition.left.toFixed(0)}, {turtlePosition.top.toFixed(0)})
        </Parameter>
        <Parameter Icon={Rotation} title={t('currentDir')} data-testid={getTestSelector('editor.turtle_dir')}>
          {turtles[idx] ? radToDeg(turtles[idx]._heading).toFixed(0) : 0}{' '}
        </Parameter>
      </div>

      <div className={styles.container}>
        <Button
          flavor={ButtonFlavor.Gray}
          size={ButtonSize.Small}
          onClick={handleIdxPrev}
          disabled={idx <= 0}
          name="turtleIdxPrev"
          title={t('prevTurtle')}
          Icon={ChevronLeft}
        />
        <Input value={idxInput} className={styles.stepInput} onChange={handleIdxChange} title={t('currentTurtle')} />
        <span className={styles.length} title={t('currentTurtle')}>
          / {turtles.length}
        </span>
        <Button
          flavor={ButtonFlavor.Gray}
          size={ButtonSize.Small}
          onClick={handleIdxNext}
          disabled={idx >= turtles.length}
          name="turtleIdxNext"
          title={t('nextTurtle')}
          Icon={ChevronRight}
        />
      </div>
    </>
  );
};

export default PositionIndicator;
