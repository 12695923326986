export class Color {
  readonly name: string;
  readonly hex: string;
  readonly rgb: number[];

  constructor(name: string, value: string | number[]) {
    this.name = name;
    this.hex = this.parseHex(value);
    this.rgb = this.parseRgb(value);
  }

  get r(): number {
    return this.rgb[0];
  }

  get g(): number {
    return this.rgb[1];
  }

  get b(): number {
    return this.rgb[2];
  }

  get code(): string {
    const { rgb } = this;
    return rgb.length >= 3 ? `(rgb ${rgb[0]} ${rgb[1]} ${rgb[2]})` : '()';
  }

  private parseHex(value: string | number[]): string {
    return Color.IS_HEX(value)
      ? <string>value
      : Color.RGB_TO_HEX((<number[]>value)[0], (<number[]>value)[1], (<number[]>value)[2]);
  }

  private parseRgb(value: string | number[]): number[] {
    return Color.IS_RGB(value) ? <number[]>value : Color.HEX_TO_RGB(<string>value);
  }

  static IS_HEX(value: string | number[]): boolean {
    if (typeof value !== 'string') {
      return false;
    }
    return Color.HEX_REGEXP.test(value);
  }

  static IS_RGB(value: string | number[]): boolean {
    if (!Array.isArray(value)) {
      return false;
    }
    return (value.length === 3 || value.length === 4) && value.every((v) => v >= 0 && v <= 255);
  }

  static readonly HEX_REGEXP = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

  static readonly COLOR_EXPRESSION_REGEXP = /color:\(rgb\s(\d{1,3})\s(\d{1,3})\s(\d{1,3})\)/i;

  static readonly ColorValues: { [key: string]: string } = {
    green: '#00E7A0',
    lightgreen: '#B3FAD4',
    darkgreen: '#04815D',
    lightyellow: '#FBE28A',
    yellow: '#EEC244',
    darkblue: '#5249E0',
    blue: '#6B99F8',
    lightblue: '#AADBFF',
    pink: '#F01DDB',
    lightpink: '#FD97F3',
    red: '#FF0421',
    orange: '#FF6F1E',
    lightgray: '#A8B9D0',
    gray: '#7B7E8B',
    white: '#FFFFFF',
  };

  private static readonly ByName: { [key: string]: Color } = Object.entries(Color.ColorValues).reduce(
    (obj: { [key: string]: Color }, [key, value]) => {
      obj[key] = new Color(key, value);
      return obj;
    },
    {}
  );

  static FROM_NAME(name: string): Color | undefined {
    return Color.ByName[name];
  }

  static FROM_HEX(hex: string): Color {
    return new Color(hex, hex);
  }

  static FROM_RGB(r: number, g: number, b: number): Color {
    // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
    return new Color(`(rgb ${r} ${g} ${b})`, Color.RGB_TO_HEX(r, g, b));
  }

  static HEX_TO_RGB(hex: string): number[] {
    // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    const value = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = Color.HEX_REGEXP.exec(value);

    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
  }

  static RGB_TO_HEX(r: number, g: number, b: number): string {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  static ATTR_VALUE(color: Color): string {
    return color.name.includes('rgb') ? color.code : color.name;
  }

  static ALL_DEFAULT: Color[] = Object.values(Color.ByName);
  static ALL_NAMES: string[] = Object.keys(Color.ByName);
}
