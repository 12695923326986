import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimationOnServer } from 'types/program';
import { LinkButton, ButtonSize, ButtonFlavor } from 'ui/components/common/dumb/Button';
import { pathForAnimation, pathForAnimationImage } from 'ui/components/routing/programHelpers';
import styles from './AnimationItem.module.scss';
import { formatDate } from 'util/dateUtil';
import Image from 'ui/components/common/dumb/Image';

interface Props {
  code: AnimationOnServer;
}

const AnimationItem: React.FC<Props> = ({ code }) => {
  const { t } = useTranslation(['myAnimations', 'toast']);

  return (
    <div className={styles.container}>
      <Image src={pathForAnimationImage(code.id)} className={styles.thumbnail} alt={code.name} />
      <div className={styles.content}>
        <h3 className={styles.name}>{code.name || t('animation.untitled')}</h3>
        <p className={styles.info}>{formatDate(code.created)}</p>
        <div className={styles.actionBox}>
          <LinkButton size={ButtonSize.Small} flavor={ButtonFlavor.Gray} to={pathForAnimation(code.id, code.userLogin)}>
            {t(`open`)}
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

export default AnimationItem;
