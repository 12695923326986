import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import PromptBox from 'ui/components/common/dumb/PromptBox';
import { useForm } from 'react-hook-form';
import useApi from 'ui/components/hooks/useApi';
import { usePromise } from 'react-use-promise-matcher';
import Form from 'ui/components/common/dumb/Form';
import Input from 'ui/components/common/dumb/Input';
import FormError from 'ui/components/common/dumb/FormError';
import TextArea from 'ui/components/common/dumb/TextArea';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import Loader from 'ui/components/common/dumb/Loader';
import styles from './ContactUsForm.module.scss';
import inMainScreen from 'ui/components/hocs/inMainScreen';
import { useSelector } from 'react-redux';
import { userSelector } from 'ui/store/user/selectors';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';

const codePropSelector = createCodeStatePropSelector('creativeCode', 'challengeCode');

const ContactUsForm: React.FC = () => {
  const user = useSelector(userSelector);
  const { creativeCode, challengeCode } = useSelector(codePropSelector);
  const { contact } = useApi();

  const { register, reset, handleSubmit, formState, setValue, trigger } = useForm({
    mode: 'onChange',
  });

  const {
    t,
    i18n: { language },
  } = useTranslation(['userForm', 'contactUs']);

  const { errors } = formState;
  const combinedCodes = useMemo(() => [creativeCode, challengeCode].join('\n---\n'), [creativeCode, challengeCode]);

  const contactUs = useCallback(
    ({ email, message }: { [key: string]: string }) => contact(email, message, combinedCodes),
    [contact, combinedCodes]
  );

  const [result, load] = usePromise(contactUs);

  const setEmail = useCallback(() => {
    if (user) {
      setValue('email', user.email);
    }
  }, [user, setValue]);

  const renderForm = useCallback(() => {
    return (
      <Form onSubmit={handleSubmit(load)}>
        <Input
          className={styles.input}
          type="email"
          placeholder={t('userForm:emailPlaceholder')}
          {...register('email', {
            required: t('userForm:emailRequired') as string,
          })}
        />
        <FormError errors={errors} name="email" />
        <TextArea
          className={styles.input}
          placeholder={t('contactUs:yourMessagePlaceholder')}
          {...register('message', {
            required: t('contactUs:yourMessageRequired') as string,
          })}
        />
        <FormError errors={errors} name="message" />
        <Button flavor={ButtonFlavor.Success} disabled={!formState.isValid}>
          {t('contactUs:send')}
        </Button>
      </Form>
    );
  }, [formState, register, errors, handleSubmit, load]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  useEffect(() => {
    if (user) {
      setValue('email', user.email);
      trigger('email');
    }
  }, [user, trigger]);

  useEffect(() => {
    result.match({
      Loading: () => null,
      Rejected: () => {
        setEmail();
      },
      Resolved: () => {
        setEmail();
      },
    });
  }, [result, setEmail]);

  const initialMount = useRef(true);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
    } else {
      trigger();
    }
  }, [language, trigger, initialMount]);

  return (
    <PromptBox>
      <PromptBox.Row>
        <PromptBox.Heading>{t('contactUs:contactUs')}</PromptBox.Heading>
      </PromptBox.Row>
      {result.match({
        Idle: () => <PromptBox.Row>{renderForm()}</PromptBox.Row>,
        Loading: () => <Loader />,
        Rejected: (err: any) => (
          <>
            <PromptBox.Row>{renderForm()}</PromptBox.Row>
            <PromptBox.Row>
              <p className={classnames(styles.message, styles.error)}>{err}</p>,
            </PromptBox.Row>
          </>
        ),
        Resolved: () => (
          <>
            <PromptBox.Row>{renderForm()}</PromptBox.Row>
            <PromptBox.Row>
              <p className={classnames(styles.message, styles.success)}>{t('contactUs:messageSent')}</p>
            </PromptBox.Row>
          </>
        ),
      })}
    </PromptBox>
  );
};

export default inMainScreen(ContactUsForm);
