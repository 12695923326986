import React, { useCallback } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { usePromise } from 'react-use-promise-matcher';
import classnames from 'classnames';
import queryString from 'query-string';
import { DataItem } from 'types/data';
import PromptBox from 'ui/components/common/dumb/PromptBox';
import useApi from 'ui/components/hooks/useApi';
import Form from 'ui/components/common/dumb/Form';
import Input from 'ui/components/common/dumb/Input';
import FormError from 'ui/components/common/dumb/FormError';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import Loader from 'ui/components/common/dumb/Loader';
import inMainScreen from 'ui/components/hocs/inMainScreen';
import styles from './ResetPasswordForm.module.scss';

const ResetPasswordForm: React.FC = () => {
  const { register, formState, handleSubmit, clearErrors, getValues, watch } = useForm({ mode: 'onChange' });
  const { search } = useLocation();
  const { t } = useTranslation('passwordDetails');
  const { resetPassword } = useApi();
  const { errors } = formState;

  const submitHandler = useCallback(
    (payload: Record<string, string>) => {
      const { code } = queryString.parse(search);
      return resetPassword((code as string) || '', payload.newPassword);
    },
    [search, resetPassword]
  );

  const [result, load] = usePromise<any, any, DataItem>(submitHandler);

  const isValid = useCallback(() => {
    const pass = getValues().newPassword;
    const repeat = getValues().repeatedNewPassword;
    const areSame: boolean = pass === repeat;
    return pass && repeat && pass.length > 0 && repeat.length > 0 && areSame;
  }, [getValues]);

  const renderForm = useCallback(() => {
    const passwordError: string = t('repeatPasswordError') as string;
    return (
      <Form onSubmit={handleSubmit(load)}>
        <Input
          className={styles.input}
          type="password"
          placeholder={t('newPassword')}
          {...register('newPassword', {
            required: t('passwordRequired') as string,
            validate: (value: string) => value === watch('repeatedNewPassword') || passwordError,
          })}
        />
        {errors.newPassword && errors.newPassword.type === 'required' && (
          <FormError errors={errors} name="newPassword" />
        )}
        <Input
          className={styles.input}
          type="password"
          placeholder={t('repeatPasswordPlaceholder')}
          {...register('repeatedNewPassword', {
            required: passwordError,
            validate: (value: string) => value === watch('newPassword') || passwordError,
          })}
        />
        <FormError errors={errors} name="repeatedNewPassword" />
        <Button flavor={ButtonFlavor.Success} disabled={!isValid()} className={styles.btn}>
          {t('resetPassword')}
        </Button>
      </Form>
    );
  }, [register, handleSubmit, isValid, clearErrors, errors, load, getValues, t]);

  return (
    <PromptBox>
      <PromptBox.Row>
        <PromptBox.Heading>{t('setNewPassword')}</PromptBox.Heading>
      </PromptBox.Row>
      <PromptBox.Row className={styles.row}>
        {result.match({
          Idle: renderForm,
          Loading: () => <Loader />,
          Rejected: (err: any) => (
            <>
              {renderForm()}
              <p className={classnames(styles.message, styles.error)}>{err.response.data.error}</p>
            </>
          ),
          Resolved: () => <Navigate to="/user/login" />,
        })}
      </PromptBox.Row>
    </PromptBox>
  );
};

export default inMainScreen(ResetPasswordForm);
