import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useLocalStorage, writeStorage, deleteFromStorage } from '@rehooks/local-storage';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { renderToastPopup } from 'util/toast';
import useApi from 'ui/components/hooks/useApi';
import {
  CREATIVE_CONTENT_STORAGE_KEY,
  PROGRAM_STATE_STORAGE_KEY,
  CHALLENGE_CONTENT_STORAGE_KEY,
  STORAGE_API_KEY,
  USER_TOKEN_STORAGE_KEY,
} from 'ui/constants';
import Loader from 'ui/components/common/dumb/Loader';
import { ProgramOnServer } from 'types/program';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';

const FbCallback: React.FC = () => {
  const { search } = useLocation();
  const [redirectTo, setRedirectTo] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();
  const { setProgram, setCreativeCode, setChallengeCode } = useCodeStoreActions(dispatch);

  const [restoredProgram, , deleteRestoredProgram] = useLocalStorage<ProgramOnServer>(PROGRAM_STATE_STORAGE_KEY);
  const [restoredCreativeContent, , deleteRestoredCreativeContent] = useLocalStorage(CREATIVE_CONTENT_STORAGE_KEY);
  const [restoredChallengeContent, , deleteRestoredChallengeContent] = useLocalStorage(CHALLENGE_CONTENT_STORAGE_KEY);
  const { loginOrRegisterUsingFb } = useApi();

  useEffect(() => {
    async function run() {
      const { code } = queryString.parse(search);
      try {
        if (typeof code !== 'string') {
          throw 'No login code!';
        }

        const apiKey = await loginOrRegisterUsingFb(code);

        if (restoredProgram) {
          setProgram(restoredProgram);
          deleteRestoredProgram();
        }

        if (restoredCreativeContent) {
          setCreativeCode(restoredCreativeContent);
          deleteRestoredCreativeContent();
        }

        if (restoredChallengeContent) {
          setChallengeCode(restoredChallengeContent);
          deleteRestoredChallengeContent();
        }

        setRedirectTo('/');
        writeStorage(STORAGE_API_KEY, apiKey);
        deleteFromStorage(USER_TOKEN_STORAGE_KEY);
      } catch (err) {
        renderToastPopup('error', 'Cannot login using Facebook!');
        window.console.error(err);
        setRedirectTo('/user/login');
      }
    }

    run();
  }, [loginOrRegisterUsingFb]);

  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  } else {
    return <Loader overlay />;
  }
};

export default FbCallback;
