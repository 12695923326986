import { PositionRange } from '../../util/Position';

export type InterpretErrorCode = string;

export class InterpretError {
  readonly pos: PositionRange;
  readonly code: string;
  readonly args: any[];

  constructor(pos: PositionRange, code: InterpretErrorCode, ...args: any[]) {
    this.pos = pos;
    this.code = code;
    this.args = args;
  }

  static readonly _SYNTAX = 'syntax';
  static SYNTAX(pos: PositionRange, msg: string): InterpretError {
    return new InterpretError(pos, InterpretError._SYNTAX, msg);
  }

  static readonly UNKNOWN = 'unknown';

  static readonly _INTERPRETED_EXPS_EXCEEDED = 'interpreted_exps_exceeded';
  static INTERPRETED_EXPS_EXCEEDED(pos: PositionRange, count: number): InterpretError {
    return new InterpretError(pos, InterpretError._INTERPRETED_EXPS_EXCEEDED, count);
  }

  static readonly _UNKNOWN_NAME = 'unknown_name';
  static UNKNOWN_NAME(pos: PositionRange, name: string): InterpretError {
    return new InterpretError(pos, InterpretError._UNKNOWN_NAME, name);
  }

  static readonly _NOT_NUMBER = 'not_number';
  static NOT_NUMBER(pos: PositionRange, value: string): InterpretError {
    return new InterpretError(pos, InterpretError._NOT_NUMBER, value);
  }

  static readonly _NOT_BOOLEAN = 'not_boolean';
  static NOT_BOOLEAN(pos: PositionRange, value: string): InterpretError {
    return new InterpretError(pos, InterpretError._NOT_BOOLEAN, value);
  }

  static readonly _NOT_STRING = 'not_string';
  static NOT_STRING(pos: PositionRange, value: string): InterpretError {
    return new InterpretError(pos, InterpretError._NOT_STRING, value);
  }

  static readonly _APPLY_NOT_TO_SUB_EXP = 'apply_not_to_sub_exp';
  static APPLY_NOT_TO_SUB_EXP(pos: PositionRange, value: string): InterpretError {
    return new InterpretError(pos, InterpretError._APPLY_NOT_TO_SUB_EXP, value);
  }

  static readonly _NOT_ATTR = 'not_attr';
  static NOT_ATTR(pos: PositionRange, value: string): InterpretError {
    return new InterpretError(pos, InterpretError._NOT_ATTR, value);
  }

  static readonly _INVALID_ATTR = 'invalid_attr';
  static INVALID_ATTR(pos: PositionRange, key: string, value: string): InterpretError {
    return new InterpretError(pos, InterpretError._INVALID_ATTR, key, value);
  }

  static readonly _NO_ATTRS = 'no_attrs';
  static NO_ATTRS(pos: PositionRange, value: string): InterpretError {
    return new InterpretError(pos, InterpretError._NO_ATTRS, value);
  }

  static readonly _ARG_COUNT = 'arg_count';
  static ARG_COUNT(pos: PositionRange, expectedCount: number, actualCount: number): InterpretError {
    return new InterpretError(pos, InterpretError._ARG_COUNT, expectedCount, actualCount);
  }

  static readonly _DIV_BY_ZERO = 'div_by_zero';
  static DIV_BY_ZERO(pos: PositionRange): InterpretError {
    return new InterpretError(pos, InterpretError._DIV_BY_ZERO);
  }

  static readonly _ALREADY_DEFINED = 'already_defined';
  static ALREADY_DEFINED(pos: PositionRange, name: string): InterpretError {
    return new InterpretError(pos, InterpretError._ALREADY_DEFINED, name);
  }

  static readonly _BUILT_IN = 'built_in';
  static BUILT_IN(pos: PositionRange, name: string): InterpretError {
    return new InterpretError(pos, InterpretError._BUILT_IN, name);
  }

  static readonly _RESERVED = 'reserved';
  static RESERVED(pos: PositionRange, name: string): InterpretError {
    return new InterpretError(pos, InterpretError._RESERVED, name);
  }

  static readonly UNBALANCED_STRING = 'unbalanced_string';
  static readonly UNBALANCED_PARENS_LEFT = 'unbalanced_parens_left';
  static readonly UNBALANCED_PARENS_RIGHT = 'unbalanced_parens_right';

  static readonly INVALID_LET_NAME = 'invalid_let_name';
  static readonly INVALID_LET_POSITION = 'invalid_let_position';
  static readonly INVALID_LET_WITHOUT_NAME_VALUE = 'invalid_let_without_name_value';
  static readonly INVALID_LET_WITHOUT_EQ_VALUE = 'invalid_let_without_eq_value';
  static readonly INVALID_LET_WITHOUT_EQ = 'invalid_let_without_eq';
  static readonly INVALID_LET_WITHOUT_VALUE = 'invalid_let_without_value';
  static readonly INVALID_LET_WITHOUT_NAME = 'invalid_let_without_name';

  static readonly INVALID_DOT_DOUBLE = 'invalid_dot_double';
  static readonly INVALID_DOT_NO_SUBEXP = 'invalid_dot_no_subexp';
  static readonly INVALID_DOT_NO_IDENTS = 'invalid_dot_no_idents';

  static readonly INVALID_IF_POSITION = 'invalid_if_position';
  static readonly INVALID_IF_WITHOUT_THEN = 'invalid_if_without_then';
  static readonly INVALID_IF_EMPTY_CONDITION = 'invalid_if_empty_condition';
  static readonly INVALID_IF_EMPTY_THEN = 'invalid_if_empty_then';
  static readonly INVALID_IF_EMPTY_ELSE = 'invalid_if_empty_else';

  static readonly INVALID_NUMBER = 'invalid_number';
  static readonly INVALID_OPERATOR = 'invalid_operator';

  static readonly _COMPARISON_MISSING_BINARY = 'comparison_missing_binary';
  static COMPARISON_MISSING_BINARY(pos: PositionRange, operator: string): InterpretError {
    return new InterpretError(pos, InterpretError._COMPARISON_MISSING_BINARY, operator);
  }
  static readonly _ARITHMETIC_MISSING_BINARY = 'arithmetic_missing_binary';
  static ARITHMETIC_MISSING_BINARY(pos: PositionRange, operator: string): InterpretError {
    return new InterpretError(pos, InterpretError._ARITHMETIC_MISSING_BINARY, operator);
  }

  static readonly _TOO_MANY_IMAGES = 'too_many_images';
  static TOO_MANY_IMAGES(pos: PositionRange, maxImages: number): InterpretError {
    return new InterpretError(pos, InterpretError._TOO_MANY_IMAGES, maxImages);
  }

  static readonly _TOO_MANY_STEPS_IN_INSTR = 'too_many_steps_in_instr';
  static TOO_MANY_STEPS_IN_INSTR(pos: PositionRange, maxSteps: number): InterpretError {
    return new InterpretError(pos, InterpretError._TOO_MANY_STEPS_IN_INSTR, maxSteps);
  }

  static readonly ILLEGAL_CHARACTER = 'illegal_characters';

  static readonly _RGB_OUT_OF_RANGE = 'rgb_out_of_range';
  static RGB_OUT_OF_RANGE(pos: PositionRange, value: number): InterpretError {
    return new InterpretError(pos, InterpretError._RGB_OUT_OF_RANGE, value);
  }
}
