export const APP_URL = process.env.APP_URL!;
export const CHALLENGE_PACK_ID = process.env.CHALLENGE_PACK_ID!;
export const AXIOS_BASE_URL = process.env.AXIOS_BASE_URL!;
export const FACEBOOK_URL = process.env.FACEBOOK_URL!;

export const STORAGE_API_KEY = 'apiKey';
export const PROGRAM_STATE_STORAGE_KEY = 'program_state';
export const CREATIVE_CONTENT_STORAGE_KEY = 'program_content';
export const CHALLENGE_CONTENT_STORAGE_KEY = 'challenge_content';
export const USER_TOKEN_STORAGE_KEY = 'user_token';
export const HOUR_OF_CODE_TOKEN_STORAGE_KEY = 'hour_of_code_token';
export const CONTEST_NAME = 'hour-of-code';
export const STORAGE_COOKIE_CONSENT_KEY = 'cookie_consent';
export const USER_CONTEXT = 'user';
export const PROGRAM_CONTEXT = 'program';
export const ANIMATION_CONTEXT = 'animation';
export const CHALLENGE_CONTEXT = 'challenge';
export const USER_OAUTH_CONTEXT = 'oauth';
export const API_KEY_VALID_HOURS: number = 24 * 30;
export const INITIAL_POSITION = [0, 0];
export const MODEL_MARKERS_OWNER = 'paintscript';
export const PROGRAM_PATHS = ['/p', '/p/:id', '/p/:userLogin/:id'];
export const CHALLENGE_PATHS = ['/', '/c/:id', '/c/:userLogin/:id'];
export const ANIMATION_PATHS = ['/a', '/a/:id', '/a/:userLogin/:id'];
export const CANVAS_CONFIG = {
  WIDTH: 1500,
  HEIGHT: 1500,
  ZOOM_MIN: 0.38, // 10 steps
  ZOOM_MAX: 2.61, // 10 steps
  ZOOM_STEP: 1.1,
  ZOOM_STEP_WHEEL: 1.02,
};
export const PAGE_TOUR_STORAGE_KEY = 'page_tour';
export const DEFAULT_DOWNLOAD_PNG_NAME = 'drawing.png';
export const SHELLY_TWITTER_HREF = 'https://twitter.com/shelly_dot_dev';
export const MAX_DIFFICULTY_RATING = 5;
export const MAX_SOLUTION_RATING = 5;
export const DATE_TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};
export const START_REFERENCE_ARTICLE = 'start_reference';
export const START_CONTEXT_ARTICLE = 'start_context';
export const DEFAULT_CHALLENGE_USER_LOGIN = 'shelly';
export const TOUR_ROOT_ELEMENT_ID = 'tour_root';
export const USER_LOGIN_REGEX = /^[a-zA-Z0-9_.-]*$/;
export const LIST_PORTION_LENGTH = 12;
export const DEFAULT_ANIMATION_TIME = 10;
