import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkButton, ButtonSize } from 'ui/components/common/dumb/Button';
import { pathForCode } from '../../programHelpers';
import Trophy from 'ui/components/svg/Trophy';
import MyPicture from 'ui/components/svg/MyPicture';
import { CodeType } from 'types/code';
import styles from './ProgramModeMenu.module.scss';
import { PageTourStep } from 'ui/components/common/smart/PageTour/types';
import { getChallengeInProgress } from 'ui/store/code/helpers';
import { getTestSelector } from 'util/testUtil';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import MyAnimation from 'ui/components/svg/MyAnimation';

const codePropsSelector = createCodeStatePropSelector('challenge', 'program', 'animation', 'challengeNodes');

const ProgramModeMenu: React.FC = () => {
  const { t } = useTranslation('navigation');
  const { challenge, program, animation, challengeNodes } = useSelector(codePropsSelector);
  const isHomePage = useMatch('/');

  const pointedChallenge = useMemo(
    () => challenge || getChallengeInProgress(challengeNodes)?.data,
    [challenge, challengeNodes]
  );

  return (
    <div className={styles.container} data-tour-area={PageTourStep.Modes}>
      <LinkButton
        data-testid={getTestSelector('navigation.challenge_mode')}
        Icon={Trophy}
        size={ButtonSize.Small}
        to={!!isHomePage ? '/' : pathForCode(CodeType.Challenge, pointedChallenge?.id, challenge?.userLogin)}
        title={t('navigation:mode.challenge')}
        noBorder
        bigIcon
      />
      <LinkButton
        data-testid={getTestSelector('navigation.creative_mode')}
        Icon={MyPicture}
        size={ButtonSize.Small}
        to={pathForCode(CodeType.Creative, program.id, program.userLogin)}
        title={t('navigation:mode.creative')}
        noBorder
        bigIcon
      />
      <LinkButton
        Icon={MyAnimation}
        size={ButtonSize.Small}
        to={pathForCode(CodeType.Animation, animation.id, animation.userLogin)}
        title={t('navigation:mode.animation')}
        noBorder
        bigIcon
      />
    </div>
  );
};

export default ProgramModeMenu;
