import { editor } from 'monaco-editor';
import { InterpretError } from './error/InterpretError';
import { InstrsWithStats } from './InterpretResultWithStats';
import { InterpretWorker } from './worker/InterpretWorker';
import MonacoWebWorker = editor.MonacoWebWorker;

export class InterpretController {
  private readonly onError: (e: InterpretError) => void;
  private readonly onInstr: (i: InstrsWithStats) => void;
  private readonly worker: MonacoWebWorker<InterpretWorker>;

  private interpretInProgress = false;
  private programToInterpretBuffer: string | null = null;

  constructor(
    worker: MonacoWebWorker<InterpretWorker>,
    onError: (e: InterpretError) => void,
    onInstr: (i: InstrsWithStats) => void
  ) {
    this.worker = worker;
    this.onError = onError;
    this.onInstr = onInstr;
  }

  onModelChanged(program: string): void {
    this.programToInterpretBuffer = program;
    if (!this.interpretInProgress) {
      this.interpret();
    }
  }

  private interpret() {
    this.interpretInProgress = true;

    const nextProgram = this.programToInterpretBuffer;
    this.programToInterpretBuffer = null;

    if (nextProgram === null) {
      // a failsafe just in case
      this.interpretInProgress = false;
      return;
    }

    this.worker
      .getProxy()
      .then((p) => {
        return p.doInterpret(nextProgram);
      })
      .then((r) => this.interpretDone(r));
  }

  private interpretDone(r: any) {
    // type information is erased when sending from webworker
    if ('code' in r) {
      this.onError(new InterpretError(r.pos, r.code, ...r.args));
    } else {
      this.onInstr(<InstrsWithStats>r);
    }

    if (this.programToInterpretBuffer !== null) {
      this.interpret();
    } else {
      this.interpretInProgress = false;
    }
  }
}
