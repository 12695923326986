import { type AxiosResponse } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PromiseResultShape } from 'react-use-promise-matcher';
import { CodeType } from 'types/code';
import { useSocialMediaShare } from 'ui/components/hooks/useSocialMediaShare';
import { pathForChallenge } from 'ui/components/routing/programHelpers';
import ProgramLinkCopy from 'ui/components/common/smart/ProgramLinkCopy';
import SocialShareLink from 'ui/components/common/smart/SocialShareLink';
import Modal from 'ui/components/common/smart/Modal';
import ErrorWrapper from '../ErrorWrapper';
import LoaderWrapper from '../LoaderWrapper';
import { useSelector } from 'react-redux';
import { userSelector } from 'ui/store/user/selectors';
import { codeIdSelector } from 'ui/store/code/selectors';

export type Props = {
  result: PromiseResultShape<AxiosResponse<any> | undefined, string>;
  onClose: () => void;
};

const ShareChallengeModal: React.FC<Props> = ({ result, onClose }) => {
  const { t } = useTranslation('socialMedia');
  const codeId = useSelector(codeIdSelector);
  const user = useSelector(userSelector);
  const codePath = pathForChallenge(codeId, user?.login);
  const { facebookText, twitterText } = useSocialMediaShare(codePath, true, CodeType.Challenge);

  return (
    <Modal onClose={onClose} heading={t('shareYourChallenge')} showLogo showLang={false}>
      {result.match({
        Loading: () => <LoaderWrapper />,
        Rejected: () => <ErrorWrapper />,
        Resolved: (res) => (
          <>
            <ProgramLinkCopy responseChallengeId={res?.data.id} codeType={CodeType.Challenge} />
            <SocialShareLink via="fb" text={facebookText} />
            <SocialShareLink via="twitter" text={twitterText} />
          </>
        ),
      })}
    </Modal>
  );
};

export default ShareChallengeModal;
