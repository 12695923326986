import React, { useCallback } from 'react';
import PositionIndicator from './PositionIndicator';
import Questionnaire from 'ui/components/svg/Questionnaire';
import CheckList from 'ui/components/svg/CheckList';
import { useTranslation } from 'react-i18next';
import Parameter from 'ui/components/common/dumb/Parameter';
import styles from '../Stats.module.scss';
import { getTestSelector } from 'util/testUtil';
import { useSelector } from 'react-redux';
import { interpretResultSelector } from 'ui/store/editor/selectors';

const StatsInfo: React.FC = () => {
  const interpretResult = useSelector(interpretResultSelector);
  const { t } = useTranslation('header');

  const renderInfoData = useCallback(() => {
    if (!('instructions' in interpretResult)) return;

    return (
      <div className={styles.container}>
        <Parameter Icon={CheckList} title={t('numberOfInstr')} data-testid={getTestSelector('editor.instr_count')}>
          {interpretResult.instructions.length}
        </Parameter>
        <Parameter
          Icon={Questionnaire}
          title={t('numberOfTokens')}
          data-testid={getTestSelector('editor.tokens_count')}
        >
          {interpretResult.astSize}
        </Parameter>
      </div>
    );
  }, [interpretResult]);

  return 'instructions' in interpretResult ? (
    <div className={styles.stats}>
      <PositionIndicator />
      {renderInfoData()}
    </div>
  ) : null;
};

export default StatsInfo;
