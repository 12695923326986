import React from 'react';
import Loader from 'ui/components/common/dumb/Loader';
import styles from '../SocialShare.module.scss';

const LoaderWrapper: React.FC = () => {
  return (
    <div className={styles.loaderWrapper}>
      <Loader />
    </div>
  );
};

export default LoaderWrapper;
