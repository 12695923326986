import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CanvasControls.module.scss';
import Button, { ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import ZoomIn from 'ui/components/svg/ZoomIn';
import Centralize from 'ui/components/svg/Centralize';
import ZoomOut from 'ui/components/svg/ZoomOut';
import Turtle from 'ui/components/svg/Turtle';
import { CANVAS_CONFIG } from 'ui/constants';
import { clamp } from 'util/numberUtil';
import { createCanvasStatePropSelector } from 'ui/store/canvas/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useCanvasStoreActions } from 'ui/store/canvas/useCanvasStoreActions';
import { getTestSelector } from 'util/testUtil';

const canvasPropSelector = createCanvasStatePropSelector('canvasZoomAndPos', 'lastTurtlePos');

const CanvasControls: React.FC = () => {
  const dispatch = useDispatch();
  const { setCanvasZoomAndPos, resetCanvas } = useCanvasStoreActions(dispatch);
  const { canvasZoomAndPos, lastTurtlePos } = useSelector(canvasPropSelector);
  const { t } = useTranslation('canvas');

  const zoomIn = useCallback(() => {
    const newZoom = clamp(
      canvasZoomAndPos.zoom * CANVAS_CONFIG.ZOOM_STEP,
      CANVAS_CONFIG.ZOOM_MIN,
      CANVAS_CONFIG.ZOOM_MAX
    );
    setCanvasZoomAndPos({ ...canvasZoomAndPos, zoom: newZoom, applied: false }); // pos will remain the same after zoom
  }, [setCanvasZoomAndPos, canvasZoomAndPos]);

  const zoomOut = useCallback(() => {
    const newZoom = clamp(
      canvasZoomAndPos.zoom / CANVAS_CONFIG.ZOOM_STEP,
      CANVAS_CONFIG.ZOOM_MIN,
      CANVAS_CONFIG.ZOOM_MAX
    );
    setCanvasZoomAndPos({ ...canvasZoomAndPos, zoom: newZoom, applied: false }); // pos will remain the same after zoom
  }, [setCanvasZoomAndPos, canvasZoomAndPos]);

  const centerCanvas = useCallback(() => {
    resetCanvas();
  }, [resetCanvas]);

  const findTurtle = useCallback(() => {
    setCanvasZoomAndPos({ ...canvasZoomAndPos, pos: lastTurtlePos, applied: false });
  }, [setCanvasZoomAndPos, canvasZoomAndPos, lastTurtlePos]);

  return (
    <div className={styles.container}>
      <Button
        onClick={zoomIn}
        title={t('zoomIn')}
        flavor={ButtonFlavor.Gray}
        size={ButtonSize.Small}
        Icon={ZoomIn}
        data-testid={getTestSelector('canvas.controls.zoom_in')}
      />
      <Button
        onClick={zoomOut}
        title={t('zoomOut')}
        flavor={ButtonFlavor.Gray}
        size={ButtonSize.Small}
        Icon={ZoomOut}
        data-testid={getTestSelector('canvas.controls.zoom_out')}
      />
      <Button
        onClick={findTurtle}
        title={t('locateTurtle')}
        flavor={ButtonFlavor.Gray}
        size={ButtonSize.Small}
        Icon={Turtle}
        data-testid={getTestSelector('canvas.controls.locate_turtle')}
      />
      <Button
        onClick={centerCanvas}
        title={t('resetCenter')}
        flavor={ButtonFlavor.Gray}
        size={ButtonSize.Small}
        Icon={Centralize}
        data-testid={getTestSelector('canvas.controls.center')}
      />
    </div>
  );
};
export default CanvasControls;
