import React from 'react';

type UseRect = () => [DOMRect | undefined, React.MutableRefObject<HTMLDivElement | null>];

const useRect: UseRect = () => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [rect, setRect] = React.useState<DOMRect>();

  const set = () => setRect(ref?.current?.getBoundingClientRect());

  React.useEffect(() => {
    set();
    window.addEventListener('resize', set);
    return () => window.removeEventListener('resize', set);
  }, []);

  return [rect, ref];
};

export default useRect;
