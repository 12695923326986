import classnames from 'classnames';
import React from 'react';
import Tooltip from 'ui/components/common/smart/Tooltip';
import styles from './Button.module.scss';
import { IconComponent } from 'types/icon';
import { ButtonFlavor, ButtonSize } from './types';

interface Props extends React.ButtonHTMLAttributes<any> {
  Icon?: IconComponent;
  className?: string;
  flavor?: ButtonFlavor;
  size?: ButtonSize;
  noBorder?: boolean;
  bigIcon?: boolean;
  title?: string;
  'data-testid'?: string;
}

const Button: React.FC<Props> = ({
  children,
  Icon,
  className,
  flavor = ButtonFlavor.Default,
  size = ButtonSize.Default,
  noBorder = false,
  bigIcon = false,
  title = '',
  ...props
}) => (
  <Tooltip tooltip={title}>
    <button
      className={classnames(styles.container, styles[flavor], styles[size], { [styles.noBorder]: noBorder }, className)}
      {...props}
    >
      {Icon && <Icon className={classnames(styles.icon, { [styles.bigIcon]: bigIcon })} />}
      {children && <span className={styles.content}>{children}</span>}
    </button>
  </Tooltip>
);

export default Button;
