import React, { MutableRefObject, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Vector2d } from 'konva/lib/types';
import classNames from 'classnames';
import { InterpretError } from 'interpret/error/InterpretError';
import { CanvasSize } from 'paint/CanvasSize';
import styles from './PaintCanvas.module.scss';
import CanvasControls from './CanvasControls';
import ChallengesResultBox from './ChallengesResultBox';
import { CANVAS_CONFIG, DEFAULT_DOWNLOAD_PNG_NAME } from 'ui/constants';
import { PageTourStep } from 'ui/components/common/smart/PageTour/types';
import paint, { paintElement } from './paint';
import { homeScreenStateSelector } from 'ui/store/homescreen/selectors';
import { interpretResultSelector, selectedTurtleSelector } from 'ui/store/editor/selectors';
import { useEditorStoreActions } from 'ui/store/editor/useEditorStoreActions';
import { createCanvasStatePropSelector } from 'ui/store/canvas/selectors';
import { useCanvasStoreActions } from 'ui/store/canvas/useCanvasStoreActions';
import { getTestSelector } from 'util/testUtil';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { CodeType } from 'types/code';

type Props = { crosshair?: boolean };

const canvasPropSelector = createCanvasStatePropSelector('showTurtle', 'canvasZoomAndPos', 'downloadImage');
const codeTypeSelector = createCodeStatePropSelector('codeType');

const PaintCanvas: React.FC<Props> = ({ crosshair = true }) => {
  const dispatch = useDispatch();
  const interpretResult = useSelector(interpretResultSelector);
  const selectedTurtle = useSelector(selectedTurtleSelector);
  const { setError, setByPos } = useEditorStoreActions(dispatch);
  const { showTurtle, canvasZoomAndPos, downloadImage } = useSelector(canvasPropSelector);
  const { codeType } = useSelector(codeTypeSelector);
  const { setCanvasZoomAndPos, setLastTurtlePos, setDownloadImage, setDataUrlImageGetter } =
    useCanvasStoreActions(dispatch);

  const { userLogin } = useParams<{ userLogin: string | undefined }>();
  const { subscreen } = useSelector(homeScreenStateSelector);
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const setZoomAndPos = useCallback(
    ({ zoom, position }: { position: Vector2d; zoom: number }) => {
      // updating the values in contexts; marking the change as already applied, so that they
      // don't get re-applied, possibly overwriting a newer pos/zoom update, which is also
      // done asynchronously

      setCanvasZoomAndPos({ zoom, pos: position, applied: true });
    },
    [setCanvasZoomAndPos]
  );

  const resizePaint = useCallback(() => {
    if (!ref.current) return;

    const paintArea = ref.current;
    const canvasSize = new CanvasSize(
      Math.max(CANVAS_CONFIG.WIDTH, paintArea.clientWidth),
      Math.max(CANVAS_CONFIG.HEIGHT, paintArea.clientHeight),
      paintArea.clientWidth,
      paintArea.clientHeight
    );
    paint.resize(canvasSize);
  }, [ref]);

  useEffect(() => {
    if (!ref.current) return;

    paint.setOnViewChanged(setZoomAndPos);
    resizePaint();
    ref.current.append(paintElement);

    return () => {
      ref.current?.removeChild(paintElement);
    };
  }, [ref, setZoomAndPos, resizePaint]);

  const runPaint = useCallback(async () => {
    if (paint && ref.current && 'instructions' in interpretResult) {
      let lastTurtlePos: Vector2d | undefined = undefined;
      try {
        const byPos = await paint.paint(interpretResult.instructions);
        await setByPos({ turtleByPos: byPos[0], valueByPos: byPos[1] });

        const lastTurtle = byPos[0].findLast()!;
        if (lastTurtle) {
          lastTurtlePos = {
            x: lastTurtle.x,
            y: -lastTurtle.y, // the y axis is flipped
          };
        }
      } catch (e) {
        if (e instanceof InterpretError) {
          await setError(e);
        } else {
          throw e;
        }
      } finally {
        // the default (0, 0) is used in case of an error, or if there's no last turtle
        setLastTurtlePos(lastTurtlePos || { x: 0, y: 0 });
      }
    }
  }, [ref, setError, setByPos, setLastTurtlePos, interpretResult]);

  useEffect(() => {
    runPaint();
  }, [runPaint]);

  useEffect(() => {
    window.addEventListener('resize', resizePaint);
    return () => {
      window.removeEventListener('resize', resizePaint);
    };
  }, [ref, resizePaint]);

  useEffect(() => {
    const paintArea = ref.current;
    if (
      paint.canvasSize.clientHeight === paintArea?.clientHeight &&
      paint.canvasSize.clientWidth === paintArea?.clientWidth
    )
      return;
    resizePaint();
  }, [subscreen, ref]);

  useEffect(() => {
    paint.setTurtleShow(showTurtle);
  }, [showTurtle]);

  useEffect(() => {
    paint.setCrosshairShow(crosshair);
  }, [crosshair]);

  useEffect(() => {
    if (canvasZoomAndPos.applied) return;

    paint.setZoomAndPosition(canvasZoomAndPos.zoom, canvasZoomAndPos.pos);
  }, [canvasZoomAndPos]);

  useEffect(() => {
    if (selectedTurtle) {
      paint.setTurtleSelected(selectedTurtle);
    }
  }, [selectedTurtle]);

  useEffect(() => {
    if (downloadImage) {
      const anchor = createDownloadLink(paint.getDataUrlImage());
      anchor.click();
      setDownloadImage(false);
    }
  }, [downloadImage, setDownloadImage, showTurtle]);

  useEffect(() => {
    setDataUrlImageGetter(paint.getDataUrlImage.bind(paint));
  }, [setDataUrlImageGetter]);

  return (
    <div className={classNames(styles.container, { [styles.animations]: codeType === CodeType.Animation })}>
      <div
        ref={ref}
        className={styles.canvas}
        data-tour-area={PageTourStep.Canvas}
        data-testid={getTestSelector('canvas')}
      />
      <CanvasControls />
      {codeType === CodeType.Challenge && !userLogin ? <ChallengesResultBox /> : null}
    </div>
  );
};

export default PaintCanvas;

function createDownloadLink(dataUrlImage: string): HTMLAnchorElement {
  const a = document.createElement('a');
  a.href = dataUrlImage;
  a.target = '_blank';
  a.download = DEFAULT_DOWNLOAD_PNG_NAME;
  return a;
}
