import React from 'react';
import Context from './Context';
import actions from './actions';

type Props = React.HTMLAttributes<HTMLDivElement>;

const Spotlight: React.FC<Props> = ({ style, ...htmlAttributes }) => {
  const { state, dispatch } = React.useContext(Context);
  const { elementInset } = state;

  const handleClick = React.useCallback(() => {
    actions(dispatch).nextStep();
  }, [dispatch]);

  if (!elementInset) return null;

  const { top, bottom, left, right } = elementInset;
  return (
    <div
      style={{
        position: 'absolute',
        top: `${top}px`,
        bottom: `${bottom}px`,
        left: `${left}px`,
        right: `${right}px`,
        ...style,
      }}
      onClick={handleClick}
      {...htmlAttributes}
    />
  );
};

export default Spotlight;
