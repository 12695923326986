import React, { useMemo } from 'react';
import styles from './ChallengesResultBox.module.scss';
import { LinkButton, ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import Star from 'ui/components/svg/Star';
import { useSelector } from 'react-redux';
import { challengeNodesSelector } from 'ui/store/code/selectors';
import { getOverallScore } from 'ui/store/code/helpers';

const ChallengesResultBox: React.FC = () => {
  const { challengeNodes } = useSelector(challengeNodesSelector);
  const score = useMemo(() => getOverallScore(challengeNodes), [challengeNodes]);

  return (
    <div className={styles.container}>
      <LinkButton to="/user/challenges" flavor={ButtonFlavor.Gray} size={ButtonSize.Small} Icon={Star}>
        {`${score.score} / ${score.maxScore}`}
      </LinkButton>
    </div>
  );
};
export default ChallengesResultBox;
