import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Pause: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      className={styles.yellow}
      strokeWidth={0}
      d="M17,43H9c-1.10457,0-2-0.89543-2-2V7c0-1.10457,0.89543-2,2-2h8c1.10457,0,2,0.89543,2,2v34 C19,42.10457,18.10457,43,17,43z"
    ></path>{' '}
    <path
      className={styles.yellow}
      strokeWidth={0}
      d="M39,43h-8c-1.10457,0-2-0.89543-2-2V7c0-1.10457,0.89543-2,2-2h8c1.10457,0,2,0.89543,2,2v34 C41,42.10457,40.10457,43,39,43z"
    ></path>
  </svg>
);

export default Pause;
