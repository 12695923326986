import { setupCanvasDrag } from 'ui/components/turtle/canvasDrag';
import { CanvasSize } from 'paint/CanvasSize';
import { setupMouseWheelZoom } from 'ui/components/turtle/mouseWheelZoom';
import { Paint } from 'paint/Paint';
import { CANVAS_CONFIG } from 'ui/constants';

export const paintElement = document.createElement('div');

const createPaint = () => {
  const initialCanvasSize = new CanvasSize(
    CANVAS_CONFIG.WIDTH,
    CANVAS_CONFIG.HEIGHT,
    CANVAS_CONFIG.WIDTH,
    CANVAS_CONFIG.HEIGHT
  );
  const paint = new Paint(paintElement, initialCanvasSize, [setupMouseWheelZoom, setupCanvasDrag]);

  return paint;
};

export default createPaint();
