import { PropsWithChildren } from 'react';
import { NavLink, type NavLinkProps } from 'react-router-dom';
import classnames from 'classnames';
import Tooltip from 'ui/components/common/smart/Tooltip';
import styles from './Button.module.scss';
import { IconComponent } from 'types/icon';
import { ButtonFlavor, ButtonSize } from './types';

interface LinkProps extends NavLinkProps {
  className?: string;
  flavor?: ButtonFlavor;
  size?: ButtonSize;
  noBorder?: boolean;
  disabled?: boolean;
}

interface Props extends LinkProps {
  Icon?: IconComponent;
  bigIcon?: boolean;
  title?: string;
  'data-testid'?: string;
}

const LinkButton = ({ children, Icon, bigIcon = false, title = '', ...props }: PropsWithChildren<Props>) => (
  <Tooltip tooltip={title}>
    <ProtectedLink {...props}>
      {Icon && <Icon className={classnames(styles.icon, { [styles.bigIcon]: bigIcon })} />}
      {children && <span className={styles.content}>{children}</span>}
    </ProtectedLink>
  </Tooltip>
);

const ProtectedLink = ({
  children,
  className,
  flavor = ButtonFlavor.Default,
  size = ButtonSize.Default,
  noBorder = false,
  disabled = false,
  ...props
}: PropsWithChildren<LinkProps>) => {
  const componentClass = classnames(
    styles.container,
    styles[flavor],
    styles[size],
    { [styles.noBorder]: noBorder, [styles.disabled]: disabled },
    className
  );

  if (disabled) return <div className={componentClass}>{children}</div>;

  return (
    <NavLink className={({ isActive }) => `${componentClass} ${isActive ? styles.current : ''}`} {...props}>
      {children}
    </NavLink>
  );
};

export default LinkButton;
