import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { ChallengeEvaluation, ChallengeNodeList, ChallengeOnServer, ChallengePackLevel } from 'types/challenge';
import { CodeType } from 'types/code';
import { AnimationOnServer, ProgramOnServer } from 'types/program';
import { createInitialAnimation, createInitialProgram } from './helpers';
import { initialCodeContents } from './constants';

export interface CodeState {
  codeType: CodeType;
  creativeCode: string;
  challengeCode: string;
  animationCode: string;
  animationTime: number;
  animation: AnimationOnServer;
  program: ProgramOnServer;
  challenge?: ChallengeOnServer;
  challengeLevels: ChallengePackLevel[];
  challengeNodes: ChallengeNodeList;
  lastChallengeEvaluation?: ChallengeEvaluation;
  customChallengeDesc: string;
  customChallengeName: string;
  hint: string;
  isHintLoading: boolean;
  askForHint: boolean;
}

export const initialCodeState: CodeState = {
  codeType: CodeType.Challenge,
  creativeCode: initialCodeContents.creative,
  animationCode: initialCodeContents.animation,
  animationTime: 0,
  challengeCode: initialCodeContents.challenge,
  animation: createInitialAnimation(),
  program: createInitialProgram(),
  challengeLevels: [],
  challengeNodes: {},
  customChallengeDesc: '',
  customChallengeName: '',
  hint: '',
  isHintLoading: false,
  askForHint: false,
};

export const codeSlice = createSlice({
  name: 'code',
  initialState: initialCodeState,
  reducers: {
    setCodeType: (state, action: PayloadAction<CodeType>) => {
      state.codeType = action.payload;
    },
    setAnimation: (state, action: PayloadAction<AnimationOnServer>) => {
      state.animation = action.payload;
    },
    setAnimationCode: (state, action: PayloadAction<string>) => {
      state.animationCode = action.payload;
    },
    setAnimationName: (state, action: PayloadAction<string>) => {
      state.animation = {
        ...state.animation,
        name: action.payload,
      };
    },
    setAnimationTime: (state, action: PayloadAction<number>) => {
      state.animationTime = action.payload;
    },
    resetAnimationCode: (state, action: PayloadAction<AnimationOnServer>) => {
      state.animationCode = initialCodeContents.animation;
      state.animation = action.payload;
    },
    resetAnimation: (state, action: PayloadAction<AnimationOnServer>) => {
      state.animationCode = initialCodeContents.animation;
      state.animation = action.payload;
    },
    setCreativeCode: (state, action: PayloadAction<string>) => {
      state.creativeCode = action.payload;
    },
    resetCreativeCode: (state, action: PayloadAction<ProgramOnServer>) => {
      state.creativeCode = initialCodeContents.creative;
      state.program = action.payload;
    },
    setChallengeCode: (state, action: PayloadAction<string>) => {
      state.challengeCode = action.payload;
    },
    resetChallengeCode: (state) => {
      state.challengeCode = initialCodeContents.challenge;
    },
    setChallenge: (state, action: PayloadAction<ChallengeOnServer>) => {
      state.challenge = action.payload;
    },
    setChallengeLevels: (state, action: PayloadAction<ChallengePackLevel[]>) => {
      state.challengeLevels = action.payload;
    },
    setChallengeNodes: (state, action: PayloadAction<ChallengeNodeList>) => {
      state.challengeNodes = action.payload;
    },
    setChallengeEvaluation: (state, action: PayloadAction<ChallengeEvaluation>) => {
      state.lastChallengeEvaluation = action.payload;
    },
    resetChallenge: (state) => {
      state.challengeCode = '';
      state.challenge = undefined;
    },
    setCustomChallengeName: (state, action: PayloadAction<string>) => {
      state.customChallengeName = action.payload;
    },
    setCustomChallengeDesc: (state, action: PayloadAction<string>) => {
      state.customChallengeDesc = action.payload;
    },
    setProgram: (state, action: PayloadAction<ProgramOnServer>) => {
      state.program = action.payload;
    },
    setProgramName: (state, action: PayloadAction<string>) => {
      state.program = {
        ...state.program,
        name: action.payload,
      };
    },
    resetProgram: (state, action: PayloadAction<ProgramOnServer>) => {
      state.creativeCode = initialCodeContents.creative;
      state.program = action.payload;
    },
    resetProgramUserData: (state) => {
      state.program = {
        ...state.program,
        saveToken: undefined,
        userLogin: undefined,
      };
      state.creativeCode = '';
      state.challenge = undefined;
      state.challengeCode = '';
    },
    setHint: (state, action: PayloadAction<string>) => {
      state.hint = action.payload;
    },
    setIsHintLoading: (state, action: PayloadAction<boolean>) => {
      state.isHintLoading = action.payload;
    },
    setAskForHint: (state, action: PayloadAction<boolean>) => {
      state.askForHint = action.payload;
    },
  },
});

export const codeActions = codeSlice.actions;

export default codeSlice.reducer;
