import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Potion: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M18,7v7.11a17,17,0,1,0,12,0V7Z" className={styles.white} />
      <path
        d="M24,29a13.973,13.973,0,0,1-14.928-.416C9.028,29.05,9,29.522,9,30a15,15,0,0,0,30,0c0-.188-.021-.37-.028-.556A13.975,13.975,0,0,0,24,29Z"
        className={styles.darkGreen}
      />
      <circle cx="16" cy="25" className={styles.darkGreen} r="2" />
      <circle cx="30.5" cy="22.5" className={styles.darkGreen} r="1" />
      <circle cx="23.5" cy="18.5" className={styles.darkGreen} r="1.5" />
      <rect height="6" width="16" className={styles.yellow} rx="1" x="16" y="1" />
      <path d="M20,7h8a0,0,0,0,1,0,0v3a1,1,0,0,1-1,1H21a1,1,0,0,1-1-1V7A0,0,0,0,1,20,7Z" className={styles.yellow} />
    </g>
  </svg>
);

export default Potion;
