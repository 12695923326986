import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useApi from 'ui/components/hooks/useApi';
import Loader from 'ui/components/common/dumb/Loader';
import UserChallengeList from './UserChallengeList';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import styles from '../Challenges.module.scss';
import useListLoader from 'ui/components/hooks/useListLoader';

const MyChallenges: React.FC = () => {
  const { t } = useTranslation();
  const { getChallengeList } = useApi();

  const { loadPortion, items: challenges, moreAvailable, result } = useListLoader(getChallengeList);

  useEffect(() => {
    if (result.isIdle) loadPortion();
  }, [result, loadPortion]);

  return (
    <div className={styles.container}>
      <h1>{t('challenges:descriptions.heading.my_challenges')}</h1>
      {challenges.length > 0 && <UserChallengeList list={challenges} />}

      {challenges.length === 0 && result.isResolved && <p>{t('challenges:noChallengesCreated')}</p>}

      {result.isLoading && <Loader />}

      {moreAvailable && !result.isLoading && (
        <Button flavor={ButtonFlavor.Gray} noBorder onClick={loadPortion}>
          {t(result.isResolved ? 'user_activity:load_more' : 'user_activity:retry')}
        </Button>
      )}
    </div>
  );
};

export default MyChallenges;
