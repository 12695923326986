import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkButton, ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import PodiumTrophy from 'ui/components/svg/PodiumTrophy';

const AllChallengesButton: React.FC = () => {
  const { t } = useTranslation('challenges');
  return (
    <LinkButton flavor={ButtonFlavor.Gray} size={ButtonSize.Small} to="/user/challenges" Icon={PodiumTrophy}>
      {t('navigation:user.menu.challenges')}
    </LinkButton>
  );
};

export default AllChallengesButton;
