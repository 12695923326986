import { count } from '../util/stringUtil';

export function format(text: string, indentWith: string): string {
  let indentLevel = 0;
  return text
    .split('\n')
    .map((line: string) => {
      const l = line.trim();

      let brackets = [...l].filter((v) => v === '(' || v === ')').join('');
      while (brackets.includes('()')) {
        brackets = brackets.replace('()', '');
      }

      const closing = count(brackets, ')');
      const opening = count(brackets, '(');

      const thisLineIndent = Math.max(0, indentLevel - closing);
      const ll = indentWith.repeat(thisLineIndent) + l;

      indentLevel = Math.max(0, indentLevel + (opening - closing));

      return ll;
    })
    .join('\n');
}
