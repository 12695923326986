import Twitter from 'ui/components/svg/Twitter';
import { SHELLY_TWITTER_HREF } from 'ui/constants';
import styles from '../Footer.module.scss';

const SocialProfilesList: () => JSX.Element = () => {
  return (
    <div className={styles.socialProfilesList}>
      <a href={SHELLY_TWITTER_HREF} target="_blank" rel="noopener noreferrer" className={styles.socialLink}>
        <Twitter className={styles.socialIcon} />
      </a>
    </div>
  );
};

export default SocialProfilesList;
