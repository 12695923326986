import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { type RGBColor } from 'react-color';
import classnames from 'classnames';
import { StdFnNames } from 'turtle/StdFnName';
import 'src/i18n';
import ColorPicker from '../ColorPicker';
import { Color } from 'turtle/Color';
import { useZoneViewType } from 'ui/components/hooks/useZoneViewType';
import Brush from 'ui/components/svg/Brush';
import { useLocalColor } from '../useLocalColor';
import styles from './PenEditZone.module.scss';
import commonStyles from '../EditZone.module.scss';

interface Props {
  zoneNode: HTMLElement;
  currentColor?: Color;
  applyToCodeHandler: (color: Color) => void;
  callbackSetupFn: (node: HTMLElement) => void;
}

const PenEditZone: React.FC<Props> = ({ zoneNode, currentColor, applyToCodeHandler, callbackSetupFn }) => {
  const [viewType, toggle] = useZoneViewType();
  const [localColor, setLocalColor, onChange, onPredefinedColorClick] = useLocalColor(applyToCodeHandler);
  const { t } = useTranslation('editZone');

  useEffect(() => {
    if (viewType === 'main') {
      callbackSetupFn(zoneNode);
    }
  }, [viewType, callbackSetupFn, zoneNode]);

  useEffect(() => {
    if (currentColor) {
      setLocalColor({
        r: currentColor.r,
        g: currentColor.g,
        b: currentColor.b,
      });
    }
  }, [currentColor, setLocalColor]);

  const c = localColor as RGBColor;

  return (
    <>
      {viewType === 'main' ? (
        <>
          <div className={commonStyles.row}>
            <div className={commonStyles.color}>
              {Color.ALL_DEFAULT.map((c) => (
                <button
                  title={t('color', { color: t(c.name) })}
                  key={c.name}
                  className={commonStyles.colorEntry}
                  style={{ backgroundColor: c.hex }}
                  data-value={c.name}
                  onClick={onPredefinedColorClick}
                ></button>
              ))}
              <button
                style={{ backgroundColor: `rgb(${c.r}, ${c.g}, ${c.b})` }}
                className={commonStyles.customColorBtn}
                onClick={toggle}
                title={t('selectCustomColor')}
              >
                <Brush className={commonStyles.icon} />
              </button>
            </div>
          </div>
          <div className={commonStyles.row}>
            <div className={styles.pattern}>
              <button className={classnames(styles.patternBtn, styles.line)} data-value="none"></button>
              {StdFnNames.ALL_PATTERNS.map((p) => (
                <button
                  title={t('btnPattern', { pattern: t(p) })}
                  key={p}
                  className={classnames(styles.patternBtn, styles[p])}
                  data-value={p}
                ></button>
              ))}
            </div>
            <div className={styles.thickness}>
              {StdFnNames.ALL_THICKNESS.map((th) => (
                <button
                  title={t('thickness', { thickness: t(th) })}
                  key={th}
                  className={classnames(styles.thicknessEntry, styles[th])}
                  data-value={th}
                ></button>
              ))}
            </div>
          </div>
        </>
      ) : (
        <ColorPicker onChange={onChange} color={localColor} onGoBack={toggle} />
      )}
    </>
  );
};

export default PenEditZone;
