import React, { useState, useCallback, useEffect } from 'react';
import styles from './Footer.module.scss';
import CookiePopup from 'ui/components/common/smart/Footer/CookiePopup';
import useLocalStorage, { writeStorage } from '@rehooks/local-storage';
import { STORAGE_COOKIE_CONSENT_KEY } from 'ui/constants';
import CreatedBy from './CreatedBy';
import SocialProfilesList from './SocialProfilesList';

const Footer: React.FC = () => {
  const [cookieVisible, setCookieVisible] = useState<boolean>(false);
  const [cookieConsent] = useLocalStorage(STORAGE_COOKIE_CONSENT_KEY);

  const handleClick = useCallback(() => {
    setCookieVisible(false);
    writeStorage(STORAGE_COOKIE_CONSENT_KEY, true);
  }, [setCookieVisible]);

  useEffect(() => {
    if (!cookieConsent) {
      setCookieVisible(true);
    }
  }, [cookieConsent, setCookieVisible]);

  return (
    <footer className={styles.container}>
      <SocialProfilesList />
      <CreatedBy />
      {cookieVisible && <CookiePopup onClick={handleClick} />}
    </footer>
  );
};

export default Footer;
