import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../SocialShare.module.scss';

const ErrorWrapper: React.FC = () => {
  const { t } = useTranslation('errors');
  return <p className={styles.error}>{t('errors:error.unknown')}</p>;
};

export default ErrorWrapper;
