import { useCallback } from 'react';
import { type Action, type Dispatch } from '@reduxjs/toolkit';
import { HomeSubscreen } from './enums';
import { setHomeScreen as setHomeScreenAction } from './slice';

type UsehomeScreenStoreActions = {
  setHomeScreen: (homescreen: HomeSubscreen) => void;
};

export const useHomeScreenStoreActions = (dispatch: Dispatch<Action>): UsehomeScreenStoreActions => {
  const setHomeScreen = useCallback(
    (homescreen: HomeSubscreen) => {
      dispatch(setHomeScreenAction(homescreen));
    },
    [dispatch]
  );

  return { setHomeScreen };
};
