import { Color } from './Color';

export class StdFnNames {
  static REPEAT: StdFnName = 'repeat';

  static SQRT: StdFnName = 'sqrt';
  static SIN: StdFnName = 'sin';
  static COS: StdFnName = 'cos';
  static TAN: StdFnName = 'tan';
  static PI: StdFnName = 'pi';
  static E: StdFnName = 'e';
  static ROUND: StdFnName = 'round';
  static FLOOR: StdFnName = 'floor';
  static CEIL: StdFnName = 'ceil';
  static POW: StdFnName = 'pow';
  static MIN: StdFnName = 'min';
  static MAX: StdFnName = 'max';
  static ABS: StdFnName = 'abs';
  static LN: StdFnName = 'ln';
  static RGB: StdFnName = 'rgb';

  // thickness, opacities
  static THIN: StdFnName = 'thin';
  static MEDIUM: StdFnName = 'medium';
  static THICK: StdFnName = 'thick';
  static FULL: StdFnName = 'full';
  static LOW: StdFnName = 'low';

  // patterns
  static HEARTS: StdFnName = 'hearts';
  static HOOKS: StdFnName = 'hooks';
  static CLOVERS: StdFnName = 'clovers';
  static LEAFS: StdFnName = 'leafs';
  static TREES: StdFnName = 'trees';
  static MAPLE: StdFnName = 'maple';
  static STARS: StdFnName = 'stars';
  static CARS: StdFnName = 'cars';
  static CRABS: StdFnName = 'crabs';
  static FISHES: StdFnName = 'fishes';
  static CIRCLES: StdFnName = 'circles';

  // + colors

  // none
  static NONE: StdFnName = 'none';

  // all

  static ALL_PATTERNS: StdFnName[] = [
    StdFnNames.CARS,
    StdFnNames.CIRCLES,
    StdFnNames.CLOVERS,
    StdFnNames.CRABS,
    StdFnNames.FISHES,
    StdFnNames.HEARTS,
    StdFnNames.HOOKS,
    StdFnNames.LEAFS,
    StdFnNames.TREES,
    StdFnNames.MAPLE,
    StdFnNames.STARS,
    StdFnNames.NONE,
  ];

  static ALL_THICKNESS: StdFnName[] = [StdFnNames.THIN, StdFnNames.MEDIUM, StdFnNames.THICK];

  static ALL_OPACITIES: StdFnName[] = [StdFnNames.FULL, StdFnNames.MEDIUM, StdFnNames.LOW];

  static ALL_COLORS: StdFnName[] = Color.ALL_NAMES;

  static ALL: StdFnName[] = [
    StdFnNames.REPEAT,
    StdFnNames.SQRT,
    StdFnNames.SIN,
    StdFnNames.COS,
    StdFnNames.TAN,
    StdFnNames.PI,
    StdFnNames.E,
    StdFnNames.ROUND,
    StdFnNames.FLOOR,
    StdFnNames.CEIL,
    StdFnNames.POW,
    StdFnNames.MIN,
    StdFnNames.MAX,
    StdFnNames.ABS,
    StdFnNames.LN,
    StdFnNames.RGB,
    StdFnNames.THIN,
    StdFnNames.MEDIUM,
    StdFnNames.THICK,
    StdFnNames.FULL,
    StdFnNames.LOW,
    ...StdFnNames.ALL_PATTERNS,
    ...StdFnNames.ALL_COLORS,
  ];
}

export type StdFnName = string;
