import React, { useCallback, useEffect, useState } from 'react';
import { writeStorage } from '@rehooks/local-storage';
import confetti from 'canvas-confetti';
import Loader from 'ui/components/common/dumb/Loader';
import styles from './ChallengeSolveResult.module.scss';
import { useTranslation } from 'react-i18next';
import Button, { LinkButton, ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import { getNextChallenge } from 'ui/store/code/helpers';
import ChallengeEvaluationList from './ChallengeEvaluationList';
import { pathForChallenge } from 'ui/components/routing/programHelpers';
import Congratulations from 'ui/components/modals/Congratulations';
import { ChallengeNodeList, ChallengeState } from 'types/challenge';
import { USER_TOKEN_STORAGE_KEY } from 'ui/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useHomeScreenStoreActions } from 'ui/store/homescreen/useHomeScreenStoreActions';
import { HomeSubscreen } from 'ui/store/homescreen/enums';
import { getTestSelector } from 'util/testUtil';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';

const selector = createCodeStatePropSelector('lastChallengeEvaluation', 'challenge', 'challengeNodes');

const ChallengeSolveResult: React.FC = () => {
  const dispatch = useDispatch();
  const { lastChallengeEvaluation, challenge, challengeNodes } = useSelector(selector);
  const { resetChallengeCode, setAskForHint, setHint } = useCodeStoreActions(dispatch);
  const { setHomeScreen } = useHomeScreenStoreActions(dispatch);
  const { t } = useTranslation('challenges');
  const isSolved = lastChallengeEvaluation?.stars && lastChallengeEvaluation.stars > 0;
  const nextChallenge = challenge && getNextChallenge(challenge, challengeNodes);

  useEffect(() => {
    if (lastChallengeEvaluation && isSolved) {
      confetti({
        particleCount: 500,
        spread: 120,
        startVelocity: 30,
        zIndex: 1000,
        origin: {
          x: 0.5,
          y: 0.25,
        },
      });
    }

    setHint('');
    setAskForHint(false);
  }, [lastChallengeEvaluation, isSolved]);

  const [accomplishedInfo, setAccomplishedInfo] = useState(false);

  useEffect(() => {
    const userToken = lastChallengeEvaluation?.userToken;

    if (!userToken) return;

    writeStorage(USER_TOKEN_STORAGE_KEY, userToken);
  }, [lastChallengeEvaluation]);

  useEffect(() => {
    const isAccomplished = Object.values(challengeNodes as ChallengeNodeList).every(
      ({ state }) => state === ChallengeState.Done
    );
    setAccomplishedInfo(isAccomplished);
  }, [setAccomplishedInfo, challengeNodes]);

  const closeAccomplishedInfo = useCallback(() => setAccomplishedInfo(false), [setAccomplishedInfo]);

  const clickHandler = useCallback(
    (withReset?: boolean) => {
      if (withReset) resetChallengeCode();
      setHomeScreen(HomeSubscreen.Challenges);
    },
    [setHomeScreen, resetChallengeCode]
  );

  const onTryAgainClick = useCallback(() => {
    clickHandler();
  }, [clickHandler]);

  const onNextChallengeClick = useCallback(() => {
    clickHandler(true);
  }, [clickHandler]);

  if (lastChallengeEvaluation) {
    return (
      <div className={styles.container} data-testid={getTestSelector('challenge.solve.result')}>
        <div className={styles.content}>
          {lastChallengeEvaluation.stars > 0 && (
            <h1 className={styles.heading} data-testid={getTestSelector('challenge.solve.heading')}>
              {t('challenge.solve.heading', {
                result: lastChallengeEvaluation.stars,
              })}
            </h1>
          )}
          <ChallengeEvaluationList evaluation={lastChallengeEvaluation} />
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={onTryAgainClick}
            size={ButtonSize.Small}
            data-testid={getTestSelector('challenge.solve.try_again')}
          >
            {t('challenge.solve.try_again')}
          </Button>
          <LinkButton
            onClick={onNextChallengeClick}
            to={pathForChallenge(nextChallenge?.id, nextChallenge?.userLogin)}
            size={ButtonSize.Small}
            flavor={ButtonFlavor.Success}
            disabled={!isSolved || !nextChallenge}
            id="challenge-solve-next-challenge"
          >
            {t('challenge.solve.next_challenge')}
          </LinkButton>
        </div>
        {accomplishedInfo && <Congratulations onClose={closeAccomplishedInfo} />}
      </div>
    );
  }
  return <Loader overlay />;
};

export default ChallengeSolveResult;
