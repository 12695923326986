import React from 'react';
import classnames from 'classnames';
import styles from './IconRadioGroup.module.scss';
import { IconComponent } from 'types/icon';
import Button, { ButtonSize, ButtonFlavor } from 'ui/components/common/dumb/Button';
import { getTestSelector } from 'util/testUtil';

export interface RadioIconProps {
  id: string;
  icon: IconComponent;
  tooltipText: string;
  onClick: (id: string) => void;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  activeItem?: string;
  className?: string;
  vertical?: boolean;
  radioIcons: RadioIconProps[];
}

const IconRadioGroup: React.FC<Props> = ({
  activeItem,
  className,
  vertical = false,
  radioIcons,
  ...htmlAttributes
}) => {
  const createClickHandler = (id: string, onClick: (i: string) => void) => (): void => onClick(id);

  return (
    <div
      className={classnames(styles.container, className, {
        [styles.vertical]: vertical,
      })}
      {...htmlAttributes}
    >
      {radioIcons.map((radioIcon) => {
        const Icon = radioIcon.icon;
        const isActive = activeItem && activeItem === radioIcon.id;
        const onClick =
          !isActive && radioIcon.onClick ? createClickHandler(radioIcon.id, radioIcon.onClick) : undefined;

        return (
          <Button
            className={classnames(styles.btn, { [styles.active]: isActive })}
            id={radioIcon.id}
            data-testid={getTestSelector(`navigation.${radioIcon.id}`)}
            onClick={onClick}
            key={radioIcon.id}
            title={radioIcon.tooltipText}
            Icon={Icon}
            size={ButtonSize.Small}
            flavor={isActive ? ButtonFlavor.Current : ButtonFlavor.Default}
            noBorder
            bigIcon
          />
        );
      })}
    </div>
  );
};

export default IconRadioGroup;
