import * as monaco from 'monaco-editor';
import { TERRAPIN_ID } from './terrapinSetup';

export const modelOptions = {
  language: TERRAPIN_ID,
  value: '',
};

export function editorOptions(model: monaco.editor.ITextModel): monaco.editor.IStandaloneEditorConstructionOptions {
  return {
    model: model,
    glyphMargin: false,
    language: TERRAPIN_ID,
    minimap: {
      enabled: false,
    },
    hover: {
      enabled: false,
    },
    scrollBeyondLastLine: false,
    formatOnType: false,
    formatOnPaste: false,
    autoIndent: 'advanced',
    codeLens: false,
    fontSize: 14,
    fontFamily: 'Fira Code',
    automaticLayout: true,
  };
}

declare let self: any;

export function editorSetup(): void {
  monaco.editor.defineTheme('shelly', {
    base: 'vs-dark',
    inherit: true,
    rules: [
      { token: '', foreground: 'ffffff' },
      { token: 'keyword', foreground: '20eeb0' },
      { token: 'variable.predefined', foreground: 'ffffff' },
      { token: 'number', foreground: '9ebdfa' },
      { token: 'delimiter', foreground: 'b3fad4' },
      { token: 'operator', foreground: 'b3fad4' },
    ],
    colors: {
      'editor.background': '#353540',
      'editor.selectionBackground': '#4a4a5a',
      'editor.lineHighlightBackground': '#4a4a5a',
    },
  });
  monaco.editor.setTheme('shelly');
  self.MonacoEnvironment = {
    getWorker: () => {
      return new Worker(new URL('../interpret/worker/Worker', import.meta.url));
    },
  };
}
