import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from './ModalBox.module.scss';
import LanguageSwitch from 'ui/components/common/smart/LanguageSwitch';
import Close from 'ui/components/svg/Close';
import Logo from 'ui/components/svg/Logo';

export type ModalBoxProps = PropsWithChildren<{
  onClose: (event: React.MouseEvent) => void;
  heading: string;
  showLogo?: boolean;
  showLang?: boolean;
  className?: string;
  'data-testid'?: string;
}>;

const ModalBox: React.FC<ModalBoxProps> = ({
  children,
  onClose,
  showLogo,
  showLang = true,
  heading,
  className,
  ...props
}) => {
  const stopPropagation = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <div className={classnames(styles.container, className)} onClick={stopPropagation} {...props}>
      {showLang && <LanguageSwitch className={styles.languageSwitch} />}
      <button className={styles.transparent} onClick={onClose} id="close" aria-label="Close">
        <Close className={styles.close} width={18} />
      </button>
      {showLogo && <Logo className={styles.logo} width={78} />}
      <h1 className={styles.title}>{heading}</h1>
      {children}
    </div>
  );
};

export default ModalBox;
