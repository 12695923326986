import { DATE_TIME_FORMAT_OPTIONS } from 'ui/constants';

export function formatDate(dateString: string): string {
  const intl = new Intl.DateTimeFormat(window.navigator.language, DATE_TIME_FORMAT_OPTIONS);
  const parsedString = Date.parse(dateString);
  return intl.format(new Date(parsedString));
}

export function getCurrentDate(): string {
  return new Date().toISOString();
}
