import { useCallback } from 'react';
import { type Action, type Dispatch } from 'redux';
import { actionCreators } from './slice';

type UsePageTourStoreActions = {
  setTourVisibility: (val: boolean) => void;
  setTourIntroVisibility: (val: boolean) => void;
};

export const usePageTourStoreActions = (dispatch: Dispatch<Action>): UsePageTourStoreActions => {
  const setTourVisibility = useCallback(
    (val: boolean) => {
      dispatch(actionCreators.setTourVisibility(val));
    },
    [dispatch]
  );

  const setTourIntroVisibility = useCallback(
    (val: boolean) => {
      dispatch(actionCreators.setTourIntroVisibility(val));
    },
    [dispatch]
  );

  return {
    setTourVisibility,
    setTourIntroVisibility,
  };
};
