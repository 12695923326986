import React from 'react';
import { useTranslation } from 'react-i18next';
import { pathForChallengeImage } from 'ui/components/routing/programHelpers';
import { ImageType } from 'types/image';
import styles from './ChallengePattern.module.scss';
import { challengeSelector } from 'ui/store/code/selectors';
import { useSelector } from 'react-redux';
import { createCanvasStatePropSelector } from 'ui/store/canvas/selectors';

const canvasPropSelector = createCanvasStatePropSelector('canvasZoomAndPos');

const ChallengePattern: React.FC = () => {
  const { t } = useTranslation('challenges');
  const { canvasZoomAndPos } = useSelector(canvasPropSelector);
  const { challenge } = useSelector(challengeSelector);

  if (!challenge) return null;

  const { id } = challenge;
  const zoomedPosition = {
    x: canvasZoomAndPos.pos.x * canvasZoomAndPos.zoom,
    y: canvasZoomAndPos.pos.y * canvasZoomAndPos.zoom,
  };

  return (
    <div className={styles.container}>
      <img
        key={id}
        src={pathForChallengeImage(id, ImageType.Original)}
        className={styles.image}
        alt={t('descriptions.alt')}
        style={{
          transform: `translate(${-1 * zoomedPosition.x}px, ${-zoomedPosition.y}px) scale(${canvasZoomAndPos.zoom})`,
        }}
      />
    </div>
  );
};

export default ChallengePattern;
