import React from 'react';
import styles from './MyProfile.module.scss';
import ProfileDetails from './ProfileDetails';
import PasswordDetails from './PasswordDetails';
import inMainScreen from 'ui/components/hocs/inMainScreen';

const MyProfile: React.FC = () => (
  <div className={styles.container}>
    <ProfileDetails />
    <PasswordDetails />
  </div>
);

export default inMainScreen(MyProfile);
