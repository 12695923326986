import React from 'react';
import classnames from 'classnames';
import styles from './TooltipWrapper.module.scss';
import { Direction } from '../types';
import useRect from 'ui/components/hooks/useRect';
import TooltipCard from '../TooltipCard';
import { convertRemToPixels } from 'util/remUtil';

const TOOLTIP_DISTANCE = 0.85;

interface Props extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  fillParent?: boolean;
  permanent?: boolean;
  tooltip?: React.ReactNode;
  tooltipDistance?: number;
}

const TooltipWrapper: React.FC<Props> = ({
  children,
  className,
  fillParent = false,
  permanent = false,
  tooltip,
  tooltipDistance = TOOLTIP_DISTANCE,
  ...props
}) => {
  const [rect, ref] = useRect();

  const tooltipDirection = React.useMemo(() => {
    if (!rect) return Direction.Bottom;

    const { innerWidth, innerHeight } = window;
    const { right, left, top } = rect;
    const horizontalArea = 100;
    const verticalBreakpoint = innerHeight / 2;

    if (left < horizontalArea) return Direction.Right;
    if (innerWidth - right < horizontalArea) return Direction.Left;
    if (top > verticalBreakpoint) return Direction.Top;
    return Direction.Bottom;
  }, [rect]);

  return (
    <div className={classnames(styles.wrapper, className, { [styles.fill_parent]: fillParent })} {...props} ref={ref}>
      {tooltip ? (
        <TooltipCard
          className={classnames(styles.tooltip, { [styles.permanent]: permanent })}
          direction={tooltipDirection}
          distance={tooltipDistance ? convertRemToPixels(tooltipDistance) : undefined}
        >
          {tooltip}
        </TooltipCard>
      ) : null}
      {children}
    </div>
  );
};

export default TooltipWrapper;
