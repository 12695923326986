import { IconComponent } from 'types/icon';

const Logo: IconComponent = (props) => (
  <svg viewBox="0 0 57 43" fill="none" {...props}>
    <path
      d="M33.902 23.141s-1.281 8.738-7.599 10.424c-.383.098-.678-.329-.416-.613 1.292-1.424 3.548-4.478 2.825-7.96l5.19-1.85zM7.373 29.974s-.066 7.346 6.35 10.061c.394.165.8-.547.57-.832-1.14-1.423-2.968-4.675-1.643-7.335l-5.277-1.894z"
      fill="#1B1B22"
    />
    <path
      d="M56.468 10.287c-5.453-16.751-24.493 8.059-24.493 8.059s-16.872-3.143-21.47 1.456c-3.493 3.493-8.3 10.916-8.3 10.916s.985 8.967 9 11.157c.131.033.493-.033.35-.493-.525-1.73-2.835-6.449-2.452-8.868 0 0 15.755-2.738 24.58-8.256-.055 5.135-3.186 9.471-4.993 11.54-.383.438.033 1.117.602.964 8.968-2.387 11.847-13.96 12.504-17.343.055-.033-.11-1.533 1.139-2.07 8.496-3.744 10.806-4.456 12.898-5.507.558-.306.832-.952.635-1.555z"
      fill="#F2F0D9"
      stroke="#916F5D"
      strokeWidth=".565"
      strokeMiterlimit="10"
    />
    <path
      d="M40.975 9.587c.11-.23.153-.8-.011-.997-1.752-2.014-12.12-12.744-26.398-4.543-14.058 8.09-13.74 22.86-13.5 25.96a.65.65 0 00.624.601c4.062.11 29.037.592 39.285-21.021z"
      fill="#16AC8F"
      stroke="#12491D"
      strokeWidth=".565"
      strokeMiterlimit="10"
    />
    <path
      d="M34.614 4.649c-4.654-3.011-11.705-5.113-20.146.285C6.957 9.74 3.628 16.704 2.194 22.605c3.022-.493 23.682-4.358 32.42-17.956z"
      fill="#3AD8BA"
    />
    <path
      d="M39.486 8.01a25.123 25.123 0 00-1.796-1.423C27.98 20.83 5.391 24.927 1.931 25.474a31.354 31.354 0 00-.285 3.186c4.303-.734 27.603-5.332 37.84-20.65z"
      fill="#3AD8BA"
    />
    <path d="M41.062 16.583s1.019 1.413.723 2.825l-.723-2.825z" fill="#F2F0D9" />
    <path d="M41.062 16.583s1.019 1.413.723 2.825" stroke="#916F5D" strokeWidth=".565" strokeMiterlimit="10" />
    <path d="M9.103 32.503s.23-1.15 0-1.369v1.369z" fill="#F2F0D9" />
    <path d="M9.103 32.503s.23-1.15 0-1.369" stroke="#916F5D" strokeWidth=".565" strokeMiterlimit="10" />
    <path d="M33.672 24.247s.285-1.544 0-1.872v1.872z" fill="#F2F0D9" />
    <path d="M33.672 24.247s.285-1.544 0-1.872" stroke="#916F5D" strokeWidth=".565" strokeMiterlimit="10" />
    <path d="M50.697 8.744a.876.876 0 100-1.752.876.876 0 000 1.752z" fill="#604B3B" />
    <path d="M1.012 27.17s26.65-3.853 37.083-20.945" stroke="#12491D" strokeWidth=".565" strokeMiterlimit="10" />
    <path d="M1.296 23.952s25.401-3.526 35.354-19.15" stroke="#12491D" strokeWidth=".528" strokeMiterlimit="10" />
    <path d="M2.194 20.273s23.78-3.011 33.098-16.347" stroke="#12491D" strokeWidth=".472" strokeMiterlimit="10" />
  </svg>
);

export default Logo;
