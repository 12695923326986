import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const ZoomIn: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M40,45c-0.25586,0-0.51172-0.09766-0.70703-0.29297l-11-11c-0.38965-0.38965-0.39062-1.021-0.00293-1.41162 l3.98633-4.01416c0.1875-0.18848,0.44238-0.29492,0.70801-0.29541c0.00098,0,0.00098,0,0.00195,0 c0.26562,0,0.51953,0.10547,0.70703,0.29297l11.01367,11.01416c0.39062,0.39062,0.39062,1.02344,0,1.41406l-4,4 C40.51172,44.90234,40.25586,45,40,45z"
        className={styles.yellow}
      />
      <circle cx="20" cy="20" className={styles.white} r="18" />
      <path
        d="M20,39C9.52344,39,1,30.47656,1,20S9.52344,1,20,1s19,8.52344,19,19S30.47656,39,20,39z M20,3 C10.62598,3,3,10.62598,3,20s7.62598,17,17,17s17-7.62598,17-17S29.37402,3,20,3z"
        className={styles.black}
      />
      <path
        d="M26,19h-5v-5c0-0.55225-0.44775-1-1-1s-1,0.44775-1,1v5h-5c-0.55225,0-1,0.44775-1,1s0.44775,1,1,1h5v5 c0,0.55225,0.44775,1,1,1s1-0.44775,1-1v-5h5c0.55225,0,1-0.44775,1-1S26.55225,19,26,19z"
        className={styles.black}
      />
    </g>
  </svg>
);

export default ZoomIn;
