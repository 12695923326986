import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Bulb from 'ui/components/svg/Bulb';
import Close from 'ui/components/svg/Close';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';
import Button, { ButtonSize } from 'ui/components/common/dumb/Button';
import styles from './HintBox.module.scss';

type Props = {
  hint: string;
};

const HintBox: React.FC<Props> = ({ hint }) => {
  const { t } = useTranslation('user_activity');
  const dispatch = useDispatch();
  const { setHint } = useCodeStoreActions(dispatch);

  const handleClose = useCallback(() => {
    setHint('');
  }, []);

  return (
    <div className={styles.container} data-testid="hint.box">
      <Button
        Icon={Close}
        size={ButtonSize.Small}
        onClick={handleClose}
        noBorder
        className={styles.close}
        aria-label="Close hint"
      />
      <div className={styles.heading}>
        <Bulb className={styles.icon} />
        <h4 className={styles.h4}>{t('hint')}</h4>
      </div>
      <p className={styles.info} data-testid="hint.content">
        {hint}
      </p>
    </div>
  );
};

export default HintBox;
