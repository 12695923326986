import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

const modalRoot = document.getElementById('modal_root');

const ModalPortal = ({ children }: { children: ReactNode }) => {
  if (!modalRoot) return null;

  return createPortal(children, modalRoot);
};

export default ModalPortal;
