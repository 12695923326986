import React from 'react';
import styles from './TooltipNavigation.module.scss';
import Button, { ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import ChevronLeft from 'ui/components/svg/ChevronLeft';
import ChevronRight from 'ui/components/svg/ChevronRight';
import { getTestSelector } from 'util/testUtil';

interface Props {
  index: number;
  onPrevClick: () => void;
  onNextClick: () => void;
}

const TooltipNavigation: React.FC<Props> = ({ index, onPrevClick, onNextClick }) => (
  <div className={styles.container}>
    <Button
      size={ButtonSize.Small}
      onClick={onPrevClick}
      disabled={index === 0}
      Icon={ChevronLeft}
      data-testid={getTestSelector('pagetour.prev_step')}
    />
    <Button
      size={ButtonSize.Small}
      flavor={ButtonFlavor.Success}
      onClick={onNextClick}
      Icon={ChevronRight}
      data-testid={getTestSelector('pagetour.next_step')}
    />
  </div>
);
export default TooltipNavigation;
