import React from 'react';
import styles from './EditorCodePrefix.module.scss';
import { PageTourStep } from 'ui/components/common/smart/PageTour';

type Props = {
  code: string;
};

const EditorPrefixCode: React.FC<Props> = ({ code }: Props) => (
  <div className={styles.container} data-tour-area={PageTourStep.AnimationPrefix}>
    <span className={styles.content}>{code}</span>
  </div>
);

export default EditorPrefixCode;
