import React from 'react';
import { NavLink } from 'react-router-dom';
import Button, { ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import styles from './CookiePopup.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
}

const CookiePopup: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation('cookies');
  return (
    <div
      className={styles.container}
      role="dialog"
      aria-live="polite"
      aria-label="cookieconsent"
      aria-describedby="cookieconsent:desc"
    >
      <p className={styles.text} id="cookieconsent:desc">
        {t('weUse')}
        <NavLink className={styles.link} to="/cookie-policy">
          {t('cookiesPolicy')}
        </NavLink>
      </p>
      <Button flavor={ButtonFlavor.Gray} size={ButtonSize.Small} className={styles.btn} onClick={onClick}>
        {t('agreeAndContinue')}
      </Button>
    </div>
  );
};

export default CookiePopup;
