import React from 'react';
import Context from './Context';
import actions from './actions';
import getInset from './getInset';

type UseUpdateInset = () => void;

const useUpdateInset: UseUpdateInset = () => {
  const { state, dispatch } = React.useContext(Context);
  const { step, requestedStepIndex } = state;

  const setInset = React.useCallback(() => {
    const elementInset = step ? getInset(step.area) : null;
    actions(dispatch).set({ elementInset });
  }, [dispatch, step?.area]);

  React.useEffect(() => {
    setInset();
  }, [setInset]);

  React.useEffect(() => {
    return () => {
      actions(dispatch).set({ elementInset: null });
    };
  }, [dispatch, requestedStepIndex]);

  React.useEffect(() => {
    window.addEventListener('resize', setInset);
    return () => {
      window.removeEventListener('resize', setInset);
    };
  }, [setInset]);
};

export default useUpdateInset;
