import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { usePromise } from 'react-use-promise-matcher';
import classnames from 'classnames';
import PromptBox from 'ui/components/common/dumb/PromptBox';
import Form from 'ui/components/common/dumb/Form';
import Input from 'ui/components/common/dumb/Input';
import Button, { ButtonFlavor } from 'ui/components/common/dumb/Button';
import FormError from 'ui/components/common/dumb/FormError';
import useApi from 'ui/components/hooks/useApi';
import Loader from 'ui/components/common/dumb/Loader';
import inMainScreen from 'ui/components/hocs/inMainScreen';
import styles from './RecoverPasswordForm.module.scss';

const RecoverPasswordForm: React.FC = () => {
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });
  const { t } = useTranslation('passwordDetails');
  const { claimPasswordReset } = useApi();
  const { errors } = formState;

  const submitHandler = (payload: Record<string, any>) => claimPasswordReset(payload.loginOrEmail);

  const [result, load] = usePromise(submitHandler);

  const renderForm = useCallback(() => {
    return (
      <Form onSubmit={handleSubmit(load)}>
        <Input
          type="text"
          placeholder={t('loginOrEmail')}
          {...register('loginOrEmail', {
            required: t('loginOrEmailRequired') as string,
          })}
        />
        <FormError errors={errors} name="loginOrEmail" />
        <Button flavor={ButtonFlavor.Success} disabled={!formState.isValid} className={styles.btn}>
          {t('recoverPassword')}
        </Button>
      </Form>
    );
  }, [handleSubmit, load, register, errors, formState, t]);

  return (
    <PromptBox>
      <PromptBox.Row>
        <PromptBox.Heading>{t('recoverPassword')}</PromptBox.Heading>
      </PromptBox.Row>
      <PromptBox.Row className={styles.row}>
        {result.match({
          Idle: renderForm,
          Loading: () => <Loader />,
          Rejected: (err: any) => (
            <>
              {renderForm()}
              <p className={classnames(styles.message, styles.error)}>{err.toString()}</p>
            </>
          ),
          Resolved: () => (
            <>
              {renderForm()}
              <p className={classnames(styles.message, styles.success)}>{t('passwordResetSent')}</p>
            </>
          ),
        })}
      </PromptBox.Row>
      {result.isResolved && (
        <PromptBox.Row>
          <NavLink className={classnames(styles.btn, styles.success)} to="/user/login">
            {t('login')}
          </NavLink>
        </PromptBox.Row>
      )}
    </PromptBox>
  );
};

export default inMainScreen(RecoverPasswordForm);
