import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import { editor, Range, Selection } from 'monaco-editor';
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;
import { FillAttrNames, PenAttrNames } from 'turtle/attrs';
import { InstrNames } from 'turtle/InstrNames';
import { currentStmt } from 'util/editorUtil';
import { getColorFromStmtValue, PositionedStmt } from 'util/stringUtil';
import { StdFnNames } from 'turtle/StdFnName';
import { Color } from 'turtle/Color';
import { Attr } from 'ui/components/turtle/zone/Attr';
import EditZone from 'ui/components/common/smart/EditZone/EditZone';

export class AttrEditZone {
  private readonly editor: IStandaloneCodeEditor;
  private stmt: PositionedStmt;
  private readonly attrs: Attr[];

  readonly attributable: string;
  private readonly attrNames: string[];

  private zoneId: string | undefined;

  private constructor(
    editor: editor.IStandaloneCodeEditor,
    stmt: PositionedStmt,
    attrs: Attr[],
    attributable: string,
    attrNames: string[]
  ) {
    this.editor = editor;
    this.stmt = stmt;
    this.attrs = attrs;

    this.attributable = attributable;
    this.attrNames = attrNames;
  }

  async addToEditor(): Promise<void> {
    const self = this;
    const isPenAttributable: boolean = this.attributable === 'pen';
    const editZoneType = isPenAttributable ? 'pen' : 'fill';
    const zoneHeight: number = isPenAttributable ? 110 : 100;
    const zoneNode: HTMLElement = <HTMLElement>(
      document.getElementById(`${this.attributable}_edit_zone`)!.cloneNode(true)
    );
    const zoneHandler = (color: Color) => {
      this.setAttr('color', Color.ATTR_VALUE(color));
    };
    const currentColor: Color | undefined = getColorFromStmtValue(this.stmt.value);
    const lineNumber = this.getLineNumber();
    const editorHeight = this.editor.getLayoutInfo().height;
    const zone: editor.IViewZone = {
      afterLineNumber: lineNumber,
      domNode: zoneNode,
      heightInPx: zoneHeight,
      suppressMouseDown: false,
      onDomNodeTop: (top: number) => {
        // The reason behind 'top < 0' condition:
        // there is a bug in the onDomNode method probably,
        // in that it logs -10000 top value when typing 'pen' second and next time,
        // which makes it impossible to show the edit zone and scroll a few lines down.
        if ((top > editorHeight - zoneHeight || top < 0) && !firstScroll) {
          // setTimeout fixes a bug with not displaying the AttrEditZone
          // after pressing Enter and typing "pen:" instruction on the next line
          // ¯\_(ツ)_/¯
          setTimeout(() => {
            self.editor.setScrollTop(self.editor.getScrollTop() + 120);
          }, 0);
          firstScroll = true;
        }
      },
    };
    let firstScroll = false;

    zoneNode.removeAttribute('id');
    zoneNode.removeAttribute('style'); // show

    createRoot(zoneNode).render(
      createElement(EditZone, {
        type: editZoneType,
        applyToCodeHandler: zoneHandler,
        zoneNode,
        callbackSetupFn: self.setupCallbacks.bind(self),
        currentColor,
      })
    );
    this.editor.changeViewZones((a) => {
      self.zoneId = a.addZone(zone);
      self.setupCallbacks(zoneNode);
    });
  }

  private setupCallbacks(node: HTMLElement) {
    this.attrNames.forEach((attrName) => {
      let list: string[];
      switch (attrName) {
        case PenAttrNames.COLOR:
          list = Color.ALL_NAMES;
          break;
        case PenAttrNames.THICKNESS:
          list = StdFnNames.ALL_THICKNESS;
          break;
        case PenAttrNames.PATTERN:
          list = StdFnNames.ALL_PATTERNS;
          break;
        case FillAttrNames.OPACITY:
          list = StdFnNames.ALL_OPACITIES;
          break;
        default:
          list = [];
          break;
      }
      list.forEach((item) => {
        const subnode = node.querySelector(`[data-value="${item}"]`);
        subnode?.addEventListener('click', (e) => {
          const value = (<HTMLElement>e.target)!.dataset.value;
          if (value) {
            this.setAttr(attrName, value);
          }
        });
      });
    });
  }

  setAttr(k: string, v: string): void {
    this.updateAttrs(k, v);
    this.updateStmtInEditor();
    this.editor.focus();
    this.stmt = currentStmt(this.editor);
  }

  private updateAttrs(k: string, v: string) {
    const attr = new Attr(k, v);
    const existing = this.attrs.findIndex((e) => e.key.toLowerCase() === k);
    if (existing >= 0) {
      this.attrs[existing] = attr;
    } else {
      this.attrs.unshift(attr);
    }
  }

  private updateStmtInEditor() {
    const lineNumber = this.getLineNumber();

    const newLine = this.attributable + ' ' + this.attrs.map((a) => a.toString()).join(' ');
    // placing the cursor at the end of the updated statement
    const newEndColumn = this.stmt.startColumn + newLine.length;
    this.editor.executeEdits(
      'self',
      [
        {
          range: new Range(lineNumber, this.stmt.startColumn, lineNumber, this.stmt.endColumn),
          text: newLine,
        },
      ],
      [new Selection(lineNumber, newEndColumn, lineNumber, newEndColumn)]
    );
  }

  private getLineNumber(): number {
    const currentPosition = this.editor.getPosition();
    return currentPosition ? currentPosition.lineNumber : 1;
  }

  removeFromEditor(): void {
    if (this.zoneId) {
      const zid = this.zoneId;
      this.editor.changeViewZones((a) => {
        a.removeZone(zid);
      });
    }
  }

  static CREATE(editor: editor.IStandaloneCodeEditor, stmt: PositionedStmt): AttrEditZone | undefined {
    const attrData = AttrEditZone.isAttributable(stmt.value);
    if (attrData !== undefined) {
      const attributes = Attr.PARSE(stmt.value);
      const zone = new AttrEditZone(editor, stmt, attributes, attrData[0], attrData[1]);
      zone.addToEditor();
      return zone;
    } else {
      return undefined;
    }
  }

  private static isAttributable(v: string): [string, string[]] | undefined {
    const s = v.toLowerCase();
    const isPen = s.startsWith(InstrNames.Attributable.PEN);
    const isFill = s.startsWith(InstrNames.Attributable.FILL);
    const index = isPen ? 3 : 4;
    const hasEmptySpaceOrNothing: boolean = (s[index] && s[index] === ' ') || !s[index];
    if (isPen && hasEmptySpaceOrNothing) {
      return [InstrNames.Attributable.PEN, PenAttrNames.ALL];
    }
    if (isFill && hasEmptySpaceOrNothing) {
      return [InstrNames.Attributable.FILL, FillAttrNames.ALL];
    }
    return undefined;
  }
}
