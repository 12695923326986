import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Padlock: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <g>
      <path
        className={styles.gray}
        d="M17,26V12c0-3.85986,3.14014-7,7-7s7,3.14014,7,7v14h4V12c0-6.06543-4.93457-11-11-11S13,5.93457,13,12v14 H17z"
      />{' '}
      <path
        className={styles.yellow}
        d="M24,47c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15S32.271,47,24,47z"
      />{' '}
      <path
        className={styles.black}
        d="M28,29c0-2.20917-1.79083-4-4-4c-2.20911,0-4,1.79083-4,4c0,1.47681,0.80945,2.75232,2,3.44519V39h4 v-6.55481C27.19061,31.75226,28,30.47681,28,29z"
      />
    </g>
  </svg>
);

export default Padlock;
