import React from 'react';
import Routing from 'ui/components/routing';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import '../theme/_normalize.scss';
import '../theme/_typography.scss';
import '../theme/_responsive.scss';
import './styles/index.scss';
import toastStyles from './styles/toast.scss';
import ChallengeDataLoader from './components/common/smart/ChallengeDataLoader';
import store from './store/store';

const App: React.FC = () => (
  <Provider store={store}>
    <ChallengeDataLoader>
      <ToastContainer position="top-right" className={toastStyles.container} toastClassName={toastStyles.toast} />
      <Router>
        <Routing />
      </Router>
    </ChallengeDataLoader>
  </Provider>
);

export default App;
