import { Dispatch } from 'react';
import { ValuesType } from 'utility-types';
import { ChallengeEvaluation, ChallengeOnServer, ChallengePackLevel, ChallengeNodeList } from './challenge';
import { ProgramOnServer } from './program';

export enum CodeType {
  Animation = 'animation',
  Creative = 'creative',
  Challenge = 'challenge',
}

export enum CodeActionType {
  SetCodeType = 'set_code_type',
  SetCreativeCode = 'set_creative_code',
  SetChallengeCode = 'set_challenge_code',
  ResetCreativeCode = 'reset_creative_code',
  ResetChallengeCode = 'reset_challenge_code',
  ResetChallenge = 'reset_challenge',
  SetProgram = 'set_program',
  ResetProgram = 'reset_program',
  SetChallenge = 'set_challenge',
  SetProgramName = 'set_program_name',
  SetChallengeLevels = 'set_challenge_levels',
  SetChallengeNodes = 'set_challenge_nodes',
  SetChallengeEvaluation = 'set_challenge_evaluation',
  ResetProgramUserData = 'reset_program_user_data',
  SetCustomChallengeDesc = 'set_custom_challenge_desc',
  SetCustomChallengeName = 'set_custom_challenge_name',
}

export type CodeAction<T = CodeActionPayload, E = CodeActionType> = {
  type: E;
  payload: T;
};

export interface CodeActions {
  SetCodeType: CodeAction<CodeType, CodeActionType.SetCodeType>;
  SetCreativeCode: CodeAction<string, CodeActionType.SetCreativeCode>;
  ResetCreativeCode: CodeAction<undefined, CodeActionType.ResetCreativeCode>;
  SetChallengeCode: CodeAction<string, CodeActionType.SetChallengeCode>;
  ResetChallengeCode: CodeAction<undefined, CodeActionType.ResetChallengeCode>;
  ResetChallenge: CodeAction<undefined, CodeActionType.ResetChallenge>;
  SetProgram: CodeAction<ProgramOnServer, CodeActionType.SetProgram>;
  ResetProgram: CodeAction<undefined, CodeActionType.ResetProgram>;
  SetChallenge: CodeAction<ChallengeOnServer, CodeActionType.SetChallenge>;
  SetProgramName: CodeAction<string, CodeActionType.SetProgramName>;
  SetChallengeLevels: CodeAction<ChallengePackLevel[], CodeActionType.SetChallengeLevels>;
  SetChallengeNodes: CodeAction<ChallengeNodeList, CodeActionType.SetChallengeNodes>;
  SetChallengeEvaluation: CodeAction<ChallengeEvaluation, CodeActionType.SetChallengeEvaluation>;
  ResetProgramUserData: CodeAction<undefined, CodeActionType.ResetProgramUserData>;
  SetCustomChallengeDesc: CodeAction<string, CodeActionType.SetCustomChallengeDesc>;
  SetCustomChallengeName: CodeAction<string, CodeActionType.SetCustomChallengeName>;
}

export type CodeState = {
  codeType: CodeType;
  creativeCode: string;
  challengeCode: string;
  program: ProgramOnServer;
  challenge?: ChallengeOnServer;
  challengeLevels: ChallengePackLevel[];
  challengeNodes: ChallengeNodeList;
  lastChallengeEvaluation?: ChallengeEvaluation;
  customChallengeDesc: string;
  customChallengeName: string;
};

export type CodeActionPayload =
  | string
  | undefined
  | ProgramOnServer
  | ChallengeOnServer
  | ChallengePackLevel[]
  | ChallengeNodeList
  | ChallengeEvaluation
  | CodeType;

export type CodeContextValue = {
  state: CodeState;
  dispatch: Dispatch<ValuesType<CodeActions>>;
};
