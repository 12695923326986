import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import FlagPl from 'ui/components/svg/FlagPl';
import FlagGb from 'ui/components/svg/FlagGb';
import styles from './LanguageSwitch.module.scss';
import { IconComponent } from 'types/icon';
import ClickOutsideDetector from 'ui/components/common/dumb/ClickOutsideDetector';

interface Props {
  id?: string;
  className?: string;
}

const languageMap: { [key: string]: IconComponent } = {
  pl: FlagPl,
  en: FlagGb,
};

const LanguageSwitch: React.FC<Props> = ({ id, className }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { i18n } = useTranslation('header');

  const handleChange = useCallback(
    (event: React.MouseEvent) => {
      i18n.changeLanguage((event.currentTarget as HTMLDivElement).id);
    },
    [i18n]
  );

  const CurrentFlag = languageMap[i18n.language];

  const onClickOutside = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onClick = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  return (
    <div className={classnames(styles.container, className)} id={id}>
      <ClickOutsideDetector
        className={styles.dropdown}
        onClickOutside={onClickOutside}
        onClick={onClick}
        aria-haspopup="listbox"
        aria-labelledby={id}
      >
        {CurrentFlag && <CurrentFlag className={styles.flag} />}
        {open && (
          <ul className={styles.panel} tabIndex={-1} role="listbox" aria-labelledby={id}>
            {Object.keys(languageMap)
              .filter((l) => l !== i18n.language)
              .map((l) => {
                const Flag = languageMap[l];
                return (
                  <li
                    aria-selected={i18n.language === l}
                    role="option"
                    className={styles.item}
                    key={l}
                    id={l}
                    onClick={handleChange}
                  >
                    {Flag && <Flag className={styles.flag} />}
                  </li>
                );
              })}
          </ul>
        )}
      </ClickOutsideDetector>
    </div>
  );
};

export default LanguageSwitch;
