import Konva from 'konva';
import Vector2d = Konva.Vector2d;

export class CanvasSize {
  readonly canvasWidth: number;
  readonly canvasHeight: number;
  readonly clientWidth: number;
  readonly clientHeight: number;

  constructor(canvasWidth: number, canvasHeight: number, clientWidth: number, clientHeight: number) {
    this.canvasWidth = canvasWidth;
    this.canvasHeight = canvasHeight;
    this.clientWidth = clientWidth;
    this.clientHeight = clientHeight;
  }

  clientPosWhenCentered(zoom: number): Vector2d {
    return {
      x: (this.clientWidth - this.canvasWidth * zoom) / 2,
      y: (this.clientHeight - this.canvasHeight * zoom) / 2,
    };
  }
}
