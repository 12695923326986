import { type ReactNode, useCallback } from 'react';
import styles from './EditorError.module.scss';
import { InterpretError } from 'interpret/error/InterpretError';
import { useDispatch } from 'react-redux';
import { hrefFromMouseEvent } from 'util/htmlUtil';
import { useTranslation, Trans } from 'react-i18next';
import { type TFunction } from 'i18next';
import { useHelpStoreActions } from 'ui/store/help/useHelpStoreActions';
import { useHomeScreenStoreActions } from 'ui/store/homescreen/useHomeScreenStoreActions';
import { HomeSubscreen } from 'ui/store/homescreen/enums';
import { getTestSelector } from 'util/testUtil';

const Span = ({ children }: { children: ReactNode }) => <span className={styles.text}>{children}</span>;

const getErrorMessage = (e: InterpretError, t: TFunction): React.ReactElement => {
  let i18nKey: string;
  let values: any = { a: e.args[0] };
  let components: React.ReactNode[] = [];

  switch (e.code) {
    case InterpretError._SYNTAX:
      i18nKey = 'error.program.syntax';
      break;
    case InterpretError.UNKNOWN:
      i18nKey = 'error.program.unknown';
      break;
    case InterpretError._INTERPRETED_EXPS_EXCEEDED:
      i18nKey = 'error.program.interpreted_exps_exceeded';
      break;
    case InterpretError._UNKNOWN_NAME:
      i18nKey = 'error.program.unknown_name';
      break;
    case InterpretError._NOT_NUMBER:
      i18nKey = 'error.program.not_number';
      components = [<a key="types" href="types"></a>];
      break;
    case InterpretError._NOT_BOOLEAN:
      i18nKey = 'error.program.not_boolean';
      components = [<a key="types" href="types"></a>];
      break;
    case InterpretError._NOT_STRING:
      i18nKey = 'error.program.not_string';
      components = [<a key="types" href="types"></a>];
      break;
    case InterpretError._APPLY_NOT_TO_SUB_EXP:
      i18nKey = 'error.program.apply_not_to_sub_exp';
      components = [<a key="argument" href="argument"></a>, <a key="function" href="function"></a>];
      break;
    case InterpretError._NOT_ATTR:
      i18nKey = 'error.program.not_attr';
      components = [<a key="attribute" href="attribute"></a>];
      break;
    case InterpretError._INVALID_ATTR:
      i18nKey = 'error.program.invalid_attr';
      values = { a: e.args[0], b: e.args[1] };
      components = [<a key="attribute" href="attribute"></a>];
      break;
    case InterpretError._NO_ATTRS:
      i18nKey = 'error.program.no_attrs';
      components = [<a key="attribute" href="attribute"></a>];
      break;
    case InterpretError._ARG_COUNT:
      i18nKey = 'error.program.arg_count';
      values = { a: e.args[0], b: e.args[1] };
      components = [<a key="argument" href="argument"></a>];
      break;
    case InterpretError._DIV_BY_ZERO:
      i18nKey = 'error.program.div_by_zero';
      break;
    case InterpretError._ALREADY_DEFINED:
      i18nKey = 'error.program.already_defined';
      break;
    case InterpretError._BUILT_IN:
      i18nKey = 'error.program.built_in';
      break;
    case InterpretError._RESERVED:
      i18nKey = 'error.program.reserved';
      break;
    case InterpretError.UNBALANCED_STRING:
      i18nKey = 'error.program.unbalanced_string';
      components = [<a key="types" href="types"></a>];
      break;
    case InterpretError.UNBALANCED_PARENS_LEFT:
      i18nKey = 'error.program.unbalanced_parens_left';
      break;
    case InterpretError.UNBALANCED_PARENS_RIGHT:
      i18nKey = 'error.program.unbalanced_parens_right';
      break;
    case InterpretError.INVALID_LET_NAME:
      i18nKey = 'error.program.invalid_let_name';
      components = [<a key="let" href="let"></a>];
      break;
    case InterpretError.INVALID_LET_POSITION:
      i18nKey = 'error.program.invalid_let_position';
      components = [<a key="let" href="let"></a>, <code></code>];
      break;
    case InterpretError.INVALID_LET_WITHOUT_NAME_VALUE:
      i18nKey = 'error.program.invalid_let_without_name_value';
      components = [<a key="let" href="let"></a>];
      break;
    case InterpretError.INVALID_LET_WITHOUT_EQ_VALUE:
      i18nKey = 'error.program.invalid_let_without_eq_value';
      components = [<a key="let" href="let"></a>];
      break;
    case InterpretError.INVALID_LET_WITHOUT_EQ:
      i18nKey = 'error.program.invalid_let_without_eq';
      components = [<a key="let" href="let"></a>, <code></code>];
      break;
    case InterpretError.INVALID_LET_WITHOUT_VALUE:
      i18nKey = 'error.program.invalid_let_without_value';
      components = [<a key="let" href="let"></a>];
      break;
    case InterpretError.INVALID_LET_WITHOUT_NAME:
      i18nKey = 'error.program.invalid_let_without_name';
      components = [<a key="let" href="let"></a>];
      break;
    case InterpretError.INVALID_DOT_DOUBLE:
      i18nKey = 'error.program.invalid_dot_double';
      components = [<a key="function" href="function"></a>];
      break;
    case InterpretError.INVALID_DOT_NO_SUBEXP:
      i18nKey = 'error.program.invalid_dot_no_subexp';
      components = [<a key="function" href="function"></a>];
      break;
    case InterpretError.INVALID_DOT_NO_IDENTS:
      i18nKey = 'error.program.invalid_dot_no_idents';
      components = [<a key="function" href="function"></a>];
      break;
    case InterpretError.INVALID_IF_POSITION:
      i18nKey = 'error.program.invalid_if_position';
      components = [<a key="if" href="if"></a>, <code key="code"></code>];
      break;
    case InterpretError.INVALID_IF_WITHOUT_THEN:
      i18nKey = 'error.program.invalid_if_without_then';
      components = [<a key="if" href="if"></a>, <code key="code"></code>];
      break;
    case InterpretError.INVALID_IF_EMPTY_CONDITION:
      i18nKey = 'error.program.invalid_if_empty_condition';
      components = [<a key="if" href="if"></a>, <a href="comparison"></a>, <code key="code"></code>];
      break;
    case InterpretError.INVALID_IF_EMPTY_THEN:
      i18nKey = 'error.program.invalid_if_empty_then';
      components = [<a key="if" href="if"></a>, <code key="code"></code>];
      break;
    case InterpretError.INVALID_IF_EMPTY_ELSE:
      i18nKey = 'error.program.invalid_if_empty_else';
      components = [<a key="if" href="if"></a>, <code key="code"></code>];
      break;
    case InterpretError.INVALID_NUMBER:
      i18nKey = 'error.program.invalid_number';
      components = [<code key="code"></code>];
      break;
    case InterpretError.INVALID_OPERATOR:
      i18nKey = 'error.program.invalid_operator';
      components = [<a key="comparison" href="comparison"></a>, <a key="arithmetic" href="arithmetic"></a>];
      break;
    case InterpretError._COMPARISON_MISSING_BINARY:
      i18nKey = 'error.program.comparison_missing_binary';
      components = [<a key="comparison" href="comparison"></a>];
      break;
    case InterpretError._ARITHMETIC_MISSING_BINARY:
      i18nKey = 'error.program.arithmetic_missing_binary';
      components = [<a key="arithmetic" href="arithmetic"></a>];
      break;
    case InterpretError._TOO_MANY_IMAGES:
      i18nKey = 'error.program.too_many_images';
      break;
    case InterpretError._TOO_MANY_STEPS_IN_INSTR:
      i18nKey = 'error.program.too_many_steps_in_instr';
      break;
    case InterpretError.ILLEGAL_CHARACTER:
      i18nKey = 'error.program.illegal_character';
      return <Span key={i18nKey}>{t(i18nKey)}</Span>;
    case InterpretError._RGB_OUT_OF_RANGE:
      i18nKey = 'error.program.rgb_out_of_range';
      break;
    default:
      i18nKey = 'error.program.unknown';
  }

  return (
    <Span>
      <Trans t={t} i18nKey={i18nKey} values={values}>
        {components}
      </Trans>
    </Span>
  );
};

interface Props {
  error: InterpretError;
}

const EditorError: React.FC<Props> = ({ error }) => {
  const dispatch = useDispatch();
  const { setReference } = useHelpStoreActions(dispatch);
  const { setHomeScreen } = useHomeScreenStoreActions(dispatch);
  const { t } = useTranslation('programErrors');

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const href = hrefFromMouseEvent(e);
      if (href) {
        setReference(href);
        setHomeScreen(HomeSubscreen.Index);
      }
      e.preventDefault();
    },
    [setReference, setHomeScreen]
  );

  return (
    <div className={styles.container} onClick={handleClick} data-testid={getTestSelector('editor.error')}>
      {getErrorMessage(error, t)}
    </div>
  );
};

export default EditorError;
