import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PromiseResultShape, usePromise } from 'react-use-promise-matcher';
import { AnimationOnServer } from 'types/program';
import { renderToastPopup } from 'util/toast';
import useApi from 'ui/components/hooks/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';

const codePropSelector = createCodeStatePropSelector('animation');

const useServerAnimation = (idFromUrl: string | undefined): PromiseResultShape<AnimationOnServer | null, string> => {
  const { t } = useTranslation();
  const { getAnimation } = useApi();
  const dispatch = useDispatch();
  const { setAnimation, setAnimationCode } = useCodeStoreActions(dispatch);
  const { animation } = useSelector(codePropSelector);

  const fetchAnimation = useCallback(() => {
    if (idFromUrl === undefined || idFromUrl === animation.id) return Promise.resolve(null);

    return getAnimation(idFromUrl).then((response) => response.data);
  }, [idFromUrl, getAnimation, animation]);
  const [result, load] = usePromise(fetchAnimation);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    result.match({
      Loading: () => null,
      Resolved: (animation) => {
        if (animation === null) return;

        setAnimation(animation);
        setAnimationCode(animation.text);
      },
      Rejected: (err) => {
        renderToastPopup('error', t(`toast:cannotLoadAnimation`));
        console.error(err);
      },
    });
  }, [result]);

  return result;
};

export default useServerAnimation;
