import { configureStore } from '@reduxjs/toolkit';
import canvas from './canvas/slice';
import code from './code/slice';
import editor from './editor/slice';
import help from './help/slice';
import homescreen from './homescreen/slice';
import tour from './tour/slice';
import user from './user/slice';

const store = configureStore({
  reducer: {
    canvas,
    code,
    editor,
    help,
    homescreen,
    tour,
    user,
  },
  devTools: process.env.APP_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
