import React from 'react';
import ChallengeText from './ChallengeText';
import NavigationPanel from './NavigationPanel';

const ChallengeDescription: React.FC = () => (
  <>
    <NavigationPanel />
    <ChallengeText />
  </>
);

export default ChallengeDescription;
