import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { usePromise } from 'react-use-promise-matcher';
import classnames from 'classnames';
import Button, { ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import useApi from 'ui/components/hooks/useApi';
import { renderToastPopup } from 'util/toast';
import styles from './EditorTopChallenge.module.scss';
import { PageTourStep } from 'ui/components/common/smart/PageTour/types';
import { useDispatch, useSelector } from 'react-redux';
import { useHomeScreenStoreActions } from 'ui/store/homescreen/useHomeScreenStoreActions';
import { homeScreenStateSelector } from 'ui/store/homescreen/selectors';
import { HomeSubscreen } from 'ui/store/homescreen/enums';
import { getTestSelector } from 'util/testUtil';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';

const codePropSelector = createCodeStatePropSelector('challenge', 'challengeCode');

const EditorTopChallenge: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['asideTop']);
  const { postSolveChallenge } = useApi();
  const dispatch = useDispatch();
  const [solveResult, fetchSolveResult] = usePromise(postSolveChallenge);
  const { challenge, challengeCode } = useSelector(codePropSelector);
  const { setChallengeEvaluation } = useCodeStoreActions(dispatch);

  const { setHomeScreen } = useHomeScreenStoreActions(dispatch);
  const { subscreen } = useSelector(homeScreenStateSelector);

  const isChallengeTextActive = React.useMemo(() => subscreen === HomeSubscreen.Challenges, [subscreen]);

  const handleChallengeTextClick = React.useCallback(() => {
    setHomeScreen(isChallengeTextActive ? HomeSubscreen.None : HomeSubscreen.Challenges);
  }, [setHomeScreen, isChallengeTextActive]);

  const handleChallengeCheck = React.useCallback(async () => {
    if (!challenge) return;

    await fetchSolveResult(challenge.id, challengeCode);
  }, [challenge, challengeCode]);

  React.useEffect(() => {
    solveResult.match({
      Loading: () => null,
      Resolved: ({ data }) => {
        setHomeScreen(HomeSubscreen.ChallengeSolved);
        setChallengeEvaluation(data);
      },
      Rejected: (err) => {
        renderToastPopup('error', t('challenges:challenge.solve.error'));
        console.error(err);
      },
    });
  }, [solveResult]);

  return (
    <header className={styles.container}>
      <h3 className={styles.programName} data-testid={getTestSelector('editor.program_name')}>
        {challenge?.texts[language].name || t('challenges:challenge.unknown_name')}
      </h3>
      <div className={styles.buttons}>
        <Button
          data-tour-area={PageTourStep.ToggleChallengeDescription}
          size={ButtonSize.Small}
          flavor={isChallengeTextActive ? ButtonFlavor.Active : ButtonFlavor.Gray}
          className={classnames(styles.button, styles.challengeContent)}
          onClick={handleChallengeTextClick}
        >
          {t('asideTop:challengeText')}
        </Button>
        <Button
          data-tour-area={PageTourStep.CheckChallenge}
          size={ButtonSize.Small}
          flavor={ButtonFlavor.Success}
          className={classnames(styles.button, styles.challengeCheck)}
          onClick={handleChallengeCheck}
          disabled={solveResult.isLoading}
        >
          {t(solveResult.isLoading ? 'challenges:challenge.solve.loading' : 'asideTop:challengeCheck')}
        </Button>
      </div>
    </header>
  );
};

export default EditorTopChallenge;
