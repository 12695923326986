import React from 'react';
import { LinkButton, ButtonSize } from 'ui/components/common/dumb/Button';
import { useTranslation } from 'react-i18next';
import Enter from 'ui/components/svg/Enter';
import Add29 from 'ui/components/svg/Add29';
import { getTestSelector } from 'util/testUtil';

const UnauthorizedContent: React.FC = () => {
  const { t } = useTranslation('navigation');

  return (
    <>
      <LinkButton
        size={ButtonSize.Small}
        to="/user/login"
        title={t('user.menu.login')}
        Icon={Enter}
        noBorder
        bigIcon
        data-testid={getTestSelector('navigation.login')}
      />
      <LinkButton
        data-testid={getTestSelector('navigation.register')}
        size={ButtonSize.Small}
        to="/user/register"
        title={t('user.menu.register')}
        Icon={Add29}
        noBorder
        bigIcon
      />
    </>
  );
};

export default UnauthorizedContent;
