import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Star from 'ui/components/svg/Star';
import { getOverallScore } from 'ui/store/code/helpers';
import { challengeNodesSelector } from 'ui/store/code/selectors';
import styles from './OverallResultsBox.module.scss';

const OverallResultsBox: React.FC = () => {
  const { challengeNodes } = useSelector(challengeNodesSelector);
  const { t } = useTranslation('challenges');

  const score = useMemo(() => getOverallScore(challengeNodes), [challengeNodes]);

  return (
    <div className={styles.container}>
      <Star className={styles.star} />
      <p className={styles.info}>{t('challenges.overall_result', score)}</p>
    </div>
  );
};

export default OverallResultsBox;
