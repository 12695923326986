import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from './Overlay.module.scss';
import { getTestSelector } from 'util/testUtil';

type OverlayProps = PropsWithChildren<{
  className?: string;
  onClick: (event: React.MouseEvent) => void;
}>;

const Overlay: React.FC<OverlayProps> = ({ children, onClick, className }) => (
  <div
    className={classnames(styles.overlay, className)}
    onClick={onClick}
    role="dialog"
    data-testid={getTestSelector('pagetour.overlay')}
  >
    {children}
  </div>
);

export default Overlay;
