import { useCallback } from 'react';
import { type Action, type Dispatch } from '@reduxjs/toolkit';
import { InterpretError } from 'interpret/error/InterpretError';
import { InstrsWithStats } from 'interpret/InterpretResultWithStats';
import { Turtle } from 'turtle/Turtle';
import { editorActions, SetByPosPayload, SetPosPayload, SetUndoRedoPayload } from './slice';

type UseEditorStoreActions = {
  setError: (payload: InterpretError) => void;
  setInstr: (payload: InstrsWithStats) => void;
  setByPos: (payload: SetByPosPayload) => void;
  setPos: (payload: SetPosPayload) => void;
  setUndoRedo: (payload: SetUndoRedoPayload) => void;
  setSelectedTurtle: (payload: Turtle) => void;
};

export const useEditorStoreActions = (dispatch: Dispatch<Action>): UseEditorStoreActions => {
  const setError = useCallback(
    (payload: InterpretError) => {
      dispatch(editorActions.setError(payload));
    },
    [dispatch]
  );

  const setInstr = useCallback(
    (payload: InstrsWithStats) => {
      dispatch(editorActions.setInstr(payload));
    },
    [dispatch]
  );

  const setByPos = useCallback(
    (payload: SetByPosPayload) => {
      dispatch(editorActions.setByPos(payload));
    },
    [dispatch]
  );

  const setPos = useCallback(
    (payload: SetPosPayload) => {
      dispatch(editorActions.setPos(payload));
    },
    [dispatch]
  );

  const setUndoRedo = useCallback(
    (payload: SetUndoRedoPayload) => {
      dispatch(editorActions.setUndoRedo(payload));
    },
    [dispatch]
  );

  const setSelectedTurtle = useCallback(
    (turtle: Turtle) => {
      dispatch(editorActions.setSelectedTurtle(turtle));
    },
    [dispatch]
  );

  return {
    setError,
    setInstr,
    setByPos,
    setPos,
    setUndoRedo,
    setSelectedTurtle,
  };
};
