import { useCallback, useState } from 'react';
import { renderToastPopup } from 'util/toast';
import { useTranslation } from 'react-i18next';
import useApi from 'ui/components/hooks/useApi';
import { getCurrentDate } from 'util/dateUtil';
import { replaceUrl } from 'ui/components/routing/programHelpers';
import { CodeType } from 'types/code';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from 'ui/store/user/selectors';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';
import { createCanvasStatePropSelector } from 'ui/store/canvas/selectors';

type UseCodeSave = [() => Promise<void>, (whenNew: string, whenUpdate: string, whenFork: string) => string, boolean];

const codePropSelector = createCodeStatePropSelector('program');
const animationPropSelector = createCodeStatePropSelector('animation', 'animationCode');
const canvasPropSelector = createCanvasStatePropSelector('getDataUrlImage');

const useCodeSave = (content: string, isAnimation?: boolean, ignoreNoChangesError?: boolean): UseCodeSave => {
  const [saving, setSaving] = useState<boolean>(false);
  const { t } = useTranslation('toast');
  const { program } = useSelector(codePropSelector);
  const { animation } = useSelector(animationPropSelector);
  const dispatch = useDispatch();
  const { setAnimation, setProgram } = useCodeStoreActions(dispatch);
  const { saveImage, saveCode } = useApi();

  const user = useSelector(userSelector);
  const { getDataUrlImage } = useSelector(canvasPropSelector);

  const { name, id, saveToken, originalName, originalContent } = isAnimation ? animation : program;

  const handleImageSave = useCallback(
    async (identifier: string, token: string) => {
      if (getDataUrlImage) {
        try {
          const url = getDataUrlImage();
          const image = dataURIToBlob(url);
          await saveImage(identifier, image, token, isAnimation);
        } catch (err) {
          console.error(err);
        }
      }
    },
    [getDataUrlImage, t, saveImage]
  );

  const labelFromSaveType = useCallback(
    (whenNew: string, whenUpdate: string, whenFork: string) => {
      let tKey: string;
      if (saveToken !== undefined) {
        tKey = whenUpdate;
      } else if (id !== undefined) {
        tKey = whenFork;
      } else {
        tKey = whenNew;
      }

      return t(tKey);
    },
    [saveToken, t]
  );

  const handleCodeSave = useCallback(async () => {
    setSaving(true);
    try {
      if (!content) {
        renderToastPopup('error', t('toast:nothingToSave'));
      } else if (content === originalContent && name === originalName) {
        if (!ignoreNoChangesError) {
          renderToastPopup('error', t('toast:noChangesToSave'));
        }
      } else {
        const saveResult = await saveCode(name, content, id, saveToken, isAnimation ? animation.length : undefined);
        await handleImageSave(saveResult.id, saveResult.saveToken);
        const setCodeFn = isAnimation ? setAnimation : setProgram;
        setCodeFn({
          id: saveResult.id,
          saveToken: saveResult.saveToken,
          userLogin: user?.login,
          name,
          text: content,
          originalName: name,
          originalContent: content,
          created: getCurrentDate(),
          length: animation.length,
        });
        replaceUrl(isAnimation ? CodeType.Animation : CodeType.Creative, saveResult.id, user?.login);
        renderToastPopup('success', labelFromSaveType('saved!', 'updated!', 'copied&saved!'));
      }
    } catch (err) {
      renderToastPopup('error', err as string);
    } finally {
      setSaving(false);
    }
  }, [
    setSaving,
    content,
    name,
    id,
    saveToken,
    setProgram,
    originalName,
    originalContent,
    user,
    ignoreNoChangesError,
    handleImageSave,
    saveCode,
    animation.length,
  ]);

  return [handleCodeSave, labelFromSaveType, saving];
};

export default useCodeSave;

// https://stackoverflow.com/questions/12168909/blob-from-dataurl
function dataURIToBlob(dataURI: string): Blob {
  const byteString: string = atob(dataURI.split(',')[1]);
  const mimeString: string = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);

  let i;
  for (i = 0; i < byteString.length; i += 1) {
    intArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeString });
}
