import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const MyAnimation: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" {...props}>
    <rect className={styles.green} x="2.03" y="6.32" width="43.93" height="35.36" rx="2.77" />
    <circle className={styles.white} cx="24" cy="24" r="11.46" />
    <path
      className={styles.darkGreen}
      d="M20.07,28.88V19.12a1,1,0,0,1,1.55-.81l7.05,4.88a1,1,0,0,1,0,1.62l-7,4.88A1,1,0,0,1,20.07,28.88Z"
    />
  </svg>
);

export default MyAnimation;
