import React, { useCallback } from 'react';
import classnames from 'classnames';
import Star from 'ui/components/svg/Star';
import styles from './ChallengeEvaluationListItem.module.scss';
import { useTranslation } from 'react-i18next';
import { MAX_SOLUTION_RATING } from 'ui/constants';

interface Props {
  passed: boolean;
  translationKey: string;
  value?: number;
  index: number;
}

const ChallengeEvaluationListItem: React.FC<Props> = ({ passed, translationKey, value, index }) => {
  const { t } = useTranslation('challenges');

  const getTranslationText = useCallback((): string => {
    const passedInfo = passed ? 'passed' : 'not_passed';
    switch (translationKey) {
      case 'exact':
      case 'covered':
        return t(`challenge.solve.${translationKey}.${passedInfo}`, { [translationKey]: value as number });
      default:
        return t(`challenge.solve.${translationKey}.${passedInfo}`);
    }
  }, [translationKey, passed, value]);
  return (
    <li className={styles.container}>
      <Star
        className={classnames(styles.icon, {
          [styles['icon--passed']]: passed,
          [styles['icon--bonus']]: index > MAX_SOLUTION_RATING - 1,
        })}
      />
      <span className={styles.text}>{getTranslationText()}</span>
    </li>
  );
};

export default ChallengeEvaluationListItem;
