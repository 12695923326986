import React from 'react';
import styles from './Editor.module.scss';
import EditorCodePane from './EditorCodePane';
import EditorControls from './EditorControls';
import EditorPrefixCode from './EditorPrefixCode';
import { PageTourStep } from 'ui/components/common/smart/PageTour/types';

type Props = {
  onChange?: (value: string) => void;
  code: string;
  prefixLines?: string;
};

const Editor: React.FC<Props> = ({ onChange, code, prefixLines }) => (
  <div className={styles.container} data-tour-area={PageTourStep.Editor}>
    <EditorControls />
    {prefixLines && <EditorPrefixCode code={prefixLines} />}
    <EditorCodePane onChange={onChange} code={code} />
  </div>
);

export default Editor;
