import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Controller: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <g>
      <path
        className={styles.gray}
        d="M24,21c-0.55225,0-1-0.44775-1-1V6c0-0.55225,0.44775-1,1-1s1,0.44775,1,1v14C25,20.55225,24.55225,21,24,21 z"
      />{' '}
      <path
        className={styles.white}
        d="M36,41c-4.14258,0-7.91504-2.33398-9.78809-6h-4.42383C19.91504,38.66602,16.14258,41,12,41 C5.93457,41,1,36.06543,1,30s4.93457-11,11-11h24c6.06543,0,11,4.93457,11,11S42.06543,41,36,41z"
      />{' '}
      <circle className={styles.gray} cx="12" cy="30" r="7" />{' '}
      <path
        className={styles.green}
        d="M16,29h-3v-3c0-0.55225-0.44775-1-1-1s-1,0.44775-1,1v3H8c-0.55225,0-1,0.44775-1,1s0.44775,1,1,1h3v3 c0,0.55225,0.44775,1,1,1s1-0.44775,1-1v-3h3c0.55225,0,1-0.44775,1-1S16.55225,29,16,29z"
      />{' '}
      <circle className={styles.green} cx="31" cy="30" r="2" />
      <circle className={styles.red} cx="41" cy="30" r="2" />
      <circle className={styles.blue} cx="36" cy="25" r="2" />
      <circle className={styles.yellow} cx="36" cy="35" r="2" />
    </g>
  </svg>
);

export default Controller;
