import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const CodeEditor: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M47,11V6c0-1.105-0.895-2-2-2H3C1.895,4,1,4.895,1,6v5H47z" className={styles.black} />
      <path d="M45,43H15V11h32v30C47,42.105,46.105,43,45,43z" className={styles.black} />
      <circle cx="4.5" cy="7.5" className={styles.red} r="1.5" />
      <circle cx="9.5" cy="7.5" className={styles.yellow} r="1.5" />
      <circle cx="14.5" cy="7.5" className={styles.green} r="1.5" />
      <path d="M16,43H3c-1.105,0-2-0.895-2-2V11h15V43z" className={styles.white} />
      <rect height="2" width="7" className={styles.gray} x="5" y="14" />
      <rect height="2" width="5" className={styles.gray} x="5" y="20" />
      <rect height="2" width="7" className={styles.gray} x="5" y="26" />
      <rect height="2" width="5" className={styles.gray} x="5" y="32" />
      <rect height="2" width="7" className={styles.gray} x="5" y="38" />
      <rect height="2" width="5" className={styles.red} x="20" y="14" />
      <rect height="2" width="7" className={styles.yellow} x="24" y="20" />
      <rect height="2" width="10" className={styles.white} x="27" y="26" />
      <rect height="2" width="7" className={styles.yellow} x="24" y="32" />
      <rect height="2" width="5" className={styles.red} x="20" y="38" />
    </g>
  </svg>
);

export default CodeEditor;
