import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CodeType } from 'types/code';
import Button, { ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import Input from 'ui/components/common/dumb/Input';
import { pathForCode } from 'ui/components/routing/programHelpers';
import { createAnimation } from 'ui/store/code/helpers';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';
import SocialShareToggle from '../../CreativeEditor/EditorTopCreative/SocialShareToggle';
import AnimationTimeSlider from './AnimationTimeSlider';
import styles from './EditorTopAnimation.module.scss';
import useCodeSave from 'ui/components/hooks/useCodeSave';
import FloppyDisk from 'ui/components/svg/FloppyDisk';

const codePropSelector = createCodeStatePropSelector('animation', 'animationCode');

const EditorTopAnimation: React.FC = () => {
  const { t } = useTranslation('asideTop');
  const {
    animation: { name },
    animationCode,
  } = useSelector(codePropSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setAnimationCode, setAnimation, setAnimationName } = useCodeStoreActions(dispatch);
  const [handleAnimationSave, labelFromSaveType, saving] = useCodeSave(animationCode, true, true);

  const handleAnimationNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAnimationName(e.target.value);
    },
    [setAnimationName]
  );

  const handleNewAnimation = useCallback(() => {
    const newAnimation = createAnimation();
    setAnimation(newAnimation);
    setAnimationCode(newAnimation.text);
    navigate(pathForCode(CodeType.Animation));
  }, [setAnimation, setAnimationCode, history]);

  return (
    <header className={classNames(styles.container, styles.animations)}>
      <div className={classNames(styles.row, styles['row--spaceBetween'])}>
        <Input
          className={styles.input}
          placeholder={t('asideTop:nameYourAnimation')}
          onChange={handleAnimationNameChange}
          value={name || ''}
          compact
        />
        <div className={styles.buttons}>
          <Button
            size={ButtonSize.Small}
            flavor={ButtonFlavor.Gray}
            className={classNames(styles.button, styles.save)}
            onClick={handleAnimationSave}
            disabled={saving}
            Icon={FloppyDisk}
            title={t(labelFromSaveType('save', 'update', 'saveCopy'))}
          />
          <SocialShareToggle className={classNames(styles.button, styles.share)} />
          <Button
            size={ButtonSize.Small}
            flavor={ButtonFlavor.Gray}
            className={classNames(styles.button, styles.newProgram)}
            onClick={handleNewAnimation}
          >
            {t('asideTop:newAnimation')}
          </Button>
        </div>
      </div>
      <div className={styles.row}>
        <AnimationTimeSlider />
      </div>
    </header>
  );
};

export default EditorTopAnimation;
