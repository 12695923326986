import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const MyAnimations: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" {...props}>
    <path
      className={styles.green}
      d="M38.63,8.52v.76l-30.2.28a2.79,2.79,0,0,0-2.76,2.8l.22,23.11H5.12a2.79,2.79,0,0,1-2.8-2.76L2.1,8.86A2.8,2.8,0,0,1,4.85,6.05l31-.28A2.79,2.79,0,0,1,38.63,8.52Z"
    />
    <rect
      className={styles.green}
      x="9.26"
      y="12.67"
      width="36.53"
      height="29.43"
      rx="2.78"
      transform="translate(-0.25 0.26) rotate(-0.53)"
    />
    <circle className={styles.white} cx="27.53" cy="27.38" r="9.02" />
    <path
      className={styles.darkGreen}
      d="M24.47,31.25l-.07-7.69a.78.78,0,0,1,1.21-.65l5.58,3.8a.77.77,0,0,1,0,1.27l-5.52,3.9A.77.77,0,0,1,24.47,31.25Z"
    />
  </svg>
);

export default MyAnimations;
