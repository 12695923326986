import { StdFnName } from './StdFnName';

export class InstrNames {
  static FW: StdFnName = 'fw';
  static BK: StdFnName = 'bk';
  static RIGHT: StdFnName = 'right';
  static LEFT: StdFnName = 'left';
  static UP: StdFnName = 'up';
  static DOWN: StdFnName = 'down';
  static SQUARE: StdFnName = 'square';
  static RECTANGLE: StdFnName = 'rectangle';
  static CIRCLE: StdFnName = 'circle';
  static ELLIPSE: StdFnName = 'ellipse';
  static TRIANGLE: StdFnName = 'triangle';
  static TEXT: StdFnName = 'text';
  static GOTO: StdFnName = 'goto';
  static HEADING: StdFnName = 'heading';

  static Aliases = class {
    static FORWARD = 'forward';
    static BACKWARD = 'backward';
    static RECT = 'rect';
  };

  static Attributable = class {
    static PEN = 'pen';
    static FILL = 'fill';
  };

  static Stack = class {
    static PUSH = 'push';
    static POP = 'pop';

    static IS_UP = 'isUp';
  };

  static Meta = class {
    static POS = 'pos';
    static VAL = 'val';
  };

  static ALL: StdFnName[] = [
    InstrNames.FW,
    InstrNames.BK,
    InstrNames.RIGHT,
    InstrNames.LEFT,
    InstrNames.UP,
    InstrNames.DOWN,
    InstrNames.SQUARE,
    InstrNames.RECTANGLE,
    InstrNames.CIRCLE,
    InstrNames.ELLIPSE,
    InstrNames.TRIANGLE,
    InstrNames.TEXT,
    InstrNames.GOTO,
    InstrNames.HEADING,
    InstrNames.Aliases.FORWARD,
    InstrNames.Aliases.BACKWARD,
    InstrNames.Aliases.RECT,
  ];

  static ALL_ATTRIBUTABLE: StdFnName[] = [InstrNames.Attributable.PEN, InstrNames.Attributable.FILL];
}
