import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LinkButton, ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import ChevronLeft from 'ui/components/svg/ChevronLeft';
import ChevronRight from 'ui/components/svg/ChevronRight';
import ProgressBar from 'ui/components/common/dumb/ProgressBar';
import ProgressIndicator from 'ui/components/common/dumb/ProgressIndicator';
import styles from './NavigationPanel.module.scss';
import { pathForChallenge } from 'ui/components/routing/programHelpers';
import { useBoundingChallenges } from 'ui/components/hooks/useBoundingChallenges';
import { getTestSelector } from 'util/testUtil';
import { challengeSelector } from 'ui/store/code/selectors';

const NavigationPanel: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('challenges');
  const { challenge } = useSelector(challengeSelector);
  const title = challenge?.texts[language].name;

  const { index, count, nextChallenge, prevChallenge, nextChallengeAvailable, prevChallengeAvailable } =
    useBoundingChallenges();

  return (
    <nav className={styles.content} data-testid={getTestSelector('navigation.panel')}>
      <h1 className={styles.title}>{title}</h1>
      {index !== -1 && (
        <div className={styles.controls}>
          <LinkButton
            flavor={ButtonFlavor.SuccessBordered}
            size={ButtonSize.Small}
            to={pathForChallenge(prevChallenge?.data.id, prevChallenge?.data.userLogin)}
            disabled={!prevChallengeAvailable}
            Icon={ChevronLeft}
          />
          <div className={styles.progress}>
            <ProgressIndicator
              value={index + 1}
              range={count}
              text={t('descriptions.indicator')}
              className={styles.progressIndicator}
            />
            <ProgressBar value={index + 1} max={count} />
          </div>
          <LinkButton
            flavor={ButtonFlavor.SuccessBordered}
            size={ButtonSize.Small}
            to={pathForChallenge(nextChallenge?.data.id, nextChallenge?.data.userLogin)}
            disabled={!nextChallengeAvailable}
            Icon={ChevronRight}
          />
        </div>
      )}
    </nav>
  );
};

export default NavigationPanel;
