import type { Inset } from './types';

const combinedBounds = (elements: Element[]) =>
  elements
    .map((element) => element.getBoundingClientRect())
    .map(({ top, bottom, left, right }) => ({ top, bottom, left, right }))
    .reduce((boundsA, boundsB) => ({
      top: Math.min(boundsA.top, boundsB.top),
      bottom: Math.max(boundsA.bottom, boundsB.bottom),
      left: Math.min(boundsA.left, boundsB.left),
      right: Math.max(boundsA.right, boundsB.right),
    }));

export default (area: string): Inset | null => {
  const elements = Array.from(document.querySelectorAll(`[data-tour-area~=${area}]`));

  if (elements.length == 0) {
    console.warn(`Unable to find area: "${area}"`);
    return null;
  }

  const bounds = combinedBounds(elements);

  const { pageXOffset, pageYOffset } = window;
  const { clientWidth, clientHeight } = document.body;

  return {
    top: bounds.top + pageYOffset,
    bottom: clientHeight - (bounds.bottom + pageYOffset),
    left: bounds.left + pageXOffset,
    right: clientWidth - (bounds.right + pageXOffset),
    width: bounds.right - bounds.left,
    height: bounds.bottom - bounds.top,
  };
};
