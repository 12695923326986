import { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from 'translations/en.json';
import pl from 'translations/pl.json';

import * as referenceEn from './translations/reference/en';
import * as referencePl from './translations/reference/pl';
import { pascalToSnake } from './util/stringUtil';

type MdxImportObject = {
  [key: string]: typeof import('*.mdx');
};

type MdxImportMapCollection = Record<string, MdxImportMap>;

export type MdxImportMap = Map<string, typeof import('*.mdx')>;

// in index.ts, the articles are exported using PascalCase. We need to convert this to snake_case, so that the links work correctly.
const getImportMap = (imp: MdxImportObject): MdxImportMap =>
  new Map(Object.entries(imp).map(([key, value]) => [pascalToSnake(key), value]));

const referenceSubjects: MdxImportMapCollection = {
  en: getImportMap(referenceEn),
  pl: getImportMap(referencePl),
};

const resources = {
  en: {
    mdx: { reference: referenceSubjects.en },
    ...en,
  },
  pl: {
    mdx: { reference: referenceSubjects.pl },
    ...pl,
  },
};

const i18n = use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: ['pl', 'en'],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },
    returnObjects: true,
  });

export default i18n;
