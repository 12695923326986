import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Winner: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M18.828,32.828l-6.47-6.47a3.474,3.474,0,0,0-3.027-1,2.276,2.276,0,0,0-1.429.763A2.937,2.937,0,0,0,7.173,28,2.809,2.809,0,0,0,8,30l5,5a1,1,0,1,1-1.414,1.414l-5-5A4.794,4.794,0,0,1,5.173,28,4.7,4.7,0,0,1,7,24.248V17.17A3.114,3.114,0,0,0,4.161,14,3,3,0,0,0,1,17V30.6a4,4,0,0,0,.877,2.5L9,42v4a1,1,0,0,0,1,1h9a1,1,0,0,0,1-1V35.657A4,4,0,0,0,18.828,32.828Z"
        className={styles.darkGreen}
      />
      <path
        d="M29.172,32.828l6.47-6.47a3.474,3.474,0,0,1,3.027-1,2.276,2.276,0,0,1,1.429.763A2.937,2.937,0,0,1,40.827,28,2.809,2.809,0,0,1,40,30l-5,5a1,1,0,1,0,1.414,1.414l5-5A4.794,4.794,0,0,0,42.827,28,4.7,4.7,0,0,0,41,24.248V17.17A3.114,3.114,0,0,1,43.839,14,3,3,0,0,1,47,17V30.6a4,4,0,0,1-.877,2.5L39,42v4a1,1,0,0,1-1,1H29a1,1,0,0,1-1-1V35.657A4,4,0,0,1,29.172,32.828Z"
        className={styles.darkGreen}
      />
      <path
        d="M29.957,23A5,5,0,0,1,25,18V16H23v2a5,5,0,0,1-4.957,5A1.018,1.018,0,0,0,17,24v1a1,1,0,0,0,1,1H30a1,1,0,0,0,1-1V24A1.018,1.018,0,0,0,29.957,23Z"
        className={styles.yellow}
      />
      <path
        d="M36,1H12a1,1,0,0,0-1,1V5c0,4.006,2.03,7.018,5.225,7.854a7.991,7.991,0,0,0,15.55,0C34.97,12.018,37,9.006,37,5V2A1,1,0,0,0,36,1ZM13,5V3h3v7.64C14.129,9.788,13,7.744,13,5ZM35,5c0,2.744-1.129,4.788-3,5.64V3h3Z"
        className={styles.yellow}
      />
    </g>
  </svg>
);

export default Winner;
