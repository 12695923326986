import React from 'react';
import classnames from 'classnames';
import styles from './ProgressIndicator.module.scss';

interface Props {
  value: number;
  range: number;
  className?: string;
  valueClassName?: string;
  text?: string;
}

const ProgressIndicator: React.FC<Props> = ({ value, range, className, valueClassName, text }) => {
  return (
    <label className={classnames(styles.container, className)}>
      {text}&nbsp;<span className={classnames(styles.value, valueClassName)}>{value}</span>/{range}
    </label>
  );
};

export default ProgressIndicator;
