import { type Dispatch } from 'redux';
import * as monaco from 'monaco-editor';
import { editorActions } from 'ui/store/editor/slice';
import type { SetUndoRedoPayload, EditorAction } from 'ui/store/editor/slice';

const INITIAL_VERSION = 1;

class VersionManager {
  private lastVersion: number;
  private currentVersion: number;
  private dispatch: Dispatch<EditorAction>;

  constructor(dispatch: Dispatch<EditorAction>) {
    this.dispatch = dispatch;
    this.lastVersion = INITIAL_VERSION;
    this.currentVersion = INITIAL_VERSION;

    this.update();
  }

  track = (e: monaco.editor.IModelContentChangedEvent): void => {
    const { isUndoing, isFlush, changes } = e;

    if (isFlush) {
      this.lastVersion = this.currentVersion = INITIAL_VERSION;
    } else if (isUndoing) {
      this.currentVersion -= changes.length;
    } else {
      this.currentVersion += changes.length;
    }

    this.lastVersion = Math.max(this.lastVersion, this.currentVersion);

    this.update();
  };

  update = (): void => {
    const payload: SetUndoRedoPayload = {
      redoDisabled: this.currentVersion === this.lastVersion,
      undoDisabled: this.currentVersion === INITIAL_VERSION,
    };

    this.dispatch(editorActions.setUndoRedo(payload));
  };
}

export default VersionManager;
