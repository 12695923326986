import React from 'react';
import classnames from 'classnames';
import styles from './Separator.module.scss';

type Props = {
  vertical?: boolean;
};

const Separator: React.FC<Props> = ({ vertical = false }) => (
  <div className={classnames(styles.container, vertical ? styles.vertical : styles.horizontal)} />
);

export default Separator;
