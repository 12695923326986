import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const CheckAll: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M11.631,36.841,1,26l3-3,8,5L35,4l3,2L14.65,36.653A2,2,0,0,1,11.631,36.841Z" className={styles.green} />
      <path d="M46,13H41a1,1,0,0,1,0-2h5a1,1,0,0,1,0,2Z" className={styles.white} />
      <path d="M46,22H34a1,1,0,0,1,0-2H46a1,1,0,0,1,0,2Z" className={styles.white} />
      <path d="M46,31H27a1,1,0,0,1,0-2H46a1,1,0,0,1,0,2Z" className={styles.white} />
      <path d="M46,40H20a1,1,0,0,1,0-2H46a1,1,0,0,1,0,2Z" className={styles.white} />
    </g>
  </svg>
);

export default CheckAll;
