import React from 'react';
import classnames from 'classnames';
import styles from './SaveAsImage.module.scss';
import { useTranslation } from 'react-i18next';
import Button, { ButtonFlavor, ButtonSize } from 'ui/components/common/dumb/Button';
import PhotoFrame from 'ui/components/svg/PhotoFrame';
import { useDrawingDownload } from 'ui/components/hooks/useDrawingDownload';
import DownloadImageRaw from 'ui/components/svg/DownloadImageRaw';

interface Props {
  as: 'a' | 'button';
}

const SaveAsImage: React.FC<Props> = ({ as }) => {
  const { t } = useTranslation('turtleInfoBar');
  const { handleImageDownload } = useDrawingDownload(as === 'a');
  const cssClasses = classnames(styles.btn, styles.default);

  return (
    <>
      {as === 'button' ? (
        <Button
          flavor={ButtonFlavor.Gray}
          size={ButtonSize.Small}
          title={t('saveAsImage')}
          onClick={handleImageDownload}
          Icon={PhotoFrame}
          noBorder
        />
      ) : (
        <a
          className={classnames(cssClasses, styles.link)}
          href=""
          rel="noopener noreferrer"
          onClick={handleImageDownload}
        >
          <DownloadImageRaw width={15} className={styles.rawIcon} />
          {t('saveAsImage')}
        </a>
      )}
    </>
  );
};

export default SaveAsImage;
