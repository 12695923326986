import { IconComponent } from 'types/icon';

const FlagPl: IconComponent = (props) => (
  <svg viewBox="0 0 268 134" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="268" height="134" fill="white" />
    <rect y="67" width="268" height="67" fill="#E32438" />
  </svg>
);

export default FlagPl;
