import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkButton, ButtonSize, ButtonFlavor } from 'ui/components/common/dumb/Button';
import { ChallengeOnServer } from 'types/challenge';
import { pathForChallenge, pathForChallengeImage } from 'ui/components/routing/programHelpers';
import styles from './UserChallengeList.module.scss';
import { challengeLanguageFallback } from 'util/langUtil';
import { clamp } from 'util/stringUtil';
import Image from 'ui/components/common/dumb/Image';

interface Props {
  list: ChallengeOnServer[];
}

const UserChallengeList: React.FC<Props> = ({ list }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <ul className={styles.container}>
      {list.map(challengeLanguageFallback(language)).map((challenge: ChallengeOnServer) => (
        <li className={styles.item} key={challenge.id}>
          <Image
            src={pathForChallengeImage(challenge.id)}
            className={styles.thumbnail}
            alt={challenge.texts[language].name}
          />
          <div className={styles.content}>
            <h2 className={styles.title}>{challenge.texts[language].name}</h2>
            <p className={styles.description}>{clamp(challenge.texts[language].description, 100)}</p>
            <div className={styles.actionBox}>
              <LinkButton
                to={pathForChallenge(challenge.id, challenge.userLogin)}
                size={ButtonSize.Small}
                flavor={ButtonFlavor.Gray}
              >
                {t('challenges:cta.load')}
              </LinkButton>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default UserChallengeList;
