import * as monaco from 'monaco-editor';
import { InterpretWorker } from 'interpret/worker/InterpretWorker';
import { editorOptions, modelOptions } from 'monaco/editorSetup';
import { TERRAPIN_ID } from 'monaco/terrapinSetup';

export const editorElement = document.createElement('div');
editorElement.style.width = '100%';
editorElement.style.height = '100%';

export const model = monaco.editor.createModel(modelOptions.value, modelOptions.language);

export const worker = monaco.editor.createWebWorker<InterpretWorker>({
  moduleId: TERRAPIN_ID,
});

const editor = monaco.editor.create(editorElement, editorOptions(model));

export default editor;
