export const dataTestSelectors: string[] = [
  'user.form',
  'user.form.heading',
  'user.form.submit',
  'homescreen.mdx_wrapper',
  'help.all_topics',
  'editor.undo',
  'editor.redo',
  'editor.message',
  'editor.error',
  'editor.instr_count',
  'editor.tokens_count',
  'editor.turtle_pos',
  'editor.turtle_dir',
  'editor.program_name',
  'pagetour.overlay',
  'pagetour.step_counter',
  'pagetour.step_content',
  'pagetour.prev_step',
  'pagetour.next_step',
  'pagetour.modal',
  'pagetour.fadeout',
  'pagetour.take_tour',
  'layout.grid',
  'navigation.index',
  'navigation.whatis',
  'navigation.tour',
  'navigation.challenge_mode',
  'navigation.creative_mode',
  'navigation.logout',
  'navigation.login',
  'navigation.register',
  'navigation.panel',
  'navigation.system_challenges',
  'navigation.loader',
  'challenge.solve.result',
  'challenge.solve.heading',
  'system_challenges.heading',
  'system_challenges.container',
  'social_media.share_button',
  'social_media.custom_challenge_form',
  'social_media.share_program_panel',
  'social_media.create_challenge',
  'social_media.program_link_copy',
  'social_media.custom_challenge_submit',
  'canvas.controls.zoom_in',
  'canvas.controls.zoom_out',
  'canvas.controls.center',
  'canvas.controls.locate_turtle',
  'canvas.hide_turtle',
  'canvas',
  'challenge.solve.try_again',
  'hint.button',
];
