import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Centralize: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M6.879,9.706,3.293,13.292a1,1,0,0,0,.543,1.693l12,2a.988.988,0,0,0,.871-.279,1,1,0,0,0,.28-.871l-2-12a1,1,0,0,0-1.694-.543L9.707,6.878,5.414,2.585A2,2,0,0,0,2.586,5.413Z"
        className={styles.yellow}
      />
      <path
        d="M42.587,2.586,38.294,6.879,34.708,3.293a1,1,0,0,0-1.693.543l-2,12a.988.988,0,0,0,.279.871,1,1,0,0,0,.871.28l12-2a1,1,0,0,0,.543-1.694L41.122,9.707l4.293-4.293a2,2,0,0,0-2.779-2.877C42.62,2.553,42.6,2.569,42.587,2.586Z"
        className={styles.yellow}
      />
      <path
        d="M41.121,38.294l3.586-3.586a1,1,0,0,0-.543-1.693l-12-2a.988.988,0,0,0-.871.279,1,1,0,0,0-.28.871l2,12a1,1,0,0,0,1.694.543l3.586-3.586,4.293,4.293a2,2,0,1,0,2.877-2.779l-.049-.049Z"
        className={styles.yellow}
      />
      <path
        d="M15.835,31.013l-12,2a1,1,0,0,0-.543,1.694l3.586,3.586L2.585,42.586a2,2,0,0,0,2.828,2.828l4.293-4.293,3.586,3.586a1,1,0,0,0,1.693-.543l2-12a.988.988,0,0,0-.279-.871A1,1,0,0,0,15.835,31.013Z"
        className={styles.yellow}
      />
      <circle cx="24" cy="24" className={styles.green} r="7" />
    </g>
  </svg>
);

export default Centralize;
