import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const FloppyDisk: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M43,45H5c-0.55228,0-1-0.44772-1-1V4c0-0.55228,0.44772-1,1-1h35l4,4v37C44,44.55228,43.55228,45,43,45z"
        className={styles.black}
      />
      <path
        d="M39,22H9c-0.55229,0-1,0.44772-1,1v19c0,0.55228,0.44771,1,1,1h30c0.55228,0,1-0.44772,1-1V23 C40,22.44772,39.55228,22,39,22z"
        className={styles.white}
      />
      <path
        d="M20,30h-8c-0.55273,0-1-0.44727-1-1s0.44727-1,1-1h8c0.55273,0,1,0.44727,1,1S20.55273,30,20,30z"
        className={styles.black}
      />
      <path
        d="M16,36h-4c-0.55273,0-1-0.44727-1-1s0.44727-1,1-1h4c0.55273,0,1,0.44727,1,1S16.55273,36,16,36z"
        className={styles.black}
      />
      <path d="M35,17H9c-0.55229,0-1-0.44772-1-1V3h28v13C36,16.55228,35.55228,17,35,17z" className={styles.gray} />
      <path d="M35,17H15c-0.55229,0-1-0.44772-1-1V3h22v13C36,16.55228,35.55228,17,35,17z" className={styles.white} />
      <path
        d="M31,15h-4c-0.55228,0-1-0.44771-1-1V6c0-0.55228,0.44772-1,1-1h4c0.55228,0,1,0.44772,1,1v8 C32,14.55229,31.55228,15,31,15z"
        className={styles.black}
      />
    </g>
  </svg>
);

export default FloppyDisk;
