import Konva from 'konva';
import { Group } from 'konva/lib/Group';
import { InterpretError } from '../interpret/error/InterpretError';
import { Turtle } from '../turtle/Turtle';
import { degToRad } from '../util/numberUtil';
import konvaPaths from './patternPaths';

export class PatternShapes {
  withNewCounter(maxImages: number): CountingPatternShapes {
    return new CountingPatternShapes(maxImages);
  }
}

/** Assumes that the turtle color is always defined - must be checked before. */
export class CountingPatternShapes {
  private readonly maxImages: number;
  private imageCounter: number;

  constructor(maxImages: number) {
    this.maxImages = maxImages;
    this.imageCounter = 0;
  }

  line(t: Turtle, length: number): Group {
    const path = CountingPatternShapes.pathForPattern(t);
    return this.createPatternLine(t, path, length);
  }

  rectangle(t: Turtle, width: number, height: number): Group {
    const path = CountingPatternShapes.pathForPattern(t);
    const w = width;
    const wh = w / 2;
    const h = height;
    const hh = h / 2;
    return this.createPatternShape(t, path, [
      { x: -wh, y: -hh, length: h, rotation: 0 },
      { x: -wh, y: hh, length: w, rotation: degToRad(270) },
      { x: wh, y: hh, length: h, rotation: degToRad(180) },
      { x: wh, y: -hh, length: w, rotation: degToRad(90) },
    ]);
  }

  ellipse(t: Turtle, a: number, b: number): Group {
    const path = CountingPatternShapes.pathForPattern(t);
    const group = new Konva.Group();
    const w = path.width();
    const wo = -w / 2;
    const h = path.height();
    const ho = h / 2;
    const circuit = Math.PI * Math.sqrt(2 * (a * a + b * b));
    const copies = Math.max(Math.floor(circuit / h), 1);
    const angle = (2 * Math.PI) / copies;
    const rotationBase = (3 * Math.PI) / 2;

    this.addToImageCounterAndVerify(copies);

    for (let i = 0; i < copies; i++) {
      const dx = a * Math.sin(angle * i);
      const dy = b * Math.cos(angle * i);
      // Copying the width, height & data from the original path. Bit more efficient than cloning.
      group.add(
        new Konva.Path({
          width: path.width(),
          height: path.height(),
          data: path.data(),
          x: dx,
          y: dy,
          offsetX: -wo,
          offsetY: ho,
          rotation: rotationBase - angle * i,
          scaleY: -1,
          fill: t._pen._color!.hex,
        })
      );
    }

    return group;
  }

  triangle(t: Turtle, a: number, points: number[]): Group {
    const path = CountingPatternShapes.pathForPattern(t);
    return this.createPatternShape(t, path, [
      { x: points[0], y: points[1], length: a, rotation: degToRad(210) },
      { x: points[2], y: points[3], length: a, rotation: degToRad(90) },
      { x: points[4], y: points[5], length: a, rotation: degToRad(330) },
    ]);
  }

  /**
   * Raw path for the given pattern. Cannot be used directly, only as a template to copy/clone!
   */
  private static pathForPattern(t: Turtle): Konva.Path {
    return konvaPaths[t._pen._pattern];
  }

  private createPatternShape(
    t: Turtle,
    path: Konva.Path,
    segments: { x: number; y: number; length: number; rotation: number }[]
  ) {
    const group = new Konva.Group();
    segments.forEach((segment) => {
      const { length, ...coords } = segment;
      const l = this.createPatternLine(t, path, length);
      l.setAttrs(coords);
      group.add(l);
    });
    return group;
  }

  private createPatternLine(t: Turtle, path: Konva.Path, length: number): Konva.Group {
    const group = new Konva.Group();
    const w = path.width();
    const wo = -w / 2;
    const h = path.height();
    const ho = h / 2;
    const copies = Math.floor(length / h) + 1;

    this.addToImageCounterAndVerify(copies);

    for (let i = 0; i < copies; i++) {
      // Copying the width, height & data from the original path. Bit more efficient than cloning.
      group.add(
        new Konva.Path({
          width: path.width(),
          height: path.height(),
          data: path.data(),
          x: wo,
          y: ho + i * h,
          scaleY: -1,
          fill: t._pen._color!.hex,
        })
      );
    }

    return group;
  }

  private addToImageCounterAndVerify(n: number) {
    this.imageCounter += n;
    if (this.imageCounter > this.maxImages) {
      throw InterpretError._TOO_MANY_IMAGES;
    }
  }
}
