import * as monaco from 'monaco-editor';
import { InstrNames } from '../turtle/InstrNames';
import { StdFnNames } from '../turtle/StdFnName';
import { format } from './format';
import IMonarchLanguage = monaco.languages.IMonarchLanguage;
import LanguageConfiguration = monaco.languages.LanguageConfiguration;

export const TERRAPIN_ID = 'terrapin';

export function terrapinSetup(): void {
  monaco.languages.register({ id: TERRAPIN_ID });
  monaco.languages.setMonarchTokensProvider(TERRAPIN_ID, terrapinMonarchLanguage);
  monaco.languages.setLanguageConfiguration(TERRAPIN_ID, terrapinLanguageConfiguration);

  const documentFormatter: monaco.languages.DocumentFormattingEditProvider = {
    provideDocumentFormattingEdits: (
      document: monaco.editor.ITextModel,
      options: monaco.languages.FormattingOptions
    ): monaco.languages.TextEdit[] => {
      const text = document.getValue();
      const indentWith = options.insertSpaces ? ' '.repeat(options.tabSize) : '\t';
      const formatted = format(text, indentWith);
      return [
        {
          range: document.getFullModelRange(),
          text: formatted,
        },
      ];
    },
  };
  monaco.languages.registerDocumentFormattingEditProvider(TERRAPIN_ID, documentFormatter);

  const completionItemProvider: monaco.languages.CompletionItemProvider = {
    provideCompletionItems: (model, position) => {
      const word = model.getWordUntilPosition(position);
      const range: monaco.IRange = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      };
      return {
        suggestions: completionItems(range),
      };
    },
  };
  monaco.languages.registerCompletionItemProvider(TERRAPIN_ID, completionItemProvider);
}

// https://microsoft.github.io/monaco-editor/monarch.html
const terrapinMonarchLanguage: IMonarchLanguage & {
  keywords: string[];
  operators: string[];
  symbols: RegExp;
  escapes: RegExp;
} = {
  ignoreCase: true,

  keywords: ['let', 'if', 'then', 'else'],

  operators: ['=', '>', '<', '!', '.', '==', '<=', '>=', '!=', '&&', '||', '+', '-', '*', '/', '&', '|', '^', '%'],

  // we include these common regular expressions
  symbols: /[=><!~?:&|+\-*/^%]+/,

  // C# style strings
  escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

  // The main tokenizer for our languages
  tokenizer: {
    root: [
      // identifiers and keywords
      [
        /[a-z][\w]*/,
        {
          cases: {
            '@keywords': 'keyword',
            '@default': 'identifier',
          },
        },
      ],

      // whitespace
      { include: '@whitespace' },

      // delimiters and operators
      [/[()]/, '@brackets'],
      [
        /@symbols/,
        {
          cases: {
            '@operators': 'operator',
            '@default': '',
          },
        },
      ],

      // numbers
      [/-?\d*\.\d+([eE][-+]?\d+)?/, 'number.float'],
      [/-?0[xX][0-9a-fA-F]+/, 'number.hex'],
      [/-?\d+/, 'number'],

      // strings
      [/"([^"\\]|\\.)*$/, 'string.invalid'], // non-teminated string
      [/"/, { token: 'string.quote', bracket: '@open', next: '@string' }],
    ],

    string: [
      [/[^\\"]+/, 'string'],
      [/@escapes/, 'string.escape'],
      [/\\./, 'string.escape.invalid'],
      [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }],
    ],

    whitespace: [
      [/[ \t\r\n]+/, 'white'],
      [/#.*$/, 'comment'],
    ],
  },
};

const terrapinLanguageConfiguration: LanguageConfiguration = {
  comments: {
    lineComment: '#',
  },
  brackets: [
    ['(', ')'],
    ['[', ']'],
  ],
  wordPattern: /(-?\d*\.\d\w*)|([^`~!@#%^&*()\-=+[{\]}\\|;:'",.<>/?\s]+)/g,
};

function completionItems(range: monaco.IRange): monaco.languages.CompletionItem[] {
  return InstrNames.ALL.map((f) => {
    return {
      label: f,
      kind: monaco.languages.CompletionItemKind.Event,
      insertText: f,
      range: range,
    };
  })
    .concat(
      StdFnNames.ALL.map((f) => {
        return {
          label: f,
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: f,
          range: range,
        };
      })
    )
    .concat(
      InstrNames.ALL_ATTRIBUTABLE.map((f) => {
        return {
          label: f,
          kind: monaco.languages.CompletionItemKind.Color,
          insertText: f,
          range: range,
        };
      })
    )
    .concat([
      {
        label: 'let',
        kind: monaco.languages.CompletionItemKind.Class,
        insertText: 'let',
        range: range,
      },
      {
        label: 'if',
        kind: monaco.languages.CompletionItemKind.Class,
        insertText: 'if',
        range: range,
      },
    ]);
}
