import axios, { type AxiosResponse, type AxiosRequestConfig } from 'axios';
import {
  USER_CONTEXT,
  USER_OAUTH_CONTEXT,
  API_KEY_VALID_HOURS,
  PROGRAM_CONTEXT,
  CHALLENGE_CONTEXT,
  ANIMATION_CONTEXT,
} from 'ui/constants';
import { User } from 'types/user';
import { makeId } from 'util/stringUtil';
import { ChallengeOnServer, ChallengePack, ChallengePackOnServer, ChallengesText } from 'types/challenge';
import type { AnimationOnServer, ProgramOnServer } from 'types/program';

interface AdditionalHeaders {
  [key: string]: string;
}

export default class ApiService {
  private apiKey: string | null;
  private userToken: string | null;
  private lang: string;
  private defaultConfig: AxiosRequestConfig;

  constructor(lang: string, apiKey: string | null, userToken: string | null) {
    this.apiKey = apiKey;
    this.userToken = userToken;
    this.lang = lang;
    this.defaultConfig = this.prepareConfig();
  }

  private prepareConfig = (config: Partial<AxiosRequestConfig> = {}): Partial<AxiosRequestConfig> => {
    const headers: AdditionalHeaders = {
      'Accept-Language': this.lang,
    };
    if (this.apiKey) headers['Authorization'] = `Bearer ${this.apiKey}`;
    else if (this.userToken) headers['X-User-Token'] = this.userToken;

    return { ...config, headers: { ...config.headers, ...headers } };
  };

  static evaluateListResponse = <T>(response: AxiosResponse<T[]>): T[] => {
    if (response.status === 200) {
      return response.data;
    }
    return [];
  };

  contact = (email: string, message: string, programContent: string): Promise<AxiosResponse<any>> =>
    axios.post(
      'contact',
      {
        email,
        message,
        programContent,
      },
      this.defaultConfig
    );

  register = (login: string, email: string, password: string, emailAgreement: boolean): Promise<AxiosResponse<any>> =>
    axios.post(
      `${USER_CONTEXT}/register`,
      {
        login,
        email,
        password,
        emailAgreement,
      },
      this.defaultConfig
    );

  login = (loginOrEmail: string, password: string): Promise<AxiosResponse<any>> =>
    axios.post(
      `${USER_CONTEXT}/login`,
      {
        loginOrEmail,
        password,
        apiKeyValidHours: API_KEY_VALID_HOURS,
      },
      this.defaultConfig
    );

  getCurrentUser = async (): Promise<AxiosResponse<User>> => axios.get(USER_CONTEXT, this.defaultConfig);

  changeProfileDetails = (login: string, email: string, emailAgreement: boolean): Promise<AxiosResponse<any>> =>
    axios.patch(USER_CONTEXT, { login, email, emailAgreement }, this.defaultConfig);

  changePassword = (currentPassword: string, newPassword: string): Promise<AxiosResponse<any>> =>
    axios.post(
      `${USER_CONTEXT}/changepassword`,
      {
        currentPassword,
        newPassword,
      },
      this.defaultConfig
    );

  loginOrRegisterUsingFb = async (code: string): Promise<string> => {
    const response = await axios.get(`${USER_OAUTH_CONTEXT}/fb?code=${code}`, this.defaultConfig);
    if (response.data.apiKey) {
      return response.data.apiKey;
    } else {
      throw `Login/register using fb failed=${response}`;
    }
  };

  saveCode = async (
    name: string,
    content: string,
    id: string | undefined,
    saveToken: string | undefined,
    length?: number | undefined
  ): Promise<{ id: string; saveToken: string }> => {
    const isAnimation = Boolean(length);

    const data = {
      name,
      text: content,
      originalId: id,
      id,
      saveToken,
      length,
    };

    if (data.saveToken === undefined) {
      data.saveToken = makeId(32);
      data.id = undefined;
    }

    const result = await axios.post(isAnimation ? ANIMATION_CONTEXT : PROGRAM_CONTEXT, data, this.defaultConfig);

    return {
      id: result.data.id,
      saveToken: data.saveToken,
    };
  };

  getProgram = async (id: string): Promise<AxiosResponse<ProgramOnServer>> =>
    axios.get(`${PROGRAM_CONTEXT}/${id}`, this.defaultConfig);

  getAnimation = async (id: string): Promise<AxiosResponse<AnimationOnServer>> =>
    axios.get(`${ANIMATION_CONTEXT}/${id}`, this.defaultConfig);

  getProgramList = (limit: number, offset: number): Promise<ProgramOnServer[]> =>
    axios
      .get<ProgramOnServer[]>(PROGRAM_CONTEXT, this.prepareConfig({ params: { limit, offset } }))
      .then(ApiService.evaluateListResponse);

  getAnimationList = (limit: number, offset: number): Promise<AnimationOnServer[]> =>
    axios
      .get<AnimationOnServer[]>(ANIMATION_CONTEXT, this.prepareConfig({ params: { limit, offset } }))
      .then(ApiService.evaluateListResponse);

  claimPasswordReset = (loginOrEmail: string): Promise<AxiosResponse<any>> =>
    axios.post('passwordreset/forgot', { loginOrEmail }, this.defaultConfig);

  resetPassword = (code: string, password: string): Promise<AxiosResponse<any>> =>
    axios.post('passwordreset/reset', { code, password }, this.defaultConfig);

  saveImage = (id: string, data: Blob, saveToken: string, isAnimation?: boolean): Promise<AxiosResponse<any>> =>
    axios.post(
      `${isAnimation ? ANIMATION_CONTEXT : PROGRAM_CONTEXT}/${id}/image`,
      data,
      this.prepareConfig({
        headers: { contentType: 'application/octet-stream' },
        params: { saveToken },
      })
    );

  getChallenge = (id: string): Promise<AxiosResponse<ChallengeOnServer>> =>
    axios.get(`challenge/${id}`, this.defaultConfig);

  getChallengeList = (limit: number, offset: number): Promise<ChallengeOnServer[]> =>
    axios
      .get<ChallengeOnServer[]>(CHALLENGE_CONTEXT, this.prepareConfig({ params: { limit, offset } }))
      .then(ApiService.evaluateListResponse);

  patchChallenge = (id: string, texts: { [key: string]: ChallengesText }): Promise<AxiosResponse<any>> =>
    axios.patch(`challenge/${id}`, { texts }, this.defaultConfig);

  postChallenge = (programId: string, texts: { [key: string]: ChallengesText }): Promise<AxiosResponse<any>> =>
    axios.post('challenge', { programId, texts }, this.defaultConfig);

  postSolveChallenge = (id: string, text: string): Promise<AxiosResponse<any>> =>
    axios.post(`challenge/${id}/solve`, { text }, this.defaultConfig);

  getChallengePack = (id: string): Promise<AxiosResponse<ChallengePackOnServer>> =>
    axios.get(`challenge-pack/${id}`, this.defaultConfig);

  postChallengePack = (data: ChallengePack): Promise<AxiosResponse<{ [key: string]: string }>> =>
    axios.post('challenge-pack', data, this.defaultConfig);

  postHint = (challengeId: string, userProgram: string): Promise<AxiosResponse<{ hint: string }>> =>
    axios.post(`hint/${challengeId}`, { userProgram }, this.defaultConfig);
}
