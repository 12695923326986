import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ChallengeEvaluation } from 'types/challenge';
import { useEvaluationListState } from 'ui/components/hooks/useEvaluationListState';
import styles from './ChallengeEvaluationList.module.scss';
import ChallengeEvaluationListItem from './ChallengeEvaluationListItem/ChallengeEvaluationListItem';

interface Props {
  evaluation: ChallengeEvaluation;
}

const ChallengeEvaluationList: React.FC<Props> = ({ evaluation }) => {
  const { t } = useTranslation('challenges');
  const { getListEntries } = useEvaluationListState(evaluation);

  const getTranslationTextValue = useCallback(
    (key: string): number | undefined => {
      if (key === 'exact') return evaluation.exactness;
      else if (key === 'covered') return evaluation.cover;
      return undefined;
    },
    [evaluation]
  );

  return (
    <>
      {evaluation.stars > 0 ? (
        <ul className={styles.list}>
          {getListEntries().map(([key, value], index) => (
            <ChallengeEvaluationListItem
              key={key}
              passed={value}
              translationKey={key}
              value={getTranslationTextValue(key)}
              index={index}
            />
          ))}
        </ul>
      ) : (
        <>
          <h2>{t('challenge.solve.correct.not_passed')}</h2>
          {evaluation.cover < 95 && <p>{t('challenge.solve.covered.not_passed', { covered: evaluation.cover })}</p>}
          {evaluation.exactness < 95 && <p>{t('challenge.solve.exact.not_passed', { exact: evaluation.exactness })}</p>}
        </>
      )}
    </>
  );
};

export default ChallengeEvaluationList;
