import { InputHTMLAttributes, Ref, forwardRef } from 'react';
import classnames from 'classnames';
import styles from './Input.module.scss';

interface Props extends InputHTMLAttributes<any> {
  className?: string;
  name?: string;
  compact?: boolean;
}

const Input = forwardRef<HTMLInputElement, Props>(
  ({ className, name, compact, ...props }, ref: Ref<HTMLInputElement>) => (
    <input
      id={name}
      name={name}
      ref={ref}
      className={classnames(styles.container, className, { [styles.compact]: compact })}
      {...props}
    />
  )
);

export default Input;
