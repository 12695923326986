import { Color } from '../turtle/Color';

export function stmtAround(s: string, column: number): PositionedStmt {
  const start = charFrom(s, column - 2, ';', -1); // column <= 1 ? -1 : s.lastIndexOf(';', column - 2);
  const end = charFrom(s, column - 1, ';', 1); //s.indexOf(';', column - 1);

  const start2 = start < 0 ? 0 : start + 1;
  const end2 = end < 0 ? s.length : end;

  const startParens = charFrom(s, column - 2, '(', -1); // column <= 1 ? -1 : s.lastIndexOf('(', column - 2);
  const endParens = charFrom(s, startParens + 1, ')', 1);

  const start3 = start2 < startParens + 1 ? startParens + 1 : start2;
  const end3 = end2 > endParens && endParens >= 0 ? endParens : end2;

  const start4 = start3 + whitespaceCountFrom(s, start3, 1);
  const end4 = end3 - whitespaceCountFrom(s, end3 - 1, -1);

  if (end4 < start4) {
    return new PositionedStmt('', column, column);
  } else {
    return new PositionedStmt(s.substring(start4, end4), start4 + 1, end4 + 1);
  }
}

function charFrom(s: string, start: number, which: string, delta: number): number {
  let height = 0;
  let i = start;
  while (i >= 0 && i < s.length) {
    const sc = s.charAt(i);
    if (sc === which && height === 0) return i;
    if (sc === ')') height--;
    if (sc === '(') height++;
    i += delta;
  }
  return -1;
}

function whitespaceCountFrom(s: string, from: number, delta: number): number {
  let count = 0;
  let i = from;
  while (s.charAt(i).trim() === '' && i < s.length && i >= 0) {
    count++;
    i += delta;
  }

  return count;
}

export class PositionedStmt {
  readonly value: string;
  readonly startColumn: number;
  readonly endColumn: number;

  constructor(value: string, startColumn: number, endColumn: number) {
    this.value = value;
    this.startColumn = startColumn;
    this.endColumn = endColumn;
  }
}

export function count(s: string, what: string): number {
  return s.split(what).length - 1;
}

// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export function makeId(length: number): string {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    // tslint:disable-next-line:insecure-random
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function upperFirst(str: string): string {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

export function pascalToSnake(s: string): string {
  return s.replace(/(?:^|\.?)([A-Z])/g, (x, y) => '_' + y.toLowerCase()).replace(/^_/, '');
}

export function getColorFromStmtValue(stmt: string): Color | undefined {
  const presetColorKeys = Object.keys(Color.ColorValues);
  const presetFound = presetColorKeys.find((key) => stmt.includes(`:${key}`));
  if (presetFound) {
    const hexColor: string = Color.ColorValues[presetFound];
    return new Color(presetFound, hexColor);
  }
  if (Color.COLOR_EXPRESSION_REGEXP.test(stmt)) {
    const color = stmt
      .split(' ')
      .filter((s) => /[0-9]+/.test(s))
      .map((s) => parseInt(s, 10));
    if (color.length === 3) {
      return new Color(`(rgb ${color[0]} ${color[1]} ${color[2]})`, color);
    }
    return new Color(`white`, [255, 255, 255]);
  }
  return undefined;
}

export const clamp = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text;

  const words = text.split(' ');
  const clampedText = words.reduce((result, word) => (result.length < maxLength ? `${result} ${word}` : result), '');

  return `${clampedText}...`;
};
