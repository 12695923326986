import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const MyPicture: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" {...props}>
    <path
      className={styles.white}
      d="M43.5,9.47V23.7l-5.55-6a2.37,2.37,0,0,0-3.37,0L21.66,31.64l-5.85-6.31a2.38,2.38,0,0,0-3.37,0L4.5,33.9V9.47a.65.65,0,0,1,.64-.65H42.85A.66.66,0,0,1,43.5,9.47Z"
    />
    <path
      className={styles.white}
      d="M20.1,30l-4.29-4.63a2.38,2.38,0,0,0-3.37,0L4.5,33.9v-.09l7.9-8.55a2.37,2.37,0,0,1,3.36,0Z"
    />
    <path
      className={styles.white}
      d="M38,17.64l5.53,6v.08l-5.55-6a2.37,2.37,0,0,0-3.37,0L23.36,29.8,34.61,17.64A2.35,2.35,0,0,1,38,17.64Z"
    />
    <path
      className={styles.darkGreen}
      d="M38,17.64l5.53,6V38.53a.64.64,0,0,1-.64.64H14.71l7-7.52h0l.07-.07,1.63-1.77L34.61,17.64A2.35,2.35,0,0,1,38,17.64Z"
    />
    <path
      className={styles.green}
      d="M21.66,31.64h0l-6.95,7.52H5.15a.65.65,0,0,1-.65-.64V33.81l7.9-8.55a2.37,2.37,0,0,1,3.36,0L20.1,30l1.49,1.61Z"
    />
    <path
      className={styles.green}
      d="M42.86,41.67H5.14A3.15,3.15,0,0,1,2,38.53V9.47A3.15,3.15,0,0,1,5.14,6.33H42.86A3.15,3.15,0,0,1,46,9.47V38.53A3.15,3.15,0,0,1,42.86,41.67ZM5.14,8.83a.65.65,0,0,0-.64.64V38.53a.65.65,0,0,0,.64.64H42.86a.65.65,0,0,0,.64-.64V9.47a.65.65,0,0,0-.64-.64Z"
    />
    <circle className={styles.yallow} cx="21.67" cy="17.03" r="4.14" />
  </svg>
);

export default MyPicture;
