import { StdFnNames } from './StdFnName';

export class Thickness {
  readonly name: string;
  readonly width: number;

  constructor(name: string, width: number) {
    this.name = name;
    this.width = width;
  }

  static FROM_NAME(name: string): Thickness | undefined {
    switch (name) {
      case StdFnNames.THIN:
        return new Thickness(name, 1);
      case StdFnNames.MEDIUM:
        return new Thickness(name, 3);
      case StdFnNames.THICK:
        return new Thickness(name, 5);
      default:
        return undefined;
    }
  }
}
