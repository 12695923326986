import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Check: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <g>
      <path className={styles.green} d="M4,24l4-4l8,6c0,0,8.75-10.91667,26-16l2,4c0,0-18.875,8.75-28,26L4,24z" />
    </g>
  </svg>
);

export default Check;
