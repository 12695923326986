import { Color } from './Color';
import { StdFnNames } from './StdFnName';
import { Thickness } from './Thickness';

export class Pen {
  readonly _thickness: Thickness;
  readonly _color: Color | undefined;
  readonly _pattern: string;

  constructor(thickness: Thickness, color: Color | undefined, pattern: string) {
    this._thickness = thickness;
    this._color = color;
    this._pattern = pattern;
  }

  thickness(t: Thickness): Pen {
    return new Pen(t, this._color, this._pattern);
  }

  color(c: Color | undefined): Pen {
    return new Pen(this._thickness, c, this._pattern);
  }

  pattern(p: string): Pen {
    return new Pen(this._thickness, this._color, p);
  }

  hasPattern(): boolean {
    return this._pattern != StdFnNames.NONE;
  }
}
