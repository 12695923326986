import React from 'react';
import ModalPortal from 'ui/components/common/dumb/ModalPortal';
import Overlay from './Overlay';
import ModalBox, { ModalBoxProps } from './ModalBox';

const Modal: React.FC<ModalBoxProps> = ({ children, ...props }) => (
  <ModalPortal>
    <Overlay onClick={props.onClose}>
      <ModalBox {...props}>{children}</ModalBox>
    </Overlay>
  </ModalPortal>
);

export default Modal;
