import { IconComponent } from 'types/icon';
import styles from './ColorIcons.module.scss';

const Close: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M38,45c-0.25586,0-0.51172-0.09766-0.70703-0.29297L24,31.41406L10.70703,44.70703 c-0.39062,0.39062-1.02344,0.39062-1.41406,0l-6-6c-0.39062-0.39062-0.39062-1.02344,0-1.41406L16.58594,24L3.29297,10.70703 c-0.39062-0.39062-0.39062-1.02344,0-1.41406l6-6c0.39062-0.39062,1.02344-0.39062,1.41406,0L24,16.58594L37.29297,3.29297 c0.39062-0.39062,1.02344-0.39062,1.41406,0l6,6c0.39062,0.39062,0.39062,1.02344,0,1.41406L31.41406,24l13.29297,13.29297 c0.39062,0.39062,0.39062,1.02344,0,1.41406l-6,6C38.51172,44.90234,38.25586,45,38,45z"
        fill={styles.white}
      />
    </g>
  </svg>
);

export default Close;
