import { IconComponent } from 'types/icon';

const FlagGb: IconComponent = (props) => (
  <svg viewBox="0 0 269 135" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M268.005 0H0V134.003H268.005V0Z" fill="#000066" />
    <path
      d="M0 0V14.9819L238.042 134.003H268.005V119.022L29.963 0.000446675H0L0 0ZM268.005 0V14.9815L29.963 134.003H0V119.021L238.042 0H268.005Z"
      fill="white"
    />
    <path d="M111.669 0V134.003H156.336V0H111.669ZM0 44.6676V89.3351H268.005V44.6676H0Z" fill="white" />
    <path d="M0 53.6011V80.4016H268.005V53.6011H0ZM120.602 0V134.003H147.403V0H120.602Z" fill="#CC0000" />
    <path
      d="M0 134.003L89.3351 89.3351H109.31L19.9753 134.003H0H0ZM0 0L89.3351 44.6676H69.3598L0 9.98811V0L0 0ZM158.695 44.6676L248.03 0H268.005L178.67 44.6676H158.695ZM268.005 134.003L178.67 89.3351H198.646L268.005 124.015V134.003Z"
      fill="#CC0000"
    />
  </svg>
);

export default FlagGb;
