import React from 'react';
import classnames from 'classnames';
import styles from './Loader.module.scss';
import { getTestSelector } from 'util/testUtil';

interface Props {
  className?: string;
  overlay?: boolean;
}

const Loader: React.FC<Props> = ({ className, overlay = false }) => (
  <div
    data-testid={getTestSelector('navigation.loader')}
    className={classnames(styles.container, { [styles.overlay]: overlay }, className)}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 45" width={136} className={styles.logo}>
      <g fillRule="evenodd">
        <path className={styles.sml} fill="#2780C0" d="M9.347 20.704h6.162V.988H9.347z" />
        <path className={styles.sml} fill="#58BA47" d="M52.475 20.704h6.161V.988h-6.161z" />
        <path className={styles.sml} fill="#975CA6" d="M26.598 20.704h6.162V.988h-6.162z" />
        <path className={styles.sml} fill="#E5529A" d="M35.224 24.399h6.161V4.685h-6.161z" />
        <path className={styles.sml} fill="#B370AE" d="M43.85 20.704h6.161V.988H43.85z" />
        <path className={styles.sml} fill="#66C4DD" d="M24.134 4.068a3.08 3.08 0 11-6.16.001 3.08 3.08 0 016.16 0" />
        <path
          className={styles.sml}
          fill="#35539B"
          d="M6.883 17.622a3.08 3.08 0 11-6.161.002 3.08 3.08 0 016.161-.002"
        />
        <path
          className={styles.sml}
          fill="#B2D234"
          d="M67.261 17.622a3.08 3.08 0 11-6.161.002 3.08 3.08 0 016.161-.002"
        />
      </g>
    </svg>
  </div>
);

export default Loader;
