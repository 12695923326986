import { useCallback } from 'react';
import { type Action, type Dispatch } from '@reduxjs/toolkit';
import { User } from 'types/user';
import { actionCreators } from './slice';

type UseUserStoreActions = {
  setUser: (user: User) => void;
  setAnonymousUser: () => void;
};

export const useUserStoreActions = (dispatch: Dispatch<Action>): UseUserStoreActions => {
  const setUser = useCallback(
    (user: User) => {
      dispatch(actionCreators.setUser(user));
    },
    [dispatch]
  );

  const setAnonymousUser = useCallback(() => {
    dispatch(actionCreators.setAnonymousUser());
  }, [dispatch]);

  return {
    setUser,
    setAnonymousUser,
  };
};
