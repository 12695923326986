import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PromiseResultShape, usePromise } from 'react-use-promise-matcher';
import { ChallengeOnServer } from 'types/challenge';
import { getChallengeNodeById } from 'ui/store/code/helpers';
import { renderToastPopup } from 'util/toast';
import useApi from 'ui/components/hooks/useApi';
import { challengeLanguageFallback } from 'util/langUtil';
import { createCodeStatePropSelector } from 'ui/store/code/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useCodeStoreActions } from 'ui/store/code/useCodeStoreActions';

const codePropSelector = createCodeStatePropSelector('challenge', 'challengeNodes');

const useServerChallenge = (idFromUrl: string | undefined): PromiseResultShape<ChallengeOnServer | null, string> => {
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();
  const { challenge, challengeNodes } = useSelector(codePropSelector);
  const dispatch = useDispatch();
  const { setChallenge } = useCodeStoreActions(dispatch);
  const { getChallenge } = useApi();

  const fetchChallenge = useCallback(() => {
    return idFromUrl
      ? getChallenge(idFromUrl)
          .then((response) => response.data)
          .then(challengeLanguageFallback(lang))
      : Promise.resolve(null);
  }, [idFromUrl, lang, getChallenge]);

  const getChallengeData = useCallback(() => {
    if (idFromUrl === challenge?.id) {
      return Promise.resolve(null);
    }

    const storedChallenge = getChallengeNodeById(challengeNodes, idFromUrl);
    if (storedChallenge) {
      return Promise.resolve(storedChallenge.data);
    }

    return fetchChallenge();
  }, [idFromUrl, fetchChallenge, challengeNodes, challenge]);

  const [result, load] = usePromise(getChallengeData);

  useEffect(() => {
    load();
  }, [load, idFromUrl, challengeNodes]);

  useEffect(() => {
    result.match({
      Loading: () => null,
      Resolved: (challenge) => {
        if (challenge === null) return;

        setChallenge(challenge);
      },
      Rejected: (err) => {
        renderToastPopup('error', t(`toast:cannotLoadChallenge`));
        console.error(err);
      },
    });
  }, [result]);

  return result;
};

export default useServerChallenge;
