export enum ButtonFlavor {
  Default = 'default',
  Gray = 'gray',
  Danger = 'danger',
  Success = 'success',
  SuccessBordered = 'success-bordered',
  Active = 'active',
  Current = 'current',
  FB = 'fb',
  Twitteer = 'twitter',
}

export enum ButtonSize {
  Default = 'default',
  Small = 'small',
}
