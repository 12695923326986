import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { InterpretError } from 'interpret/error/InterpretError';
import { InstrsWithStats } from 'interpret/InterpretResultWithStats';
import { Turtle } from 'turtle/Turtle';
import { type ActionType } from 'typesafe-actions';
import { INITIAL_POSITION } from 'ui/constants';
import { ByPos } from 'util/ByPos';
import { Position } from 'util/Position';

export interface EditorState {
  currentExp?: string;
  interpretResult: InstrsWithStats | InterpretError;
  position: Position;
  turtleByPos: ByPos<Turtle>;
  valueByPos: ByPos<any>;
  selectedTurtle?: Turtle;
  redoDisabled: boolean;
  undoDisabled: boolean;
}

export const initialEditorState: EditorState = {
  interpretResult: new InstrsWithStats([], 0),
  position: new Position(INITIAL_POSITION[0], INITIAL_POSITION[1]),
  turtleByPos: new ByPos(),
  valueByPos: new ByPos(),
  redoDisabled: true,
  undoDisabled: true,
};

const createByPosValues = (): Pick<EditorState, 'valueByPos' | 'turtleByPos'> => ({
  valueByPos: new ByPos() as ByPos<Turtle>,
  turtleByPos: new ByPos() as ByPos<Turtle>,
});

const editorSlice = createSlice({
  name: 'editor',
  initialState: initialEditorState,
  reducers: {
    setUndoRedo: (state, action: PayloadAction<SetUndoRedoPayload>) => {
      state.redoDisabled = action.payload.redoDisabled;
      state.undoDisabled = action.payload.undoDisabled;
    },
    setByPos: (state, action: PayloadAction<SetByPosPayload>) => {
      state.valueByPos = action.payload.valueByPos;
      state.turtleByPos = action.payload.turtleByPos;
    },
    setInstr: (state, action: PayloadAction<InstrsWithStats>) => {
      const byPosValues = createByPosValues();
      state.interpretResult = action.payload;
      state.valueByPos = byPosValues.valueByPos;
      state.turtleByPos = byPosValues.turtleByPos;
    },
    setError: (state, action: PayloadAction<InterpretError>) => {
      const byPosValues = createByPosValues();
      state.interpretResult = action.payload;
      state.valueByPos = byPosValues.valueByPos;
      state.turtleByPos = byPosValues.turtleByPos;
    },
    setPos: (state, action: PayloadAction<SetPosPayload>) => {
      state.currentExp = action.payload.currentExp;
      state.position = action.payload.position;
    },
    setSelectedTurtle: (state, action: PayloadAction<Turtle>) => {
      state.selectedTurtle = action.payload;
    },
  },
});

export type SetByPosPayload = Pick<EditorState, 'turtleByPos' | 'valueByPos'>;

export type SetInstrOrErrorPayload = Pick<EditorState, 'interpretResult' | 'turtleByPos' | 'valueByPos'>;

export type SetPosPayload = Pick<EditorState, 'position' | 'currentExp'>;

export type SetUndoRedoPayload = Pick<EditorState, 'redoDisabled' | 'undoDisabled'>;

export const editorActions = editorSlice.actions;

export type EditorAction = ActionType<typeof editorActions>;

export default editorSlice.reducer;
