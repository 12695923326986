import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { START_CONTEXT_ARTICLE, START_REFERENCE_ARTICLE } from 'ui/constants';

export interface HelpState {
  contextHelp: string;
  reference: string;
}

export const initialHelpState: HelpState = {
  contextHelp: START_CONTEXT_ARTICLE,
  reference: START_REFERENCE_ARTICLE,
};

export const helpSlice = createSlice({
  name: 'help',
  initialState: initialHelpState,
  reducers: {
    setContextHelp: (state: HelpState, action: PayloadAction<string>) => {
      state.contextHelp = action.payload;
    },
    setReference: (state: HelpState, action: PayloadAction<string>) => {
      state.reference = action.payload;
    },
  },
});

export const actionCreators = helpSlice.actions;

export default helpSlice.reducer;
