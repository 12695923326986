import React from 'react';

// tslint:disable-next-line:export-name
export function hrefFromMouseEvent(e: React.MouseEvent<HTMLElement, MouseEvent>): string | undefined {
  function getAnchor(el: HTMLElement): HTMLAnchorElement | undefined {
    if (el instanceof HTMLAnchorElement) return el;
    const p = el.parentElement;
    if (p) return getAnchor(p);
    else return undefined;
  }

  const a = e.target instanceof HTMLElement ? getAnchor(e.target) : undefined;

  if (a) {
    const href = a.getAttribute('href');
    if (href) {
      return href;
    }
  }

  return undefined;
}
