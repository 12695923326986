import React from 'react';
import Context from './Context';
import actions from './actions';
import type { StepProps, StepConfig } from './types';

type UseStepProps = (step: StepConfig | null) => StepProps | null;

const useStepProps: UseStepProps = (step) => {
  const { state, dispatch } = React.useContext(Context);
  const { requestedStepIndex, steps } = state;

  const stepProps = React.useMemo<StepProps | null>(() => {
    if (!step) return null;

    const index = steps.findIndex((item) => item === step);

    return {
      step,
      index,
      steps,
      actions: actions(dispatch),
    };
  }, [requestedStepIndex, step, steps, dispatch]);

  return stepProps;
};

export default useStepProps;
