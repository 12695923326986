import React from 'react';
import FillEditZone from './FillEditZone';
import PenEditZone from './PenEditZone';
import styles from './EditZone.module.scss';
import { Color } from 'turtle/Color';

interface Props {
  type: 'fill' | 'pen';
  zoneNode: HTMLElement;
  // Current color value in the editor
  currentColor?: Color;
  // A function that applies newly selected custom color to the Shelly's code
  applyToCodeHandler: (color: Color) => void;
  // A function from AttrEditZone, that adds event listners to the zone's buttons after each render
  callbackSetupFn: (node: HTMLElement) => void;
}

const EditZone: React.FC<Props> = ({ type, zoneNode, currentColor, applyToCodeHandler, callbackSetupFn }) => (
  <div className={styles.container}>
    {type === 'fill' ? (
      <FillEditZone
        applyToCodeHandler={applyToCodeHandler}
        callbackSetupFn={callbackSetupFn}
        zoneNode={zoneNode}
        currentColor={currentColor}
      />
    ) : (
      <PenEditZone
        applyToCodeHandler={applyToCodeHandler}
        callbackSetupFn={callbackSetupFn}
        zoneNode={zoneNode}
        currentColor={currentColor}
      />
    )}
  </div>
);

export default EditZone;
