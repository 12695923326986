import { Instr } from './InterpretResult';

export class InterpretResultWithStats {
  readonly instructions: Instr[];
  readonly value: any;
  readonly astSize: number;

  constructor(instructions: Instr[], value: unknown, astSize: number) {
    this.instructions = instructions;
    this.value = value;
    this.astSize = astSize;
  }
}

export class InstrsWithStats {
  readonly instructions: Instr[];
  readonly astSize: number;

  constructor(instructions: Instr[], astSize: number) {
    this.instructions = instructions;
    this.astSize = astSize;
  }
}
