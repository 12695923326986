import React from 'react';
import useUpdateInset from './useUpdateInset';
import useUpdateStep from './useUpdateStep';

const TourCore: React.FC = () => {
  useUpdateStep();
  useUpdateInset();

  return null;
};

export default TourCore;
