import { IconComponent } from 'types/icon';

const ChevronLeft: IconComponent = (props) => (
  <svg viewBox="0 0 9 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 6.274c0 .256.12.496.32.642l7.5 5.49a.725.725 0 00.777.053.79.79 0 00.403-.696V.783a.789.789 0 00-.403-.695.725.725 0 00-.777.053l-7.5 5.49a.794.794 0 00-.32.642v.001z"
      stroke="none"
    />
  </svg>
);

export default ChevronLeft;
