import { useCallback } from 'react';
import { type Action, type Dispatch } from '@reduxjs/toolkit';
import { Vector2d } from 'konva/lib/types';
import { ZoomAndPos } from './types';
import { actionCreators } from './slice';

type UseCanvasStoreActions = {
  setCanvasZoomAndPos: (payload: ZoomAndPos) => void;
  setLastTurtlePos: (payload: Vector2d) => void;
  setShowTurtle: (payload: boolean) => void;
  setDownloadImage: (payload: boolean) => void;
  setDataUrlImageGetter: (payload: (() => string) | null) => void;
  resetCanvas: () => void;
};

export const useCanvasStoreActions = (dispatch: Dispatch<Action>): UseCanvasStoreActions => {
  const setCanvasZoomAndPos = useCallback(
    (payload: ZoomAndPos) => {
      dispatch(actionCreators.setCanvasZoomAndPos(payload));
    },
    [dispatch]
  );

  const setLastTurtlePos = useCallback(
    (payload: Vector2d) => {
      dispatch(actionCreators.setLastTurtlePos(payload));
    },
    [dispatch]
  );

  const setShowTurtle = useCallback(
    (payload: boolean) => {
      dispatch(actionCreators.setShowTurtle(payload));
    },
    [dispatch]
  );

  const setDownloadImage = useCallback(
    (payload: boolean) => {
      dispatch(actionCreators.setDownloadImage(payload));
    },
    [dispatch]
  );

  const setDataUrlImageGetter = useCallback(
    (payload: (() => string) | null) => {
      dispatch(actionCreators.setDataUrlImageGetter(payload));
    },
    [dispatch]
  );

  const resetCanvas = useCallback(() => {
    setCanvasZoomAndPos({ zoom: 1, pos: { x: 0, y: 0 }, applied: false });
  }, [setCanvasZoomAndPos]);

  return {
    setCanvasZoomAndPos,
    setLastTurtlePos,
    setShowTurtle,
    setDownloadImage,
    setDataUrlImageGetter,
    resetCanvas,
  };
};
