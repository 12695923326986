export class InterpretResult {
  static VALUE(v: unknown): InterpretResult {
    return new InterpretResult([], v);
  }

  instructions: Instr[] = [];
  value: any;

  constructor(instructions: Instr[], value: unknown) {
    this.instructions = instructions;
    this.value = value;
  }

  merge(other: InterpretResult, instr: Instr | undefined = undefined): InterpretResult {
    let mergeValue;
    if (other.value === undefined) mergeValue = this.value;
    else mergeValue = other.value;
    return new InterpretResult(
      [...this.instructions, ...other.instructions, ...(instr === undefined ? [] : [instr])],
      mergeValue
    );
  }

  prepend(instr: Instr[]): InterpretResult {
    if (instr.length > 0) {
      return new InterpretResult([...instr, ...this.instructions], this.value);
    } else return this;
  }

  append(instr: Instr[]): InterpretResult {
    if (instr.length > 0) {
      return new InterpretResult([...this.instructions, ...instr], this.value);
    } else return this;
  }

  withValue(value: unknown): InterpretResult {
    return new InterpretResult(this.instructions, value);
  }
}

export interface Instr {
  name: string;
  args: any[];
}

export class NoValue {
  static INSTANCE: NoValue = new NoValue();
  static AS_STRING = 'no value';
}
