import classnames from 'classnames';
import React, { useState, useEffect } from 'react';
import styles from './EditorMessages.module.scss';
import { InterpretError } from 'interpret/error/InterpretError';
import EditorError from './EditorError';
import { NoValue } from 'interpret/InterpretResult';
import { useTranslation } from 'react-i18next';
import { getTestSelector } from 'util/testUtil';
import { useSelector } from 'react-redux';
import { interpretResultSelector, positionSelector, valueByPosSelector } from 'ui/store/editor/selectors';

const EditorMessages: React.FC = () => {
  const interpretResult = useSelector(interpretResultSelector);
  const valueByPos = useSelector(valueByPosSelector);
  const position = useSelector(positionSelector);

  const [text, setText] = useState<string>('');
  const [isValue, setIsValue] = useState<boolean>(false);
  const vs = valueByPos.findInLine(position).filter((v) => v !== NoValue.AS_STRING);
  const { t } = useTranslation('errors');

  useEffect(() => {
    if (vs.length > 0) {
      const prefix = t(vs.length > 2 ? 'values' : 'value');
      setText(prefix + showValues(vs));
      setIsValue(true);
    }
    return () => {
      setIsValue(false);
    };
  }, [JSON.stringify(vs), setText, setIsValue]);

  const isError: boolean = interpretResult instanceof InterpretError;

  return (
    <div
      className={classnames(styles.container, { [styles.error]: isError, [styles.success]: isValue || !isError })}
      data-testid={getTestSelector('editor.message')}
    >
      {!isError && !isValue && <>OK</>}
      {isError && <EditorError error={interpretResult as InterpretError} />}
      {isValue && <>OK, {text}</>}
    </div>
  );
};

export default EditorMessages;

function showValues(vs: any[]): string {
  const arrToStr = (a: any[]) => a.map((v) => v.toString()).join(',');
  if (vs.length == 1) {
    return vs[0].toString();
  }
  if (vs.length <= 5) {
    return arrToStr(vs);
  }
  // more than 5: returning 'v0,v1,v2,...,vn-2,vn-1'
  const head = arrToStr(vs.slice(0, 3));
  const tail = arrToStr(vs.slice(vs.length - 2, vs.length));
  return head + ',...,' + tail;
}
