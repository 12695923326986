import Konva from 'konva';
import { CANVAS_CONFIG } from 'ui/constants';
import { Paint } from 'paint/Paint';
import { clamp } from 'util/numberUtil';
import Vector2d = Konva.Vector2d;

export function setupMouseWheelZoom(paint: Paint): void {
  const { stage } = paint;
  addMouseWheelListener();

  const zoomToOrigin = (newZoom: number, clientOriginOffset: Vector2d): void => {
    const oldZoom = paint.getCurrentZoom();
    const oldPosition = paint.getCurrentPosition();

    if (Paint.isEqualZoom(oldZoom, newZoom)) return;

    const oldOriginOffsetUnzoomed = {
      x: clientOriginOffset.x / oldZoom,
      y: clientOriginOffset.y / oldZoom,
    };
    const newOriginOffsetUnzoomed = {
      x: clientOriginOffset.x / newZoom,
      y: clientOriginOffset.y / newZoom,
    };

    const newPosition = {
      x: oldPosition.x - (newOriginOffsetUnzoomed.x - oldOriginOffsetUnzoomed.x),
      y: oldPosition.y - (newOriginOffsetUnzoomed.y - oldOriginOffsetUnzoomed.y),
    };

    paint.setZoomAndPosition(newZoom, newPosition);
  };

  function addMouseWheelListener() {
    stage.on('wheel', (e) => {
      const { canvasSize } = paint;

      const oldZoom = paint.getCurrentZoom();
      const pointerPosition = paint.stage.getPointerPosition() || { x: 0, y: 0 };
      const pointerPositionToCenter = {
        x: pointerPosition.x - canvasSize.clientWidth / 2,
        y: pointerPosition.y - canvasSize.clientHeight / 2,
      };

      const zoomStep = CANVAS_CONFIG.ZOOM_STEP_WHEEL;
      const newZoom = e.evt.deltaY < 0 ? oldZoom * zoomStep : oldZoom / zoomStep;
      const adjustedZoom = clamp(Paint.fixZoom(newZoom), CANVAS_CONFIG.ZOOM_MIN, CANVAS_CONFIG.ZOOM_MAX);
      zoomToOrigin(adjustedZoom, pointerPositionToCenter);
    });
  }
}
