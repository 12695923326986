import { Dispatch } from 'react';
import { Action, ActionType, TourState } from './Context';

export interface Actions {
  set: (payload: Partial<TourState>) => void;
  nextStep: () => void;
  previousStep: () => void;
}

export default (dispatch: Dispatch<Action>): Actions => ({
  set: (payload: Partial<TourState>): void => dispatch({ type: ActionType.Set, payload }),
  nextStep: () => dispatch({ type: ActionType.NextStep }),
  previousStep: (): void => dispatch({ type: ActionType.PreviousStep }),
});
