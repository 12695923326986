import React from 'react';
import { createRoot } from 'react-dom/client';
import { editorSetup } from './monaco/editorSetup';
import { terrapinSetup } from './monaco/terrapinSetup';
import axios from 'axios';
import App from 'ui/App';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import { AXIOS_BASE_URL } from 'ui/constants';

axios.defaults.baseURL = AXIOS_BASE_URL;
editorSetup();
terrapinSetup();

const container = document.getElementById('app') as HTMLElement;

createRoot(container).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

if (module.hot) {
  module.hot.accept();
}
